import React, { useState } from "react";
import placeHolder from "../../assets/images/CuroRX-logo-transparent.png";
import {addPharmacyLogo} from '../../services/Admin';
import { Pharmacy } from "../../utils/globalState";



const LogoPictureUploader = (props) => {
  const {id, logo_url} = props.pharmacy;
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [curUserInfo, setCurUserInfo] = useState({});
 const pharmacyInfo = Pharmacy.getState().info;


  // const phId = props.pharmacy.id;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
   

    if (file && !file.type.startsWith('image/')) {
      setErrorMessage('Please select an image file.');
    } else {
      setErrorMessage('');
    }
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
   
    // Send the formData to the server using your preferred method (e.g., fetch or Axios).
    addPharmacyLogo(id, formData)
      .then((response) => {
       
        setCurUserInfo({
          ...curUserInfo,
          logo_url: `${response.data.url}?v=${Date.now()}`,
        });

            Pharmacy.setState({
              info: { ...pharmacyInfo,
              logo_url: response.data.url },
            });
     
        setIsUpload(false);
        // window.location.reload(false);
      })
      .catch((err) => {
        console.error('Error uploading file', err);
      });
  };


  // eslint-disable-next-line no-unused-vars
  const renderPreviewImage = () => {
    if (selectedFile) {
      return URL.createObjectURL(selectedFile);
    } else if (curUserInfo.logo_url) {
      return `${curUserInfo.logo_url}?v=${Date.now()}`;
    } else {
      let logo = logo_url || placeHolder; ;
      
      return logo;
    }
  };

  return (
    <>
      
        <>
          <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <div className="container-fluid text-center">
                {isUpload ? (
                  <div className="col-8 mx-auto">
                    <div className="row">
                      <img
                        src={renderPreviewImage()}
                        alt="profile pic placeholder"
                        className="mb-2 col-12 mx-auto"
                        style={{ width: '60vh' }}
                      />
                    </div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="btn col-lg-8 btn-outline-success me-3"
                    />
                    {errorMessage && (
                      <p className="text-danger fs-6">{errorMessage}</p>
                    )}
                    <button
                      type="button"
                      onClick={() => handleUpload()}
                      disabled={!selectedFile || errorMessage}
                      className="btn btn-success col-lg-2 me-1"
                    >
                      upload
                    </button>
                    <button
                      className="btn btn-danger col-lg-1 "
                      onClick={() => {
                        setIsUpload(false);
                        setSelectedFile(null);
                      }}
                    >
                      X
                    </button>
                  </div>
                ) : (
                  <>
                    <img
                      src={renderPreviewImage()}
                      alt="profile pic placeholder"
                      className="w-25 mb-2"
                    />
                    <br />
                    <button
                      className="btn btn-success"
                      onClick={() => setIsUpload(true)}
                    >
                      Change Pharmacy Logo
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
   
    </>
  );
};

export default LogoPictureUploader;
