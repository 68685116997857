import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const StaffCompletedVSPendingChart = (props) => {
  const { name1, name2, data1, data2, title, color1, color2, type, total } =
    props;

  const intialData = {
    series: [
      {
        name: name1.text || "data",
        data: data1,
      },
      {
        name: name2.text || "data",
        data: data2,
      },
    ],
    options: {
      colors: [color1, color2],
      chart: {
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: title || "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        type: "date",
      },
      legend: {
        position: "left",
        horizontalAlign: "right",
      },
      fill: {
        opacity: 1,
      },
      animation: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
  };

  const [data, setData] = useState(intialData);

  useEffect(() => {
    const namesArr = [name1, name2];

    setData({
      series: [
        {
          name: name1.text || "data",
          data: data1,
        },
        {
          name: name2.text || "data",
          data: data2,
        },
      ],
      options: {
        colors: [color1, color2],
        chart: {
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        title: {
          text: title || "",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "date",
        },
        legend: {
          position: "left",
          horizontalAlign: "right",
          formatter: function (seriesName, opts) {
            return [
              seriesName + ` (${namesArr[opts.seriesIndex].count})`,
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex],
            ];
          },
        },
        fill: {
          opacity: 1,
        },
        animation: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    });
  }, [name1, name2, data1, data2, title, color1, color2]);

  return (
    <>
      <h4
        style={{
          fontSize: "0.9rem",
          padding: 0,
          margin: "0.5rem 0 0 1.5rem",
          fontWeight: "bold",
        }}>
        {total}
      </h4>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type={type || "bar"}
        height={350}
      />
    </>
  );
};

export default StaffCompletedVSPendingChart;
