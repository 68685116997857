import React, { useState } from "react";
import Countdown from "react-countdown";

const OTPTimer = (props) => {
  const [restartValue, setRestartValue] = useState(Date.now() + 120000);

  const Completionist = () => {
    return (
      <span>
        00:00{" "}
        <br />
        <button
          onClick={(e) => {
            e.preventDefault();
            props.sendOTP();
            setRestartValue(Date.now() + 120000);
          }}
          className="btn btn-sm pl-3 ml-2 text-danger"

        >
          Resend OTP
        </button>
      </span>
    );
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {

      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className="otptimer p-3">
      <p style={{ fontSize: "0.9rem", color: "gray" }}>
        OTP will expire in{" "}
        <span style={{ color: "rgb(255, 0, 0, .5)" }}>
          <Countdown
            date={Date.now() + 120000}
            renderer={renderer}
            onComplete={() => {
              localStorage.setItem("otp", "");
            }}
            key={restartValue}
          />
        </span>
      </p>
    </div>
  );
};

export default OTPTimer;
