import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

// ################## Care Plans Start ##################
export const handleGetCarePlans = async (pharmacyId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/cp/pharmacy/${pharmacyId}`);
};

export const handleGetCarePlanById = async (cpid) => {
  return axiosWithAuth().get(`${baseURL.curoback}/cp/${cpid}`);
};

export const handleAddCarePlan = async (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/cp`, payload);
};

export const handleUpdateCarePlan = async (cpid, payload) => {
  return axiosWithAuth().put(`${baseURL.curoback}/cp/${cpid}`, payload);
};

export const handleDeleteCarePlan = async (cpid) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/cp/${cpid}`);
};

export const updateCpOptOutByPatientId = async (patientId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/patient/cpoptout/${patientId}`,
    payload
  );
};
// ################## End Care Plans ####################

// ################## Care Plans Forms CRUD Start ##################
export const handleAddCarePlanForm = async (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/cpforms`, payload);
};

export const handleGetCpFormsByPharmacyId = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/cpforms/pharmacy/${pharmacyId}`
  );
};

export const handleGetInitForms = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/cpforms/pharmacy/init/${pharmacyId}`
  );
};

export const handleGetFormById = async (formId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/cpforms/${formId}`);
};

export const handleGetFuForms = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/cpforms/pharmacy/fu/${pharmacyId}`
  );
};

export const handleUpdateCarePlanForm = async (formId, payload) => {
  return axiosWithAuth().put(`${baseURL.curoback}/cpforms/${formId}`, payload);
};

export const handleDeleteCarePlanForm = async (formId) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/cpforms/${formId}`);
};

// ################## Care Plans Responses CRUD Start ##################
export const handleGetCpResponsesByCpId = async (cpId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/cpresponses/cp/${cpId}`);
};

export const handleGetResponseById = async (responseId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/cpResponses/${responseId}`);
};

export const handleAddResponse = async (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/cpResponses`, payload);
};

export const handleUpdateResponse = async (responseId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/cpResponses/${responseId}`,
    payload
  );
};
// ################## Care Plans Responses CRUD End ##################

// ################## End Care Plans Forms ####################
