import React, { useState, useEffect } from "react";
import { SideBar, SelectedSurvey } from "../../../utils/globalState";
import {
  getAllRes,
  handleGetptbyid,
  getSurveyById,
} from "../../../services/PharmacyUser";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Model } from "survey-core";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Alert from "@mui/material/Alert";

const SurveyAnalyticsTabulator = require("survey-analytics/survey.analytics.tabulator");

const Results = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const navigate = useNavigate();

  // Get the survey from global state.
  const incomingSurvey = SelectedSurvey.getState().ThisSurvey;
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const [survey, setSurvey] = useState(incomingSurvey);
  const [allQuestionsConst, setAllQuestions] = useState([]);
  const [surveyResults, setSurveyResults] = useState([]);
  const [resLoadingFlag, setResLoadingFlag] = useState(true);
  const [msg, setMsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [columnDef, setColumnDef] = useState([]);
const [fromDate, setFromDate] = useState(null);
const [toDate, setToDate] = useState(null);
const [restultsHolder, setRestultsHolder] = useState([]);
console.log(fromDate, toDate)

  const getResponsesBySurvid = (surveyId) => {
    setResLoadingFlag(true);
    getAllRes()
      .then((res) => {
        console.log("allResp api call", res.data);
        if (res.data.All_Patients_Response.length > 0) {
          Promise.all(
            res.data.All_Patients_Response.map((i) => {
              try {
                JSON.parse(i.survey_response);
                i.survey_response = JSON.parse(i.survey_response);
              } catch (error) {
                i.survey_response = {};
              }

              return i;
            })
              .filter(
                (item) =>
                  Number(item.survey_response.surveyId) === Number(survey.id)
              )
              .map((i) => {
                i.survey_response.response.id = i.id;
                i.survey_response.response.created_at = dayjs(
                  i.created_at
                ).format("MM/DD/YYYY hh:mm a");
                i.survey_response.response.source = i.survey_response.source
                  ? i.survey_response.source
                  : "SMS";
                return handleGetptbyid(i.pt_id)
                  .then((res) => {
                    i.survey_response.response.firstName =
                      res?.data?.patient?.firstName || "";
                    i.survey_response.response.lastName =
                      res?.data?.patient?.lastName || "";
                    i.survey_response.response.MRN =
                      res?.data?.patient?.MRN || "";
                    i.survey_response.response.DOB =
                      res?.data?.patient?.DOB || "";
                    i.survey_response.response.providingPharmacy =
                      res?.data?.patient?.providing_pharmacy || "";
                    i.survey_response.response.phone =
                      res?.data?.patient?.phone || "";
                    return i.survey_response.response;
                  })
                  .catch((err) => {
                    i.survey_response.response.firstName = "";
                    i.survey_response.response.lastName = "";
                    i.survey_response.response.MRN = "";
                    i.survey_response.response.DOB = "";
                    i.survey_response.response.providingPharmacy = "";
                    i.survey_response.response.phone = "";
                    return i.survey_response.response;
                  });
              })
          ).then((res) => {
            const orderedRes = res.sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at);
            });
            setSurveyResults(orderedRes);
            setResLoadingFlag(false);
          });
        } else {
          setSurveyResults([]);
          setResLoadingFlag(false);
        }
      })
      .catch((err) => {
        setMsg(err.message);
      });
  };
console.log("surveyResults", surveyResults);
  useEffect(() => {
    getSurveyById(id).then((res) => {
      res.data[0].json = JSON.parse(res.data[0].survey);
      setSurvey(res.data[0]);
    });
  }, [id]);

  useEffect(() => {
    getResponsesBySurvid(survey.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  const handleQA = (answer, allQuestions) => {
    let newAnswer = {};
    Object.keys(answer).forEach((key) => {
      if (allQuestions.find((q) => q.name === key) !== undefined) {
        let match = allQuestions.find((q) => q.name === key);
        newAnswer[match.title] = answer[match.name];
      }
    });

    return newAnswer;
  };
  useEffect(() => {
    if (Object.keys(survey).length === 0) {
      return;
    }
    var options = {
      haveCommercialLicense: true, //Add this line
    };

    if (
      survey.json.pages[0].elements.some(
        (e) => e.name === "question6" && e.type === "html"
      )
    ) {
      const index = survey.json.pages[0].elements.indexOf(
        survey.json.pages[0].elements.find(
          (e) => e.name === "question6" && e.type === "html"
        )
      );
      survey.json.pages[0].elements.splice(index, 1);
    }
    survey.json.pages[0].elements.unshift(
      { name: "id", type: "text" },
      { name: "firstName", type: "text" },
      { name: "lastName", type: "text" },
      { name: "DOB", type: "text" },
      { name: "providingPharmacy", type: "text" },
      { name: "phone", type: "text" },
      { name: "created_at", type: "text" }
    );
    let model = new Model(survey.json);

    var surveyAnalyticsTabulator = new SurveyAnalyticsTabulator.Tabulator(
      model,
      surveyResults,
      options
    );
    let allQuestions = model.getPlainData();
    let allAnswers = surveyAnalyticsTabulator.tableData;
    setAllQuestions(allQuestions);

    setTableData(
      allAnswers.map((a) => {
        return handleQA(a, allQuestions);
      })
    );
  }, [surveyResults, survey]);

  const colArray = [];

  useEffect(() => {
    if (allQuestionsConst.length > 0) {
      allQuestionsConst.forEach((q) => {
        if (q.type !== "html") {
          let newCol;
          if (q.title === "created_at") {
            newCol = {
              field: q.title,
              headerName: "Completed On",
              minWidth: 200,
              maxWidth: 200,
              sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
              },
            };
          } else {
            newCol = {
              field: q.title,
              headerName: q.title,
              minWidth: 200,
              maxWidth: 200,
            };
          }
          if (colArray.some((e) => e.field === newCol.field) === false) {
            colArray.push(newCol);
          }
        }
      });
      setColumnDef([...colArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestionsConst]);

  const handleFilter = (e) => {
   e.preventDefault();
   setFromDate(null);
   setToDate(null);
   if (restultsHolder.length > 0) {
    setSurveyResults(restultsHolder);
   }
  };

  useEffect(() => {
 if (fromDate !== null && toDate !== null) {
  if (new Date(fromDate) > new Date(toDate)) {
    setMsg("From Date cannot be greater than To Date");
    return;
  }
  setRestultsHolder(surveyResults);
setSurveyResults(
  surveyResults.filter(
    (item) =>
      new Date(item.created_at) >= new Date(fromDate) &&
      new Date(item.created_at) <= new Date(toDate)
  )
) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}
              >
                Surveys Management Board
              </li>{" "}
              <li className="breadcrumb-item active">Results</li>
            </ol>
          </nav>
        </div>
        {msg !== "" && <Alert severity="error" className="m-1">{msg}</Alert>}
        {/* <!-- End Page Title --> */}
        {/* && colArray.length > 0  */}
        {resLoadingFlag && colArray.length > 0 ? (
          <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="ms-4 align-middle text-secondary">
              Loading Survey Data Please wait...
            </div>
          </div>
        ) : (
          <section className="section profile">
            <div className="row">
              <div className="col-12 m-1">
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From date"
                    value={fromDate}
                    onChange={setFromDate}
                  />
                  <span className="mx-1 text-secondary font-weight-bold align-middle">
                    {" "}
                    -{" "}
                  </span>
                  <DatePicker
                    label="To date"
                    value={toDate}
                    onChange={setToDate}
                  />
                </LocalizationProvider>
                <button className="btn btn-sm btn-primary mx-1 " onClick={handleFilter}>
                  Clear Filter
                </button>
              </div>
              <div className="col-12" style={{ height: 700, width: "100%" }}>
                <DataGrid
                  loading={tableData.length === 0 && resLoadingFlag}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  pageSize={25}
                  rows={tableData}
                  getRowId={(row) => row.id}
                  columns={columnDef.filter((element, index) => {
                    return columnDef.indexOf(element) === index;
                  })}
                  density="compact"
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default Results;
