import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import {
  updatePharmacyAnnouncement,
  getPharmacyAnnouncement,
} from "../../services/PharmacyAdmin";

const PharmacyAnnouncements = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");
  const [pharmacyAnnc, setPharmacyAnnc] = useState("");
  const [providerAnnc, setProviderAnnc] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [prSuccessMessage, setPrSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPhDisabled, setIsPhDisabled] = useState(true);
  const [isPrDisabled, setIsPrDisabled] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      getPharmacyAnnouncement(pharmacy_id)
        .then((res) => {
          setPharmacyAnnc(res.data.pharmacy_announcement);
          setProviderAnnc(res.data.provider_announcement);
        })
        .catch((err) => {
          console.error(err);
        });

      setIsLoading(false);
    };

    fetchData();
  }, [pharmacy_id]);

  const handleSubmitPharmacy = async (event) => {
    event.preventDefault();
    updatePharmacyAnnouncement(pharmacy_id, {
      pharmacy_announcement: pharmacyAnnc,
    })
      .then((res) => {
        setSuccessMessage(res.data.message);
        setIsPhDisabled(true);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  const handleSubmitProvider = async (event) => {
    event.preventDefault();
    updatePharmacyAnnouncement(pharmacy_id, {
      provider_announcement: providerAnnc,
    })
      .then((res) => {
        setPrSuccessMessage(res.data.message);
        setIsPrDisabled(true);
        setTimeout(() => {
          setPrSuccessMessage("");
        }, 2500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Announcements</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">Manage Announcements</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            <div className="card" style={{ minHeight: "75vh" }}>
              {isLoading ? (
                <div className="m-5">Loading...</div>
              ) : (
                <div className="row">
                  <div className=" col-10  mx-auto my-0">
                    <form
                      onSubmit={handleSubmitPharmacy}
                      className="d-flex flex-column align-items-center my-3">
                      <div className="form-group d-flex flex-row flex-wrap col-11 ">
                        <label className="card-title fs-5 mx-auto text-center">
                          Current Pharmacy Announcement
                        </label>
                        <textarea
                          type="text"
                          value={pharmacyAnnc}
                          onChange={(e) => {
                            setPharmacyAnnc(e.target.value);
                            setIsPhDisabled(false);
                          }}
                          className="form-control rounded mx-auto my-1"
                          rows={6}
                        />
                      </div>

                      {successMessage ? (
                        <p className="text-success">{successMessage}</p>
                      ) : (
                        <small className="d-block mb-2 mx-2 text-center">
                          type a new announcement and click on the update button
                          to publish it
                        </small>
                      )}
                      <button
                        type="submit"
                        className="btn btn-success col-auto"
                        disabled={isPhDisabled}>
                        update
                      </button>
                    </form>
                  </div>
                  <hr className="w-75 mx-auto mt-4" />
                  <div className=" col-10  mx-auto my-0">
                    <form
                      onSubmit={handleSubmitProvider}
                      className="d-flex flex-column align-items-center my-3">
                      <div className="form-group d-flex flex-row flex-wrap col-11 ">
                        <label className="card-title fs-5 mx-auto text-center">
                          Current Announcement for Providers
                        </label>
                        <textarea
                          type="text"
                          value={providerAnnc}
                          onChange={(e) => {
                            setProviderAnnc(e.target.value);
                            setIsPrDisabled(false);
                          }}
                          className="form-control rounded mx-auto my-1"
                          rows={6}
                        />
                      </div>

                      {prSuccessMessage ? (
                        <p className="text-success">{prSuccessMessage}</p>
                      ) : (
                        <small className="d-block mb-2 mx-2 text-center">
                          type a new announcement and click on the update button
                          to publish it
                        </small>
                      )}
                      <button
                        type="submit"
                        className="btn btn-success col-auto"
                        disabled={isPrDisabled}>
                        update
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PharmacyAnnouncements;
