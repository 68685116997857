import React, { useState } from "react";
import {
  DialogContent,
  DialogActions,
  Box,
  Slider,
  Button,
  Typography,
} from "@mui/material";
import { Cancel, Crop } from "@mui/icons-material";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

const CropEasy = (props) => {
  const { photoURL, setOpenCrop, setSelectedFile, setPhotoURL, fileName } =
    props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`;
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation,
        fileName
      );
      setPhotoURL(url);
      setSelectedFile(file);
      setOpenCrop(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <DialogContent
        dividers
        sx={{
          backgroundColor: "#333",
          position: "relative",
          height: 400,
          width: "auto",
          minWidth: { sm: 500 },
        }}>
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Typography>Zoom: {zoomPercent(zoom)} </Typography>
          <Slider
            valueLabelDisplay="off"
            valueLabelFormat={zoomPercent}
            min={1}
            max={3}
            step={0.03}
            value={zoom}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Box>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Typography>Rotation: {rotation} </Typography>
          <Slider
            valueLabelDisplay="off"
            min={-180}
            max={180}
            value={rotation}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}>
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => {
              setOpenCrop(false);
              setSelectedFile(null);
            }}>
            Cancel
          </Button>
          <Button variant="contained" startIcon={<Crop />} onClick={cropImage}>
            Crop
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropEasy;
