import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import placeholder from "../../../assets/images/patient_placeholder.png";

const RenderCellImage = (params) => {
  const apiRef = useGridApiContext();
  const gridDensity = apiRef.current.state.density.value;

  let imageSize;
  switch (gridDensity) {
    case "comfortable":
      imageSize = { width: "60px", height: "60px" };
      break;
    case "standard":
      imageSize = { width: "45px", height: "45px" };
      break;
    default: // 'compact'
      imageSize = { width: "35px", height: "35px" };
  }

  return (
    <img
      src={params.row.pic_url ? params.row.pic_url : placeholder}
      alt="patient"
      className="img-fluid"
      style={{
        width: imageSize.width,
        height: imageSize.height,
        borderRadius: "50%",
      }}
    />
  );
};

export default RenderCellImage;
