import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import { getAllUsersByTypeAndPhId } from "../../services/Common";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { RRule } from "rrule";
import {
  handleGetOwners,
  handleAddOwner,
  handleDeleteOwner,
  handleGetEvents,
  handleUpdateOwner,
} from "../../services/Calendar";

const ManageCalendarOwners = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [alluserslist, setAllUsersList] = useState([]);
  const [users, setUsers] = useState([]);
  const [ownersData, setOwnersData] = useState([]);
  const [owners, setOwners] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [msg, setMsg] = useState({ text: "", color: "success" });
  const [checked, setChecked] = useState([]);
  const [currentColor, setCurrentColor] = useState("#000000");

  const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };

  const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  };

  const union = (a, b) => {
    return [...a, ...not(b, a)];
  };

  const leftChecked = intersection(checked, users);
  const rightChecked = intersection(checked, owners);

  function futureEventCalc(event) {
    const { RecurrenceRule, EndTime } = event;
    const now = new Date();

    if (!RecurrenceRule && new Date(EndTime) <= now) {
      return false;
    }

    if (!RecurrenceRule && new Date(EndTime) > now) {
      return true;
    }

    const sanitizedRule = RecurrenceRule.slice(0, RecurrenceRule.length - 1);

    const rule = RRule.fromString(sanitizedRule);

    const occurrences = rule.between(
      now,
      new Date(now.getFullYear() + 10, now.getMonth(), now.getDate())
    );

    return occurrences.some((occurrence) => occurrence > now);
  }

  const handleGetUsers = () => {
    getAllUsersByTypeAndPhId(pharmacy_id)
      .then((res) => {
        const allusers = [];

        setAllUsersList(
          res.data.data.filter(
            (user) =>
              user.type.toLowerCase() === "user" ||
              user.type.toLowerCase() === "pharmacyadmin"
          )
        );
        handleGetOwners(pharmacy_id)
          .then((ownrRes) => {
            setOwnersData(ownrRes.data);
            setOwners(
              ownrRes.data.map(
                (ownr) => `${ownr.OwnerId} - ${ownr.first} ${ownr.last}`
              )
            );
            res.data.data
              .filter(
                (user) =>
                  user.type.toLowerCase() === "user" ||
                  user.type.toLowerCase() === "pharmacyadmin"
              )
              .forEach((i) => {
                if (
                  ownrRes.data &&
                  !ownrRes.data.some((u) => u.OwnerId === i.id)
                ) {
                  allusers.push(i);
                }
                setUsers(allusers.map((i) => `${i.id} - ${i.first} ${i.last}`));
              });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        setUsers([]);
        setMsg({
          text: "Something went wrong, please try again later",
          color: "danger",
        });
        console.error(err);
      });
  };

  const handleGetfutureEvents = () => {
    handleGetEvents(pharmacy_id)
      .then((res) => {
        setFutureEvents(res.data.filter((event) => futureEventCalc(event)));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    handleGetUsers();
    handleGetfutureEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setMsg({ text: "", color: "success" });
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setMsg({ text: "", color: "success" });
    setUsers(not(users, leftChecked));
    setChecked(not(checked, leftChecked));

    checked.forEach((i) => {
      let user = alluserslist.find(
        (user) => user.id.toString() === i.split(" - ")[0].trim()
      );

      let data = {
        OwnerId: user.id,
        pharmacyId: Number(pharmacy_id),
        Color: getRandomColor(),
      };
      handleAddOwner(data)
        .then((res) => {
          setMsg({ text: "User Added Successfully", color: "success" });
          handleGetUsers();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleCheckedLeft = () => {
    setMsg({ text: "", color: "success" });

    const ownersEventObj = {};

    checked.forEach((i) => {
      let user = alluserslist.find(
        (user) => user.id.toString() === i.split(" - ")[0].trim()
      );

      const ownerId = user.id;

      const ownerEvents = futureEvents.filter(
        (event) => event.OwnerId === ownerId
      );

      ownersEventObj[ownerId] = ownerEvents;

      if (ownerEvents.length > 0) {
        alert(
          "One or more selected users have future events and can not be removed. Please remove events or assign them to another user first."
        );
        return;
      } else {
        handleDeleteOwner(ownerId)
          .then(() => {
            setMsg({ text: "User Removed Successfully", color: "success" });
            setUsers(users.concat(rightChecked));
            setChecked(not(checked, rightChecked));
            handleGetUsers();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  function getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const colorUpdateHandler = (value) => {
    const ownerId = parseInt(value);

    const payload = {
      Color: currentColor,
    };

    handleUpdateOwner(ownerId, payload)
      .then((res) => {
        setMsg({ text: "Color Updated Successfully", color: "success" });
        handleGetUsers();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, bgcolor: "#bdddf7" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 530,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
              {title !== "Available Users" && (
                <input
                  type="color"
                  name="Color"
                  id="owner_color"
                  defaultValue={
                    ownersData.find(
                      (el) =>
                        el.OwnerId === parseInt(value.split(" - ")[0].trim())
                    )?.Color
                  }
                  onChange={(e) => setCurrentColor(e.target.value)}
                  onBlur={() =>
                    colorUpdateHandler(value.split(" - ")[0].trim())
                  }
                />
              )}
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Careplan Owners Management</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">
                Careplan Owners Management
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid" style={{ minHeight: "85vh" }}>
              <div className="row">
                <div className="col-md-12">
                  {msg.text !== "" && (
                    <p className={`text-${msg.color}`}>{msg.text}</p>
                  )}
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">
                    <Grid item>{customList("Available Users", users)}</Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedRight}
                          disabled={leftChecked.length === 0}
                          aria-label="move selected right">
                          &gt;
                        </Button>
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedLeft}
                          disabled={rightChecked.length === 0}
                          aria-label="move selected left">
                          &lt;
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {customList(
                        "Current Careplan Owners",
                        owners.map((i) => i)
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageCalendarOwners;
