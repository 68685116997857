import React, { useState, useEffect } from "react";
import { updatePassword } from "../../services/Common";
import img1 from "../../assets/images/patient_placeholder.png";
import { User } from "../../utils/globalState";
import { decodeToken } from "react-jwt";
import { getProfile } from "../../services/PharmacyUser";
import { handleProfileUpdate } from "../../services/PharmacyUser";
import { SideBar } from "../../utils/globalState";
import "../../styles/page-styles/profile.css";

const Profile = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const [msg, setMsg] = useState("");
  const [curUserInfo, setCurUserInfo] = useState({});
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [form, setForm] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const userInformation = User.getState().info;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = userInformation.id || myDecodedToken.userId;

  useEffect(() => {
    getProuserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getProuserInfo = () => {
    getProfile(userId)
      .then((res) => {
        res.data.data.user[0].address =
          res.data.data.address.length > 0
            ? res.data.data.address
            : [
                {
                  street: "",
                  city: "",
                  state: "",
                  zip: "",
                },
              ];
        res?.data?.data?.user?.length > 0 &&
          setCurUserInfo(res.data.data.user[0]);
      })
      .catch((err) => {
        setMsg("fail");
        console.error("err", err);
      });
  };

  useEffect(() => {
    setForm(curUserInfo);
  }, [curUserInfo]);

  const discardClickHandler = () => {
    setForm(curUserInfo);
    setIsEdit(false);
    setMsg("");
  };

  const changeHandler = (e) => {
    if (["street", "city", "zip", "state"].some((el) => el === e.target.name)) {
      setForm({
        ...form,
        address: [
          { ...form.address[0], [e.target.name]: e.target.value.trim() },
        ],
        passwordUpdated: false,
      });
    } else {
      // setForm({ ...form, [e.target.name]: e.target.value });
      if (
        e.target.name === "password" &&
        e.target.value.trim() !== curUserInfo.password
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.trim(),
          passwordUpdated: true,
        });
      } else {
        setForm({
          ...form,
          [e.target.name]: e.target.value.trim(),
          passwordUpdated: false,
        });
      }
    }
    setIsDisabled(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setMsg("");
    const newForm = form;
    [
      "id",
      "contacts",
      "first",
      "last",
      "updatedAt",
      "createdAt",
      "userID",
      "active",
      "pharmacyID",
      "type",
    ].forEach((el) => delete newForm[el]);

    const { id, first, last, type } = curUserInfo;

    const updatedByObj = {
      id: id,
      first: first,
      last: last,
      type: type,
    };

    const updatedByString = JSON.stringify(updatedByObj);

    handleProfileUpdate(id, { ...newForm, updatedBy: updatedByString })
      .then((r) => {
        getProuserInfo();
        setMsg(r.data.data);
        setIsEdit(false);
        setIsDisabled(true);
      })
      .catch((err) => {
        console.error(err);
        setMsg(err.response.data.message);
      });
  };

  const passwordHandler = (e) => {
    e.target.value.length > 0 ? setIsDisabled(false) : setIsDisabled(true);
    e.target.name === "password"
      ? setPassword(e.target.value)
      : setPassword2(e.target.value);
  };

  const passwordSubmitHandler = (e) => {
    e.preventDefault();

    password === password2
      ? updatePassword(userId, { password })
          .then((res) => {
            setMsg(res.data.message + " successfully");
            setIsPasswordEdit(false);
          })
          .catch((err) => {
            setMsg(err.response.data.message);
          })
      : setMsg("Passwords Do Not Match");
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">profile</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {msg === "fail" ? (
                <>
                  <div className="alert alert-danger" role="alert">
                    Something Went Wrong. Please Refresh The Page And Try Again.
                    If You Keep Getting This Error Please Contact The Admin.
                  </div>
                </>
              ) : (
                <p
                  className="fs-5 ms-3"
                  style={{
                    color: msg.toLocaleLowerCase().includes("success")
                      ? "green"
                      : "red",
                  }}>
                  {msg}
                </p>
              )}
              <div className="wrapper">
                {curUserInfo.id ? (
                  <section className="section profile container-fluid py-1">
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="card bg-toggler">
                          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                            <img
                              src={
                                curUserInfo?.pic_url
                                  ? `${curUserInfo.pic_url}?v${Date.now()}`
                                  : img1
                              }
                              alt="Profile"
                              className="rounded-circle mb-4"
                              id="profile-img-big"
                            />
                            <h2 className="txt-toggler">
                              {curUserInfo.first} {curUserInfo.last}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8">
                        <div className="card bg-toggler">
                          <div className="card-body pt-3">
                            {/* Bordered Tabs */}
                            <ul className="nav nav-tabs nav-tabs-bordered">
                              <li className="nav-item">
                                <button
                                  className={
                                    !isEdit && !isPasswordEdit
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  data-bs-toggle="tab"
                                  data-bs-target="#profile-overview"
                                  onClick={() => {
                                    setIsEdit(false);
                                    setIsPasswordEdit(false);
                                    setMsg("");
                                    setIsDisabled(true);
                                    setPassword("");
                                    setPassword2("");
                                  }}>
                                  Overview
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={
                                    isEdit && !isPasswordEdit
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  data-bs-toggle="tab"
                                  data-bs-target="#profile-edit"
                                  onClick={() => {
                                    setIsEdit(true);
                                    setIsPasswordEdit(false);
                                    setMsg("");
                                    setIsDisabled(true);
                                    setPassword("");
                                    setPassword2("");
                                  }}>
                                  Edit Profile
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={
                                    isPasswordEdit
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  data-bs-toggle="tab"
                                  data-bs-target="#profile-edit"
                                  onClick={() => {
                                    setIsEdit(false);
                                    setIsPasswordEdit(true);
                                    setMsg("");
                                    setIsDisabled(true);
                                    setPassword("");
                                    setPassword2("");
                                  }}>
                                  Change Password
                                </button>
                              </li>
                            </ul>
                            <div className="tab-content pt-2 provider_info">
                              {!isEdit && !isPasswordEdit ? (
                                <div
                                  className="tab-pane fade show active profile-overview"
                                  id="profile-overview">
                                  <h4 className="card-title text-center my-3">
                                    Profile Details
                                  </h4>
                                  <div className="row">
                                    <p className="col-lg-5 col-md-4  label fw-semibold">
                                      Full Name
                                    </p>
                                    <p className="col-lg-7 col-md-8">
                                      {curUserInfo.first} {curUserInfo.last}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <p className="col-lg-5 col-md-4 label fw-semibold">
                                      Phone
                                    </p>
                                    <p className="col-lg-7 col-md-8">
                                      {curUserInfo.phone}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <p className="col-lg-5 col-md-4 label fw-semibold">
                                      Email
                                    </p>
                                    <p className="col-lg-7 col-md-8">
                                      {curUserInfo.email}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <p className="col-lg-5 col-md-4 label fw-semibold">
                                      Address
                                    </p>
                                    <p className="col-lg-7 col-md-8">
                                      {curUserInfo?.address?.length === 0 ? (
                                        <></>
                                      ) : (
                                        <>
                                          {`${
                                            curUserInfo.address[0].street
                                              ? curUserInfo.address[0].street +
                                                ", "
                                              : ""
                                          }
                                      ${
                                        curUserInfo.address[0].city
                                          ? curUserInfo.address[0].city + ", "
                                          : ""
                                      }
                                      ${
                                        curUserInfo.address[0].state
                                          ? curUserInfo.address[0].state + ", "
                                          : ""
                                      }
                                      ${curUserInfo.address[0].zip}`}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              ) : isEdit && !isPasswordEdit ? (
                                <div
                                  className="tab-pane active profile-edit pt-3"
                                  id="profile-edit">
                                  {/* Profile Edit Form */}
                                  <form onSubmit={submitHandler}>
                                    <div className="row mb-2">
                                      <label
                                        htmlFor="phone"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        Phone
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="phone"
                                          type="text"
                                          className="form-control"
                                          id="phone"
                                          value={form.phone}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mb-2">
                                      <label
                                        htmlFor="email"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        email
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="email"
                                          type="text"
                                          className="form-control"
                                          id="email"
                                          value={form.email}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <label
                                        htmlFor="street"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        Street
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="street"
                                          type="text"
                                          className="form-control"
                                          id="street"
                                          value={form.address[0].street}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <label
                                        htmlFor="city"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        City
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="city"
                                          type="text"
                                          className="form-control"
                                          id="city"
                                          value={form.address[0].city}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <label
                                        htmlFor="state"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        State
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="state"
                                          type="text"
                                          className="form-control"
                                          id="state"
                                          value={form.address[0].state}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <label
                                        htmlFor="zip"
                                        className="col-md-4 col-lg-3 col-form-label">
                                        Zip
                                      </label>
                                      <div className="col-md-8 col-lg-9">
                                        <input
                                          onChange={changeHandler}
                                          name="zip"
                                          type="text"
                                          className="form-control"
                                          id="zip"
                                          value={form.address[0].zip}
                                        />
                                      </div>
                                    </div>

                                    {/* </div> */}
                                    {!isDisabled && (
                                      <div className="text-center ">
                                        <button
                                          type="submit"
                                          className="btn btn-success col-12 col-sm-3 my-2">
                                          Save
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-success bg-secondary border-secondary col-12 col-sm-3 mx-sm-5 my-md-1"
                                          onClick={() => discardClickHandler()}>
                                          Cancel
                                        </button>
                                      </div>
                                    )}
                                    <p
                                      className="fs-5 ms-3 mt-3"
                                      style={{
                                        color: msg
                                          .toLocaleLowerCase()
                                          .includes("success")
                                          ? "green"
                                          : "red",
                                      }}>
                                      {msg}
                                    </p>
                                  </form>
                                  {/* End Profile Edit Form */}
                                </div>
                              ) : (
                                <form onSubmit={passwordSubmitHandler}>
                                  <div className="row mb-2 mt-4">
                                    <label
                                      htmlFor="password"
                                      className="col-md-4 col-lg-3 col-form-label">
                                      New Password
                                    </label>
                                    <div className="col-md-8 col-lg-9">
                                      <input
                                        onChange={passwordHandler}
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Enter a new password"
                                        value={password.trim()}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-2">
                                    <label
                                      htmlFor="password2"
                                      className="col-md-4 col-lg-3 col-form-label"></label>
                                    <div className="col-md-8 col-lg-9">
                                      <input
                                        onChange={passwordHandler}
                                        name="password2"
                                        type="password"
                                        className="form-control"
                                        id="password2"
                                        placeholder="Please confirm your password"
                                        value={password2.trim()}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-2">
                                    <label
                                      htmlFor="password_info"
                                      className="col-md-4 col-lg-3 col-form-label"></label>
                                    <div className="col-md-8 col-lg-9 text-center text-md-start">
                                      <small style={{ fontSize: "0.7rem" }}>
                                        Password must be at least 8 characters
                                        in length, at least 1 upper case, 1
                                        number, and 1 special character
                                      </small>{" "}
                                    </div>
                                  </div>
                                  {!isDisabled && (
                                    <div className="text-center ">
                                      <button
                                        type="submit"
                                        className="btn btn-success col-12 col-sm-3 my-2">
                                        Save
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary text-dark fw-bold col-12 col-sm-3 mx-sm-5 my-md-1"
                                        onClick={() => discardClickHandler()}>
                                        Cancel
                                      </button>
                                    </div>
                                  )}
                                </form>
                              )}
                              <div
                                className="tab-pane fade pt-3"
                                id="profile-settings"></div>
                            </div>
                            {/* End Bordered Tabs */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;
