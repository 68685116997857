import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  getTokens,
  addNewSurveyPatient,
  handleGetptbyid,
  createNewToken,
  getSurveyPtByPhone,
} from "../../../services/PharmacyUser";
import { v4 as uuidv4 } from "uuid";
import baseURL from "../../../utils/baseURL";
import {
  sendEmail,
  sendSMS,
  urlShortner,
  getPharmacyById,
} from "../../../services/Common";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Log from "./Log";
import dayjs from "dayjs";

const SurveySender = (props) => {
  const { csvData } = props;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const SurveyId = urlParams.get("id");
  const pharmacyId = localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const [rows, setRows] = useState(csvData);
  const [errorsFlag, setErrorsFlag] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cleanData, setCleanData] = useState([]);
  const [tokensList, setTokensList] = useState([]);
  const [msg, setMsg] = useState("");
  const [PharmacySMSNum, setPharmacySMSNum] = useState(null);

  let sixweeks = [];
  let successes = [];
  let failers = [];
  let tempTokens = [];
  let procced = [];

  const [sixWeeksCount, setSixweeksCount] = useState(0);
  const [successesCount, setSuccessesCount] = useState(0);
  const [failersCount, setFailersCount] = useState(0);
  const [dublicates, setDublicates] = useState([]);
  const [failerData, setFailerData] = useState([]);
  const [logsflag, setLogsflag] = useState(false);
  const [validdata, setValiddata] = useState(false);
  let columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fname", headerName: "First name", width: 150 },
    { field: "lname", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "mrn", headerName: "MRN", width: 150 },
    { field: "patientPhone", headerName: "Patient Phone", width: 150 },
    { field: "zipCode", headerName: "Zip Code", width: 150 },
    { field: "patientCompany", headerName: "Patient Company", width: 250 },
    {
      field: "dispenseDate",
      headerName: "Dispense Date",
      width: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "dispensePayorType", headerName: "Payor Type", width: 150 },
    { field: "note", headerName: "Note", width: 150 },
  ];
  const [isLoading, setIsLoading] = useState(false);

  const gettoKens = () => {
    getTokens()
      .then((res) => {
        setTokensList(
          res.data
            .map((token) => {
              return JSON.parse(token.token);
            })
            .filter((item) => item.servid === SurveyId)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    gettoKens();

    getPharmacyById(pharmacyId)
      .then((res) => {
        setPharmacySMSNum(res.data.smsPhoneNum);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  useEffect(() => {
    setRows(
      csvData.map((i) => {
        i.note = "";
        if (i.fname.trim() === "") {
          i.note = `${i.note} First Name Is Missing`;
          setErrorsFlag(true);
        }
        if (i.lname.trim() === "") {
          i.note = `${i.note}, Last Name Is Missing`;
          setErrorsFlag(true);
        }
        if (i.dob.trim() === "") {
          i.note = `${i.note},DOB Is Missing`;
          setErrorsFlag(true);
        } else {
          i.dob = dayjs(i.dob).format("MM/DD/YYYY");
        }
        if (i.email.trim() === "" && i.patientPhone.trim() === "") {
          i.note = `${i.note}, Email and Phone Number Are Missing`;
          setErrorsFlag(true);
        }

        if (i.mrn.trim() === "") {
          i.note = `${i.note}, MRN Is Missing`;
          setErrorsFlag(true);
        }

        if (i.dispenseDate.trim() === "") {
          i.note = `${i.note}, Dispense Date Is Missing`;
          setErrorsFlag(true);
        } else {
          i.dispenseDate = dayjs(i.dispenseDate).format("MM/DD/YYYY");
        }

        if (i.patientPhone.trim() !== "") {
          let clean = ("" + i.patientPhone.trim()).replace(/\D/g, "");
          let match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
          const invcodes = [
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "001",
            "011",
            "002",
            "000",
          ];
          if (
            match &&
            match[0].length === 10 &&
            typeof Number(match[0]) === "number"
          ) {
            if (invcodes.includes(match[1])) {
              i.note = `${i.note}, ${i.patientPhone} Invalid Phone Number`;
              i.patientPhone = "";
            } else {
              i.patientPhone = `${match[1]}${match[2]}${match[3]}`;
            }
          } else {
            setErrorsFlag(true);
            i.note = `${i.note}, ${i.phone} Is Invalid Phone Number`;
            i.patientPhone = "";
          }
        } else {
          i.note = `${i.note}, Phone Number Is Missing`;
          setErrorsFlag(true);
        }

        if (i.email.trim() !== "") {
          let validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!i.email.match(validRegex)) {
            i.note = `${i.note}, Invalid Email Address`;
            setErrorsFlag(true);
            i.email = "";
          }
        }
        return i;
      })
    );
  }, [csvData]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const uniqueData = (arr, key) => {
    return [...new Map(arr.map((item) => [key(item), item])).values()];
  };
  useEffect(() => {
    if (checked) {
      setErrorsFlag(false);

      setCleanData(rows.filter((i) => i.note === ""));
    }
  }, [checked, rows]);

  useEffect(() => {
    if (!errorsFlag) {
      let keyarray = rows.map(
        (i) => i.fname + i.lname + i.dob + i.patientPhone
      );
      setDublicates(
        keyarray
          .filter((item, index) =>
            keyarray.some((elem, idx) => elem === item && idx !== index)
          )
          .map((i) => {
            console.log(i);
            rows.find(
              (item) =>
                item.fname + item.lname + item.patientPhone + item.dob === i
            );
          })
      );
      setCleanData(
        uniqueData(
          rows.filter((i) => i.note === ""),
          (k) => k.fname + k.lname + k.dob + k.patientPhone
        )
      );
    }
  }, [errorsFlag, rows]);

  const handelSurveySend = () => {
    setIsLoading(true);
    if (cleanData.length === 0) {
      setMsg("No Valid Data Found");
      setValiddata(true);
      return;
    }
    Promise.all(
      cleanData.map((i) => {
        procced.push(i);

        return addNewSurveyPatient({
          firstName: i.fname,
          lastName: i.lname,
          DOB: i.dob,
          MRN: i.mrn,
          phone:
            i.patientPhone === "Invalid or blank phone number"
              ? ""
              : i.patientPhone,
          providing_pharmacy: i.patientCompany,
          pharmacy_NPI: i.pharmacyNPI,
          dispense_date: "",
          dispense_type: i.dispensePayorType,
        }).then((res) => {
          if (res.status === 200) {
            // res.data.patinet_id
            res.data.patinet_id =
              res?.data?.patinet_id !== undefined
                ? res.data.patinet_id
                : res.data.patient[0].id;
            handleGetptbyid(res.data.patinet_id).then(async (r) => {
              if (r.status === 200) {
                if (
                  r.data.patient === undefined ||
                  r.data.message === "No patient found!" ||
                  r.data.patient.optOut === false ||
                  r.data.patient.optOut === 0
                ) {
                  console.log(res.data.patinet_id);

                  let sorted =
                    tokensList.length > 0
                      ? tokensList
                          .filter(
                            (t) =>
                              Number(t.ptid) === Number(res.data.patinet_id) &&
                              Number(t.servid) === Number(SurveyId)
                          )
                          .sort((b, a) => {
                            return a.date > b.date
                              ? -1
                              : b.date > a.date
                              ? 1
                              : 0;
                          })
                      : [];
                  if (
                    i.patientPhone !== "Invalid or blank phone number" ||
                    i.patientPhone !== ""
                  ) {
                    await getSurveyPtByPhone(i.patientPhone).then(
                      (phoneres) => {
                        console.log(phoneres);
                        let tokensForPhone = phoneres.data.patients
                          .map((t) => {
                            if (tokensList.some((e) => e.ptid === t.id)) {
                              return tokensList.find((e) => e.ptid === t.id);
                            }
                          })
                          .sort((b, a) => {
                            return a.date > b.date
                              ? -1
                              : b.date > a.date
                              ? 1
                              : 0;
                          })
                          .filter(
                            (t) =>
                              t !== undefined &&
                              Number(t.servid) === Number(SurveyId)
                          );
                        console.log(tokensForPhone);
                        sorted = [...sorted, ...tokensForPhone];
                      }
                    );
                  }

                  console.log(sorted);
                  let last = new Date(
                    Math.max(...sorted.map((e) => new Date(e.date)))
                  );
                  let now = new Date();
                  let diff = Math.abs(now - last);
                  let sixweeksms = 8 * 7 * 24 * 60 * 60 * 1000;
                  if (
                    (diff > sixweeksms || sorted.length === 0) &&
                    tempTokens.find((t) => t.ptid === res.data.patinet_id) ===
                      undefined
                  ) {
                    let token = uuidv4();
                    let date = new Date();
                    date = date.toString();
                    let tokenObject = {
                      token: token,
                      date: date,
                      dispenseDate: i.dispenseDate,
                      ptid: res.data.patinet_id,
                      resflag: false,
                      location: i.patientCompany,
                      locationNPI: i.pharmacyNPI,
                      servid: SurveyId,
                    };
                    tempTokens.push(tokenObject);
                    setTokensList([...tokensList, ...tempTokens]);

                    createNewToken({ token: JSON.stringify(tokenObject) }).then(
                      (res) => {
                        // gettoKens();
                      }
                    );

                    urlShortner(
                      `${baseURL.frontEnd}/appless-survey/?id=${SurveyId}&pid=${res.data.patinet_id}&pdate=${i.dispenseDate}&token=${token}&phid=${pharmacyId}`
                    ).then((url) => {
                      const EmailMessage = `<!DOCTYPE html>
  <html style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
  <head>
  <meta name="viewport" content="width=device-width" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <title>Actionable emails e.g. reset password</title>
  
  
  <style type="text/css">
  img {
  max-width: 100%;
  }
  body {
  -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em;
  }
  body {
  background-color: #f6f6f6;
  }
  @media only screen and (max-width: 640px) {
    body {
      padding: 0 !important;
    }
    h1 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h2 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h3 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h4 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h1 {
      font-size: 22px !important;
    }
    h2 {
      font-size: 18px !important;
    }
    h3 {
      font-size: 16px !important;
    }
    .container {
      padding: 0 !important; width: 100% !important;
    }
    .content {
      padding: 0 !important;
    }
    .content-wrap {
      padding: 10px !important;
    }
    .invoice {
      width: 100% !important;
    }
  }
  </style>
  </head>
  
  <body itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6">
  
  <table class="body-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
      <td class="container" width="600" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;" valign="top">
        <div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
          <table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope itemtype="http://schema.org/ConfirmAction" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9;" bgcolor="#fff"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
                <meta itemprop="name" content="Confirm Email" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" /><table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                   Dear ${i.fname} ${i.lname} (or legal guardian),
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                  At ${i.patientCompany}, providing you the very best in health care is our top priority. We gauge success on maintaining the satisfaction of our patients and their families. As a promise to continually improve our services, we ask for your feedback both in areas we can improve and where you think we do well.
                  It's our mission to take this feedback and improve the patient experience for you and others. We appreciate your time and ask that you spend just a few minutes with this short survey by using the link below. Anything that you share is confidential, and you are encouraged to tell us exactly what you think.  
                  Thank you for your feedback.
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      <a href="${url}" class="btn-success" itemprop="url" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Start Survey</a>
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      &mdash; Sincerely,
                      Admin/Compliance Officer ${i.patientCompany}
                    </td>
                  </tr></table></td>
            </tr></table><div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
            <table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;" align="center" valign="top">If you do not wish to participate, please click on the follwing link ${baseURL.frontEnd}/optout/?id=${res.data.patinet_id} to opt out of this survey.</td>
              </tr></table></div></div>
      </td>
      <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
    </tr></table></body>
  </html>`;

                      let SmsMessage = `${pharmacy_name} Pharmacy would like your feedback. Please rate them at ${url}  .
  To opt out please type the word "stop".`;

                      if (i.patientPhone && i.patientPhone !== "") {
                        const smsPayload = {
                          to: i.patientPhone,
                          body: SmsMessage,
                          from: PharmacySMSNum,
                        };
                        sendSMS(smsPayload)
                          .then((smsres) => {
                            successes.push(i);
                            setSuccessesCount(successes.length);
                          })
                          .catch((err) => {
                            let er = JSON.parse(err.response.data.message)
                              .value[0];
                            let errObj = {
                              patientId: res.data.patinet_id,
                              patientName: `${i.fname} ${i.lname}`,
                              patientPhone: er.to,
                              error: er.errorMessage,
                            };
                            failers.push(errObj);
                            setFailersCount(failers.length);
                            setFailerData(failers);
                            if (i.email) {
                              sendEmail({
                                to: i.email,
                                message: EmailMessage,
                              })
                                .then(() => {
                                  successes.push(i);
                                  setSuccessesCount(successes.length);
                                })
                                .catch((err) => {
                                  console.error(err);
                                  let errObj = {
                                    patientId: res.data.patinet_id,
                                    patientName: `${i.fname} ${i.lname}`,
                                    patientPhone: i.email,
                                    error: err.response.data.message,
                                  };
                                  failers.push(errObj);
                                  setFailersCount(failers.length);
                                  setFailerData(failers);
                                });
                            }
                          });
                      } else if (i.email) {
                        sendEmail({ to: i.email, message: EmailMessage })
                          .then(() => {
                            successes.push(i);
                            setSuccessesCount(successes.length);
                          })
                          .catch((err) => {
                            console.error(err);
                            let errObj = {
                              patientId: res.data.patinet_id,
                              patientName: `${i.fname} ${i.lname}`,
                              patientPhone: i.email,
                              error: err.response.data.message,
                            };
                            failers.push(errObj);
                            setFailersCount(failers.length);
                            setFailerData(failers);
                          });
                      }
                    });
                  } else {
                    sixweeks.push(i);
                    setSixweeksCount(sixweeks.length);
                    // setLoadingflag("sent");
                  }
                } else if (
                  r.data.patient.optOut === true ||
                  r.data.patient.optOut === 1
                ) {
                  // optedout.push(i);
                  // setOptedoutCount(optedout.length);
                }
              }
            });
          }
        });
      })
    )
      .catch((err) => {
        let errObj = {
          patientId: "",
          patientName: "",
          patientPhone: "",
          error: err.response.data.message,
        };
        failers.push(errObj);
        setFailersCount(failers.length);
        setFailerData(failers);
      })
      .finally(() => {
        if (
          cleanData.length !== 0 &&
          procced.length === cleanData.length &&
          procced.length > 0
        ) {
          setMsg("Data has been processed!!");
          setIsLoading(false);
        } else {
          setMsg("Processing...");
        }
      });
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        {msg && <p style={{ color: "green" }}>{msg}</p>}
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            border: "1px solid grey",
            paddingTop: "10px",
            height: "auto",
            marginBottom: "20px",
            marginLeft: "0px",
          }}
          className="row">
          <div className="col-12">
            <h5>Statistics Board</h5>
            <ul className="list-group list-group-horizontal justify-content-center ">
              <li className="list-group-item">Total Patients: {rows.length}</li>
              <li className="list-group-item">
                Patients With Missing Info:{" "}
                {rows.filter((i) => i.note !== "").length}
              </li>
              <li className="list-group-item">
                Patients Received Survey In The Last 60 Days: {sixWeeksCount}
              </li>
              <li className="list-group-item">
                File Duplicated Records: {dublicates.length}
              </li>
              <li className="list-group-item">
                Failed To Send: {failersCount}
              </li>
              <li className="list-group-item">
                Total Not Sent: {failersCount + sixWeeksCount}
              </li>
              <li className="list-group-item">
                Succeed To Send: {successesCount}
              </li>
            </ul>
            <p>
              {errorsFlag && (
                <>
                  Errors detected. By checking this switch button &nbsp; &nbsp;
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    label=""
                  />
                  you agreeing on importing the data and exclude the missing
                  records.{" "}
                </>
              )}
            </p>
            <p>
              {(failersCount > 0 || dublicates.length > 0) && (
                <button
                  className="btn btn-link mt-3"
                  onClick={() => setLogsflag(!logsflag)}>
                  {logsflag ? "Hide Logs" : "Show Logs"}
                </button>
              )}
            </p>
          </div>
          <div className="col-12 mt-3 mb-3">
            <LoadingButton
              onClick={handelSurveySend}
              endIcon={<SendIcon />}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
              style={{ float: "right" }}
              disabled={
                errorsFlag ||
                msg !== "" ||
                sixWeeksCount > 0 ||
                isLoading ||
                failersCount > 0 ||
                msg === "Survey sent successfully!!" ||
                validdata
              }>
              <span>Send</span>
            </LoadingButton>
          </div>
        </div>

        {logsflag ? (
          <Log log={failerData} dublicates={dublicates} />
        ) : (
          <DataGrid
            rows={checked ? cleanData : rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowsPerPageOptions={[100]}
            initialState={{
              sorting: { sortModel: [{ field: "dispenseDate", sort: "desc" }] },
            }}
            loading={rows.length === 0}
            components={{
              Toolbar: GridToolbar,
            }}
            checkboxSelection
            getRowClassName={(params) =>
              params.row.note && params.row.note !== "" ? `errorrow` : ""
            }
            getCellClassName={(params) => {
              if (params.row.note && params.row.note !== "") {
                if (
                  params.field === "fname" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
                if (
                  params.field === "fname" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
                if (
                  params.field === "dob" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
                if (
                  params.field === "mrn" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
                if (
                  params.field === "patientPhone" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
                if (
                  params.field === "email" &&
                  (params.value.trim() === "" || params.value === null)
                ) {
                  return "errorcell";
                }
              } else {
                return "";
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SurveySender;
