const baseURL = {
  frontEnd:
    process.env.REACT_APP_ENV === "prod"
      ? "https://curorx.life"
      : process.env.REACT_APP_ENV === "beta"
      ? "https://curo-v2-beta.azurewebsites.net"
      : "http://localhost:3000",

  curoback:
    process.env.REACT_APP_ENV === "beta"
      ? "https://curo-back-v2-beta.azurewebsites.net"
      : process.env.REACT_APP_ENV === "prod"
      ? "https://curo-back-v2.azurewebsites.net"
      : "http://localhost:5000",
};

export default baseURL;
