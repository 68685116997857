import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MassTextSummary from "./../../components/pharmacyUser/MassTextSummary";
import { sendSMS, getUserById } from "../../services/Common";
import { getPatientscharts } from "../../services/Provider";
import { decodeToken } from "react-jwt";

const MassText = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const token = localStorage.getItem("Token");
  const decodedToken = decodeToken(token);
  const providerId = decodedToken.userId;

  const [patients, setPatients] = useState([]);
  const [text, setText] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [isSummary, setIsSummary] = useState(false);
  const [summary, setSummary] = useState([]);
  const [button, setButton] = useState("Send");
  const [success, setSuccess] = useState(0);
  const [failed, setFailed] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [patientsExists, setPatientsExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [providerName, setProviderName] = useState({});

  const columns = [
    { field: "id", headerName: "Patient ID", width: 100 },
    { field: "first", headerName: "First name", width: 130 },
    { field: "last", headerName: "Last name", width: 130 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "MRN", headerName: "MRN", width: 100 },
  ];

  useEffect(() => {
    getPatientscharts(providerId).then((res) => {
      if (res.data.length > 0) {
        setPatientsExists(true);
      }
      res.data.length > 0 &&
        setPatients(
          res.data
            .map((el) => {
              return {
                first: el.first,
                last: el.last,
                phone: el.phone
                  .split("")
                  .filter((el) => !isNaN(el))
                  .join(""),
                id: el.id,
                isRecepient: false,
                MRN: el.MRN || "N/A",
              };
            })
            .filter((el) => el.phone.length > 9)
        );
      setIsLoading(false);
    });

    getUserById(providerId)
      .then((res) => {
        const { user } = res.data.data;
        const providerFullName = user[0].first + " " + user[0].last;
        setProviderName(providerFullName || "");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [providerId]);

  const checkPtsHandler = (e) => {
    setCheckedList(e);
  };

  const modifyText = (rawText, pharmacy) => {
    if (pharmacy) {
      return `You have a new message from Dr.${providerName}:  \n\n ${rawText}`;
    } else return rawText;
  };

  const textChangeHandler = (e) => {
    setText(e.target.value);
    setIsCompleted(false);
    setSuccess(0);
    setFailed(0);
    setSummary([]);
  };

  const SendClickHandler = async (e) => {
    e.preventDefault();
    setSummary([]);
    let successCount = 0;
    let failedCount = 0;
    setButton("Sending...");
    setIsSending(true);

    await Promise.all(
      // eslint-disable-next-line array-callback-return
      patients.map((el) => {
        if (checkedList.includes(el.id)) {
          el.isRecepient = true;
          return sendSMS({ body: modifyText(text, providerName), to: el.phone })
            .then(() => {
              successCount++;
              setSuccess(successCount);
              el.sent = true;
            })
            .catch((err) => {
              failedCount++;
              setFailed(failedCount);
              el.sent = false;
              let beNote = err.response?.data?.message
                ? JSON.parse(err.response.data.message)?.value[0]
                    ?.errorMessage || "Error Sending SMS"
                : "Error Sending SMS";
              el.notes = beNote;
            });
        } else el.isRecepient = false;
      })
    );

    setButton("Send");
    setIsCompleted(true);
    setIsSending(false);
    setText("");
  };

  const summaryClickHandler = (e) => {
    setSummary(
      // eslint-disable-next-line array-callback-return
      patients
        .filter((el) => el.isRecepient)
        .map((el) => {
          return {
            id: el.id,
            first: el.first,
            last: el.last,
            phone: el.phone,
            sent: el.sent,
            notes: el.notes,
          };
        })
    );
    setIsSummary(!isSummary);
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Bulk Messages</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Messages Center</li>
              <li className="breadcrumb-item active">Bulk Messages</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row gy-3">
                {!isSummary ? (
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="text-body col-12 col-md-11 col-lg-5 mb-4 mx-auto">
                            <form>
                              <div className="form-group">
                                <label htmlFor="text-msg">
                                  <h5 className="mb-3 fs-5"> Message</h5>
                                </label>
                                <textarea
                                  className="form-control w-100 mb-2"
                                  maxLength={500}
                                  id="text-msg"
                                  placeholder="Enter your Message"
                                  rows={6}
                                  value={text}
                                  onChange={textChangeHandler}
                                />
                              </div>
                              <button
                                className="btn btn-success col-12 col-sm-4 col-xl-3"
                                type="submit"
                                onClick={SendClickHandler}
                                disabled={!text.length || !checkedList.length}>
                                {button}
                              </button>
                              {(isCompleted || isSending) && (
                                <div className="my-1 d-flex flex-wrap justify-content-around col-11 col-sm-12 mx-auto">
                                  <p className="my-3 ">
                                    {success} messages sent successfully,&nbsp;
                                    {failed} failed
                                  </p>
                                  {isCompleted && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success  h-25 mt-2"
                                      onClick={() => summaryClickHandler()}>
                                      Summary
                                    </button>
                                  )}
                                </div>
                              )}
                            </form>
                          </div>
                          <div className="patients-list col-12 col-lg-7 align-items-center justify-content-center">
                            <h5 className="ms-4 mb-3 fs-5">
                              Select Recepients
                            </h5>

                            <div className="card col-12 col-md-11  mx-auto mt-2 ">
                              <div className="card-body p-0">
                                <div id="patients-long-list">
                                  {isLoading && !patientsExists ? (
                                    <div className="d-flex flex-row justify-content-center align-items-center ">
                                      <div
                                        className="spinner-border text-warning"
                                        role="status">
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <div className="ms-4 align-middle text-secondary px-1 py-5">
                                        Loading Patients Data Please wait...
                                      </div>
                                    </div>
                                  ) : isLoading && patientsExists ? (
                                    <div className="text-danger">
                                      no patient with a valid phone numbder
                                      exists
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        height: "65vh",
                                        width: "100%",
                                      }}>
                                      <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                          rows={patients}
                                          columns={columns}
                                          pageSize={100}
                                          density="compact"
                                          rowsPerPageOptions={[100]}
                                          onRowSelectionModelChange={
                                            checkPtsHandler
                                          }
                                          components={{
                                            Toolbar: GridToolbar,
                                          }}
                                          checkboxSelection
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      className="btn btn-sm btn-success my-3"
                      onClick={() => {
                        setIsSummary(false);
                        setSummary([]);
                        setIsCompleted(false);
                        setIsSending(false);
                        setCheckedList([]);
                      }}>
                      go back
                    </button>
                    <MassTextSummary summary={summary} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MassText;
