import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

export const handleGetMessages = async (channelId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/messages/${channelId}`
  );
};

export const handleSendMessage = async (data) => {
  return axiosWithAuth().post(`${baseURL.curoback}/message/messages`, data);
};

export const handleFiles = async (data) => {
  return axiosWithAuth().post(`${baseURL.curoback}/message/upload_file`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const handleToggleShowChannel = async (channelId, users) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/message/channels/${channelId}`,
    {
      users
    }
  );
};

export const handleGetChannels = async (pharmacyId, userId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/channels/${pharmacyId}/${userId}`
  );
};

export const handleCreateChannel = async (data) => {
  return axiosWithAuth().post(`${baseURL.curoback}/message/channels`, data);
};

export const getChannelById = async (channelId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/channels/${channelId}`
  );
};
