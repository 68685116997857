import React from "react";

const NotAuthorized = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "96vh",
        fontSize: "4rem",
        // marginLeft: "15%",
      }}>
      <h1 className="text-danger">403</h1>
      <p className="text-danger">Not Authorized</p>
      <div
        style={{
          fontSize: "1rem",
          maxWidth: "500px",
          marginTop: "20px",
        }}>
        <p>
          {" "}
          You might not have the right privilege to access this page, <br />
          If you believe there is something wrong please contact pharmacy admin
        </p>
      </div>
    </div>
  );
};

export default NotAuthorized;
