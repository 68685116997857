import axiosWithAuth from "../utils/axiosWithAuth";
import baseURL from "../utils/baseURL";

export const getPatientFullInfo = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/patient/${id}`);
};

export const handleProfileUpdate = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/patient/patient/${id}`,
    payload
  );
};

// ====================================== Survey Services Start ======================================
export const addPtToSurvey = (pttosurvey) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/addPatientSurvey`,
    pttosurvey
  );
};

export const getSurveyPtById = (id) => {
  return axiosWithAuth()
    .post(`${baseURL.curoback}/servey/patintId`, {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const handleAddsurrespond = (pid, res) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/add_response`, {
    pt_id: pid,
    survey_response: res,
  });
};

export const handleGetptbyid = (id) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/patintId`, {
    id: id,
  });
};

// ====================================== Survey Services End ======================================
