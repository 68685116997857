import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import { SideBar } from "../../../utils/globalState";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SurveyHelper, FlatQuestion } from "survey-pdf";
import { SurveyPDF } from "survey-pdf";
import { Model } from "survey-core";
import dayjs from "dayjs";
import { RRule } from "rrule";
import "survey-core/modern.min.css";
import "../../../styles/page-styles/forms.css";
import ReviewResponse from "../../../components/pharmacyUser/formCenter/ReviewResponse";
import {
  handleGetEventById,
  handleDeleteEventWithAllFollowings,
} from "../../../services/Calendar";
import {
  getUserById,
  getUserRole,
  getUserPrivilege,
} from "../../../services/Common";
import {
  handleGetCarePlanById,
  handleUpdateCarePlan,
  handleGetCpResponsesByCpId,
  handleUpdateResponse,
} from "../../../services/CarePlan";

const CarePlanHistoryDetails = () => {
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("p");

  const portal = window.location.pathname.split("/")[1];

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const firstName = localStorage.getItem("first");
  const lastName = localStorage.getItem("last");
  const userFullName = `${firstName} ${lastName}`;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [currentResponse, setCurrentResponse] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [curRespObj, setCurRespObj] = useState({});
  const [msg, setMsg] = useState("");
  const [privileges, setPrivileges] = useState([]);

  const [Modal, openModal, closeModal] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: true,
    closeOnEscape: true,
  });

  const fetchData = async (cpID) => {
    const allData = {};
    try {
      const { data: cpData } = await handleGetCarePlanById(cpID);
      const { data: cpResponses } = await handleGetCpResponsesByCpId(cpID);
      console.log("cpData", cpData);
      let fuEventG = {};
      if (cpData.fuEventId) {
        const { data: fuEvent } = await handleGetEventById(cpData.fuEventId);
        fuEventG = fuEvent;
      }
      allData.cp = {
        ...cpData,
        initForm: {
          ...cpData.initForm,
          formText: JSON.parse(cpData.initForm.formText),
        },
        fuForm: {
          ...cpData.fuForm,
          formText: JSON.parse(cpData.fuForm.formText),
        },
        recipient: {
          ...cpData.recipient,
          DOB: dayjs(cpData.recipient.DOB).format("MM/DD/YYYY"),
        },
        createdOn: dayjs(cpData.createdOn).format("MM/DD/YYYY hh:mm A"),
        completedOn: cpData.completedOn
          ? dayjs(cpData.completedOn).format("MM/DD/YYYY hh:mm A")
          : null,
      };
      allData.fuEvent = { ...fuEventG };

      allData.responses = cpResponses.map((response) => {
        return {
          ...response,
          // verify that value is stringified JSON, if not, set it to null
          value:
            response.value &&
            typeof response.value === "string" &&
            response.value.trim() !== ""
              ? JSON.parse(response.value)
              : null,

          formText: response.isInitResponse
            ? JSON.parse(cpData.initForm.formText)
            : JSON.parse(cpData.fuForm.formText),
          sentOn: response.sentOn
            ? dayjs(response.sentOn).format("MM/DD/YYYY hh:mm A")
            : null,
          completedOn: response.completedOn
            ? dayjs(response.completedOn).format("MM/DD/YYYY hh:mm A")
            : null,
          dateOfLastReminder: response.dateOfLastReminder
            ? dayjs(response.dateOfLastReminder).format("MM/DD/YYYY hh:mm A")
            : null,
          reviewedOn: response.reviewedOn
            ? dayjs(response.reviewedOn).format("MM/DD/YYYY hh:mm A")
            : null,
          isCompletedByPatient: response.isCompletedByPatient ? true : false,
          senderInfo: JSON.parse(response.senderInfo),
          senderName:
            JSON.parse(response.senderInfo).first +
            " " +
            JSON.parse(response.senderInfo).last,
        };
      });
      console.log("allData", allData);
      setData(allData);
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrivileges = async (userId) => {
    getUserById(userId).then(async (res) => {
      const user = res.data.data.user[0];

      if (user.type.toLowerCase().includes("admin")) {
        setPrivileges(["admin"]);
      } else {
        const role = await getUserRole(userId);
        const roleId = role.data.data[0].roleID;

        getUserPrivilege(roleId).then((privRes) => {
          const userPrivs = privRes.data.data.map((priv) =>
            priv.name.toLowerCase()
          );
          setPrivileges(userPrivs);
        });
      }
    });
  };

  useEffect(() => {
    fetchData(id);
    fetchPrivileges(userID);
  }, [id, isReviewOpen, userID]);

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  const handleDownload = (row) => {
    let current = data.responses.find((item) => item.id === row.id);
    current.recipient = {
      ...data.cp.recipient,
      recipientName: `${data.cp.recipient.first} ${data.cp.recipient.last}`,
    };

    current && setCurrentResponse(current);
    if (current) {
      let json = handleObjectRewrite(current);
      json.title = `${json.title} (${
        current.isInitResponse ? "Initial" : "Follow-up"
      } assessment form) for - Name: ${
        current.recipient.recipientName
      } - MRN: ${
        current.recipient.MRN ? current.recipient.MRN : "N/A"
      } - Phone: ${current.recipient.phone} - Email: ${
        current.recipient.email
      }`;

      function createSurveyPdfModel(surveyModel) {
        let pdfWidth =
          !!surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
        let pdfHeight =
          !!surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
        let options = {
          haveCommercialLicense: true,
          fontSize: 10,
          margins: {
            left: 10,
            right: 10,
            top: 10,
            bot: 10,
          },
          textFieldRenderAs: "multiLine",
          htmlText: true,
          format: [pdfWidth, pdfHeight],
          pageBreak: "auto",
          compress: true,
          header: {
            fontSize: 10,
            textColor: "#00008b",
            fontStyle: "bold",
            padding: 10,
            margin: 10,
            renderAs: "text",
            data: ` Name: ${current.recipient.recipientName} - MRN: ${
              current.recipient.MRN ? current.recipient.MRN : "N/A"
            } - Phone: ${current.recipient.phone} - Email: ${
              current.recipient.email
            }`,
          },
          footer: {
            fontSize: 10,
            textColor: "#00008b",
            fontStyle: "bold",
            padding: 10,
            margin: 10,
          },
          headerText: function (currentPage, pageCount) {
            return currentPage.toString() + " / " + pageCount;
          },
          footerText: function (currentPage, pageCount) {
            return currentPage.toString() + " / " + pageCount;
          },
          headerRenderAs: "text",
          footerRenderAs: "text",
          headerHeight: 10,
          footerHeight: 10,
          headerStyle: "normal",
          footerStyle: "normal",
          headerBorder: {
            top: true,
            bottom: true,
            left: true,
            right: true,
          },
        };
        const surveyPDF = new SurveyPDF(json, options);
        if (surveyModel) {
          surveyPDF.data = surveyModel.data;
        }
        surveyPDF.mode = "display";

        surveyPDF.onRenderHeader.add(function (survey, canvas) {
          const unfoldedPacks = canvas.packs[0].unfold();

          // Colorize the page title
          unfoldedPacks.forEach((el) => {
            el.textColor = "#00008b";
            el.fontSize = 12;
          });
          // Colorize the row line under the title
          unfoldedPacks[unfoldedPacks.length - 1].color = "blue";
          // Specify the thickness of the row line under the title
          unfoldedPacks[unfoldedPacks.length - 1].yTop +=
            canvas.controller.unitHeight * 0.8;
          // Render a company logo
        });

        surveyPDF.onRenderQuestion.add(function (survey, options) {
          // Colorize a question's title
          options.bricks[0].unfold()[0].textColor = "blue";
          options.bricks[0].unfold()[0].fontSize = 10;

          // Colorize the row line under a question and specify the line's thickness
          const lastRowBricks =
            options.bricks[options.bricks.length - 1].unfold();
          lastRowBricks[lastRowBricks.length - 1].color = "blue";
          lastRowBricks[lastRowBricks.length - 1].isPageBreak = true;

          lastRowBricks[lastRowBricks.length - 1].yTop +=
            options.controller.unitHeight * 1.3;
          return new Promise(function (resolve) {
            resolve();
          });
        });

        return surveyPDF;
      }
      function saveSurveyToPdf(filename, surveyModel) {
        createSurveyPdfModel(surveyModel).save(filename);
      }

      SurveyHelper.FORM_BORDER_VISIBLE = false;
      SurveyHelper.VALUE_READONLY_PADDING_SCALE = 0;
      SurveyHelper.MULTIPLETEXT_TEXT_PERS = 0.55;
      SurveyHelper.TITLE_PAGE_FONT_SIZE_SCALE = 1.4;
      SurveyHelper.TITLE_FONT_SCALE = 1.4;
      FlatQuestion.CONTENT_INDENT_SCALE = 0;
      FlatQuestion.CONTENT_GAP_VERT_SCALE = 0.1;
      SurveyHelper.TEXT_COLOR = "black";

      const survey = new Model(json);
      survey.mode = "display";

      saveSurveyToPdf("surveyResult.pdf", survey);
    }
  };

  const columnDef = [
    { field: "id", headerName: "ID", minWidth: 60, maxWidth: 60 },
    {
      field: "isInitResponse",
      headerName: "Type",
      minWidth: 100,
      valueGetter: (params) =>
        params.row.isInitResponse ? "Initial" : "Follow-up",
    },
    {
      field: "sentOn",
      headerName: "Sent/Called On",
      minWidth: 180,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "senderName", headerName: "Sent/Called By", minWidth: 150 },
    {
      field: "completedOn",
      headerName: "Completed On",
      minWidth: 180,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "isCompletedByPatient",
      headerName: "Completed By",
      minWidth: 150,
      valueGetter: (params) =>
        !params.row.completedOn
          ? ""
          : params.row.isCompletedByPatient
          ? "Patient"
          : "Care Team",
    },
    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 140,
      valueGetter: (params) => (params.row.isReviewed ? "Yes" : "No"),
    },
    {
      field: "reviewedOn",
      headerName: "Reviewed On",
      minWidth: 180,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "reviewedBy", headerName: "Reviewed By", minWidth: 140 },
    { field: "numOfReminders", headerName: "Reminders Sent", minWidth: 140 },
    {
      field: "dateOfLastReminder",
      headerName: "Last Reminder Date",
      minWidth: 180,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 280,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-sm btn-success me-2"
            disabled={!params.row.completedOn ? true : false}
            style={{ minWidth: "80px" }}
            onClick={() => {
              const reviewObject = {};
              reviewObject.value = params.row.value;
              reviewObject.formText = params.row.formText;
              reviewObject.isInitResponse = params.row.isInitResponse;
              setCurrentResponse(reviewObject);
              setCurRespObj(params.row);
              setIsReviewOpen(true);
            }}>
            {!params.row.isReviewed &&
            ["admin", "careplan review"].some((priv) =>
              privileges.includes(priv)
            )
              ? "Review"
              : "View"}
          </button>
          <button
            className="btn btn-sm btn-success me-2"
            disabled={!params.row.completedOn ? true : false}
            onClick={() => {
              handleDownload(params);
            }}>
            Download as PDF
          </button>
        </div>
      ),
    },
  ];

  const calculateCompletedFollowUps = (responses) => {
    let completedFollowUps = 0;
    responses.forEach((response) => {
      if (!response.isInitResponse && response.completedOn) {
        completedFollowUps++;
      }
    });
    return completedFollowUps;
  };

  function calculateFrequency() {
    const { RecurrenceRule } = data.fuEvent;

    if (!RecurrenceRule) {
      return "No Frequency Rule Set";
    }

    const sanitizedRule = RecurrenceRule.slice(0, RecurrenceRule.length - 1);
    const ruleArr = sanitizedRule.split(";");
    const byDay = ruleArr.find((item) => item.includes("BYDAY"));
    const bystep = ruleArr.find((item) => item.includes("BYSETPOS"));
    const byStepValue = bystep ? bystep.split("=")[1] : "";
    const byDayModified = byStepValue + byDay?.split("=")[1] || "";
    const ruleArrModified = ruleArr.map((item) => {
      if (item.includes("BYDAY")) {
        return `BYDAY=${byDayModified}`;
      } else {
        return item;
      }
    });

    const ruleStringProcessed = ruleArrModified.join(";");
    const ruleFinal = RRule.fromString(ruleStringProcessed);
    return ruleFinal.toText();
  }

  const calculateLastFollowUpDate = () => {
    const { RecurrenceRule } = data.fuEvent;

    if (!RecurrenceRule) {
      return "No Frequency Rule Set";
    }

    const sanitizedRule = RecurrenceRule.slice(0, RecurrenceRule.length - 1);

    const rule = RRule.fromString(sanitizedRule);
    const recArr = rule.all();
    const lastRec = recArr[recArr.length - 1];

    return dayjs(lastRec).format("MM/DD/YYYY");
  };

  const isFuInFuture = () => {
    const lastDate = calculateLastFollowUpDate();
    const today = dayjs().format("MM/DD/YYYY");
    return dayjs(lastDate).isAfter(today);
  };

  const handleViewForm = (index) => {
    if (index === 0) {
      setCurrentResponse({
        value: null,
        formText: data.cp.initForm.formText,
        isInitResponse: true,
      });
    } else {
      setCurrentResponse({
        value: null,
        formText: data.cp.fuForm.formText,
        isInitResponse: false,
      });
    }
    setIsReviewOpen(true);
  };

  const handleMarkReviewedClick = async () => {
    const responseId = curRespObj.id;
    const payload = {
      reviewedBy: userFullName,
      reviewedOn: new Date(),
      isReviewed: true,
    };
    handleUpdateResponse(responseId, payload)
      .then(() => {
        setCurRespObj({ ...curRespObj, ...payload });
        setMsg("Response has been marked as reviewed successfully");
        setTimeout(() => {
          setMsg("");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const markCpCompleteClickHandler = async () => {
    if (data.cp.fuEventId) {
      handleDeleteEventWithAllFollowings(data.cp.fuEventId, userID).catch(
        (err) => console.error(err)
      );
    }

    const payload = {
      completedOn: data.cp.completedOn ? null : new Date(),
      fuEventId: null,
    };

    handleUpdateCarePlan(id, payload)
      .then(() => {
        fetchData(id);
        closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        {!isReviewOpen ? (
          <>
            <div className="pagetitle">
              <h1>Care Plan Details</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Care Plan</li>
                  {parent === "cph" ? (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => navigate(-1)}>
                        Care Plan History
                      </li>
                      <li className="breadcrumb-item active">
                        Care Plan Details
                      </li>
                    </>
                  ) : parent === "cpm" ? (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => navigate(-1)}>
                        Care Plan Manager
                      </li>
                      <li className="breadcrumb-item active">
                        Care Plan Details
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() =>
                          navigate(`/${portal}/cp-center/care-plan-manager`)
                        }>
                        Care Plan Manager
                      </li>
                      <li className="breadcrumb-item active">
                        Care Plan Details
                      </li>
                    </>
                  )}
                </ol>
              </nav>
            </div>
            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                <div className="container-fluid">
                  {!isLoading ? (
                    <div>
                      <div className="card">
                        <div className="card-header">
                          <h4>Care Plan Details</h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <div className="d-flex row justify-content-between align-items-center">
                                  <label
                                    htmlFor="createdOn"
                                    className="col-auto">
                                    Created On
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="createdOn"
                                    value={data.cp.createdOn}
                                    disabled
                                    title={data.cp.createdOn}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="creatorID"
                                    className="col-auto">
                                    Created By
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="creatorID"
                                    value={`${data.cp.creator.first} ${data.cp.creator.last}`}
                                    disabled
                                    title={`${data.cp.creator.first} ${data.cp.creator.last}`}
                                  />
                                </div>
                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="completedOn"
                                    className="col-auto">
                                    Completed On
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="creatorID"
                                    value={
                                      data.cp.completedOn
                                        ? data.cp.completedOn
                                        : "Not Completed"
                                    }
                                    disabled
                                    title={
                                      data.cp.completedOn
                                        ? data.cp.completedOn
                                        : "Not Completed"
                                    }
                                  />
                                </div>
                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="recipientName"
                                    className="col-auto">
                                    Patient Name
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="recipientName"
                                    value={`${data.cp.recipient.first} ${data.cp.recipient.last}`}
                                    disabled
                                    title={`${data.cp.recipient.first} ${data.cp.recipient.last}`}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label htmlFor="MRN" className="col-auto">
                                    MRN
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="MRN"
                                    value={data.cp.recipient.MRN}
                                    disabled
                                    title={data.cp.recipient.MRN}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label htmlFor="DOB" className="col-auto">
                                    DOB
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="DOB"
                                    value={data.cp.recipient.DOB}
                                    disabled
                                    title={data.cp.recipient.DOB}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <div className="d-flex row justify-content-between align-items-center mt-2 mt-lg-0">
                                  <label
                                    htmlFor="cpOptOut"
                                    className="col-auto">
                                    Careplan Opt In
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className={`form-control col-7 text-${
                                      data.cp.recipient.cpOptOut
                                        ? "danger"
                                        : "success"
                                    }`}
                                    id="cpOptOut"
                                    value={
                                      !data.cp.recipient.cpOptOut ? "Yes" : "No"
                                    }
                                    disabled
                                    title={
                                      !data.cp.recipient.cpOptOut ? "Yes" : "No"
                                    }
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="fuOptedIn"
                                    className="col-auto">
                                    Follow-up Opt In
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className={`form-control col-7 text-${
                                      data.cp.fuOptedIn ? "success" : "danger"
                                    }`}
                                    id="fuOptedIn"
                                    value={data.cp.fuOptedIn ? "Yes" : "No"}
                                    disabled
                                    title={data.cp.fuOptedIn ? "Yes" : "No"}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="isPhoneFu"
                                    className="col-auto">
                                    Follow-up Method
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="isPhoneFu"
                                    value={
                                      data.cp.isPhoneFu
                                        ? "Phone Call"
                                        : "Form Sent By SMS"
                                    }
                                    disabled
                                    title={
                                      data.cp.isPhoneFu
                                        ? "Phone Call"
                                        : "Form Sent By SMS"
                                    }
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="initForm"
                                    className="col-auto">
                                    Initial Form
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    onClick={() => handleViewForm(0)}
                                    className="form-control col-7 clickable_text"
                                    id="initForm"
                                    defaultValue={
                                      data.cp.initForm.formText.title
                                    }
                                    title={data.cp.initForm.formText.title}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label htmlFor="fuForm" className="col-auto">
                                    Follow-up Form
                                  </label>

                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    onClick={() => handleViewForm(1)}
                                    className="form-control col-7 clickable_text"
                                    id="fuForm"
                                    defaultValue={data.cp.fuForm.formText.title}
                                    title={data.cp.fuForm.formText.title}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <div className="d-flex row justify-content-between align-items-center mt-2 mt-lg-0">
                                  <label
                                    htmlFor="isInitialCallAnswered"
                                    className="col-auto">
                                    Initial Call Answered
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="isInitialCallAnswered"
                                    value={
                                      data.cp.isInitialCallAnswered
                                        ? "Yes"
                                        : "No"
                                    }
                                    disabled
                                    title={
                                      data.cp.isInitialCallAnswered
                                        ? "Yes"
                                        : "No"
                                    }
                                  />
                                </div>

                                {data.cp.isPhoneFu ? (
                                  <></>
                                ) : (
                                  <div className="d-flex row justify-content-between align-items-center mt-2">
                                    <label
                                      htmlFor="fuSent"
                                      className="col-auto">
                                      Follow-up Sent
                                    </label>
                                    <input
                                      type="text"
                                      style={{
                                        width: "50%",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="form-control col-7"
                                      id="fuSent"
                                      value={
                                        data.responses.length > 1
                                          ? data.responses.length - 1
                                          : 0
                                      }
                                      disabled
                                      title={
                                        data.responses.length > 1
                                          ? data.responses.length - 1
                                          : 0
                                      }
                                    />
                                  </div>
                                )}

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="fuCompleted"
                                    className="col-auto">
                                    Follow-up Completed
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="fuCompleted"
                                    value={calculateCompletedFollowUps(
                                      data.responses
                                    )}
                                    disabled
                                    title={calculateCompletedFollowUps(
                                      data.responses
                                    )}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="fuFrequency"
                                    className="col-auto">
                                    Follow-up Frequency
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="fuFrequency"
                                    value={calculateFrequency()}
                                    disabled
                                    title={calculateFrequency()}
                                  />
                                </div>

                                <div className="d-flex row justify-content-between align-items-center mt-2">
                                  <label
                                    htmlFor="fuEndDate"
                                    className="col-auto">
                                    Follow-up End Date
                                  </label>
                                  <input
                                    type="text"
                                    style={{
                                      width: "50%",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="form-control col-7"
                                    id="fuEndDate"
                                    value={calculateLastFollowUpDate()}
                                    disabled
                                    title={calculateLastFollowUpDate()}
                                  />
                                </div>
                                {["admin", "careplan complete"].some((priv) =>
                                  privileges.includes(priv)
                                ) && (
                                  <div className="d-flex row justify-content-end align-items-end mt-2">
                                    <button
                                      className={`btn btn-small col-auto btn-outline-${
                                        data.cp.completedOn
                                          ? "danger"
                                          : "success"
                                      }`}
                                      onClick={() => {
                                        openModal();
                                      }}>
                                      Mark Careplan as{" "}
                                      {data.cp.completedOn
                                        ? "Incomplete"
                                        : "Completed"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h4>Care Plan Assessment Responses</h4>
                        </div>
                        <Box sx={{ width: "100%" }}>
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={data.responses}
                              getRowId={(row) => row.id}
                              columns={columnDef}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              initialState={{
                                sorting: {
                                  sortModel: [{ field: "sentOn", sort: "asc" }],
                                },
                              }}
                            />
                          </div>
                        </Box>{" "}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <h3 className="text-center mt-5">
                        Loading Data, Please wait...
                      </h3>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="pagetitle">
              <h1>{`Care Plan ${
                currentResponse.isInitResponse ? "Initial" : "Follow-up"
              } Response`}</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Care Plan</li>
                  {parent === "cph" ? (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => navigate(-1)}>
                        Care Plan History
                      </li>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => {
                          setIsReviewOpen(false);
                          setCurrentResponse({});
                        }}>
                        Care Plan Details
                      </li>
                    </>
                  ) : parent === "cpm" ? (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => navigate(-1)}>
                        Care Plan Manager
                      </li>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => {
                          setIsReviewOpen(false);
                          setCurrentResponse({});
                        }}>
                        Care Plan Details
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() =>
                          navigate(`/${portal}/cp-center/care-plan-manager`)
                        }>
                        Care Plan Manager
                      </li>
                      <li
                        className="breadcrumb-item cstm_breadcrumb"
                        onClick={() => {
                          setIsReviewOpen(false);
                          setCurRespObj({});
                          setCurrentResponse({});
                        }}>
                        Care Plan Details
                      </li>
                    </>
                  )}
                  <li className="breadcrumb-item active">
                    {`Care Plan ${
                      currentResponse.isInitResponse ? "Initial" : "Follow-up"
                    } Response`}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                <div className="container-fluid">
                  {!curRespObj.isReviewed &&
                  currentResponse.value &&
                  curRespObj.completedOn &&
                  ["admin", "careplan review"].some((priv) =>
                    privileges.includes(priv)
                  ) ? (
                    <div className="my-2">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => handleMarkReviewedClick()}>
                        Mark As Reviewed
                      </button>
                    </div>
                  ) : null}
                  {msg && (
                    <div className="alert alert-success my-2 ps-2">{msg}</div>
                  )}
                  <ReviewResponse data={currentResponse} />
                </div>
              </div>
            </div>
          </>
        )}
      </main>
      <Modal>
        {data.cp && (
          <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
            <div className="card-header bg-transparent fw-bold text-center">
              Mark Care Plan as{" "}
              {data.cp.completedOn ? "Incomplete" : "Completed"}
            </div>
            <div className="card-body">
              {!data.cp.completedOn && (
                <p className="my-3">
                  {isFuInFuture()
                    ? "This care plan has future follow-ups."
                    : ""}
                </p>
              )}
              <p>
                {data.cp.completedOn
                  ? "Are you sure you want to mark this care plan as incomplete?"
                  : "Are you sure you want to mark this care plan as completed?"}
              </p>
            </div>
            <div className="card-footer bg-transparent text-center d-flex justify-content-evenly">
              <button
                type="button"
                className="btn btn-outline-success mx-2 mx-2"
                onClick={() => markCpCompleteClickHandler()}>
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-outline-danger mx-2 mx-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  closeModal();
                }}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CarePlanHistoryDetails;
