import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";

const AutoimmuneDisorder = (props) => {
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);

  useEffect(() => {
      setRxController(stateObj);
    }, [stateObj]);

  const [QA, setQA] = useState({});
  const [rx, setRx] = useState({
    clinicalinfo: [],
  });

  // No Changes handelChange

  const handleChange = (event) => {
    setQA({ ...QA, [event.target.name]: event.target.value });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (QA) {
      var arr = Object.keys(QA).map(function (key) {
        return { [key]: QA[key] };
      });

      const ms = arr.map((x) => {
        for (const [key, value] of Object.entries(x)) {
          return { question: key, answer: value };
        }
      });
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: ms },
          completeflag: { ...rxController.completeflag, Clinical: true },
        },
      });
    } else if (!QA) {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: [] },
          completeflag: { ...rxController.completeflag, Clinical: false },
        },
      });
    }

  
  };
  return (

          <div className="container container_alzheimer">
            <form onSubmit={handleSubmit} className="col-12 col-lg-9">
              {/* 3) Clinical Information */}
              <h3 className="alzheimer_h3 mb-3 p-3 fs-3">
                Autoimmune Disorder / Clinical Information
              </h3>
              <div className="col-md-12">
                <div className="form-control mb-2 py-3">
                  {/* Q1 */}
                  <label className="mx-1">
                    {"Other drugs used to treat patient’s condition:"}
                  </label>
                  <input
                    type="text"
                    name="Drugs used fot the condition"
                    size="30"
                    className="col-12 col-sm-auto"
                    onChange={handleChange}></input>
                  <br />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control mb-2 py-3">
                  {/* Q2*/}
                  <label className="mx-1"> First dose of IGIV: </label>
                  <input
                    type="radio"
                    name="First dose of IGIV:"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="mx-1"> Yes </label>
                  <input
                    type="radio"
                    name="First dose of IGIV:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="mx-1"> No </label> &nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control mb-2 py-3">
                  {/* Q3*/}
                  <label className="mx-1"> Prior IGIV products tried: </label>
                  <input
                    type="text"
                    name="Prior IGIV products"
                    size="30"
                    className="col-12 col-sm-auto"
                    onChange={handleChange}></input>
                  <br />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control mb-2 py-3">
                  {/* Q4*/}
                  <label className="mx-1">
                    Adverse reactions with previous IGIV treatments:
                  </label>
                  <input
                    type="text"
                    name="Adverse reactions with previous IGIV treatments:"
                    size="40"
                    className="col-12 col-sm-auto"
                    onChange={handleChange}></input>
                  <br />
                  <label className="mx-1 mt-2"> ICD-10: </label>
                  <input
                    type="text"
                    name="ICD-10"
                    size="10"
                    onChange={handleChange}></input>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control mb-2 py-3">
                  {/* Q5*/}
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 1:"
                      defaultValue="Acute Infective Polyneuritis (Guillain-Barre Syndrome)"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Acute Infective Polyneuritis (Guillain-Barre Syndrome)
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 2:"
                      defaultValue="Pemphigus (Foliaceus / Vulgaris)"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Pemphigus (Foliaceus / Vulgaris)
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 3:"
                      defaultValue="Myasthenia Gravis with Exacerbation"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Myasthenia Gravis with (Acute) Exacerbation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 4:"
                      defaultValue="Myasthenia Gravis without Exacerbation"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Myasthenia Gravis without (Acute) Exacerbation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 5:"
                      defaultValue="Chronic Inflammatory Demyelinating Polyneuropathy"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Chronic Inflammatory Demyelinating Polyneuropathy (CIDP)
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 6:"
                      defaultValue="Inflammatory Polyneuropathy, Unspecified"
                      onChange={handleChange}
                    />
                    <label className="mx-1">
                      Inflammatory Polyneuropathy, Unspecified
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 7:"
                      defaultValue="Dermatomyositis"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Dermatomyositis </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 8:"
                      defaultValue="Multiple Sclerosis"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Multiple Sclerosis (MS) </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 9:"
                      defaultValue="Multifocal Neuropathy"
                      onChange={handleChange}
                    />
                    <label className="mx-1">Multifocal Neuropathy (MMN)</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 10:"
                      defaultValue="Pemphigoid"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Pemphigoid </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 11:"
                      defaultValue="Polymyositis"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Polymyositis </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 12:"
                      defaultValue="Stiff-Person Syndrome"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Stiff-Person Syndrome </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Adverse reactions with previous IGIV treatments 13:"
                      defaultValue="Other"
                      onChange={handleChange}
                    />
                    <label className="mx-1"> Other: </label>
                    <input
                      type="text"
                      name="Other"
                      size="20"
                      className="ms-2"
                      onChange={handleChange}></input>
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2 col-md-12 mb-2 mx-auto">
                <button
                  className="btn btn-outline-primary btn_alz"
                  type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
 
  );
};
export default AutoimmuneDisorder;
