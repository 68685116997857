import React, { useEffect, useState } from "react";
import {
  getMessagesUsers,
  addMessagesUser,
  deleteMessagesUser,
} from "../../../services/PharmacyAdmin";
import { getAllUsersByTypeAndPhId } from "../../../services/Common";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
const MessageUsers = () => {
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [alluserslist, setAllUsersList] = useState([]);
  const [users, setUsers] = useState([]);
  const [messagesusers, setMessagesUsers] = useState([]);
  const [msg, setMsg] = useState("");
  const [checked, setChecked] = useState([]);

  const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };

  const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  };

  const union = (a, b) => {
    return [...a, ...not(b, a)];
  };

  const leftChecked = intersection(checked, users);
  const rightChecked = intersection(checked, messagesusers);

  const handleGetUsers = () => {
    getAllUsersByTypeAndPhId(pharmacy_id)
      .then((res) => {
        let allusers = [];
        let messagesusers = [];

        setAllUsersList(
          res.data.data.filter(
            (user) =>
              user.type.toLowerCase() === "user" ||
              user.type.toLowerCase() === "pharmacyadmin"
          )
        );
        getMessagesUsers(pharmacy_id)
          .then((r) => {
            res.data.data
              .filter(
                (user) =>
                  user.type.toLowerCase() === "user" ||
                  user.type.toLowerCase() === "pharmacyadmin"
              )
              .forEach((i) => {
                if (
                  r.data.users &&
                  r.data.users.some((u) => u.userId === i.id)
                ) {
                  messagesusers.push(i);
                } else {
                  allusers.push(i);
                }
              });

            setUsers(
              allusers.map((i) => `${i.id} - ${i.first} ${i.last} ${i.email}`)
            );

            setMessagesUsers(
              messagesusers.map(
                (i) => `${i.id} - ${i.first} ${i.last} ${i.email}`
              )
            );
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        setUsers([]);
        setMsg("Something went wrong, please try again later");
        console.error(err);
      });
  };

  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setMsg("");
    setMessagesUsers(messagesusers.concat(leftChecked));
    setUsers(not(users, leftChecked));
    setChecked(not(checked, leftChecked));

    checked.forEach((i) => {
      let user = alluserslist.find(
        (user) => user.id.toString() === i.split(" - ")[0].trim()
      );

      let data = {
        userId: user.id,
        pharmacyId: Number(pharmacy_id),
      };
      addMessagesUser(data)
        .then((res) => {
          setMsg("User Added Successfully");
          handleGetUsers();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleCheckedLeft = () => {
    setMsg("");
    setUsers(users.concat(rightChecked));
    setMessagesUsers(not(messagesusers, rightChecked));
    setChecked(not(checked, rightChecked));

    checked.forEach((i) => {
      let user = alluserslist.find(
        (user) => user.id.toString() === i.split(" - ")[0].trim()
      );

      let data = {
        userId: user.id.toString(),
        pharmacyId: Number(pharmacy_id),
      };
      deleteMessagesUser(data)
        .then((res) => {
          setMsg("User Removed Successfully");
          handleGetUsers();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, bgcolor: "#bdddf7" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 530,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className="container-fluid" style={{ minHeight: "85vh" }}>
      {/* <div className="pagetitle">
        <h1>Add User To Messages Group</h1>
        <>{msg}</>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          {msg !== "" && <p className="text-success">{msg}</p>}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <Grid item>{customList("Available Users", users)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right">
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left">
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList("Messages Group Users", messagesusers)}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MessageUsers;
