import React, { useState, useEffect } from "react";
import { SideBar } from "../../../utils/globalState";
import { decodeToken } from "react-jwt";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import { ProviderAddRxController } from "../../../utils/globalState";
import { getUserById } from "../../../services/Common";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import FindPatient from "../../../components/provider/rxCenter/addNewRx/FindPatient";
import Therapy from "../../../components/provider/rxCenter/addNewRx/Therapy";
import Diagnose from "../../../components/provider/rxCenter/addNewRx/Diagnose";
import Clinical from "../../../components/provider/rxCenter/addNewRx/Clinical";
import Medication from "../../../components/provider/rxCenter/addNewRx/Medication";
import { Divider } from "@mui/material";
import Review from "../../../components/provider/rxCenter/addNewRx/Review";
import ReplyIcon from "@mui/icons-material/Reply";

const AddNewRx = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userFirstName = localStorage.getItem("first");
  const userLastName = localStorage.getItem("last");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const providerId = myDecodedToken.userId;
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const steps = [
    "Select Patient",
    "Therapy",
    "Diagnose",
    "Clinical",
    "Medication",
    "Review",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  useEffect(() => {
    getUserById(providerId)
      .then((res) => {
        ProviderAddRxController.setState({
          StepperController: {
            ...rxController,
            rx: { ...rxController.rx, providerId: providerId },
            info: {
              ...rxController.info,
              provider_name: `${res.data.data?.user[0].first} ${res.data.data?.user[0].last}`,
            },
          },
        });
        setRxController(stateObj);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleNext = () => {
    setMsg("");
    if (stateObj.completeflag[steps[activeStep]]) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setMsg(`Please complete ${steps[activeStep].toLowerCase()} step.`);
    }
  };

  const handleBack = () => {
    setMsg("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: steps[activeStep],
      },
    });
  }, [activeStep]);

  const handleReset = () => {
    // e.preventDefault();
    setActiveStep(0);
    ProviderAddRxController.setState({
      StepperController: {
        completeflag: {
          "Select Patient": false,
          Therapy: false,
          Diagnose: false,
          Clinical: false,
          Medication: false,
          Review: false,
        },
        currentstep: "",
        rx: {
          therapy: "",
          ICD10: "",
          diagnose: "",
          providerId: providerId,
          patientId: "",
          clinicalinfo: [],
          medications: [],
        },
        info: {
          provider_name: `${userFirstName} ${userLastName}`,
          patient_name: "",
        },
      },
    });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Add New Rxs</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Rx Center</li>
              <li className="breadcrumb-item active">Add New Rxs</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {msg !== "" && msg}
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ||
              rxController.completeflag.Review ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - The prescription has been added!!
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleReset();
                      }}>
                      Reset To Add More RXs
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "64%",
                      pt: 2,
                      position: "sticky",
                      top: "90px",
                      zIndex: "1",
                      marginBottom: "1rem",
                      marginLeft: "1rem",
                    }}>
                    <Button
                      startIcon={<ReplyIcon />}
                      variant="contained"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {stateObj.completeflag[steps[activeStep]] && (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        endIcon={<SendIcon />}>
                        {activeStep === steps.length - 1 ? "" : "Next"}
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    <Grid container spacing={2}>
                      {rxController.currentstep === "Review" ? (
                        <Review />
                      ) : (
                        <>
                          <Grid xs={8}>
                            <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
                              {rxController.currentstep === "" ||
                              rxController.currentstep === "Select Patient" ? (
                                <FindPatient />
                              ) : rxController.currentstep === "Therapy" ? (
                                <Therapy />
                              ) : rxController.currentstep === "Diagnose" ? (
                                <Diagnose />
                              ) : rxController.currentstep === "Clinical" ? (
                                <Clinical />
                              ) : rxController.currentstep === "Medication" ? (
                                <Medication />
                              ) : (
                                <></>
                              )}
                            </Paper>
                          </Grid>
                          <Grid xs={4}>
                            <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}>
                                    Provider Information
                                  </ListSubheader>
                                }>
                                <ListItemText
                                  primary={`Provider ID : ${rxController.rx.providerId}`}
                                />
                                <ListItemText
                                  primary={`Provider Name : ${rxController.info.provider_name}`}
                                />
                              </List>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}>
                                    Patient Information
                                  </ListSubheader>
                                }>
                                <ListItemText
                                  primary={`Patient ID : ${rxController.rx.patientId}`}
                                />
                                <ListItemText
                                  primary={`Patient Name : ${rxController.info.patient_name}`}
                                />
                              </List>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}>
                                    Prescription Information
                                  </ListSubheader>
                                }>
                                <ListItemText
                                  primary={`Therapy Type : ${rxController.rx.therapy}`}
                                />
                                <Divider />
                                <ListItemText
                                  primary={`ICD10 : ${rxController.rx.ICD10}`}
                                />
                                <Divider />
                                <ListItemText
                                  primary={`Diagnose : ${rxController.rx.diagnose}`}
                                />
                                <Divider />
                                <ListItemText primary={`Clinical Info : `} />
                                <Box
                                  component="div"
                                  sx={{
                                    border: "1px dashed #a8beda",
                                    paddingLeft: "10px",
                                  }}>
                                  <List>
                                    {rxController.rx.clinicalinfo.map((i) => {
                                      return (
                                        <ListItemText
                                          primary={`${i.question} : ${i.answer}`}
                                        />
                                      );
                                    })}
                                  </List>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <ListItemText primary={`Medications : `} />
                                <Box
                                  component="div"
                                  sx={{
                                    border: "1px dashed #a8beda",
                                    paddingLeft: "10px",
                                  }}>
                                  <List>
                                    {rxController.rx.medications.map((i) => {
                                      return (
                                        <>
                                          <ListItemText
                                            primary={`Medication Name : ${i.name}`}
                                          />
                                          <ListItemText
                                            primary={`SIG : ${i.sig}`}
                                          />
                                          <ListItemText
                                            primary={`Medication Dosage Form : ${i.type}`}
                                          />
                                          <ListItemText
                                            primary={`Number of refills : ${i.refills}`}
                                          />
                                          <ListItemText
                                            primary={`Quantity Prescribed  : ${i.quantity}`}
                                            sx={{ marginBottom: "30px" }}
                                          />
                                        </>
                                      );
                                    })}
                                  </List>
                                </Box>
                              </List>
                            </Paper>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddNewRx;
