import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../utils/globalState";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "survey-core/modern.min.css";
import "../../../styles/page-styles/forms.css";
import dayjs from "dayjs";
import {
  convertDateToInputFormat,
  timeZoneDifference,
} from "../../../utils/timeConverter";
import { handleGetCarePlans } from "../../../services/CarePlan";

const CarePlanHistory = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyID = localStorage.getItem("pharmacy");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [catCount, setCatCount] = useState({ active: 0, complete: 0 });
  const [startDate, setStartDate] = useState(
    new Date(new Date() - 1 * 24 * 60 * 60 * 1000 * 30)
  );

  const columnsActive = [
    { field: "id", headerName: "ID", minWidth: 75, maxWidth: 75 },
    { field: "recipientName", headerName: "Patient Name", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 90, maxWidth: 100 },
    {
      field: "isInitialCallAnswered",
      headerName: "Patient Answered Initial Call",
      minWidth: 220,
      valueGetter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "cpOptOut",
      headerName: "CP Opt In",
      minWidth: 100,
      valueGetter: (params) => {
        return !params.value ? "Yes" : "No";
      },
    },
    {
      field: "fuOptedIn",
      headerName: "Follow-up Opt In",
      minWidth: 140,
      valueGetter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "isPhoneFu",
      headerName: "Follow Up Method",
      minWidth: 150,
      valueGetter: (params) => {
        return params.value ? "Phone" : "SMS";
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
  ];

  const columnsComplete = [
    ...columnsActive,
    { field: "completedOn", headerName: "Completed On", minWidth: 165 },
  ];

  useEffect(() => {
    handleGetCarePlans(pharmacyID)
      .then((res) => {
        const processed = res.data
          .map((item) => ({
            ...item,
            createdOn: dayjs(item.createdOn).format("MM/DD/YYYY hh:mm A"),
            completedOn: item.completedOn
              ? dayjs(item.completedOn).format("MM/DD/YYYY hh:mm A")
              : null,
          }))
          .map((cp) => ({
            ...cp,
            recipientName: `${cp.recipient.first} ${cp.recipient.last}`,
            MRN: cp.recipient.MRN,
            DOB: cp.recipient.DOB,
            cpOptOut: cp.recipient.cpOptOut,
          }));

        setData(processed);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pharmacyID]);

  useEffect(() => {
    const filtered = data.filter((item) => {
      const creationDate = new Date(item.createdOn);

      creationDate.setTime(creationDate.getTime() + timeZoneDifference());

      return creationDate >= startDate && creationDate <= endDate;
    });
    setDateFilteredData(filtered);
    setCatCount({
      active: filtered.filter((cp) => !cp.completedOn).length,
      complete: filtered.filter((cp) => cp.completedOn).length,
    });
  }, [startDate, endDate, data]);

  const dateChangeHandler = (e) => {
    const diff = new Date().getTimezoneOffset() * 60000;
    const date = new Date(e.target.value);
    const dateUTC = new Date(date.getTime() + diff);
    const dateLocal = new Date(dateUTC.getTime() + diff);
    if (e.target.name === "startDate") {
      setStartDate(dateLocal);
    } else {
      setEndDate(dateLocal);
    }
    if (e.target.validationMessage) {
      e.target.style = "color:red;font-weight:bold;";
      setMsg(e.target.validationMessage);
    } else {
      e.target.style = "";
      setMsg("");
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Care Plan History</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Care Plan</li>
              <li className="breadcrumb-item active">Care Plan History</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {!isLoading ? (
                <div>
                  {msg && (
                    <div className="ps-2 alert alert-danger" role="alert">
                      {msg}
                    </div>
                  )}
                  <Box sx={{ width: "100%" }}>
                    <div className="d-lg-flex flex-row-reverse justify-content-between">
                      <form className="d-flex flex-row h-75 my-auto">
                        <input
                          name="startDate"
                          onChange={dateChangeHandler}
                          type="date"
                          className="form-control me-1"
                          id="startDate"
                          placeholder="Start Date"
                          value={convertDateToInputFormat(startDate)}
                          min="2008-01-01"
                          max={new Date().toISOString().split("T")[0]}
                        />
                        <input
                          name="endDate"
                          onChange={dateChangeHandler}
                          type="date"
                          className="form-control"
                          id="endDate"
                          placeholder="Start Date"
                          value={convertDateToInputFormat(endDate)}
                          min="2008-01-01"
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </form>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="tabs"
                        className="mb-1 ms-2">
                        <Tab
                          label={
                            <span>
                              Active
                              <span className="text-success fw-bold">
                                &nbsp;({catCount.active})
                              </span>
                            </span>
                          }
                        />

                        <Tab
                          label={
                            <span>
                              Complete
                              <span className="text-info fw-bold">
                                &nbsp;({catCount.complete})
                              </span>
                            </span>
                          }
                        />
                      </Tabs>
                    </div>
                    <div className="dg_1liner">
                      <DataGrid
                        pageSize={25}
                        rows={
                          !tabValue
                            ? dateFilteredData.filter((cp) => !cp.completedOn)
                            : dateFilteredData.filter((cp) => cp.completedOn)
                        }
                        getRowId={(row) => row.id}
                        getRowClassName={(params) => {
                          return `pointer-cursor`;
                        }}
                        columns={!tabValue ? columnsActive : columnsComplete}
                        density="compact"
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        onRowClick={(row) => {
                          navigate(`${row.id}?p=cph`);
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "createdOn", sort: "desc" }],
                          },
                        }}
                      />
                    </div>
                  </Box>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h3 className="text-center mt-5">
                    Loading Data, Please wait...
                  </h3>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CarePlanHistory;
