import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { convertTime } from "../../utils/timeConverter";
import { useParams, useNavigate } from "react-router-dom";
import {
  handleSetDraft,
  handleFormResponse,
  getFormInstanceById,
  getResponseByid,
} from "../../services/PharmacyUser";

const FormFillPage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const { responseId } = useParams();
  const navigate = useNavigate();

  const [currentFormResponse, setCurrentFormResponse] = useState({});
  const [surveyModel, setSurveyModel] = useState({});

  const handleCurrentPendingSurvey = async (formResponse) => {
    let survey = () => {
      if (formResponse.instanceID) {
        return getFormInstanceById(formResponse.instanceID)
          .then((res) => {
            return new Model(JSON.parse(res.data.data.formText));
          })
          .catch((err) => {
            console.error(err);
            return new Model(formResponse.formText);
          });
      } else {
        return new Model(formResponse.formText);
      }
    };

    survey = await survey();
    if (formResponse.value) {
      survey.data = formResponse.value;
    }
    survey.mode = "edit";
    survey.completedHtml = `<div style="text-align: center; margin-top: 20px; margin-bottom: 20px;"><h3>Thank you for completing the form.</h3></div>`;
    survey.onValueChanged.add((sender, options) => {
      handleSetDraft({ value: sender.data }, responseId)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    });
    survey.onComplete.add((sender, options) => {
      handleFormResponse({ value: sender.data }, responseId).catch((err) => {
        console.error(err);
      });
    });
    setSurveyModel(survey);
  };

  const handleCurrentCompletedSurvey = (formResponse) => {
    const survey = new Model(formResponse.formText);
    if (formResponse.value) {
      survey.data = formResponse.value;
    }
    survey.mode = "display";

    setSurveyModel(survey);
  };

  useEffect(() => {
    getResponseByid(responseId)
      .then((res) => {
        const item = res.data[0];
        const processedResponse = {
          ...item,
          isStop: item.isStop === 1 ? true : false,
          isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
          formText: item.formText ? JSON.parse(item.formText) : "",
          instanceId: item.instanceID,
          value: item.value ? JSON.parse(item.value) : "",
          senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
          recipientInfo: item.recipientInfo
            ? JSON.parse(item.recipientInfo)
            : "",

          sender: item.senderInfo
            ? JSON.parse(item.senderInfo).first +
              " " +
              JSON.parse(item.senderInfo).last
            : "",
          recipientName: item.recipientInfo
            ? JSON.parse(item.recipientInfo).first +
              " " +
              JSON.parse(item.recipientInfo).last
            : "",

          recipientType: item.recipientInfo
            ? JSON.parse(item.recipientInfo).type
            : "",
          MRN: item.recipientInfo
            ? JSON.parse(item.recipientInfo).MRN || "N/A"
            : "",
          formName: item.formText ? JSON.parse(item.formText).title : "",

          isArchived: item.isArchived === 1 ? true : false,
          archived_date: item.archived_date
            ? convertTime(item.archived_date)
            : "",
          isReviewed: item.isReviewed === 1 ? true : false,
          reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
          completion: item.completion ? convertTime(item.completion) : "",
          sent: item.sent ? convertTime(item.sent) : "",
          dateOfLastReminder: item.dateOfLastReminder
            ? convertTime(item.dateOfLastReminder)
            : "",
        };
        setCurrentFormResponse(processedResponse);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [responseId]);

  useEffect(() => {
    if (currentFormResponse.status === "pending") {
      handleCurrentPendingSurvey(currentFormResponse);
    } else {
      handleCurrentCompletedSurvey(currentFormResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormResponse]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div>
                <button
                  className="btn btn-success col-auto mb-1"
                  onClick={() => {
                    navigate(-1);
                  }}>
                  Go Back
                </button>
                {Object.keys(surveyModel).length > 0 ? (
                  <Survey model={surveyModel} />
                ) : (
                  <div className="text-center mt-5">
                    <h3 className="text-center mt-5">
                      Loading Data, Please wait...
                    </h3>
                    <div className="spinner-border text-info" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormFillPage;
