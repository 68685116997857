import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideBar } from '../../../utils/globalState';
import { csvfilenamecheck } from '../../../services/PharmacyUser';
import SurveySender from '../../../components/pharmacyUser/surveyCenter/SurveySender';
import { Importer, ImporterField } from 'react-csv-importer';
import PatientsFromDB from "../../../components/pharmacyUser/surveyCenter/PatientsFromDB";
import { Divider } from '@mui/material';
import 'react-csv-importer/dist/index.css';
import '../../../styles/page-styles/addbulkpatients.css';
const CsvFileUpload = () => {
    const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const SurveyId = urlParams.get("id");
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);
  // fetch the survey from the global state.
  // const survey = SelectedSurvey.getState().ThisSurvey;
  const navigate = useNavigate();
  // const [currentSurvey, setCurrentSurvey] = useState(survey);
  const [csvData, setCsvData] = useState([]);
  const [dataPreview, setDataPreview] = useState(false);
  const [dataHeaders, setDataHeaders] = useState([]);
  const [msg, setMsg] = useState('');

  // useEffect(() => {
  //   setCurrentSurvey(survey);
  // }, [survey]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>CSV File Upload</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}
              >
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Upload CSV File</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            {msg !== "" && <p className="text-success text-center ">{msg}</p>}
            {dataPreview ? (
              <SurveySender dataHeaders={dataHeaders} csvData={csvData} />
            ) : (
              <Importer
                chunkSize={100000} // optional, internal parsing chunk size in bytes
                assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                restartable={false} // optional, lets user choose to upload another file when import is complete
                skipEmptyLines={"greedy"} // optional, skips empty lines in the CSV file
                onStart={({ file }) => {
                  // optional, invoked when user has mapped columns and started import
                  ///////////////////////////// handle file upload checker invoked here //////////////////////////////
                  csvfilenamecheck(file.name)
                    .then((res) => {
                      setMsg(
                        `${file.name} ${
                          res.data.message === "File already exists."
                            ? "has been uploaded before."
                            : res.data.message
                        }`
                      );
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                processChunk={(rows) => {
                  // required, receives a list of parsed objects based on defined fields and user column mapping;
                  // may be called several times if file is large
                  // (if this callback returns a promise, the widget will wait for it before parsing more data)

                  setCsvData(
                    rows.map((i, ind) => {
                      i.id = ind + 1;
                      return i;
                    })
                  );
                }}
                onComplete={({ file, fields, preview }) => {
                  setDataHeaders(fields);
                  setDataPreview(true);
                }}
                // onClose={() => {

                // }}
              >
                <ImporterField name="fname" label="Patient First Name" />
                <ImporterField name="lname" label="Patient Last Name" />
                <ImporterField name="dob" label="Date of Birth" />
                <ImporterField name="mrn" label="Patient MRN" optional />
                <ImporterField
                  name="patientPhone"
                  label="Patient Cell Phone"
                  
                />
                <ImporterField name="email" label="Patient Email" optional />
                <ImporterField
                  name="zipCode"
                  label="Patient Zip Code"
                  optional
                />
                <ImporterField name="patientCompany" label="Patient Company" />
                <ImporterField name="dispenseDate" label="Dispense Date" />
                <ImporterField
                  name="dispensePayorType"
                  label="Dispense Payor Type"
                />
                <ImporterField name="pharmacyNPI" label="Pharmacy NPI" />
                <></>
              </Importer>
            )}
          </div>
        </section>

        <div className="row">
          <section className="section profile">
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }}>
              OR
            </Divider>
            <PatientsFromDB surveyID={SurveyId} />
          </section>
        </div>
      </main>
    </div>
  );
};

export default CsvFileUpload;
