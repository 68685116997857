import React, { useEffect, useState } from "react";
import {
  getNotificationsUsers,
  addNewRecipent,
  deleteRecipent,
} from "../../../services/PharmacyUser";
import { getAllUsersByTypeAndPhId } from "../../../services/Common";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SideBar } from "../../../utils/globalState";
import { useNavigate } from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
function Recipients() {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let id = urlParams.get("id");
  const pharmacy_id = localStorage.getItem("pharmacy");
  const navigate = useNavigate();

  const [Recipient, setRecipient] = useState([]);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [errMasg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
    getAllUsersByTypeAndPhId(pharmacy_id)
      .then((res) => {
        setUsersList(
          res.data.data.filter(
            (user) =>
              user.type.toLowerCase() === "user" ||
              user.type.toLowerCase() === "pharmacyadmin"
          )
        );
        setOptions(
          res.data.data
            .filter(
              (user) =>
                user.type.toLowerCase() === "user" ||
                user.type.toLowerCase() === "pharmacyadmin"
            )
            .map((u) => {
              return `${u.id} - ${u.first} ${u.last} - ${u.email}`;
            })
        );
      })
      .catch((err) => {
        console.error(err);
        setUsersList([]);
      });
    getNots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  console.log("usersList", usersList);

  const handleAdd = (e) => {
    e.preventDefault();
    setErrMsg("");
    let input = usersList.find(
      (user) => user.id.toString() === value.split(" - ")[0]
    );
    if (input === undefined) {
      setErrMsg("User Not Found.");
      return;
    }
    if (Recipient.find((user) => user.email === input.email)) {
      setErrMsg("User Already Exists.");
      return;
    }
    addNewRecipent({
      email: input.email,
      survey_ID: id,
      userId: input.id,
      userType: input.type,
    })
      .then((res) => {
        getNots();
      })
      .catch((err) => {
        setErrMsg("Not able to add user.");
      });
    setInputValue("");
    setValue("");
  };

  const getNots = () => {
    getNotificationsUsers()
      .then((res) => {
        setRecipient(res.data.filter((user) => user.survey_ID === id));
      })
      .catch((err) => {
        setErrMsg("No Users Found.");
        console.error(err);
      });
  };

  const handleDelete = (id) => {
    setErrMsg("");
    deleteRecipent(id)
      .then((res) => {
        getNots();
      })
      .catch((err) => {
        setErrMsg("Not able to delete user.");
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Recipients</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}>
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Survey Recipients</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <p className="text-danger m-2">{errMasg !== "" && errMasg}</p>
              <div className="row">
                <div className="col-6">
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    renderInput={(params) => (
                      <TextField {...params} label="SELECT A RECIPIENT" />
                    )}
                  />
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleAdd}>
                    {" "}
                    Add Recipient
                  </button>
                </div>
              </div>
            </div>
            <ul class="list-group">
              {Recipient.length > 0 ? (
                Recipient.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="start"
                            aria-label="delete"
                            onClick={() => handleDelete(item.email)}>
                            <DeleteIcon sx={{ fontSize: "35px" }} />
                          </IconButton>
                        }>
                        <ListItemText primary={`${item.email}`} />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Recipients;
