import React, { useState, useEffect } from "react";
import { SideBar } from "../../../utils/globalState";
import { decodeToken } from "react-jwt";
import { convertTimeLocally } from "../../../utils/timeConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAllRxsByProviderId } from "../../../services/Provider";
import RxDetails from "../../../components/provider/rxCenter/RxDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
const ManageRxs = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const providerId = myDecodedToken.userId;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
    },
    { field: "Patient ID", headerName: "Patient ID", minWidth: 115 },
    { field: "Patient Name", headerName: "Patient Name", minWidth: 150 },
    { field: "Diagnosis", headerName: "Diagnosis", minWidth: 350 },
    { field: "ICD10", headerName: "ICD10", minWidth: 100 },
    { field: "Medication", headerName: "Medication", minWidth: 300 },
    {
      field: "Created At",
      headerName: "Created At",
      minWidth: 200,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
  ];

  const [rxList, setRxList] = useState([]);
  const [fullRxInfo, setFullRxInfo] = useState([]);
  const [details, setDetails] = useState(false);
  const [curRxInfo, setCurRxInfo] = useState({});

  const hendelGetAllRxs = () => {
    getAllRxsByProviderId(providerId)
      .then((res) => {
        res?.data?.referrals && setFullRxInfo(res.data.referrals);
        res?.data?.referrals &&
          setRxList(
            res.data.referrals.map((el) => {
              return {
                id: el.id,
                "Patient ID": el.patient.id,
                "Patient Name": el.patient.first + " " + el.patient.last,
                Diagnosis: el.Diag[0].diagnose,
                ICD10: el.Diag[0].ICD10,
                "Created At": convertTimeLocally(el.created_at),
                Medication: el.medications.reduce((acc, item, idx) => {
                  return (
                    acc +
                    item.name +
                    `${idx === el.medications.length - 1 ? "" : " / "}`
                  );
                }, ""),
              };
            })
          );
      })
      .catch((err) => {
        setRxList([]);
      });
  };

  useEffect(() => {
    hendelGetAllRxs();
  }, []);

  const rowClickedListener = (params) => {
    setDetails(true);
    setCurRxInfo(fullRxInfo.find((item) => item.id === params.row.id));
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Manage Rxs</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Rx Center</li>
              <li className="breadcrumb-item active">Manage Rxs</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {details ? (
              <Paper
                elevation={1}
                sx={{ height: "100%", width: "100%", padding: "20px" }}
              >
                <IconButton
                  onClick={() => {
                    setDetails(false);
                    setCurRxInfo({});
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <RxDetails curReferral={curRxInfo} />
              </Paper>
            ) : (
              <div className="mt-2" id="mng_pt_grid">
                <div className="dg_0liner">
                  <DataGrid
                    rows={rxList}
                    columns={columns}
                    density="compact"
                    pageSizeOptions={[100]}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "Created At", sort: "desc" }],
                      },
                    }}
                    onRowClick={rowClickedListener}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageRxs;
