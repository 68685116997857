import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { registerLicense } from "@syncfusion/ej2-base";

const syncFusionKey = process.env.REACT_APP_CLENDAR_KEY;
registerLicense(syncFusionKey);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);
