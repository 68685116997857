import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../utils/globalState";
import AddNewPatient from "./AddNewPatient";
import SearchForPatient from "./SearchForPatient";

const FindPatient = () => {

    const stateObj = ProviderAddRxController((state) =>state.StepperController )
    const [rxController , setRxController] = useState(stateObj)
useEffect(()=>{
  setRxController(stateObj)
},[stateObj]) 

useEffect(() =>{
    ProviderAddRxController.setState({StepperController:{
        ...rxController, currentstep:"Select Patient"}})
},[])
  return (
<>
<h1 className="text-center">Search For A Patient</h1>
<SearchForPatient />
<h1 className="text-center">OR</h1>
<h1 className="text-center">Add New Patient</h1>
<AddNewPatient />
</>
  )
}

export default FindPatient