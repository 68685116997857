import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar, SelectedSurvey } from "../../../utils/globalState";
import { getSurveyById, getAllRes } from "../../../services/Provider";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { decodeToken } from "react-jwt";
import { convertTimeLocally } from "../../../utils/timeConverter";
import { Model } from "survey-core";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
import CircularProgress from "@mui/material/CircularProgress";
const SurveyAnalyticsTabulator = require("survey-analytics/survey.analytics.tabulator");

const Results = () => {
  const navigate = useNavigate();
  const visContainerRef = useRef(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let id = urlParams.get("id");

  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
  const pharmacy = localStorage.getItem("pharmacy");

  // SurveyJS Creator options

  const [msg, setMsg] = useState("");
  const [survey, setSurvey] = useState({});
  const [surveyResults, setSurveyResults] = useState([]);
  const [resLoadingFlag, setResLoadingFlag] = useState(true);

  const getResponsesBySurvid = (surveyId) => {
    setResLoadingFlag(true);
    getAllRes()
      .then((res) => {
        if (res.data.All_Patients_Response.length > 0) {
          Promise.all(
            res.data.All_Patients_Response.map((i) => {
              try {
                JSON.parse(i.survey_response);
                i.survey_response = JSON.parse(i.survey_response);
              } catch (error) {
                i.survey_response = {};
              }

              return i;
            })
              .filter((item) => item.survey_response.surveyId === id.toString())
              .map((i) => {
                i.survey_response.response.id = i.id;
                i.survey_response.response.created_at = convertTimeLocally(
                  i.created_at
                );
                i.survey_response.response.source = i.survey_response.source
                  ? i.survey_response.source
                  : "SMS";
                return i.survey_response.response;
              })
          ).then((res) => {
            setSurveyResults(res);
            setResLoadingFlag(false);
          });
        } else {
          setSurveyResults([]);
          setResLoadingFlag(false);
        }
      })
      .catch((err) => {
        setMsg("Something went wrong.");
      });
  };

  useEffect(() => {
    if (id) {
      getSurveyById(id)
        .then((res) => {
          res.data[0].json = JSON.parse(res.data[0].survey);
          setSurvey(res.data[0].json);
        })
        .catch((err) => {
          console.error(err);
        });

      getResponsesBySurvid(id);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      let options = {
        haveCommercialLicense: true,
        showLogicTab: true,
        showOptions: true,
        showSaveSurveyButton: true,
      };
      if (visContainerRef.current) {
        var model = new Model(survey);
        visContainerRef.current.innerHTML = "";
        var surveyAnalyticsTabulator = new SurveyAnalyticsTabulator.Tabulator(
          model,
          surveyResults.map((item) =>
            typeof item === "string" ? JSON.parse(item) : item
          ),
          options
        );
        surveyAnalyticsTabulator.render(visContainerRef.current);
      }
    })();
  }, [survey, surveyResults]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}>
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Edit & Review</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            {msg !== "" && msg}

            <>
              {resLoadingFlag ? (
                <CircularProgress />
              ) : (
                <>
                  {surveyResults.length > 0 ? (
                    <div className="sjs-results-content" ref={visContainerRef}>
                      <div className="sjs-results-placeholder">
                        <span>This survey doesn't have any answers yet</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>No responses found!</p>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Results;
