import React from "react";
import { Modal } from "@mui/material";
import placeholder from "../../../assets/images/patient_placeholder.png";

const CaregiversModal = (props) => {
  const { selectedProviderForModal, openModal, setOpenModal } = props;
  const {
    id,
    first,
    last,
    pic_url,
    phone,
    faxNumber,
    email,
    website,
    NPI,
    DEA,
    referralSource,
    stateLicense,
  } = selectedProviderForModal;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <div className="modal-content">
        {selectedProviderForModal && (
          <div>
            <button onClick={handleCloseModal}>X</button>
            <div
              className="tab-pane fade show active profile-overview mx-auto"
              id="profile-overview">
              <h4 className="card-title text-center my-3">Provider Details</h4>
              <div className="card py-2 px-3">
                <img
                  src={pic_url || placeholder}
                  alt="Profile"
                  className="rounded-circle my-4 mx-auto d-block img-fluid"
                  id="profile-img-big"
                />
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">ID: </p>
                  <p className="col-lg-7 col-md-8">{id}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">Name: </p>
                  <p className="col-lg-7 col-md-8">
                    {first} {last}
                  </p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">Phone</p>
                  <p className="col-lg-7 col-md-8">{phone}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">Fax</p>
                  <p className="col-lg-7 col-md-8">{faxNumber}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">Email</p>
                  <p className="col-lg-7 col-md-8">{email}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">Website</p>
                  <p className="col-lg-7 col-md-8">{website}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">NPI</p>
                  <p className="col-lg-7 col-md-8">{NPI}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">DEA</p>
                  <p className="col-lg-7 col-md-8">{DEA}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">
                    State License
                  </p>
                  <p className="col-lg-7 col-md-8">{stateLicense}</p>
                </div>
                <div className="row">
                  <p className="col-lg-5 col-md-4 label fw-semibold">
                    Referral Source
                  </p>
                  <p className="col-lg-7 col-md-8">{referralSource}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CaregiversModal;
