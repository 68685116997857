/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../../../styles/page-styles/messages.css";
import "animate.css";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CardHeader from "@mui/material/CardHeader";
import { decodeToken } from "react-jwt";
import { getPharmacyById, getUserById } from "./../../../services/Common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
function Channels(props) {
  // Material UI Starts
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        padding: 3,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };
  //   ======================= Material UI Ends ===================
  const { channel } = props;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
  const [classVar, setClassVar] = useState("");
  const [showhideflag, setShowhideflag] = useState(true);
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [curUserInfo, setCurUserInfo] = useState({});

  useEffect(() => {
    setClassVar(channel.className);
    setShowhideflag(channel.localShow);
  }, [channel.className, channel.show, channel.localShow]);
  useEffect(() => {
    if (channel.pharmacyId) {
      getPharmacyById(channel.pharmacyId)
        .then((res) => {
          setPharmacyInfo(res.data);
        })
        .catch((error) => {
          console.error("Error in getting pharmacy info", error);
        });
    }
  }, [channel.pharmacyId]);

  useEffect(() => {
    getUserById(userId)
      .then((res) =>
        setCurUserInfo(res?.data?.data?.user[0] ? res.data.data.user[0] : {})
      )
      .catch((error) => console.error(error));
  }, [userId]);

  const getChannelMembersNames = (channel) => {
    let membersString = "";

    channel.members.forEach((member, i) => {
      if ([1, 4].includes(channel.channelType)) {
        if (member.userId !== Number(userId)) {
          membersString += `${member.first} ${member.last}`;
          if (i < channel.members.length - 1) {
            membersString += ", ";
          }
        }
      } else {
        const curUserType = curUserInfo?.type?.toLowerCase();

        if (["provider", "patient"].includes(curUserType)) {
          membersString += `${pharmacyInfo.name} ${
            pharmacyInfo.name?.toLowerCase()?.includes("pharmacy")
              ? ""
              : "Pharmacy"
          }  `;
        } else {
          if (member.userId !== Number(userId)) {
            membersString += `${member.first} ${member.last}`;
            if (i < channel.members.length - 1) {
              membersString += ", ";
            }
          }
        }
      }
    });

    if (membersString[membersString.length - 2] === ",") {
      membersString = membersString.slice(0, -2);
    }

    return membersString;
  };

  const getChannelMembersAvatars = (channel) => {
    if ([1, 4].includes(channel.channelType)) {
      return (
        <AvatarGroup max={4}>
          {channel.members.map((member) => {
            if (member.userId !== Number(userId)) {
              return member.pic_url ? (
                <Avatar
                  src={`${member.pic_url}?v=${Date.now()}`}
                  sx={{ scale: "1.3", marginTop: "0.35rem" }}
                  key={member.userId}
                />
              ) : (
                <Avatar
                  {...stringAvatar(`${member.first} ${member.last}`)}
                  key={member.userId}
                />
              );
            }
          })}
        </AvatarGroup>
      );
    } else {
      const curUserType = curUserInfo?.type?.toLowerCase();
      if (["provider", "patient"].includes(curUserType)) {
        return (
          <AvatarGroup max={4}>
            <Avatar
              src={`${pharmacyInfo.logo_url}?v=${Date.now()}`}
              sx={{ scale: "1.3", marginTop: "0.35rem" }}
            />
          </AvatarGroup>
        );
      } else {
        return (
          <AvatarGroup max={4}>
            {channel.members.map((member) => {
              if (member.userId !== Number(userId)) {
                return member.pic_url ? (
                  <Avatar
                    src={`${member.pic_url}?v=${Date.now()}`}
                    sx={{ scale: "1.3", marginTop: "0.35rem" }}
                    key={member.userId}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(`${member.first} ${member.last}`)}
                    key={member.userId}
                  />
                );
              }
            })}
          </AvatarGroup>
        );
      }
    }
  };

  return (
    <div className="mx-1">
      <li
        className={`clearfix chat-about ${classVar} mx-0 ${
          channel.show ? "show" : "hide"
        }`}
        onClick={() => {
          channel.className = "active";
          props.onChannelUpdate(props.channel);
        }}
      >
        <div id={channel.channelId} className={`mx-0 px-0`}>
          {channel.className === "newmessage" && (
            <Badge
              badgeContent={1}
              color="primary"
              className=" float-end"
             
            ></Badge>
          )}
          <div
            id={props.channel.scroll}
            onClick={() => props.setMobHide("dont-show")}
          >
            <CardHeader
              action={
                <IconButton
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={() => props.onClose(channel)}
                >
                  {showhideflag ? (
                    <Tooltip title="Hide">
                      <VisibilityOffIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show">
                      <VisibilityIcon />
                    </Tooltip>
                  )}
                </IconButton>
              }
              avatar={
                channel.members.length > 0
                  ? getChannelMembersAvatars(channel)
                  : ""
              }
              title={
                channel.members.length > 0
                  ? getChannelMembersNames(channel)
                  : ""
              }
            />
          </div>
        </div>
      </li>
    </div>
  );
}

export default Channels;
