import React, { useState, useEffect } from "react";
import { useModal } from "react-hooks-use-modal";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getUserById } from "../../../services/Common";
import { handleDeleteEventWithAllFollowings } from "./../../../services/Calendar";
import {
  updateCpOptOutByPatientId,
  handleGetCarePlans,
  handleUpdateCarePlan,
} from "../../../services/CarePlan";

const Careplan = (props) => {
  const { curPatient, phUser, privileges } = props;
  const patientId = curPatient.id;
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"));

  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const navigate = useNavigate();

  const [curPtOptOut, setCurPtOptOut] = useState(curPatient.cpOptOut);
  const [ptCareplans, setPtCareplans] = useState([]);
  const [curPortal, setCurPortal] = useState("pharmacy-user");

  const [Modal, open, close] = useModal("root", {
    preventScroll: true,
  });

  const columnDef = [
    { field: "id", headerName: "ID", minWidth: 75, maxWidth: 75 },
    {
      field: "isInitialCallAnswered",
      headerName: "Patient Answered Initial Call",
      minWidth: 220,
      valueGetter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "cpOptOut",
      headerName: "CP Opt In",
      minWidth: 100,
      valueGetter: (params) => {
        return !params.value ? "Yes" : "No";
      },
    },
    {
      field: "fuOptedIn",
      headerName: "Follow-up Opt In",
      minWidth: 140,
      valueGetter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "isPhoneFu",
      headerName: "Follow Up Method",
      minWidth: 150,
      valueGetter: (params) => {
        return params.value ? "Phone" : "SMS";
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
  ];

  const fetchCpData = (pharmacyId) => {
    handleGetCarePlans(pharmacyId)
      .then((res) => {
        const allCps = res.data;
        const patientCps = allCps
          .filter((cp) => cp.recipientID === patientId)
          .map((cp) => ({
            ...cp,
            cpOptOut: cp.recipient.cpOptOut,
          }));
        setPtCareplans(patientCps);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!pharmacyId) return;
    fetchCpData(pharmacyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  useEffect(() => {
    if (!userID) return;
    getUserById(userID)
      .then((res) => {
        const user = res.data.data.user[0];
        if (user.type.toLowerCase() === "pharmacyadmin") {
          setCurPortal("pharmacy-admin");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userID]);

  const statusClickHandler = () => {
    if (!curPtOptOut) {
      ptCareplans.forEach((cp) => {
        cp.fuEventId &&
          handleDeleteEventWithAllFollowings(cp.fuEventId, userID);
        handleUpdateCarePlan(cp.id, { fuEventId: null });
      });
    }

    const payload = {
      cpOptOut: !curPtOptOut,
    };
    updateCpOptOutByPatientId(curPatient.id, payload)
      .then(() => {
        setCurPtOptOut(!curPtOptOut);
        close();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row">
          <p
            className="label fw-semibold"
            style={{ color: "#01297099", fontWeight: 600 }}>
            Opted in Careplan:{" "}
          </p>
          <p className={`text-${curPtOptOut ? "danger" : "success"} ms-2`}>
            {curPtOptOut ? "No" : "Yes"}
          </p>
        </div>
        {(privileges.includes("Careplan Opt Out") ||
          phUser.type === "pharmacyAdmin") && (
          <div>
            <button className="btn btn-success" onClick={() => open()}>
              Change Status
            </button>
          </div>
        )}
      </div>
      <hr className="my-3" />
      <div>
        <p
          className="label fw-semibold mb-2"
          style={{ color: "#01297099", fontWeight: 600 }}>
          Current Careplan/s:
        </p>
        {ptCareplans.length === 0 ? (
          <div
            style={{
              height: "15rem",
              display: "flex",
              alignItems: "start",
              marginTop: "2rem",
            }}>
            <p className="alert alert-warning mx-3 text-center w-100">
              No careplan found
            </p>
          </div>
        ) : (
          <DataGrid
            style={{ height: "20rem" }}
            pageSize={25}
            rows={ptCareplans}
            getRowId={(row) => row.id}
            getRowClassName={(params) => {
              return `pointer-cursor`;
            }}
            columns={columnDef}
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            onRowClick={(row) => {
              navigate(
                `/${curPortal}/cp-center/care-plan-manager/${row.id}?p=cpp`
              );
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "createdOn", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
      <Modal title="Change Careplan Status" close={close}>
        <div className="card" style={{ width: "35vw", height: "35vh" }}>
          <div className="card-body">
            <h5 className="card-title">Change Careplan Status</h5>
            <hr />
            <p className="card-text">
              Confirm you want to{" "}
              <span
                className={`fw-bold text-${
                  curPtOptOut ? "success" : "danger"
                }`}>
                {curPtOptOut ? "Opt In" : "Opt Out"}{" "}
              </span>
              {curPatient.first} {curPatient.last} for careplan
            </p>
            <div className="d-flex justify-content-between mt-3">
              <button
                className={`btn btn-outline-${
                  curPtOptOut ? "success" : "danger"
                }`}
                onClick={() => statusClickHandler()}>
                {curPtOptOut ? "Opt In" : "Opt Out"}
              </button>
              <button className="btn btn-secondary" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Careplan;
