import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";


const IGAutoimmuneDisorder = () => {
    const stateObj = ProviderAddRxController((state) => state.StepperController);
    const [rxController, setRxController] = useState(stateObj);
  
    useEffect(() => {
        setRxController(stateObj);
      }, [stateObj]);
  
    const [QA, setQA] = useState({});
    const [rx, setRx] = useState({
      clinicalinfo: [],
    });

    const handleChange = (event) => {
        setQA({ ...QA, [event.target.name]: event.target.value });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        if (QA) {
          var arr = Object.keys(QA).map(function (key) {
            return { [key]: QA[key] };
          });
    
          const ms = arr.map((x) => {
            for (const [key, value] of Object.entries(x)) {
              return { question: key, answer: value };
            }
          });
          ProviderAddRxController.setState({
            StepperController: {
              ...rxController,
              rx: { ...rxController.rx, clinicalinfo: ms },
              completeflag: { ...rxController.completeflag, Clinical: true },
            },
          });
        } else if (!QA) {
          ProviderAddRxController.setState({
            StepperController: {
              ...rxController,
              rx: { ...rxController.rx, clinicalinfo: [] },
              completeflag: { ...rxController.completeflag, Clinical: false },
            },
          });
        }
    
      
      };
    return (
        <>
            <div className="container container_hemat">
                <form>
                    {/* 3) Clinical Information */}
                    <h3 className="hemo_h3"> IG Autoimmune / Clinical Information </h3>
                    <label htmlFor="IGAutoimmuneDisorder" className="form-label form-control"> {"Other drugs used to treat patient’s condition:"}
                        <input type="text" name="Drugs used fot the condition" className="form-control" size="30"></input>
                    </label>
                    <label className="form-label form-control"> First dose of IGIV: <br/>
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" name="First dose of IGIV" defaultValue="Yes"/>
                            <label className="form-check-label" htmlFor="html"> Yes </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" name="First dose of IGIV" defaultValue="No"/>
                            <label className="form-check-label" htmlFor="css"> No </label>
                        </div>
                    </label>
                    <label className="form-label form-control" htmlFor="Prior IGIV products"> Prior IGIV products tried:
                        <input type="text" name="Prior IGIV products" className="form-control" size="30"></input>
                    <label className="form-label" htmlFor="Adverse reactions"> Adverse reactions with previous IGIV treatments:
                        <input type="text" name="Adverse reactions" className="form-control" size="60"></input>
                    </label>
                    </label>
                    <label className="form-label form-control" htmlFor="ICD-10"> ICD-10: &nbsp;
                        <input type="text" name="ICD-10" size="10"></input>
                    </label>
                    <label htmlFor="" className="form-label form-control">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" name="ICD-10-1" defaultValue="Acute Infective Polyneuritis"/>
                            <label className="form-check-label" htmlFor="html"> {"Acute Infective Polyneuritis (Guillain-Barre Syndrome)"} </label>
                        </div>
                        <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-2" defaultValue="Inflammatory Polyneuropathy"/>
                        <label htmlFor="html" className="form-check-label"> Inflammatory Polyneuropathy, Unspecified </label> <br/>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-3" defaultValue="Pemphigus"/>
                        <label htmlFor="html" className="form-check-label"> Pemphigus (Foliaceus / Vulgaris) </label>
                         </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-4" defaultValue="Dermatomyositis"/>
                        <label htmlFor="html" className="form-check-label"> Dermatomyositis </label>
                         </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-5" defaultValue="Stiff-Person Syndrome"/>
                        <label htmlFor="html" className="form-check-label"> Stiff-Person Syndrome </label> <br/>
                         </div>
                         <div className="form-check">
                        <input type="checkbox"  className="form-check-input" name="ICD-10-6" defaultValue="Myasthenia Gravis with Exacerbation"/>
                        <label htmlFor="html" className="form-check-label"> Myasthenia Gravis with (Acute) Exacerbation </label>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-7" defaultValue="Multiple Sclerosis"/>
                        <label htmlFor="html" className="form-check-label"> Multiple Sclerosis (MS) </label>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-8" defaultValue="Pemphigoid"/>
                        <label htmlFor="html" className="form-check-label"> Pemphigoid </label>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-6" defaultValue="Myasthenia Gravis without Exacerbation"/>
                        <label htmlFor="html" className="form-check-label"> Myasthenia Gravis without (Acute) Exacerbation </label>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-7" defaultValue="Multifocal Neuropathy"/>
                        <label htmlFor="html" className="form-check-label"> Multifocal Neuropathy (MMN) </label> <br/>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-8" defaultValue="Chronic Inflammatory Demyelinating Polyneuropathy"/>
                        <label htmlFor="html" className="form-check-label"> {"Chronic Inflammatory Demyelinating Polyneuropathy (CIDP)"} </label>
                        </div>
                         <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="ICD-10-9" defaultValue="Other"/>
                        <label htmlFor="html" className="form-check-label"> Other:
                            <input type="text" name="Other" className="form-control" size="60"></input>
                        </label>
                        </div>
                    </label>
                    <div className="d-grid gap-2 col-md-12 mx-auto">
                        <button className="btn btn-outline-primary btn_ast" type="submit">Submit</button>
                    </div>
                </form>
            </div>

        </>
    )
}
export default IGAutoimmuneDisorder