import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const MassTextSummary = (props) => {
  const { summary } = props;

  const [rowData, setRowData] = useState([]);

  const setColumnDefs = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      minWidth: 70,
    },
    { field: "first", headerName: "First", minWidth: 100 },
    { field: "last", headerName: "Last", minWidth: 100 },
    { field: "phone", headerName: "Phone", minWidth: 150 },
    { field: "sent", headerName: "Sent", minWidth: 100 },
    {
      field: "notes",
      headerName: "Notes",
      minWidth: 400,
    },
  ];

  useEffect(() => {
    summary.length > 0 &&
      setRowData(
        summary.map((el) => ({ ...el, sent: el.sent ? "Yes" : "No" }))
      );
  }, [summary]);

  return (
    <div className="container-fluid" style={{ minHeight: "60vh" }}>
      <div
        className="ag-theme-alpine mt-2"
        style={{ height: 420, width: "auto" }}>
        <DataGrid
          rows={rowData}
          columns={setColumnDefs}
          density="compact"
          components={{
            Toolbar: GridToolbar,
          }}
          
        />
      </div>
    </div>
  );
};

export default MassTextSummary;
