import baseURL from "../utils/baseURL";
import axiosWithAuth from "./../utils/axiosWithAuth";
import sha256 from "crypto-js/sha256";
import axios from "axios";
// Login

export const handleAuth = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/auth/login`, payload);
};

export const getUserPrivilege = (roleId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/role/${roleId}/privileges`
  );
};

export const getUserRole = (id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/user/${id}/role`
  );
};

export const uploadProfilePic = (formData, userId) => {
  return axiosWithAuth("multipart/form-data").post(
    `${baseURL.curoback}/user/uploadPicture/${userId}`,
    formData
  );
};

export const getProfilePic = (userId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/user/getUserPicture/${userId}`
  );
};

export const updatePassword = (id, newPass) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/user/${id}/updatePassword`,
    newPass
  );
};

export const handleRanPass = (email) => {
  return axiosWithAuth().put(`${baseURL.curoback}/auth/resetPassword`, {
    email: email,
    phone: "",
  });
};

export const validatePharmacy = async (BIN) => {
  return await axiosWithAuth().post(
    `${baseURL.curoback}/auth/validatePharmacy`,
    BIN
  );
};
export const validateUser = async (code) => {
  return await axiosWithAuth()
    .post(`${baseURL.curoback}/auth/validateUser`, code)
    .then((resp) => {
      return resp;
    });
};

export const userRegister = async (user) => {
  return await axiosWithAuth()
    .post(`${baseURL.curoback}/auth/register`, user)
    .then((resp) => {
      return resp;
    });
};

export const registerUserToPharmacy = async (user) => {
  return await axiosWithAuth()
    .post(`${baseURL.curoback}/auth/addUserToPharmacy`, user)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err;
    });
};

// Header
export const getUserById = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/${id}`);
};

export const getAllUsers = (phId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/pharmacy/${phId}/users`
  );
};

export const handleUpdateChannel = (data, chId) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/message/update_channel/${chId}`,
    data
  );
};

export const getNotificationsByUserID = (UserId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/notifications/notifications/${UserId}`
  );
};

export const generateToken = (userID) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/auth/app-less-auth/${userID}`
  );
};

export const sendSMS = (payload) => {
  const { to, body, from } = payload;

  const processed = {
    to: Array.isArray(to) ? to : [to],
    body,
    from,
  };

  if (!from) {
    delete processed.from;
  }

  return axiosWithAuth().post(`${baseURL.curoback}/user/sendSMS`, processed);
};

export const sendEmail = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/user/sendEmail`, payload);
};

export const handleEncryption = (plainTxt) => {
  var ciphertext = sha256(plainTxt).toString();
  return ciphertext;
};

// End point to link patient to a provider
export const linkPatientToProvider = (providerID, patientID) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/provider/addProviderPatient`,
    { providerID, patientID }
  );
};

// End point to toggle patient's provider existing link status
export const togglePatientProviderLinkStatus = (providerID, patientID) => {
  return axiosWithAuth().put(`${baseURL.curoback}/provider/togglelinkstatus`, {
    providerID,
    patientID,
  });
};

export const urlShortner = (url) => {
  return axios
    .post(
      `https://api.tinyurl.com/create?api_token=${process.env.REACT_APP_TINYURL_KEY}`,
      { url, domain: "shortlink.curorx.life" }
    )
    .then((res) => {
      return res.data.data.tiny_url;
    })
    .catch((err) => {
      console.error(err);
      return url;
    });
};

// End point to get pharmacy info by id
export const getPharmacyById = (pharmacyID) => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/pharmacy/${pharmacyID}`);
};

// End point to get users by pharmacy id and type
// type is optional: filter by type or remove to get all users
export const getAllUsersByTypeAndPhId = (pharmacyID, type = "") => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/user/users/${pharmacyID}?type=${type}`
  );
};

// end point to get system user by pharmacyId
export const getSystemUserByPharmacyId = (pharmacyID) => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/system/${pharmacyID}`);
};

// end point to toggle user active status
export const updateUserActiveStatus = (userId, active, updatedBy) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/user/toggleActive/${userId}`,
    { active, updatedBy }
  );
};
