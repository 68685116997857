import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import RecipentsFromDB from './RecipentsFromDB';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import template from '../../../assets/templates/template.xls';
import {
  sendDoc,
  storeDocResponseLinkInDB,
  deleteDocResponse,
} from '../../../services/PharmacyUser';
import { searchPt } from '../../../services/Provider';
import { decodeToken } from 'react-jwt';
import { sendSMS } from '../../../services/Common';
import baseURL from '../../../utils/baseURL';

function SendDoc(props) {
  const [dataMatchingDb, setDataMatchingDb] = useState([]);
  const [dataNotMatchingDb, setDataNotMatchingDb] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [smssuccess, setSmssuccess] = useState([]);
  const [smsfail, setSmsfail] = useState([]);
  const [columns, setColumns] = useState([]);
  const [logflag, setLogflag] = useState(false);

  const pharmacy_id = localStorage.getItem('pharmacy');
  const pharmacy_name = localStorage.getItem('pharmacyName');
  const token = localStorage.getItem('Token');
  const myDecodedToken = decodeToken(token) || {};
  const senderId = myDecodedToken.userId;

  useEffect(() => {
    let c = [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'first', headerName: 'First name', width: 100 },
      { field: 'last', headerName: 'Last name', width: 100 },
      { field: 'phone', headerName: 'Phone', width: 100 },
      { field: 'status', headerName: 'Status', width: 100 },
      { field: 'msg', headerName: 'Details', width: 500 },
    ];
    setColumns(c);
  }, []);

  function cleanPhoneNumber(phone) {
    let cleaned = phone.length > 0 ? phone.replace(/\D/g, '').trim() : phone;

    return cleaned?.length === 10 ? cleaned : phone;
  }

  const handleExcel = async (e) => {
    setDataMatchingDb([]);
    setDataNotMatchingDb([]);
    setRowData([]);
    const file = e.target.files[0];
    const data = await file?.arrayBuffer();
    const workbook = xlsx.read(data, { type: 'buffer' });

    let matching = [];
    let notMatching = [];

    const jsonData = xlsx.utils
      .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      .map((el) => {
        return { ...el, phone: cleanPhoneNumber(el.phone) };
      });

    const promises = jsonData.map(async (el) => {
      try {
        const res = await searchPt(
          {
            first: el.first ? el.first.trim() : null,
            last: el.last ? el.last.trim() : null,
            phone: el.phone || null,
          },
          senderId
        );
        matching.push({
          ...res.data?.data[0]?.user,
          MRN: res.data?.data[0]?.user?.MRN || 'N/A',
        });
      } catch (err) {
        notMatching.push(el);
        console.error(err.message);
      }
    });
    setIsWaiting(true);
    await Promise.all(promises);
    setIsWaiting(false);

    setDataMatchingDb(matching.map((el, idx) => ({ ...el, id: idx + 1 })));
    setDataNotMatchingDb(
      notMatching.map((el, idx) => ({ ...el, id: idx + 1 }))
    );
  };

  const columnDef1 = [
    { headerName: 'MRN', field: 'MRN', minWidth: 100 },
    { headerName: 'First Name', field: 'first', minWidth: 150 },
    { headerName: 'Last Name', field: 'last', minWidth: 150 },
    { headerName: 'Phone', field: 'phone', minWidth: 150 },
    { headerName: 'recipient Type', field: 'type', minWidth: 150 },
  ];
  const columnDef2 = [
    { headerName: 'First Name', field: 'first', minWidth: 150 },
    { headerName: 'Last Name', field: 'last', minWidth: 150 },
    { headerName: 'Phone', field: 'phone', minWidth: 150 },
  ];

  useEffect(() => {
    setRowData([...dataMatchingDb]);
  }, [dataMatchingDb]);

  const heandleSend = () => {
    let smssuccessholder = [];
    let smsfailholder = [];

    dataMatchingDb.forEach((el) => {
      let phone = el.phone;
      let recpID = el.userID;
      let first = el.first;
      let last = el.last;

      sendDoc({
        DocId: props.doc.ID.toString(),
        senderId: senderId.toString(),
        recipientId: recpID.toString(),
      })
        .then((res) => {
          let data = {
            date: new Date(),
            completed: '',
            PatientId: el.id,
            Token: res.data.response_id,
            FormId: props.doc.ID,
            Statusflag: 'pending',
            ActionUserId: '',
            pharmacyId: pharmacy_id,
            recipientName: `${first} ${last}`,
          };
          let url = `${baseURL.frontEnd}/appless-docs-p/?fid=${props.doc.ID}&rid=${recpID}&t=${res.data.response_id}&pr=${senderId}`;
          sendSMS({
            to: phone,
            body: `Dear ${first} ${last}, You were sent a form from ${pharmacy_name} Pharmacy. Please take a moment to fill out the form linked below. Your responses will help us provide you with the best possible care. ${url} , Thank you for your cooperation! If you would like to unsubscribe please send STOP`,
          })
            .then(() => {
              smssuccessholder = [
                ...smssuccessholder,
                {
                  id: recpID,
                  first: first,
                  last: last,
                  msg: `Sent to ${phone}`,
                  status: 'success',
                  phone: phone,
                },
              ];
              setSmssuccess(smssuccessholder);
              storeDocResponseLinkInDB(data.Token, url).catch((err) => {
                console.error(err);
              });
            })
            .catch((err) => {
              smsfailholder = [
                ...smsfailholder,
                {
                  id: recpID,
                  first: first,
                  last: last,
                  msg: err?.response?.data?.message || 'error sending SMS',
                  status: 'failed',
                  phone: phone,
                },
              ];
              setSmsfail(smsfailholder);
              deleteDocResponse(data.Token).catch((err) => {
                console.error(err);
              });
            });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setDataMatchingDb([]);
          setDataNotMatchingDb([]);
        });
    });
  };

  return (
    <>
      <div className="container-flex mx-3">
        <div className="card p-3">
          <RecipentsFromDB data={props} />
          <h1 className="text-center">OR</h1>
          <div className="card">
            <div
              className="card-header fs-5"
              style={{
                textAlign: 'center',
                color: 'whitesmoke',
                background:
                  'linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)',
              }}
            >
              Upload recipients data using spreadsheet
            </div>
            <div className="card-body">
              {' '}
              <h5 className="my-3">
                Please download the spreadsheet template provided{' '}
                <a
                  href={template}
                  download
                  className="text-success fs-5 text-decoration-none"
                >
                  HERE&nbsp;
                </a>
                and fill it with recipients data, then upload it using the
                button below.
              </h5>
              <p className="text-success">
                Note: supported file types: .xlsx, .xls and .csv
              </p>
              <p className="text-danger">
                Warning: If you uploaded your own spreadsheet and column names
                are different process will fail
              </p>
              <input
                type="file"
                className="btn btn-success my-2 col-12 col-sm-auto"
                onChange={(e) => {
                  handleExcel(e);
                }}
              />
              {!isWaiting ? (
                <div>
                  {rowData.length > 0 && (
                    <div style={{ height: '500px', width: '100%' }}>
                      <DataGrid
                        rows={rowData}
                        columns={columnDef1}
                        pageSize={10}
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                      />
                    </div>
                  )}

                  <div className="my-3">
                    {rowData.length > 0 && (
                      <button
                        className="btn btn-success my-5 col-lg-2 py-2"
                        onClick={() => heandleSend()}
                      >
                        Send
                      </button>
                    )}
                    {rowData.length > 0 && dataNotMatchingDb.length === 0 ? (
                      <h5 className="text-success  mt-3 fw-bold">
                        Import Successfull, please verify imported data and
                        click send
                      </h5>
                    ) : rowData.length === 0 && dataNotMatchingDb.length > 0 ? (
                      <>
                        <h5 className="text-danger mt-2 fw-bold">
                          All of your imported data don't match our records
                          (indicated in the table below), please register
                          recipients in the system first then try again
                        </h5>
                      </>
                    ) : rowData.length > 0 && dataNotMatchingDb.length > 0 ? (
                      <>
                        <h5 className="text-danger mt-1 fw-bold">
                          Some of your imported data don't match our records
                          (indicated in the table below), you can still send the
                          form but ONLY{' '}
                          <span className="text-success">
                            recipients listed in the above table will receive it{' '}
                          </span>
                        </h5>
                      </>
                    ) : (
                      <></>
                    )}
                    {dataNotMatchingDb.length > 0 && (
                      <div style={{ height: '500px', width: '100%' }}>
                        <DataGrid
                          rows={dataNotMatchingDb}
                          columns={columnDef2}
                          pageSize={10}
                          density="compact"
                          components={{ Toolbar: GridToolbar }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <div style={{ textAlign: 'center' }}>
                      {smssuccess.length > 0 ? (
                        <>Succeeded: {smssuccess.length}</>
                      ) : null}{' '}
                      {smsfail.length > 0 ? (
                        <> - Failed: {smsfail.length}</>
                      ) : null}{' '}
                      {smsfail.length > 0 || smssuccess.length > 0 ? (
                        <>
                          <Button
                            onClick={() => {
                              setLogflag(true);
                            }}
                            variant="text"
                          >
                            Log
                          </Button>
                        </>
                      ) : null}
                    </div>
                    {logflag ? (
                      <>
                        <div className="row">
                          {smssuccess.length > 0 && (
                            <div
                              className=" col-12 col-lg-6 my-3"
                              style={{ height: 500 }}
                            >
                              <h5 className="text-center">Success Log</h5>
                              <DataGrid
                                rows={smssuccess}
                                columns={columns}
                                pageSize={10}
                                density="compact"
                                components={{ Toolbar: GridToolbar }}
                              />
                            </div>
                          )}

                          {smsfail.length > 0 && (
                            <div
                              className=" col-12 col-lg-6 my-3"
                              style={{ height: 500 }}
                            >
                              <h5 className="text-center">Failed Log</h5>
                              <DataGrid
                                rows={smsfail}
                                columns={columns}
                                pageSize={10}
                                density="compact"
                                components={{ Toolbar: GridToolbar }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h3 className="text-center">Please wait...</h3>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendDoc;
