import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/common/layout/Header";
import Navigator from "../components/PharmacyAdmin/AdminNavs/AdminNav";
import Footer from "../components/common/layout/Footer";
import Profile from "../pages/pharmacyUser/Profile";
import CsvFileUpload from "../pages/pharmacyUser/serveyCenter/CsvFileUpload";
import EditAndReview from "../pages/pharmacyUser/serveyCenter/EditAndReview";
import Analytics from "../pages/pharmacyUser/serveyCenter/Analytics";
import Results from "../pages/pharmacyUser/serveyCenter/Results";
import NotificationsRecipients from "../pages/pharmacyUser/serveyCenter/NotificationsRecipients";
import BirthdayLog from "./../pages/pharmacyUser/BirthdayLog";
import MassText from "./../pages/pharmacyUser/MassText";
import FormsHistory from "./../pages/pharmacyUser/FormsHistory";
import FormsManage from "./../pages/pharmacyUser/FormsManage";
import DocumentsHistory from "./../pages/pharmacyUser/DocumentsHistory";
import DocumentsManage from "./../pages/pharmacyUser/DocumentsManage";
import AddProvider from "./../pages/pharmacyUser/AddProvider";
import ManageProviders from "./../pages/pharmacyUser/ManageProviders";
import AddPatient from "./../pages/pharmacyUser/AddPatient";
import ManagePatients from "./../pages/pharmacyUser/ManagePatients";
import SurveyCenter from "./../pages/pharmacyUser/serveyCenter/SurveyCenter";
import ProfilePictureUploader from "./../components/common/profile/ProfilePictureUploader";
import SurveyDissatisfaction from "./../pages/pharmacyUser/SurveyDissatisfaction";
import Dashboard from "./../pages/pharmacyUser/Dashboard";
import Messages from "./../pages/pharmacyUser/Messages";
import SessionTimeOut from "./../components/common/SessionTimeOut/SessionTimeOut";
import BulkCreatePatients from "./../pages/pharmacyUser/AddBulkPatients";
import ManageSignupCodes from "../pages/pharmacyAdmin/ManageSignupCodes";
import ManageUser from "../pages/pharmacyAdmin/ManageUser";
import ManageNewRoles from "../pages/pharmacyAdmin/ManageNewRoles";
import AddUsersToMessagesGroupe from "../pages/pharmacyAdmin/AddUsersToMessagesGroupe";
import PharmacyAnnouncements from "../pages/pharmacyAdmin/PharmacyAnnouncements";
import NotFound from "./../components/common/NotFound";
import ContactUs from "./../components/common/ContactUs";
import PharmacyLogoUpdate from "../pages/pharmacyAdmin/PharmacyLogoUpdate";
import FormsAnalytics from "../pages/pharmacyUser/FormsAnalytics";
import DocumentsAnalytics from "../pages/pharmacyUser/DocumentsAnalytics";
import CarePlanManager from "../pages/pharmacyUser/CarePlan/CarePlanManager";
import Scheduler from "./../pages/pharmacyUser/CarePlan/Scheduler";
import CarePlanHistory from "../pages/pharmacyUser/CarePlan/CarePlanHistory";
import DeletedEvents from "./../pages/pharmacyUser/CarePlan/DeletedEvents";
import ManageCalendarOwners from "./../pages/pharmacyAdmin/ManageCalendarOwners";
import FormsLib from "../pages/pharmacyUser/CarePlan/FormsLib";
import CarePlanHistoryDetails from "./../pages/pharmacyUser/CarePlan/CarePlanHistoryDetails";
import InitFormFill from "../pages/pharmacyUser/CarePlan/InitFormFill";
import DissatisfactionDetails from "./../components/pharmacyUser/surveyCenter/DissatisfactionDetails";
const PharmacyAdminRoutes = () => {
  return (
    <div>
      <SessionTimeOut />
      <Header />
      <Navigator />
      <Routes>
        <Route path="/" index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile-picture" element={<ProfilePictureUploader />} />
        <Route path="add-provider" element={<AddProvider />} />
        <Route path="manage-providers" element={<ManageProviders />} />
        <Route path="add-patient" element={<AddPatient />} />
        <Route path="manage-patients" element={<ManagePatients />} />
        <Route path="manage-documents" element={<DocumentsManage />} />
        <Route path="documents-history" element={<DocumentsHistory />} />
        <Route path="manage-forms" element={<FormsManage />} />
        <Route path="forms-history" element={<FormsHistory />} />
        <Route path="birthday-messages" element={<BirthdayLog />} />
        <Route path="bulk-messages" element={<MassText />} />
        <Route path="manage-surveys" element={<SurveyCenter />} />
        <Route
          path="survey-dissatisfaction"
          element={<SurveyDissatisfaction />}
        />
        <Route
          path="survey-dissatisfaction/:responseId"
          element={<DissatisfactionDetails />}
        />
        <Route path="manage-surveys/*">
          <Route path="csv-file-upload" element={<CsvFileUpload />} />
          <Route path="edit-and-review" element={<EditAndReview />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="results" element={<Results />} />
          <Route
            path="notifications-recipients"
            element={<NotificationsRecipients />}
          />
        </Route>
        <Route path="messages" element={<Messages />} />
        <Route path="add-bulk-patients" element={<BulkCreatePatients />} />
        <Route path="manage-codes" element={<ManageSignupCodes />} />
        <Route path="manage-users" element={<ManageUser />} />
        <Route path="add-roles" element={<ManageNewRoles />} />
        <Route path="messages-groupe" element={<AddUsersToMessagesGroupe />} />
        <Route path="announcements" element={<PharmacyAnnouncements />} />
        <Route path="cp-owners" element={<ManageCalendarOwners />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="pharmacy-logo" element={<PharmacyLogoUpdate />} />
        <Route path="forms-analytics" element={<FormsAnalytics />} />
        <Route path="documents-analytics" element={<DocumentsAnalytics />} />
        <Route path="cp-center/*">
          <Route path="care-plan-manager" element={<CarePlanManager />} />
          <Route path="scheduler" element={<Scheduler />} />
          <Route path="scheduler/deleted-events" element={<DeletedEvents />} />
          <Route path="cp-history" element={<CarePlanHistory />} />
          <Route path="cp-history/:id" element={<CarePlanHistoryDetails />} />
          <Route
            path="care-plan-manager/:id"
            element={<CarePlanHistoryDetails />}
          />
          <Route path="forms-lib" element={<FormsLib />} />
          <Route path="init-form-fill" element={<InitFormFill />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default PharmacyAdminRoutes;
