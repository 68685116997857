import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { Tabs, Tab, Box } from "@mui/material";
import "survey-core/modern.min.css";
import "../../styles/page-styles/forms.css";
import { getDocResponses } from "../../services/PharmacyUser";

import { convertTime } from "../../utils/timeConverter";
import DocsCharts from "./../../components/pharmacyUser/documentsCenter/docsCharts/DocsCharts";
import DocsTrends from "./../../components/pharmacyUser/documentsCenter/docsTrends/DocsTrends";

const DocumentsAnalytics = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyID = localStorage.getItem("pharmacy");

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);

    getDocResponses(pharmacyID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",

              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",
              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completionDate: item.completion || "",
              completion: item.completion ? convertTime(item.completion) : "",
              sentDate: item.sent || "",
              sent: item.sent ? convertTime(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyID]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Documents Analytics</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Documents Center</li>
              <li className={`breadcrumb-item ${"active"}`}>
                Documents Analytics
              </li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <>
                {!isLoading ? (
                  <div>
                    <Box sx={{ width: "100%" }}>
                      <div className="d-lg-flex flex-row justify-content-between">
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="tabs"
                          className="mb-1 ms-2">
                          <Tab label={<span>Charts</span>} />

                          <Tab label={<span>Trends</span>} />
                        </Tabs>
                      </div>
                      {value === 0 && (
                        <>
                          <DocsCharts data={data} />
                        </>
                      )}
                      {value === 1 && (
                        <>
                          <DocsTrends data={data} />
                        </>
                      )}
                    </Box>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <h3 className="text-center mt-5">
                      Loading Data, Please wait...
                    </h3>
                    <div className="spinner-border text-info" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocumentsAnalytics;
