import React, { useState } from "react";
import { CreateNewCarePlan } from "../../../../utils/globalState";
import SelectPatient from "../SelectPatient";
import AddNewCpPt from "./AddNewCpPt";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const SelectCpPatient = () => {
  const stateObj = CreateNewCarePlan((state) => state.careplanController);
  const [addptflag, setAddptflag] = useState(false);
  const [msg, setMsg] = useState("");
  const handleSelectPatient = (patient) => {
    CreateNewCarePlan.setState({
      careplanController: {
        ...stateObj,
        completeflag: {
          ...stateObj.completeflag,
          select_patient: true,
        },
        info: {
          ...stateObj.info,
          patient_info: patient,
        },
      },
    });
  };

  const addPtToggle = () => {
    setAddptflag(!addptflag);
    setMsg("Patient added successfully!");
  };
  return (
    <div className="row">
      {addptflag ? (
        <>
          <div className="col-12 mb-3 ">
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => {
                setMsg("");
                setAddptflag(!addptflag);
              }}>
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div className="col-12 ">
            <AddNewCpPt
              setPatient={handleSelectPatient}
              addPtToggle={addPtToggle}
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-12 ">
            <SelectPatient setPatient={handleSelectPatient} />
          </div>
          <div className="col-12 mt-3 mb-3 ">
            <Divider> OR </Divider>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 d-flex justify-content-center  ">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setAddptflag(!addptflag)}>
                  Add New Patient
                </Button>
              </div>
              <div className="col-12 d-flex justify-content-center pt-3 ">
                <p className="text-center text-success">{msg}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectCpPatient;
