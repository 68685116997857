import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTokens, getSurveyById } from "../../services/PharmacyUser";
import { DataGrid } from "@mui/x-data-grid";
import { convertTimeLocally } from "../../utils/timeConverter";
import PtSurveyRun from "./PtSurveyRun";
import { decodeToken } from "react-jwt";
const PtSurveysLog = (props) => {
  const { patient } = props;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userid = myDecodedToken.userId;

  const navigate = useNavigate();

  const [surveys, setSurveys] = useState([]);
  const [pTloaded, setPtLoaded] = useState(false);
  const [tokensList, setTokensList] = useState([]);
  const [thisSurvey, setThisSurvey] = useState({});
  const [showSurvey, setShowSurvey] = useState(false);

  const columns = [
    { field: "id", headerName: "S/N", sortable: true, minWidth: 150 },
    {
      field: "surveyTitle",
      headerName: "Survey Title",
      valueGetter: (params) => params.row.survey.title,
      minWidth: 150,
    },
    {
      field: "createdAt",
      headerName: "Sent Date",
      sortable: true,
      minWidth: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "dispenseDate",
      headerName: "Dispense Date",
      sortable: true,
      minWidth: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
  ];

  const handleGetTokens = () => {
    getTokens()
      .then((res) => {
        let uniqeTokens = [];
        res.data
          .filter(
            (token) =>
              (JSON.parse(token.token).ptid === patient.id ||
                JSON.parse(token.token).ptid === userid) &&
              JSON.parse(token.token).resflag === false
          )
          .map((t) => {
            return JSON.parse(t.token);
          })
          .forEach((currentValue, i) => {
            if (
              uniqeTokens.find((ut) => ut.servid === currentValue.servid) ===
              undefined
            ) {
              uniqeTokens.push(currentValue);
            } else {
              let token = uniqeTokens.find(
                (ut) => ut.servid === currentValue.servid
              );
              if (token.date < currentValue.date) {
                uniqeTokens.push(currentValue);
                uniqeTokens.splice(uniqeTokens.indexOf(token), 1);
              }
            }
          });

        setTokensList(uniqeTokens);
        setPtLoaded(true);
      })
      .catch((err) => {
        setTokensList([]);
        setPtLoaded(true);
      });
  };

  useEffect(() => {
    if (Object.keys(patient).length > 0 && patient) {
      handleGetTokens();
    } else {
      setPtLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useEffect(() => {
    if (tokensList.length > 0) {
      Promise.all(
        tokensList.map((token, i) => {
          return getSurveyById(token.servid)
            .then(async (res) => {
              return {
                id: i + 1,
                survey: JSON.parse(res.data[0].survey),
                createdAt: convertTimeLocally(token.date),
                dispenseDate: token.dispenseDate,
                patient: patient,
                surveyid: token.servid,
                token: token.token,
              };
            })
            .catch((err) => {
              console.log(err);
              return {
                id: i + 1,
                survey: err.response.data.Message,
                createdAt: convertTimeLocally(token.date),
                dispenseDate: token.dispenseDate,
                patient: patient,
                surveyid: token.servid,
                token: token.token,
              };
            });
        })
      ).then((res) => {
        setSurveys(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensList]);

  const handleRowClick = (params) => {
    navigate(`${params.row.token}`);
  };

  const goBack = () => {
    setShowSurvey(false);
    setThisSurvey({});
    setPtLoaded(false);
    handleGetTokens();
  };

  return (
    <div>
      <h1 className="text-center mt-5 mb-5">Pending Surveys</h1>

      <>
        {pTloaded ? (
          <>
            {showSurvey ? (
              <PtSurveyRun data={thisSurvey} onGoback={goBack} />
            ) : (
              <>
                {tokensList.length > 0 ? (
                  <>
                    <DataGrid
                      loading={surveys.length === 0}
                      rows={surveys}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 100,
                          },
                        },
                        sorting: {
                          sortModel: [{ field: "createdAt", sort: "desc" }],
                        }
                      }}
                      pageSizeOptions={[100]}
                      onRowClick={handleRowClick}
                    />
                  </>
                ) : (
                  <>No Pending Surveys At This Moment. </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="ms-4 align-middle text-secondary">
                Loading Surveys...
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default PtSurveysLog;
