import React, { useEffect, useState } from 'react';
import {
  generateToken,
  handleEncryption,
  sendSMS,
  sendEmail,
} from '../../services/Common';
import {
  getResponseById,
  getProfile,
  handleGetDocFiles,
  handleDocResponse,
  handleStoreFiles,
} from '../../services/PharmacyUser';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
// import SurveyHelper from "../../utils/SurveyHelper";
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
// import docsHelper from "../../utils/docsHelper";
// StylesManager.applyTheme("modern");
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PDFViewer from '../../components/pharmacyUser/documentsCenter/PDFViewer';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { Buffer } from 'buffer';
import { getDocsByCreatorID } from '../../services/Provider';

const ApplessDocsPr = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sentInstanceId = urlParams.get('t');
  const sentFormId = urlParams.get('fid');
  const recipientId = urlParams.get('rid');
  const providerId = urlParams.get('pr');
  const [otpprgressflag, setOtpprgressflag] = useState('phone');
  const [completed, setCompleted] = useState(
    localStorage.getItem('completed') === 'true' ? true : false
  );
  const [urlisvalid, setUrlisvalid] = useState(false);
  const [user, setUser] = useState({});
  const [Loadingflag, setLoadingflag] = useState(true);
  const [phoneinput, setPhoneinput] = useState('');
  const [phonenumberisvalid, setPhonenumberisvalid] = useState(true);
  const [otpinput, setOtpinput] = useState('');
  const [otpisvalid, setOtpisvalid] = useState(true);
  const [authflag, setAuthflag] = useState(
    localStorage.getItem('authflag') || false
  );
  // const [intervalId, setIntervalId] = useState(null);
  // const [resendotpflag, setResendotpflag] = useState(false);
  const [form, setForm] = useState({});
  const [activeLink, setActiveLink] = useState(true);
  const [sendingotp, setSndingOtp] = useState(false);
  const [base64pdf, setBase64pdf] = useState(null);
  const [msg, setMsg] = useState('');
  const [currentResponse, setCurrentResponse] = useState({});

  const handleDownload = (row) => {
    setMsg('');
    let current = row;

    if (current) {
      let json = handleObjectRewrite(current);

      json.title = `${json.title} form for - Name: ${
        current.recipientName
      } - MRN: ${current.MRN ? current.MRN : 'N/A'} - Phone: ${
        current.recipientInfo.phone
      } - Email: ${current.recipientInfo.email}`;
      let url = json.pages[0].elements.find((item) => item.type === 'html')
        ? json.pages[0].elements.find((item) => item.type === 'html').name
        : null;
      let signaturedefault = json.pages[0].elements.find(
        (item) => item.type === 'signaturepad'
      );
      let signature =
        signaturedefault && signaturedefault.defaultValue
          ? signaturedefault.defaultValue
          : null;

      if (url !== null && signature !== null) {
        async function modifyPdf(results) {
          // Fetch an existing PDF document
          const filename =
            results.pdfUrl.split('/')[results.pdfUrl.split('/').length - 1];
          const existingPdfBytes = await handleGetDocFiles(filename)
            .then((res) => {
              return Buffer.from(res.data.file.data, 'base64');
            })
            .catch((err) => {
              console.error(err);
            });
          // fetch(url).then(res => res.arrayBuffer());
          // Load a PDFDocument from the existing PDF bytes
          const pdfDoc = await PDFDocument.load(existingPdfBytes);

          // Embed the Helvetica font
          const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

          // Get the first page of the document
          const pages = pdfDoc.getPages();
          const firstPage = pages[pages.length - 1];

          // Fetch JPEG image

          const pngImage = await pdfDoc.embedPng(results.signature);

          // Get the width and height of the first page
          firstPage.drawText('___________________________________________', {
            x: 200,
            y: 115,
            font: helveticaFont,
            size: 8,
          });

          // Add a blank page to the document
          firstPage.drawImage(pngImage, {
            x: 220,
            y: 40,
            width: 150,
            height: 70,
          });
          firstPage.drawText(
            `__________Signed Above ${current.recipientName}_________`,
            {
              x: 200,
              y: 30,
              font: helveticaFont,
              size: 8,
            }
          );

          // Serialize the PDFDocument to bytes (a Uint8Array)
          const pdfBytes = await pdfDoc.save();

          var blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const formData = new FormData();
          formData.append('pdf', blob);
          formData.append('type', 'pdf');
          const azureLink = await handleStoreFiles(formData, user.id);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          link.download = 'new.pdf';
          link.click();
          sendEmail({
            to: user.email,
            message: `<div>Hello ${user.first},<br />You have recently completed sining a CuroRx document.<br />  Please find attached your document.<br /> Click <a href="${azureLink.data.url}">here</a> to download</div> `,
          })
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            });
        }
        modifyPdf({ pdfUrl: url, signature: signature });
      } else {
        if (signature === null) {
          setMsg('No signature found');
        }
        if (url === null) {
          setMsg('No url found');
        }
      }
    }
  };

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return JSON.parse(object.formText);
    } else {
      let form = JSON.parse(object.formText);
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: 'page1',
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  useEffect(() => {
    generateToken(recipientId).then((res) => {
      localStorage.setItem('Token', res.data.token);

      if (sentInstanceId && sentFormId && recipientId && providerId) {
        setUrlisvalid(true);
        // 1- Get the sending instance to get the status (completed or not)

        getResponseById(sentInstanceId).then((res) => {
          setCurrentResponse(res.data[0]);
          if (
            res.data[0]?.isLinkDisabled === true ||
            res.data[0]?.isLinkDisabled.toString() === '1'
          ) {
            setActiveLink(false);
          }
          res?.data[0]?.status?.toLowerCase() === 'completed' &&
            setCompleted(true);
          res?.data[0]?.status?.toLowerCase() === 'completed' &&
            localStorage.setItem('completed', true);
        });
        // 2- Get the form by id
        getDocsByCreatorID(providerId).then((res) => {
          if (res?.data?.length === 0) {
            setUrlisvalid(false);
          }

          if (
            res.data.find((form) => form.ID.toString() === sentFormId) ===
            undefined
          ) {
            setUrlisvalid(false);
          } else {
            res?.data &&
              setForm(
                JSON.parse(
                  res.data.find((form) => form.ID.toString() === sentFormId)
                    .formText
                )
              );
          }
        });
        // 3- Get the recipient by id
        getProfile(recipientId)
          .then((result) => {
            setLoadingflag(false);
            if (result?.data?.data?.user.length === 0) {
              setUrlisvalid(false);
            }
            result?.data?.data?.user[0] && setUser(result.data.data.user[0]);
          })
          .catch((err) => {
            setUrlisvalid(false);
          });
      } else {
        //   URL not valid
        setUrlisvalid(false);
        setLoadingflag(false);
      }
    });
  }, [sentInstanceId, sentFormId, recipientId, providerId]);

  const handleSendOTP = () => {
    // clearInterval(intervalId);
    // setResendotpflag(false);
    const val = Math.floor(1000 + Math.random() * 9000);

    localStorage.setItem('otp', handleEncryption(val.toString()));
    sendSMS({
      to: user?.phone,
      body: `Your CuroRx One Time Password is ${val}`,
    })
      .then((res) => {
        setOtpprgressflag('otp');
        setSndingOtp(false);
      })
      .catch((err) => {
        console.error(err);

        sendEmail({
          to: user?.email,
          message: ` Your CuroRx One Time Password is ${val}`,
        })
          .then((res) => {
            setOtpprgressflag('otp');
            setSndingOtp(false);
          })
          .catch((err) => {
            setPhonenumberisvalid(false);
          });
      });
  };

  const handleVerifyNumber = () => {
    setSndingOtp(true);
    if (phoneinput !== '') {
      if (phoneinput === user?.phone || phoneinput === user?.email) {
        setPhonenumberisvalid(true);
        handleSendOTP();
      } else {
        setPhonenumberisvalid(false);
        setSndingOtp(false);
      }
    } else {
      setPhonenumberisvalid(false);
      setSndingOtp(false);
    }
  };

  const handleVerifyOTP = () => {
    if (otpinput !== '') {
      if (handleEncryption(otpinput) === localStorage.getItem('otp')) {
        setOtpisvalid(true);
        setOtpprgressflag('form');
        localStorage.setItem('authflag', true);
        setAuthflag(true);
      } else {
        setOtpisvalid(false);
      }
    }
  };

  if (Loadingflag) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    form.showQuestionNumbers = false;
    if (form?.pages[0]?.elements.find((e) => e.type === 'html')) {
      let url = form.pages[0].elements.find((e) => e.type === 'html').name;
      let filename = url.split('/')[url.split('/').length - 1];
      const existingPdfBytes = handleGetDocFiles(filename).then((res) => {
        return Buffer.from(res.data.file.data, 'base64');
      });
      existingPdfBytes.then((res) => {
        setBase64pdf(`data:application/pdf;base64,${res.toString('base64')}`);
      });
    }
    form.pages[0].elements.find(
      (e) => e.type === 'html'
    ).html = `<p>Unable to display PDF file?. <a href="${
      form.pages[0].elements.find((item) => item.type === 'html').name
    }" target="_blank">Download</a> instead.</p>`;
    const servey = new Model(form);
    servey.onComplete.add((sender, options) => {
      setCurrentResponse({ ...currentResponse, value: sender.data });

      setCompleted(true);
      localStorage.setItem('completed', true);
      handleDocResponse({ value: sender.data }, sentInstanceId).then((res) => {
        handleDownload({ ...currentResponse, value: sender.data });
      });
    });

    return (
      <>
        {!activeLink ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100vh' }}
          >
            <h4 className="">
              The Page you requested is no longer available, sorry for any
              inconvenience
            </h4>
            {msg !== '' && <p className="text-danger">{msg}</p>}
          </div>
        ) : (
          <div>
            {urlisvalid ? (
              <div>
                {completed ? (
                  <h1>Thank you for your response</h1>
                ) : (
                  <>
                    {authflag ? (
                      <div>
                        <PDFViewer pdf={base64pdf} />
                        <Survey model={servey} />
                      </div>
                    ) : (
                      <div>
                        {otpprgressflag === 'phone' ? (
                          <Box
                            component="span"
                            sx={{
                              display: 'flex',
                              mx: '2px',
                              transform: 'scale(0.8)',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '100px',
                            }}
                          >
                            <Card sx={{ minWidth: 500 }}>
                              <CardHeader
                                title="Welcome"
                                subheader="Please enter your phone number to receive the OTP"
                                sx={{
                                  textAlign: 'center',
                                  borderRadius: '10px 10px 0px 0px',
                                  background:
                                    'linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)',
                                  color: 'whitesmoke !important',
                                  height: '70px',
                                }}
                              ></CardHeader>
                              <CardContent>
                                <Box
                                  component="form"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleVerifyNumber();
                                  }}
                                  sx={{
                                    '& .MuiTextField-root': {
                                      m: 1,
                                      width: '50ch',
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    error={!phonenumberisvalid}
                                    id="outlined-error-helper-text"
                                    label="Phone number or Email"
                                    helperText={
                                      phonenumberisvalid
                                        ? ''
                                        : 'Please enter a valid phone number or email'
                                    }
                                    placeholder="ex: 1234568765 or 3kx8W@example.com"
                                    value={phoneinput}
                                    onChange={(e) =>
                                      setPhoneinput(e.target.value)
                                    }
                                  />
                                </Box>
                              </CardContent>
                              <CardActions
                                style={{
                                  float: 'right',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                {sendingotp ? (
                                  <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                  >
                                    Sending
                                  </LoadingButton>
                                ) : (
                                  <Button
                                    onClick={handleVerifyNumber}
                                    variant="contained"
                                  >
                                    Send OTP
                                  </Button>
                                )}
                              </CardActions>
                            </Card>
                          </Box>
                        ) : otpprgressflag === 'otp' ? (
                          <Box
                            component="span"
                            sx={{
                              display: 'flex',
                              mx: '2px',
                              transform: 'scale(0.8)',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '100px',
                            }}
                          >
                            <Card sx={{ minWidth: 500 }}>
                              <CardHeader
                                title="Plewse enter the OTP"
                                sx={{
                                  textAlign: 'center',
                                  borderRadius: '10px 10px 0px 0px',
                                  background:
                                    'linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)',
                                  color: 'whitesmoke !important',
                                  height: '70px',
                                }}
                              ></CardHeader>
                              <CardContent>
                                <Box
                                  component="form"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleVerifyOTP();
                                  }}
                                  sx={{
                                    '& .MuiTextField-root': {
                                      m: 1,
                                      width: '50ch',
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    error={!otpisvalid}
                                    id="outlined-error-helper-text"
                                    label="OTP"
                                    helperText={
                                      otpisvalid ? '' : 'OTP is not valid'
                                    }
                                    placeholder="ex: 0000"
                                    value={otpinput}
                                    onChange={(e) =>
                                      setOtpinput(e.target.value)
                                    }
                                  />
                                </Box>
                              </CardContent>
                              <CardActions
                                style={{
                                  float: 'right',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Button
                                  onClick={handleVerifyOTP}
                                  variant="contained"
                                >
                                  Verify OTP
                                </Button>
                              </CardActions>
                            </Card>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <h1>URL not valid</h1>
            )}
          </div>
        )}
      </>
    );
  }
};

export default ApplessDocsPr;
