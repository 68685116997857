import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SideBar } from "../../../utils/globalState";
import { getDeletedEventsHistory } from "../../../services/Calendar";
import { getUserById } from "../../../services/Common";

const DeletedEvents = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyId = localStorage.getItem("pharmacy");
  const [deletedEvents, setDeletedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const columnDef = [
    { field: "Subject", headerName: "Event Title", minWidth: 150 },

    {
      field: "createdAt",
      headerName: "Created On",
      minWidth: 180,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "creatorName", headerName: "Created By", minWidth: 100 },
    {
      field: "updatedOn",
      headerName: "Deleted On",
      minWidth: 180,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },

    { field: "updatedByName", headerName: "Deleted By", minWidth: 150 },
    { field: "Location", headerName: "Location", minWidth: 150 },
    { field: "Priority", headerName: "Priority", minWidth: 100 },
    { field: "ownerName", headerName: "Event Owner", minWidth: 150 },
    { field: "patientName", headerName: "Patient Name", minWidth: 150 },
    { field: "Description", headerName: "Description", minWidth: 150 },
    { field: "StartTime", headerName: "Start Time", minWidth: 180 },
    { field: "EndTime", headerName: "End Time", minWidth: 180 },
    {
      field: "IsAllDay",
      headerName: "Is All Day",
      minWidth: 180,
      valueGetter: (params) => (params.value ? "Yes" : "No"),
    },
    { field: "RecurrenceRule", headerName: "Recurrence", minWidth: 180 },
  ];

  useEffect(() => {
    setIsLoading(true);
    getDeletedEventsHistory(pharmacyId)
      .then((res) => {
        const uniqueCreatorIds = [];
        const creatorsObj = {};
        const uniqueOwnerIds = [];
        const ownersObj = {};
        const historyResp = res.data.map((event) => {
          if (!uniqueCreatorIds.includes(event.creatorId)) {
            uniqueCreatorIds.push(event.creatorId);
          }
          return {
            ...event,
            StartTime: dayjs(event.StartTime).format("MM/DD/YYYY hh:mm A"),
            EndTime: dayjs(event.EndTime).format("MM/DD/YYYY hh:mm A"),
            updatedOn: dayjs(event.updatedOn).format("MM/DD/YYYY hh:mm A"),
            createdAt: dayjs(event.createdAt).format("MM/DD/YYYY hh:mm A"),
          };
        });

        Promise.all(
          uniqueCreatorIds.map(async (id) => {
            return await getUserById(id).then((resp) => {
              const user = resp.data.data.user[0];
              creatorsObj[id] = `${user.first} ${user.last}`;
            });
          })
        )
          .then(() => {
            historyResp.forEach((event) => {
              event.creatorName = creatorsObj[event.creatorId];
            });
          })
          .then(() => {
            setDeletedEvents(historyResp);
          });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pharmacyId]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Deleted Events History</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Care Plan</li>
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}>
                Calendar
              </li>
              <li className="breadcrumb-item active">Deleted Events</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {isLoading ? (
                <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                  <div className="spinner-border text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="ms-4 align-middle text-secondary">
                    Loading Deleted Events History Please wait...
                  </div>
                </div>
              ) : (
                <div className="dg_0liner">
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={deletedEvents}
                    columns={columnDef}
                    density="compact"
                    // getRowClassName={getRowClassName}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "updatedOn", sort: "desc" }],
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DeletedEvents;
