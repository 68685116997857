import React, { useState, useEffect } from 'react'
import FormUserType from "./FormUserType";
import PatientFormStatus from "./PatientFormStatus";
import UserFormsStatus from "./UserFormsStatus";
import ProviderFormStatus from "./ProviderFormsStatus";


const FormsCharts = (props) => {
    const { rawResponses } = props;
  return (
    <div className="card">
      <div className="row ">
        <div className="col-12 col-lg-6 ">
          <FormUserType responses={rawResponses} />
        </div>
        <div className="col-12 col-lg-6 ">
          <PatientFormStatus responses={rawResponses} />
        </div>
        <div className="col-12 col-lg-6 ">
          <UserFormsStatus responses={rawResponses} />
        </div>
        <div className="col-12 col-lg-6 ">
          <ProviderFormStatus responses={rawResponses} />
        </div>
      </div>
    </div>
  );
}

export default FormsCharts