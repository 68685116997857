import React, { useState, useEffect } from "react";
import { convertTime } from "../../../../../utils/timeConverter";
import TotalCompleteByUserType from "../../../documentsCenter/docsTrends/totalCompletedByUserType/TotalCompleteByUserType";
import PatientCompletedVSPending from "./../../../documentsCenter/docsTrends/patientCompletedVSPending/PatientCompletedVSPending";
import ProviderCompletedVSPending from "./../../../documentsCenter/docsTrends/providerCompletedVSPending/ProviderCompletedVSPending";
import StaffCompletedVSPending from "./../../../documentsCenter/docsTrends/staffCompletedVSPending/StaffCompletedVSPending";
import { getAllForms } from "../../../../../services/PharmacyUser";
import PatientTotalDocsByStaff from "./../../../documentsCenter/docsTrends/patientTotalDocsByStaff/PatientTotalDocsByStaff";
import ProviderTotalDocsByStaff from "./../../../documentsCenter/docsTrends/providerTotalDocsByStaff/ProviderTotalDocsByStaff";
import PharmacyUsersTotalDocsByStaff from "./../../../documentsCenter/docsTrends/pharmacyUsersTotalDocsByStaff/PharmacyUsersTotalDocsByStaff";
import AverageTurnAroundTime from "./../../../documentsCenter/docsTrends/averageTurnAroundTime/AverageTurnAroundTime";

function FormsTrends(props) {
  const { rawResponses } = props;
  const pharmacyID = localStorage.getItem("pharmacy");

  const [data, setData] = useState([]);
  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    const incoming = rawResponses
      .map((item) => {
        const updatedItem = {
          ...item,
          isStop: item.isStop === 1 ? true : false,
          isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
          formText: item.formText ? JSON.parse(item.formText) : "",
          value: item.value ? JSON.parse(item.value) : "",
          senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
          recipientInfo: item.recipientInfo
            ? JSON.parse(item.recipientInfo)
            : "",

          sender: item.senderInfo
            ? JSON.parse(item.senderInfo).first +
              " " +
              JSON.parse(item.senderInfo).last
            : "",
          recipientName: item.recipientInfo
            ? JSON.parse(item.recipientInfo).first +
              " " +
              JSON.parse(item.recipientInfo).last
            : "",

          recipientType: item.recipientInfo
            ? JSON.parse(item.recipientInfo).type
            : "",
          MRN: item.recipientInfo
            ? JSON.parse(item.recipientInfo).MRN || "N/A"
            : "",
          formName: item.formText ? JSON.parse(item.formText).title : "",

          isArchived: item.isArchived === 1 ? true : false,
          archived_date: item.archived_date
            ? convertTime(item.archived_date)
            : "",
          isReviewed: item.isReviewed === 1 ? true : false,
          reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
          completionDate: item.completion || "",
          completion: item.completion ? convertTime(item.completion) : "",
          sentDate: item.sent || "",
          sent: item.sent ? convertTime(item.sent) : "",
          dateOfLastReminder: item.dateOfLastReminder
            ? convertTime(item.dateOfLastReminder)
            : "",
        };

        return updatedItem;
      })
      .reverse();

    setData(incoming);
  }, [rawResponses]);

  useEffect(() => {
    getAllForms(pharmacyID)
      .then((res) => {
        const docsFromDB = res.data.map(
          (item) => JSON.parse(item.formText).title
        );
        setAllDocs(docsFromDB);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacyID]);
  return (
    <div>
      <TotalCompleteByUserType data={data} name="Forms" />
      <PatientCompletedVSPending data={data} name="Form" />
      <ProviderCompletedVSPending data={data} name="Form" />
      <StaffCompletedVSPending data={data} name="Form" />
      <PatientTotalDocsByStaff data={data} name="Form" allDocs={allDocs} />
      <ProviderTotalDocsByStaff data={data} name="Form" allDocs={allDocs} />
      <PharmacyUsersTotalDocsByStaff
        data={data}
        name="Form"
        allDocs={allDocs}
      />
      <AverageTurnAroundTime data={data} name="Forms" allDocs={allDocs} />
    </div>
  );
}

export default FormsTrends;
