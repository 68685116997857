import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
// eslint-disable-next-line no-unused-vars
import ColumnChart from "../../components/provider/dashboard/ColumnChart";
import Cards from "../../components/provider/dashboard/Cards";
import "../../styles/commonStyles/dashboard.css";
import { decodeToken } from "react-jwt";
import {
  yearSelect,
  monthlyCounter,
} from "../../utils/pharmacistDashboardHelpers";
import {
  getPatientsTotal,
  getPatientscharts,
  getAllRxsByProviderId,
  getProfile,
} from "../../services/Provider";
import { getPharmacyAnnouncement } from "../../services/PharmacyUser";
import { Divider } from "@mui/material";
import { getNotificationsByUserID } from "../../services/Common";
import { convertTimeLocally } from "../../utils/timeConverter";
import { SocketIoServer } from "../../utils/globalState";
const Dashboard = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyID = localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const [patients, setPatients] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [patientsTotal, setPatientsTotal] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [ptYear, setPtYear] = useState(`${new Date().getFullYear()}`);
  const [announcement, setAnnouncement] = useState("");
  const [referrals, setReferrals] = useState(0);
  const [user, setUser] = useState({});
  const value = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(value);
  }, [value]);

  // eslint-disable-next-line no-unused-vars
  const getNots = () => {
    getNotificationsByUserID(userID)
      .then((res) => {
        if (res.data.length !== 0) {
          setNotifications(
            res.data.filter(
              (note) =>
                note.receiver_id === myDecodedToken.userId.toString() &&
                note.sender_id !== myDecodedToken.userId.toString()
            )
          );
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setNotifications([]);
      });
  };

    useEffect(() => {
      if (socket !== null) {
        if (Object.keys(socket).length > 0) {
          socket.on("notifications", (data) => {
            getNots();
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

  useEffect(() => {
    getNots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPatientsTotal(userID)
      .then((res) => {
        setPatientsTotal(res.data.Patients);
      })
      .catch((err) => console.error(err.message));
    getPatientscharts(userID)
      .then((res) => {
        res?.data && setPatients(res.data);
      })
      .catch((err) => console.error(err.message));
    getPharmacyAnnouncement(pharmacyID)
      .then((res) => {
        setAnnouncement(res.data.provider_announcement);
      })
      .catch((err) => {
        setAnnouncement("Error retreiving announcement");
        console.error(err.message);
      });
    getAllRxsByProviderId(userID)
      .then((res) => {
        setReferrals(res.data.referrals?.length);
      })
      .catch((err) => console.error(err.message));
    getProfile(userID)
      .then((res) => {
        setUser(res.data.data.user[0]);
      })
      .catch((err) => console.error(err.message));
  }, [pharmacyID, userID]);

  useEffect(() => {
    patients?.length && setPatientsData(monthlyCounter(patients, ptYear, "pt"));
  }, [patients, ptYear]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Provider Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Provider Dashboard</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="row card-body justify-content-between mb-3 mx-md-0 mx-2 ">
              <div
                className="col-md-3 bg-gradient-primary d-flex justify-content-between rounded d-none d-md-flex mt-2"
                id="dashboard_welcome_note">
                <div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3 className="card-title   text-light text-center">
                      Hello Dr. {user.last}
                    </h3>
                    <p className="text-light text-center ">
                      Have a nice day at work
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assets/images/heartofdrs.png")}
                  className="col-md-3 d-none d-xl-block "
                  style={{ margin: "5px", width: "5rem" }}
                  alt="doctors in heart"
                />
              </div>
            </div>

            <div className="container ">
              <div>
                <Cards patients={patientsTotal || 0} referrals={referrals} />
              </div>
              <div className="row dashboard_container">
                <div className="col-md-7">
                  <div className="card pb-1 mb-2 mb-md-0">
                    <h5 className="card-title mt-1 mb-0 pt-3 pb-0 ">
                      Recent Messages
                    </h5>
                    <Divider className="my-4" />
                    <div className="card-body">
                      <div className="dash_messages">
                        {notifications.length > 0 ? (
                          notifications
                            .filter((el) => el.body === "message")
                            .reverse()
                            .map((el, idx) => {
                              return (
                                idx < 4 && (
                                  <div
                                    className=" mx-2 my-3 border border-2 recent_msg_div"
                                    key={idx}>
                                    <div className="card-body py-0">
                                      <h5 className="card-title text-capitalize">
                                        {el.sender_name}{" "}
                                        <small className="msg-timestamp text-lowercase fs-6">
                                          {convertTimeLocally(el.createdAt)}
                                        </small>
                                      </h5>

                                      <p className="card-text mb-2">
                                        {el.message}
                                      </p>
                                    </div>
                                  </div>
                                )
                              );
                            })
                        ) : (
                          <p className="text-center">no new messages</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card pb-1 mb-2 mb-md-0">
                    <h5 className="card-title mt-1 mb-0 pt-3 pb-0 ">
                      What's New at {pharmacy_name}!
                    </h5>
                    <Divider className="my-4" />
                    <div className="card-body">
                      <p className="card-text announcement_container">
                        {announcement}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-5">
                {patientsData.length > 0 && (
                  <div className="col-12 col-lg-6">
                    {yearSelect().length > 1 && (
                      <select
                        className="form-select form-select-sm mb-3 mx-2"
                        aria-label=".form-select-md example"
                        id="yearSelection"
                        defaultValue={"DEFAULT"}
                        onChange={(e) => setPtYear(`${e.target.value}`)}>
                        <option disabled value="DEFAULT">
                          Select Year
                        </option>
                        {yearSelect().map((el) => (
                          <option key={el} value={`${el}`}>
                            {el}
                          </option>
                        ))}
                      </select>
                    )}
                    <ColumnChart
                      nameX="Patients"
                      info={patientsData}
                      titleX={`New Patients/Month for ${ptYear}`}
                      color="#25649e"
                      type="bar"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
