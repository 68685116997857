import { create } from "zustand";
export const SideBar = create((set) => ({
  isDivVisible: true,
  toggleDivVisibility: (isDivVisible) =>
    set((state) => ({ isDivVisible: !state.isDivVisible })),
}));

export const User = create((state) => ({ info: {} }));

export const Pharmacy = create((state) => ({ info: {} }));

export const SelectedSurvey = create((state) => ({ ThisSurvey: {} }));

export const SocketIoServer = create((state) => ({ SocketIoObj: {} }));

export const CreateNewCarePlan = create((set) => ({ careplanController: {
  completeflag: {
    select_patient: false,
    init_form: false,
    followup_form: false,
  },
  currentstep: 0,
  info: {
    patient_info: {},
    init_form: {},
    followup_form: {},
  },
},
resetCarePlan: () =>
  set({
    careplanController: {
      completeflag: {
        select_patient: false,
        init_form: false,
        followup_form: false,
      },
      currentstep: 0,
      info: {
        patient_info: {},
        init_form: {},
        followup_form: {},
      },
    },
  }),
}));

export const ProviderAddRxController = create((set) => ({
  StepperController: {
    completeflag: {
      "Select Patient": false,
      Therapy: false,
      Diagnose: false,
      Clinical: false,
      Medication: false,
      Review: false,
    },
    currentstep: "",
    rx: {
      therapy: "",
      ICD10: "",
      diagnose: "",
      providerId: "",
      patientId: "",
      clinicalinfo: [],
      medications: [],
    },
    info: {
      provider_name: "",
      patient_name: "",
    },
  },
  resetRx: () =>
    set({
      StepperController: {
        completeflag: {
          "Select Patient": false,
          Therapy: false,
          Diagnose: false,
          Clinical: false,
          Medication: false,
          Review: false,
        },
        currentstep: "",
        rx: {
          therapy: "",
          ICD10: "",
          diagnose: "",
          providerId: "",
          patientId: "",
          clinicalinfo: [],
          medications: [],
        },
        info: {
          provider_name: "",
          patient_name: "",
        },
      },
    }),
}));
