/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import "../../../styles/page-styles/messages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclip,
  faSearch,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { SocketIoServer } from "../../../utils/globalState";
import "../../../styles/commonStyles/bootstrap.min.css";
import {
  handleGetMessages,
  handleFiles,
  handleSendMessage,
} from "../../../services/Messages";
import {
  sendSMS,
  sendEmail,
  urlShortner,
  getPharmacyById,
  getUserById,
} from "../../../services/Common";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CardHeader from "@mui/material/CardHeader";
import { decodeToken } from "react-jwt";
import baseURL from "../../../utils/baseURL";
import { convertTimeLocally } from "./../../../utils/timeConverter";
import { getMessagesUsers } from "../../../services/PharmacyAdmin";
import NotAuthorized from "../../common/NotAuthorized";
function MessageArea(props) {
  const { dataFromChannel } = props;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const value = SocketIoServer.getState().SocketIoObj;
  const pharmacy_id =
    dataFromChannel.pharmacyId || localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName") || "";
  let userId = myDecodedToken.userId;
  userId = userId.toString();

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagetosent, setMessageTosent] = useState("");
  const [files, setFiles] = useState([]);
  const [findval, setFindval] = useState("");
  const [filteredmsgs, setFilteredmsgs] = useState([]);
  const [count, setCount] = useState(0);
  const [numoffind, setNumoffind] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const endOfDivRef = useRef(null);
  const [curUserInfo, setCurUserInfo] = useState({});
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [messagesUsers, setMessagesUsers] = useState([]);
  const [PharmacySMSNum, setPharmacySMSNum] = useState(null);

  useEffect(() => {
    if (pharmacy_id) {
      getPharmacyById(pharmacy_id)
        .then((res) => {
          setPharmacyInfo(res.data);
          setPharmacySMSNum(res.data.smsPhoneNum);
        })
        .catch((error) => {
          console.error("Error in getting pharmacy info", error);
        });

      getMessagesUsers(pharmacy_id)
        .then((res) => {
          setMessagesUsers(res.data.users);
        })
        .catch((err) => {
          console.error(err);
          setMessagesUsers([]);
        });
    }
  }, [pharmacy_id]);

  useEffect(() => {
    getUserById(userId)
      .then((res) => {
        setCurUserInfo(res?.data?.data?.user[0] ? res.data.data.user[0] : {});
      })
      .catch((error) => console.error(error));
  }, [userId]);
  function scrollToBottom() {
    var elem = endOfDivRef.current;
    if (!elem) {
      return;
    }
    elem.scrollTop = elem.scrollHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setSocket(value);
  }, [value]);

  useEffect(() => {
    if (socket) {
      socket.on("receive_message", (data) => {
        if (data.chId === dataFromChannel.channelId) {
          handleGetMessages(dataFromChannel.channelId)
            .then((res) => {
              if (res?.data?.length > 0) {
                setMessages(res.data);
              } else {
                setMessages([]);
              }
            })
            .catch((err) => {
              setMessages(messages);
            })
            .finally(() => {
              scrollToBottom();
              setIsLoaded(true);
            });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // -----------------------------------------
  // Material UI Starts
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        padding: 3,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  //   =============================== Material UI Ends ===============================

  useEffect(() => {
    if (dataFromChannel.channelId) {
      setIsLoaded(false);
      handleGetMessages(dataFromChannel.channelId)
        .then((res) => {
          if (res?.data?.length > 0) {
            setMessages(res.data);
          } else {
            setMessages([]);
          }
        })
        .finally(() => {
          scrollToBottom();
          setIsLoaded(true);
        });
    } else {
      setMessages([]);
      setIsLoaded(true);
    }
  }, [dataFromChannel.channelId]);

  const handleChange = (e) => {
    setMessageTosent(e.target.value);
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.hexEncode = function () {
    var hex, i;

    var result = "";
    for (i = 0; i < this.length; i++) {
      hex = this.charCodeAt(i).toString(16);
      result += ("000" + hex).slice(-4);
    }

    return result;
  };

  const handleSendMsg = (m) => {
    if (m === "" && Object.values(files).length === 0) {
      return;
    } else {
      if (Object.values(files).length > 0) {
        const formData = new FormData();
        formData.append("file", Object.values(files)[0]);
        handleFiles(formData).then((res) => {
          if (res.status === 200) {
            handleSend(m, res.data.url);
          }
        });
        // });
      } else {
        handleSend(m, "");
      }
    }
  };

  const handleSend = (m, url) => {
    if (dataFromChannel.channelId) {
      setIsLoaded(false);
      setMessages([]);
      handleSendMessage({
        channelId: dataFromChannel.channelId,
        senderId: userId,
        message: m || "",
        URL: url || "",
      }) //   socket  yalla hena
        .then((r) => {
          setMessageTosent("");
          setFiles([]);
          setMessageTosent("");
          setFiles([]);
          r?.data?.data && props.onNewId(r.data.data);
          handleGetMessages(dataFromChannel.channelId).then((res) => {
            if (res?.data?.length > 0) {
              setMessages(res.data);
              let data = {
                chId: dataFromChannel.channelId,
                senderId: Number(userId),
              };
              socket.emit("send_message", data);
              handleSendNotification(m, dataFromChannel.channelId);
            } else {
              setMessages([]);
            }
          });
        })
        .finally(() => {
          setIsLoaded(true);
        });
    } else {
      setIsLoaded(false);

      handleSendMessage({
        channelId: dataFromChannel.channelId,
        senderId: userId,
        message: m || "",
        URL: url || "",
      }).then((r) => {
        setMessageTosent("");
        setFiles([]);
        handleGetMessages(dataFromChannel.channelId)
          .then((res) => {
            if (res?.data?.length > 0) {
              setMessages(res.data);
              let data = {
                chId: r.data.data,
                senderId: Number(userId),
              };
              socket.emit("send_message", data);
              handleSendNotification(m, dataFromChannel.channelId);
            } else {
              setMessages([]);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsLoaded(true);
          });
      });
    }
  };

  const handleSendNotification = (m, chId) => {
    if (dataFromChannel.members && dataFromChannel.members.length > 0) {
      let members = [];
      if ([2, 3].includes(dataFromChannel.channelType)) {
        members = [...dataFromChannel.members, ...messagesUsers];
      } else {
        members = [...dataFromChannel.members];
      }
      members.forEach((u) => {
        if (
          (curUserInfo.type.toLowerCase() === "provider" &&
            u.type.toLowerCase() === "patient") ||
          (curUserInfo.type.toLowerCase() === "patient" &&
            u.type.toLowerCase() === "provider")
        ) {
          socket.emit("noteset", {
            sender_name: `${curUserInfo.first} ${curUserInfo.last}`,
            message: m,
            sender: userId.toString(),
            receiver: u.userId.toString(),
            body: `message`,
            unread: true,
            url: `${baseURL.frontEnd}/newmessages/?id=${chId}&s=${pharmacy_id}&r=${u.userId}`,
            timestamp: Date.now(),
          });
          return null;
        } else {
          let smsurl = urlShortner(
            `${baseURL.frontEnd}/appless-sms/?id=${chId}&s=${pharmacy_id}&r=${u.userId}&sid=sms`
          );
          let emaierl = urlShortner(
            `${baseURL.frontEnd}/appless-sms/?id=${chId}&s=${pharmacy_id}&r=${u.userId}&sid=email`
          );
          if (
            u.type.toLowerCase() !== "user" &&
            u.type.toLowerCase() !== "pharmacyadmin"
          ) {
            Promise.all([smsurl, emaierl]).then((re) => {
              if (u.phone) {
                if (u.userId !== Number(userId)) {
                  const smsPayload = {
                    to: u.phone,
                    body: `New message from the ${pharmacy_name} Pharmacy. To reply please click on the link below: ${re[0]}.If you do not wish to receive these messages please type STOP.`,
                    from: PharmacySMSNum,
                  };

                  sendSMS(smsPayload)
                    .then((res) => {})
                    .catch((err) => {
                      console.error(err);
                      if (u.email) {
                        sendEmail({
                          to: u.email,
                          message: `New message from the ${pharmacy_name} Pharmacy. To reply please click on the link below: ${re[1]}`,
                        })
                          .then((res) => {})
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    });
                }
              } else if (u.email) {
                u.userId !== Number(userId) &&
                  sendEmail({
                    to: u.email,
                    message: `New message from the ${pharmacy_name} Pharmacy. To reply please click on the link below: ${re[1]}`,
                  })
                    .then((res) => {})
                    .catch((err) => {
                      console.error(err);
                    });
              }
            });
          }
        }
        socket.emit("noteset", {
          sender_name: `${curUserInfo.first} ${curUserInfo.last}`,
          message: m,
          sender: userId.toString(),
          receiver: u.userId.toString(),
          body: `message`,
          unread: true,
          url: `${baseURL.frontEnd}/newmessages/?id=${chId}&s=${pharmacy_id}&r=${u.userId}`,
          timestamp: Date.now(),
        });
      });
    }
  };

  const handleAddFiles = (e) => {
    setFiles(e.target.files);
  };

  const [arrowsFlag, setArrowsFlag] = useState(false);

  const getFindval = (e) => {
    setFindval(e.target.value.trim());
  };

  const handleFind = () => {
    setCount(0);
    setMessages(
      messages.map((message) => {
        message.className = "";
        return message;
      })
    );
    if (findval === "") {
      setFilteredmsgs([]);
      setNumoffind("");
      setArrowsFlag(false);

      return;
    } else {
      let reg = new RegExp(findval, "i");
      let msgarr = messages.filter(
        (message) => message.message.search(reg) !== -1
      );

      Promise.all(msgarr).then((res) => {
        setFilteredmsgs(res);
        setNumoffind(msgarr.length);
        setArrowsFlag(true);
      });
    }
  };

  const handleNext = (c) => {
    if (filteredmsgs.length > 0) {
      if (filteredmsgs.length - 1 === c) {
        setMessages(
          messages.map((message) => {
            if (message.id === filteredmsgs[c].id) {
              message.className = "x";
            } else {
              message.className = "";
            }
            return message;
          })
        );
        setCount(0);
      } else {
        setMessages(
          messages.map((message) => {
            if (message.id === filteredmsgs[c].id) {
              message.className = "x";
            } else {
              message.className = "";
            }
            return message;
          })
        );
        setCount(c + 1);
      }
    } else {
      return null;
    }
  };

  const handlePrev = (c) => {
    if (filteredmsgs.length > 0) {
      if (c === 0) {
        setMessages(
          messages.map((message) => {
            if (message.id === filteredmsgs[c].id) {
              message.className = "x";
            } else {
              message.className = "";
            }
            return message;
          })
        );
        setCount(filteredmsgs.length - 1);
      } else {
        setMessages(
          messages.map((message) => {
            if (message.id === filteredmsgs[c].id) {
              message.className = "x";
            } else {
              message.className = "";
            }
            return message;
          })
        );
        setCount(c - 1);
      }
    } else {
      return null;
    }
  };

  const getChannelMembersNames = (channel) => {
    let membersString = "";

    channel.members.forEach((member, i) => {
      if ([1, 4].includes(channel.channelType)) {
        if (member.userId !== Number(userId)) {
          membersString += `${member.first} ${member.last}`;
          if (i < channel.members.length - 1) {
            membersString += ", ";
          }
        }
      } else {
        const curUserType = curUserInfo?.type?.toLowerCase();

        if (["provider", "patient"].includes(curUserType)) {
          membersString += `${pharmacyInfo.name} ${
            pharmacyInfo.name?.toLowerCase()?.includes("pharmacy")
              ? ""
              : "Pharmacy"
          }  `;
        } else {
          if (member.userId !== Number(userId)) {
            membersString += `${member.first} ${member.last}`;
            if (i < channel.members.length - 1) {
              membersString += ", ";
            }
          }
        }
      }
    });

    if (membersString[membersString.length - 2] === ",") {
      membersString = membersString.slice(0, -2);
    }

    return membersString;
  };

  const getChannelMembersAvatars = (channel) => {
    if ([1, 4].includes(channel.channelType)) {
      return (
        <AvatarGroup max={4}>
          {channel.members.map((member) => {
            if (member.userId !== Number(userId)) {
              return member.pic_url ? (
                <Avatar
                  src={`${member.pic_url}?v=${Date.now()}`}
                  sx={{ scale: "1.3", marginTop: "0.35rem" }}
                  key={member.userId}
                />
              ) : (
                <Avatar
                  {...stringAvatar(`${member.first} ${member.last}`)}
                  key={member.userId}
                />
              );
            }
          })}
        </AvatarGroup>
      );
    } else {
      const curUserType = curUserInfo?.type?.toLowerCase();
      if (["provider", "patient"].includes(curUserType)) {
        return (
          <AvatarGroup max={4}>
            <Avatar
              src={`${pharmacyInfo.logo_url}?v=${Date.now()}`}
              sx={{ scale: "1.3", marginTop: "0.35rem" }}
            />
          </AvatarGroup>
        );
      } else {
        return (
          <AvatarGroup max={4}>
            {channel.members.map((member) => {
              if (member.userId !== Number(userId)) {
                return member.pic_url ? (
                  <Avatar
                    src={`${member.pic_url}?v=${Date.now()}`}
                    sx={{ scale: "1.3", marginTop: "0.35rem" }}
                    key={member.userId}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(`${member.first} ${member.last}`)}
                    key={member.userId}
                  />
                );
              }
            })}
          </AvatarGroup>
        );
      }
    }
  };
  if (
    curUserInfo.id &&
    [2, 3].includes(dataFromChannel.channelType) &&
    !messagesUsers.some((m) => m.userId === Number(userId)) &&
    ["user", "pharmacyadmin"].includes(curUserInfo.type.toLowerCase())
  ) {
    return <NotAuthorized />;
  }
  return (
    <div className={`chat chat-messages col-12 col-lg-8 ${props.mobHide}`}>
      <div className="col-12">
        <div className="chat-header clearfix ">
          <div className="row ">
            {!props.messageFlag ? (
              <span className="chat-close-btn">
                <button
                  type="button"
                  className="btn-close closebtn fs-6 "
                  aria-label="Close"
                  onClick={() => {
                    props.setMobHide("");
                  }}></button>
              </span>
            ) : null}
            <div
              id={dataFromChannel.channelId}
              className="d-flex justify-content-center">
              <CardHeader
                className="py-1 fw-semibold fs-5"
                avatar={
                  dataFromChannel.members.length > 0
                    ? getChannelMembersAvatars(dataFromChannel)
                    : ""
                }
                title={
                  dataFromChannel.members.length > 0
                    ? getChannelMembersNames(dataFromChannel)
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="chat-history">
            <div className="row">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Messages..."
                  value={findval}
                  onChange={getFindval}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="inputGroupFileAddon04"
                  onClick={handleFind}>
                  <FontAwesomeIcon size="xs" icon={faSearch} />
                </button>
              </div>
              <div className="mt-2">
                {arrowsFlag ? (
                  <>
                    <span>
                      <a
                        href="#x"
                        className="link-danger"
                        onClick={() => handlePrev(count)}>
                        <FontAwesomeIcon size="xl" icon={faArrowLeft} />{" "}
                      </a>
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    {numoffind > 0 ? <span>{numoffind} found</span> : numoffind}
                    &nbsp;&nbsp;&nbsp;
                    <span>
                      <a
                        href="#x"
                        className="link-success"
                        onClick={() => handleNext(count)}>
                        <FontAwesomeIcon size="xl" icon={faArrowRight} />{" "}
                      </a>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div>
              <ul className="m-b-0" id="chat-area" ref={endOfDivRef}>
                {isLoaded ? <></> : <p>Loading...</p>}
                {messages.length > 0 ? (
                  messages
                    .sort(function (b, a) {
                      return a.createdAt > b.createdAt
                        ? -1
                        : b.createdAt > a.createdAt
                        ? 1
                        : 0;
                    })
                    .map((m, i) => {
                      return (
                        <React.Fragment key={m.id}>
                          {m.senderId === Number(userId) ? (
                            <li
                              className={`clearfix ${m.className}`}
                              id={`${m.className}`}>
                              <div className="message-data text-end ">
                                <span className="message-data-time chat-time">
                                  {convertTimeLocally(m.createdAt)}
                                </span>
                              </div>
                              <div
                                className="message my-message float-right"
                                id={`${m.className}`}>
                                {m.message}
                                {m.URL ? (
                                  <a
                                    href={m.URL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link-warning">
                                    <img
                                      src={m.URL}
                                      alt={`${
                                        m.URL.split("/")[
                                          m.URL.split("/").length - 1
                                        ]
                                      }`}
                                      className="message-img"
                                    />
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </li>
                          ) : (
                            <>
                              <li
                                className={`clearfix ${m.className}`}
                                key={m.id}>
                                <div className="message-data">
                                  <span className="fw-bold">
                                    {`${m.first} ${m.last}`}
                                  </span>
                                  <span className="message-data-time fs-6 chat-time">
                                    {convertTimeLocally(m.createdAt)}
                                  </span>
                                </div>
                                <div
                                  className="message other-message"
                                  id={`${m.className}`}>
                                  {m.message}
                                  {m.URL ? (
                                    <a
                                      href={m.URL}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="link-warning">
                                      <img
                                        src={m.URL}
                                        alt={`${
                                          m.URL.split("/")[
                                            m.URL.split("/").length - 1
                                          ]
                                        }`}
                                        className="message-img"
                                      />
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </li>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })
                ) : (
                  <div className="text-center">No messages yet</div>
                )}
              </ul>
            </div>
          </div>
          <div className="chat-input">
            <div className="image-upload btn btn-outline-secondary ">
              <label htmlFor="formFileSm" className="form-label py-0 m-0 ">
                <FontAwesomeIcon size="sm" icon={faPaperclip} />
              </label>
              <input
                className="form-control form-control-sm"
                id="formFileSm"
                type="file"
                onChange={handleAddFiles}
              />
            </div>
            <div>
              <div className="input-group">
                {Object.values(files).length > 0 ? (
                  <textarea
                    className="form-control"
                    type="text"
                    value={Object.values(files).map((f) => {
                      return `${f.name}  \n`;
                    })}
                    aria-label="Disabled input example"
                    disabled
                    readonly></textarea>
                ) : (
                  ""
                )}
                <textarea
                  className="form-control"
                  placeholder="Type a message..."
                  id="floatingTextarea"
                  name="message"
                  value={messagetosent}
                  onChange={handleChange}></textarea>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => handleSendMsg(messagetosent)}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageArea;
