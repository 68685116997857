import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { convertTimeLocally } from "../../utils/timeConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Switch, FormControlLabel } from "@mui/material";
import PatientDetails from "../../components/pharmacyUser/patientCenter/PatientDetails";
import { IconButton } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import RenderCellImage from "../../components/common/dataGrids/RenderCellImage";
import {
  searchPt,
  getPatients,
  getPatientsTotal,
} from "../../services/PharmacyUser";

const ManagePatients = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [checked, setChecked] = useState(false);
  const [details, setDetails] = useState(false);
  const [curUserInfo, setCurUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPatients, setTotalPatients] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
    DOB: "",
    MRN: "",
  });

  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    // gridRef.current.api.setQuickFilter(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    searchPt(search, pharmacy_id).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          let usersSorted = res.data.data.map((el, idx) => {
            return {
              ...el.user,
              "patient id": el.user.id,
              createdAt: convertTimeLocally(el.user.createdAt),
              updatedAt:
                el.user.updatedAt === el.user.createdAt
                  ? "N/A"
                  : convertTimeLocally(el.user.updatedAt),
              address: el.address,
              ContactInfo: el.contacts.length
                ? el.contacts
                : [{ id: "", emName: "", emRelation: "", altPhone: "" }],
              active: el.user.active ? true : false,
              "S/N": idx + 1 + (page - 1) * 100 || idx + 1,
              providers: el.providers || [],
            };
          });

          const filtered = usersSorted.filter((el) => el.active === true);
          setPatients(usersSorted);
          setFilteredPatients(checked ? usersSorted : filtered);
          setTotalPatients(usersSorted.length);
          setIsLoading(false);
        } else {
          setPatients([]);
          setTotalPatients(0);
          setIsLoading(false);
        }
      } else {
        setPatients([]);
        setTotalPatients(0);
        setIsLoading(false);
      }
    });
  };

  const clearFilter = () => {
    setSearch({
      first: "",
      last: "",
      email: "",
      phone: "",
      DOB: "",
      MRN: "",
    });
    handlePageNumber();
    fetchPatient();
  };

  const rowClickedListener = (params) => {
    setDetails(true);
    setCurUserInfo(params.row);
  };

  const fetchPatient = async () => {
    await getPatients(pharmacy_id, page)
      .then((res) => {
        let usersSorted = res.data.data.map((el, idx) => {
          return {
            ...el.user,
            "patient id": el.user.id,
            createdAt: convertTimeLocally(el.user.createdAt),
            updatedAt:
              el.user.updatedAt === el.user.createdAt
                ? "N/A"
                : convertTimeLocally(el.user.updatedAt),
            address: el.address,
            ContactInfo: el.contacts.length
              ? el.contacts
              : [{ id: "", emName: "", emRelation: "", altPhone: "" }],
            active: el.user.active ? true : false,
            "S/N": idx + 1 + (page - 1) * 100,
            providers: el.providers || [],
          };
        });
        const filtered = usersSorted.filter((el) => el.active === true);
        setFilteredPatients(checked ? usersSorted : filtered);
        setPatients(usersSorted);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pharmacy_id, details]);

  const columnDefs = [
    {
      headerName: "S/N",
      field: "S/N",
      minWidth: 50,
      maxWidth: 60,
    },
    {
      headerName: "Patient ID",
      field: "patient id",
      sortable: true,
      minWidth: 90,
    },
    {
      headerName: "Picture",
      field: "pic_url",
      minWidth: 80,
      renderCell: (params) => RenderCellImage(params),
    },
    { headerName: "First", field: "first", minWidth: 100 },
    { headerName: "Last", field: "last", minWidth: 100 },
    { headerName: "MRN", field: "MRN", minWidth: 100 },
    { headerName: "DOB", field: "DOB", minWidth: 100 },
    { headerName: "Email", field: "email", minWidth: 160 },
    { headerName: "Phone", field: "phone", minWidth: 130 },
    {
      headerName: "Created At",
      field: "createdAt",
      minWidth: 180,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      headerName: "Last Updated On",
      field: "updatedAt",
      minWidth: 180,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      headerName: "Status",
      field: "active",
      minWidth: 100,
      valueGetter: (params) => {
        return params.value ? "Active" : "Inactive";
      },
      cellClassName: (params) => {
        return params.row.active ? "pt_active cellwa" : "pt_inactive cellwa";
      },
    },
  ];

  const handlePageNumber = () => {
    getPatientsTotal(pharmacy_id)
      .then((res) => {
        setTotalPatients(res.data.Patients);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    handlePageNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  const onFocus = (e) => {
    e.currentTarget.type = "date";
  };

  const onBlur = (e) => {
    e.currentTarget.type = "text";
  };

  const getRowClassName = (params) => {
    return "pointer-cursor";
  };

  const handleToggleShowInactive = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setFilteredPatients(patients);
    } else {
      setFilteredPatients(patients.filter((el) => el.active === true));
    }
    setChecked(e.target.checked);
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Manage Patients</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Patient Center</li>
              <li
                onClick={() => setDetails(false)}
                className={`breadcrumb-item ${
                  details ? "cstm_breadcrumb" : "active"
                }`}>
                Manage Patients
              </li>
              {details && (
                <li className="breadcrumb-item active">{`${curUserInfo.first} ${curUserInfo.last}`}</li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {!details ? (
              <div className="container-fluid">
                <div className="row g-3 pt-0">
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="first"
                      onChange={handleSearch}
                      value={search.first}
                      placeholder="First name"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="last"
                      onChange={handleSearch}
                      value={search.last}
                      placeholder="Last name"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="DOB"
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChange={handleSearch}
                      value={search.DOB}
                      placeholder="Date Of Birth (mm/dd/yyyy)"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={handleSearch}
                      value={search.email}
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      onChange={handleSearch}
                      value={search.phone}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <input
                      type="text"
                      className="form-control"
                      name="MRN"
                      onChange={handleSearch}
                      value={search.MRN}
                      placeholder="MRN"
                    />
                  </div>
                  <div className="col-6 col-md-auto">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={handleSearchSubmit}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} size={"1x"} />
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-auto">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={clearFilter}>
                      Clear Filter
                    </button>
                  </div>
                </div>

                {!isLoading ? (
                  <div className="mt-2" id="mng_pt_grid">
                    <div className="d-flex justify-content-end">
                      <FormControlLabel
                        sx={{ color: "text.primary", marginLeft: "2px" }}
                        control={
                          <Switch
                            checked={checked}
                            onChange={handleToggleShowInactive}
                          />
                        }
                        label={`${checked ? "Hide" : "Show"} Inactive`}
                      />
                    </div>
                    <div className="dg_3liner" style={{ width: "100%" }}>
                      <DataGrid
                        rows={filteredPatients}
                        columns={columnDefs}
                        onRowClick={rowClickedListener}
                        density="compact"
                        getRowClassName={getRowClassName}
                        pageSizeOptions={[100]}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "updatedAt", sort: "desc" }],
                          },
                        }}
                      />
                    </div>
                    {totalPatients > 0 && (
                      <div
                        className="ag-paging-panel ag-unselectable card rounded-0 pt-1 pb-0"
                        id="ag-132">
                        <div className="m-0 p-0 text-center">
                          {page === 1 ? 1 : (page - 1) * 100 + 1} to{" "}
                          {page === Math.ceil(totalPatients / 100)
                            ? totalPatients
                            : page === 1
                            ? 100
                            : page * 100}{" "}
                          of {totalPatients}
                        </div>
                        <div className="mng-patient-pagination d-flex flex-row align-items-center justify-content-center p-0 m-0">
                          <div>
                            <IconButton
                              onClick={() => setPage(1)}
                              disabled={page === 1}
                              size="medium">
                              <SkipPreviousIcon />
                            </IconButton>
                          </div>
                          <div>
                            <IconButton
                              onClick={() => page > 1 && setPage(page - 1)}
                              disabled={page === 1}
                              size="medium">
                              <ArrowLeftIcon fontSize="large" />
                            </IconButton>
                          </div>
                          <span
                            className="ag-paging-description d-flex gap-1"
                            role="status">
                            <span id="ag-132-start-page">Page</span>
                            <span
                              id="ag-132-start-page-number"
                              className="ag-paging-number">
                              {page}
                            </span>
                            <span id="ag-132-of-page">of</span>
                            <span
                              id="ag-132-of-page-number"
                              className="ag-paging-number">
                              {Math.ceil(totalPatients / 100)}
                            </span>
                          </span>
                          <div>
                            <IconButton
                              onClick={() =>
                                page < Math.ceil(totalPatients / 100) &&
                                setPage(page + 1)
                              }
                              disabled={page === Math.ceil(totalPatients / 100)}
                              size="medium">
                              <ArrowRightIcon fontSize="large" />
                            </IconButton>
                          </div>
                          <div>
                            <IconButton
                              onClick={() =>
                                setPage(Math.ceil(totalPatients / 100))
                              }
                              disabled={page === Math.ceil(totalPatients / 100)}
                              size="medium">
                              <SkipNextIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                    <div className="spinner-border text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="ms-4 align-middle text-secondary">
                      Loading Patients Data Please wait...
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <PatientDetails
                curUserInfo={curUserInfo}
                setCurUserInfo={setCurUserInfo}
                setDetails={setDetails}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManagePatients;
