import React, { useState } from "react";
import { sendEmail } from "../../services/Common";
import { SideBar } from "../../utils/globalState";

const ContactUs = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const [data, setData] = useState({});
  const [msg, setMsg] = useState("init");
  const [btn, setBtn] = useState("init");

  const handleChange = (event) => {
    event.preventDefault();

    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    setBtn("loading");
    event.preventDefault();
    const message = `Name: ${data.name} \n Email: ${data.email} \n Subject: ${data.subject} \n Message: ${data.message}`;
    sendEmail({ to: "info@nextehealth.com", message }).then((res) => {
      setBtn("init");

      if (res.status === 200) {
        setMsg("succsess");
      } else {
        setMsg("fail");
      }
    });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Contact Us</h1>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div>
                <div className="card shadow mb-2">
                  <div className="col gy-4">
                    <div className="row">
                      <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center mx-auto my-2">
                        <div className="col-11 col-md-4 text-center card m-2">
                          <h4 className="card-header">
                            <i className="fas fa-envelope" /> &nbsp; Email
                          </h4>
                          <p className="card-body">info@nextehealth.com</p>
                        </div>
                        <div className="col-11 col-md-4 text-center card m-2 ">
                          <h4 className="card-header">
                            <i className="fas fa-phone" id="phone_icon_pt" />
                            &nbsp; Call
                          </h4>
                          <p className="card-body">+1 (866) 413-3156</p>
                        </div>{" "}
                        <div className="col-11 col-md-4 text-center card m-2 ">
                          <h4 className="card-header">
                            <i className="fas fa-location-arrow" />
                            &nbsp; Address
                          </h4>
                          <p className="card-body">
                            16782 Von Karman Ave,
                            <br /> Irvine, CA 92606
                          </p>
                        </div>
                        <div className="col-11 col-md-4 text-center card m-2 ">
                          <h4 className="card-header">
                            <i className="fas fa-clock" />
                            &nbsp; Open Hours
                          </h4>
                          <p className="card-body">
                            Monday - Friday <br />
                            9:00AM - 05:00PM
                          </p>
                        </div>
                      </div>
                      <div className="col-11 col-md-10 mx-auto my-3">
                        <form onSubmit={handleSubmit} className="card p-3">
                          <div className="row gy-4">
                            <div className="col-md-6">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Your Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-6 ">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Your Email"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-12">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                placeholder="Subject"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-12">
                              <textarea
                                className="form-control"
                                name="message"
                                rows={6}
                                placeholder="Message"
                                required
                                defaultValue={""}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-12 text-center">
                              <>
                                {msg === "fail" ? (
                                  <div className="alert alert-danger">
                                    {" "}
                                    Something Went Wrong. Please Try Again
                                  </div>
                                ) : msg === "succsess" ? (
                                  <div className="alert alert-success">
                                    Your message has been sent. Thank you!
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              <>
                                {btn === "init" ? (
                                  <button
                                    type="submit"
                                    className="btn btn-success">
                                    Send Message
                                  </button>
                                ) : btn === "loading" ? (
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    disabled>
                                    <span
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                                    Loading...
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactUs;
