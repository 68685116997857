import React, { useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import {
  createFormInstance,
  updateFormInstance,
  getFormInstanceById,
} from "../../../services/PharmacyUser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import SendForm from "./SendForm";
import "../../../styles/page-styles/InstanceCreator.css";
function InstanceCreator(props) {
  const { userID, isAdmin, privilege, form, instance, variantName, isUpdate } =
    props;
  const [instId, setInstId] = useState(0);
  const [sendFlag, setSendFlag] = useState(false);
  const [msg, setMsg] = useState("");
  const [creatorJSON, setCreatorJSON] = useState({});
  const formId = form?.ID ? form.ID : "";
  console.log(form);
  useEffect(() => {
    isUpdate && setInstId(instance.id);
  }, [instance, isUpdate]);
  const creatorOptions = {
    showLogicTab: !privilege.includes("writeForms") && !isAdmin ? false : true,
    showDesignerTab:
      !privilege.includes("writeForms") && !isAdmin ? false : true,
    showJSONEditorTab: true,
    readOnly: !privilege.includes("writeForms") && !isAdmin ? true : false,
    showOptions: true,

    isAutoSave: false,
    haveCommercialLicense: true,
  };

  const creator = new SurveyCreator(creatorOptions);
  creator.onElementAllowOperations.add(function (sender, options) {
    options.allowDelete = false;
    options.allowEdit = false;
    options.allowCopy = false;
    options.allowAddToToolbox = false;
    options.allowDragging = false;
    options.allowChangeType = false;
    options.allowChangeRequired = false;
    options.allowShowHideTitle = false;
    options.allowChangeTitle = false;
    options.allowChangeDescription = false;
    options.allowChangeLogo = false;
    options.allowChangePageTitle = false;
    options.allowChangePageDescription = false;
    options.allowChangePageFooterDescription = false;
  });


  useEffect(() => {
    if (instId === 0) {
      setCreatorJSON(form.json);
    } else {
      setCreatorJSON(instance.json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instId]);
  creator.JSON = creatorJSON;
  creator.isAutoSave = false;
  creator.clickToolboxItem = function (sender, options) {
    setMsg("Adding new question to existing form is not allowed!");
    return null;
  };
  creator.saveSurveyFunc = (saveNo, callback) => {
    //   Save the form JSON and text into your database here

    setMsg("");
    const dataForDB = {
      formText: { ...creator.JSON },
      creatorID: userID,
      formId,
      instanceName: instance.instanceName || variantName,
    };
    if (instId === 0) {
      createFormInstance(dataForDB)
        .then((res) => {
          setInstId(res.data.data);
          setMsg("Form created successfully!");
          getFormInstanceById(res.data.data).then((res) => {
            creator.JSON = JSON.parse(res.data.data.formText);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateFormInstance(instId, {
        formText: { ...creator.JSON },
      })
        .then((res) => {
          setMsg("Form updated successfully!");
          getFormInstanceById(res.data.data).then((res) => {
            creator.JSON = JSON.parse(res.data.data.formText);
          });
        })
        .catch((err) => console.error(err));
    }
    creator.JSON = { ...creator.JSON };
    instance.json = { ...creator.JSON };
    callback(saveNo, true);
  };

  useEffect(() => {
    creator.showJSONEditorTab = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    let in1 = document.getElementsByClassName("spg-text");
    let in2 = document.getElementsByClassName("spg-comment");
    if (in1 && in2){
      console.log(in1, in2);
      for (let i = 0; i < in1.length; i++) {
        in1[i].style.display = "none";
      }
      for (let i = 0; i < in2.length; i++) {
        in2[i].style.display = "none";
      }
    }

  }, [creator, creatorJSON]);
  console.log(creator.JSON, "creator.JSON");
  return (
    <>
      {sendFlag ? (
        <>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            style={{ marginTop: "20px" }}
            onClick={() => setSendFlag(false)}
          >
            Back to form Editor
          </Button>
          <SendForm form={form} instanceId={instId} />
        </>
      ) : (
        <div id="instanceCreator">
          <p>{msg !== "" && msg}</p>
          <SurveyCreatorComponent creator={creator} />{" "}
          <button
            className="btn btn-primary w-100 mt-3"
            onClick={() => setSendFlag(true)}
            disabled={instId === 0}
          >
            Use this form
          </button>{" "}
        </div>
      )}
    </>
  );
}

export default InstanceCreator;
