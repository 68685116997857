import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdleTimerProvider } from "react-idle-timer";

import Modal from "@mui/material/Modal";
import "../../../styles/page-styles/sessiontimeout.css";

const SessionTimeOut = () => {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Material UI +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Material UI END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const navigate = useNavigate();

  const [countDownValue, setCountDownValue] = useState(20);
  const [intervalId, setIntervalId] = useState(0);
  const [timeOutId, setTimeOutId] = useState(0);

  let count = 20;

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const countDown = () => {
    if (count <= 0) {
      clearInterval(intervalId);
      count = 20;
    } else {
      count = count - 1;
      setCountDownValue(count);
    }
  };

  const handleIdle = () => {
    const LATS = localStorage.getItem("LATS");
    const currentTime = Date.now();
    const idleTime = currentTime - LATS;

    if (idleTime >= 30 * 60 * 1000) {
      handleLogOut();
    } else {
      if (
        localStorage.getItem("Token") &&
        localStorage.getItem("Token") !== ""
      ) {
        handleOpen();
        let timeOut = setTimeout(handleLogOut, 20000);
        let timer = setInterval(countDown, 1000);
        setTimeOutId(timeOut);
        setIntervalId(timer);
      }
    }
  };

  const handleClear = () => {
    clearInterval(intervalId);
    clearTimeout(timeOutId);
    setCountDownValue(20);
    count = 20;
  };

  const handleAction = () => {
    localStorage.setItem("LATS", Date.now());
    handleClear();
  };

  useEffect(() => {
    // Set the initial last active time when the component mounts
    localStorage.setItem("LATS", Date.now());
  }, []);

  return (
    <>
      <IdleTimerProvider
        timeout={14 * 60 * 1000}
        onIdle={handleIdle}
        onAction={handleAction}></IdleTimerProvider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="card  mb-3 " style={style}>
          <div className="card-header bg-transparent fw-bold text-center">
            Session Timeout
          </div>
          <div className="card-body">
            <p className="card-text text-center">
              No activity detected. Your session will expire in{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {countDownValue}s{" "}
              </span>{" "}
              for security.
            </p>
          </div>
          <div className="card-footer bg-transparent text-center">
            <button
              type="button"
              className="btn btn-danger mx-2 mx-2"
              data-bs-dismiss="modal"
              onClick={() => {
                handleLogOut();
                handleClose();
              }}>
              LogOut
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2 mx-2"
              onClick={() => {
                handleClear();
                handleClose();
              }}>
              Stay Logged In
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SessionTimeOut;
