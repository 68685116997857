import axios from "axios";

const axiosWithAuth = (contentType) => {
  const token = localStorage.getItem("Token") || "";

  return axios.create({
    headers: {
      Authorization: token,
      "Content-Type": contentType || "application/json",
    },
  });
};

export default axiosWithAuth;
