import React, { useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  handleAddCarePlanForm,
  handleUpdateCarePlanForm,
  handleGetFormById,
} from "../../../services/CarePlan";
const CpFormBuilder = (props) => {
  const { form, creatorType, userID, setShowCreator } = props;
  const pharmacyID = Number(localStorage.getItem("pharmacy"));
  const formId = form?.id ? form.id : "";
  const [msg, setMsg] = useState({ text: "", type: "" });
  const creatorOptions = {
    showLogicTab: form.isSent ? false : true,
    showDesignerTab: form.isSent ? false : true,
    showJSONEditorTab: true,
    readOnly: form.isSent ? true : false,

    showOptions: true,
    isAutoSave: false,
    haveCommercialLicense: true,
  };

  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = form.json;
  creator.isAutoSave = false;

  creator.onElementAllowOperations.add(function (sender, options) {
    if (
      [
        "first-name",
        "last-name",
        "email",
        "phone",
        "gender",
        "birthdate",
        "follow-up",
        "follow_up_preference",
        "Freq",
        "f/u",
        "repeat",
        "month",
        "day",
        "repeateon",
        "End",
        "Count",
        "ends-in",
        "personal-information",
        "startDate",
        "followup-schedule",
      ].includes(options.obj.name)
    ) {
      options.allowDelete = false;
      options.allowCopy = false;
      options.allowAddToToolbox = false;
      options.allowChangeType = false;
      options.allowChangeVisibility = false;
      options.allowEdit = false;
      options.allowEditDropdown = false;
    }
  });

  creator.saveSurveyFunc = (saveNo, callback) => {
    //   Save the form JSON and text into your database here

    const data = {
      formText: { ...creator.JSON },
      pharmacyID: creatorType === "pharmacy" ? pharmacyID : null,
      creatorID: userID,
      creatorType: creatorType ? creatorType : "pharmacy",
      isInitForm: form.isInitForm,
    };

    const dataForDBUpdate = {
      formText: { ...creator.JSON },
      isSent: form.isSent,
    };

    if (form.id) {
      handleUpdateCarePlanForm(formId, dataForDBUpdate)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    } else {
      handleAddCarePlanForm(data)
        .then((res) => {
          setMsg({ text: "Form saved successfully", type: "success" });
          if (props.setForm) {
            handleGetFormById(res.data.id)
              .then((res) => {
                res.data.json = JSON.parse(res.data.formText);
                props.setForm(res.data);
                props.handleSuccess(true);
              })
              .catch((err) => {
                setMsg({ text: err.response.data.message, type: "danger" });
                console.error(err);
                if (props.setForm) {
                  props.handleSuccess(false);
                }
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    callback(saveNo, true);
  };

  return (
    <>
      <div className="row">
        <div className="col-1">
          <p className={`text-${msg.type === "danger" ? "danger" : "success"}`}>
            {msg.text}
          </p>
          <IconButton aria-label="settings" onClick={setShowCreator}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
      </div>

      <SurveyCreatorComponent creator={creator} />
    </>
  );
};

export default CpFormBuilder;
