import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { getMessagesUsers } from "../../services/PharmacyAdmin";
import { handleAddsurrespond, handleGetptbyid } from "./../../services/Patient";
import { sendEmail } from "../../services/Common";
import { SocketIoServer } from "../../utils/globalState";
import {
  getTokenById,
  getSurveyById,
  getNotificationsUsers,
  updateToken,
} from "../../services/PharmacyUser";

const PtSurveyRun = (props) => {
  const { renderSource } = props;
  const value = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);
  const pharmacyId = localStorage.getItem("pharmacy");

  const navigate = useNavigate();

  useEffect(() => {
    setSocket(value);
  }, [value]);
  const [survey, setSurvey] = useState({});
  const [messagesusers, setMessagesusers] = useState([]);
  const [Recipients, setRecipients] = useState([]);
  let id = props.data.surveyid;
  let patientId = props.data.pateintid;
  let ddate = props.data.servicedate;
  let token = props.data.token || "";

  const getsBy = (id) => {
    getSurveyById(id)
      .then((res) => {
        setSurvey(JSON.parse(res.data[0].survey));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRecipients = () => {
    getNotificationsUsers()
      .then((res) => {
        setRecipients(res.data);
      })
      .catch((err) => {
        console.error(err);
        setRecipients([]);
      });

    getMessagesUsers(pharmacyId)
      .then((res) => {
        setMessagesusers(res.data.users);
      })
      .catch((err) => {
        setMessagesusers([]);
      });
  };

  useEffect(() => {
    id && getsBy(id);

    getRecipients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (survey.length === 0) {
    return <div>Loading...</div>;
  } else {
    const model = new Model(survey);

    model.onComplete.add((sender) => {
      handleAddsurrespond(patientId, {
        response: sender.data,
        surveyId: id,
        source: "PP",
      })
        .then((res) => {
          if (token !== "") {
            getTokenById(token)
              .then((res) => {
                let TokenObj = JSON.parse(res.data.token);
                TokenObj.resflag = true;
                updateToken(token, { token: JSON.stringify(TokenObj) })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                  });
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          console.error(err);
        });
      for (let index = 0; index < survey.pages.length; index++) {
        survey.pages[index].elements
          .filter((element) => element.type !== "html")
          .forEach((element) => {
            if (
              element.title.includes("contacted") ||
              element.title.includes("Contacted") ||
              element.title.includes("contact") ||
              element.title.includes("Contact")
            ) {
              if (sender.data[element.name] === true) {
                handleGetptbyid(patientId).then((res) => {
                  let msg = `${res.data.patient.firstName} ${res.data.patient.lastName} DOB: ${res.data.patient.DOB} has completed the survey ${survey.title} and has indicated that they need to be contacted. Please contact them at ${res.data.patient.phone} to discuss the results of the survey.`;
                  if (Recipients.length > 0) {
                    Recipients.filter((rc) => rc.survey_ID === id).forEach(
                      (recipient) => {
                        sendEmail({ to: recipient.email, message: msg });
                      }
                    );
                  }

                  messagesusers.forEach((u) => {
                    socket.emit("noteset", {
                      sender_name: `Patient: ${res.data.patient.firstName} ${res.data.patient.lastName} DOB: ${res.data.patient.DOB}`,
                      message: msg,
                      sender: res.data.patient.id.toString(),
                      receiver: u.userId.toString(),
                      body: `survey`,
                      unread: true,
                      url: `#`,
                      timestamp: Date.now(),
                    });
                  });
                });
              }
            }
          });
      }
    });

    return (
      <div>
        <div id="wrapper">
          {/* <Nav /> */}
          <div className="d-flex flex-column rounded my-2" id="content-wrapper">
            <div id="content pt_survey_content" className="pt_content">
              {renderSource === "dash" ? (
                <button
                  className="btn btn-success ms-2"
                  type="button"
                  onClick={() => navigate(-1)}>
                  <i className="fa fa-arrow-left fa-1x " aria-hidden="true"></i>{" "}
                  Back
                </button>
              ) : (
                <button
                  className="btn btn-success ms-2"
                  type="button"
                  onClick={() => props.onGoback()}>
                  <i className="fa fa-arrow-left fa-1x " aria-hidden="true"></i>{" "}
                  Back
                </button>
              )}

              <div className="container-fluid">
                <div className="pagetitle">
                  <h1>
                    {ddate !== "" ? <>for your visit of {ddate}</> : <></>}
                  </h1>
                </div>
                <Survey model={model} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PtSurveyRun;
