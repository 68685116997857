import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTokenById } from "../../services/PharmacyUser";
import PtSurveyRun from "./PtSurveyRun";
import { SideBar } from "../../utils/globalState";

const PtSurveyFillPage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const { surveyTokenId } = useParams();

  const [thisSurvey, setThisSurvey] = useState({});
  const [surveyToken, setSurveyToken] = useState([]);

  const handleGetTokens = () => {
    getTokenById(surveyTokenId)
      .then((res) => {
        setSurveyToken(JSON.parse(res.data.token));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    handleGetTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyTokenId]);

  useEffect(() => {
    if (surveyToken.token) {
      const currentSurvey = {
        surveyid: Number(surveyToken.servid),
        pateintid: surveyToken.ptid,
        servicedate: surveyToken.dispenseDate,
        token: surveyToken.token,
      };

      setThisSurvey(currentSurvey);
    }
  }, [surveyToken]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {surveyToken.token ? (
                <PtSurveyRun data={thisSurvey} renderSource="dash" />
              ) : (
                <>
                  <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                    <div className="spinner-border text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="ms-4 align-middle text-secondary">
                      Loading Survey...
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PtSurveyFillPage;
