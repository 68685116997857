import React, { useEffect, useState, useRef } from "react";
import {
  handleGetCpResponsesByCpId,
  handleGetResponseById,
  handleGetCarePlanById,
  handleUpdateCarePlan,
  handleUpdateResponse,
} from "../../../services/CarePlan";
import {
  generateToken,
  handleEncryption,
  sendSMS,
  getSystemUserByPharmacyId,
  getPharmacyById,
} from "../../../services/Common";
import { handleUpdateEvent } from "../../../services/Calendar";
import "../../../styles/page-styles/cpappless.css";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import OTPTimer from "../../../components/common/OTPTimer";
import { Button, Divider, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-react-schedule";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

const Applesscp = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const responseId = parseInt(urlParams.get("rid"));
  const sentFormId = parseInt(urlParams.get("fid"));
  const recipientId = parseInt(urlParams.get("pid"));
  const pharmacyId = parseInt(urlParams.get("phid"));
  const cpid = parseInt(urlParams.get("cpid"));
  const [validation, setValidation] = useState({
    isValid: true,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [cpData, setCpData] = useState({});
  const [validationForm, setValidationForm] = useState({
    phone: "",
    otp: "",
  });
  const [msg, setMsg] = useState("");
  const [stepFlag, setStepFlag] = useState("phone");
  const [completed, setCompleted] = useState(false);
  const [fueditor, setFueditor] = useState(false);
  const [curCpResponse, setCurCpResponse] = useState({});
  const [inputs, setInputs] = useState({
    startDate: new Date(),
    location: "form",
  });
  const [recString, setRecString] = useState("");
  const recObject = useRef(null);
  const [systemUserId, setSystemUserId] = useState("");
  let survey = new Model({});
  const [resValue, setResValue] = useState({});
  const [PharmacySMSNum, setPharmacySMSNum] = useState(null);

  const handleChange = (e) => {
    setValidationForm({
      ...validationForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleFusChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (args) => {
    setRecString(args.value);
  };

  const handleSendOTP = () => {
    setMsg("");
    let otp = Math.floor(1000 + Math.random() * 9000);
    localStorage.setItem("otp", handleEncryption(otp.toString()));

    const smsPayload = {
      to: cpData.recipient.phone,
      body: `Your CuroRx One Time Password is ${otp} `,
      from: PharmacySMSNum,
    };

    sendSMS(smsPayload)
      .then((res) => {
        // setMsg("OTP sent successfully");
        setValidationForm({
          phone: "",
        });
      })
      .catch((err) => {
        setMsg("Invalid Phone Number");
        console.error(err);
      });
  };

  const handleSubmitPhone = async (e) => {
    e.preventDefault();
    setMsg("");
    setStepFlag("phone");
    if (validationForm.phone) {
      if (cpData.recipient.phone === validationForm.phone) {
        setStepFlag("otp");
        setValidationForm({
          phone: "",
        });
        handleSendOTP();
      } else {
        setStepFlag("phone");
        setMsg(
          "The phone number you entered does not match our records. Please enter valid phone number"
        );
        return;
      }
    } else {
      setStepFlag("phone");
      setMsg("Please enter valid phone number");
      return;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSystemUserByPharmacyId(pharmacyId)
      .then((res) => {
        setSystemUserId(res.data.id);
      })
      .catch((err) => {
        console.error(err);
      });
    if (cpid && pharmacyId && recipientId && responseId && sentFormId) {
      (async () => {
        try {
          let token = await generateToken(recipientId);
          localStorage.setItem("Token", token.data.token);
          let responses = await handleGetCpResponsesByCpId(cpid);
          let latestResId = Math.max(...responses.data.map((e) => e.id));
          let latestRes = await responses.data.find(
            (e) => e.id === latestResId
          );
          if (latestRes.isLinkDisabled) {
            setValidation({
              isValid: false,
              message: "Link is disabled. Please try a new link.",
            });
          }
          if (latestRes.completedOn != null) {
            setValidation({
              isValid: false,
              message: "Form is completed. Please try a new link.",
            });
          }
          if (latestResId > responseId) {
            // isLoading(false);
            setValidation({
              isValid: false,
              message: "Link Expired. Please try a new link.",
            });
          }
          if (dayjs(latestRes.sentOn) <= dayjs().subtract(30, "day")) {
            // isLoading(false);
            setValidation({
              isValid: false,
              message: "Link is Expired. Please try a new link.",
            });
          }
          let cp = await handleGetCarePlanById(cpid);
          setCpData(cp.data);
          setIsLoading(false);

          const cpResponse = await handleGetResponseById(responseId);
          setCurCpResponse(cpResponse.data);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
          setValidation({
            isValid: false,
            message: "Something went wrong. Please try again later.",
          });
        }
      })();
    } else {
      setIsLoading(false);
      setValidation({
        isValid: false,
        message: "Invalid URL",
      });
    }

    getPharmacyById(pharmacyId)
      .then((res) => {
        setPharmacySMSNum(res.data.smsPhoneNum);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpid, pharmacyId]);

  const verifyOTP = (e) => {
    e.preventDefault();
    setMsg("");

    if (
      localStorage.getItem("otp") ===
      handleEncryption(validationForm.otp.toString())
    ) {
      setStepFlag("form");
    } else {
      setMsg("Invalid OTP");
    }
  };
  if (Object.keys(cpData).length > 0) {
    survey = new Model(JSON.parse(cpData.fuForm.formText));
  }

  const handleResponse = (answers) => {
    setMsg("");
    const response = {
      value: JSON.stringify(answers),
      isCompletedByPatient: true,
      completedOn: new Date(),
    };
    handleUpdateResponse(responseId, response)
      .then(() => {
        handleUpdateEvent(curCpResponse.fuSubEventId, {
          updatedBy: systemUserId,
          Status: true,
        });
        setCompleted(true);
      })
      .catch((err) => {
        console.error(err);
        setMsg("Something went wrong. Please try again later.");
      });
  };

  survey.onComplete.add((sender, options) => {
    if (sender.data["followup-schedule"]) {
      setFueditor(true);
      setResValue(sender.data);
    } else {
      handleResponse(sender.data);
    }
  });

  const handleBkBtn = () => {
    // survey.clear();
    survey.data = resValue;
    setFueditor(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recString !== "") {
      handleUpdateEvent(cpData.fuEventId, {
        updatedBy: recipientId,
        pharmacyId,
        creatorId: cpData.creatorID,
        OwnerId: systemUserId,
        Subject: `Follow up for ${cpData?.recipient?.first} ${cpData?.recipient?.last} Started ${inputs.startDate}`,
        StartTime: inputs.startDate,
        EndTime: inputs.startDate,
        IsAllDay: false,
        Location: inputs.location,
        Priority: "Normal",
        RecurrenceRule: recString,
        patientName: `${cpData?.recipient?.first} ${cpData?.recipient?.last}`,
        isInitEvent: false,
        isAutomated: true,
      })
        .then((r) => {
          handleResponse(resValue);
          // getAllData(fuEvent.Id);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setMsg("Please select follow up schedule.");
    }
  };

  return (
    <div className="container">
      {isLoading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="row justify-content-center p-5">
          {validation.isValid ? (
            <>
              <>
                {stepFlag === "phone" && (
                  <div className="col-6  mt-5">
                    <div className="card">
                      <div className="card-header">
                        Please Verify Your Phone Number
                      </div>
                      <div className="card-body ">
                        <p>{msg}</p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your phone number"
                          name="phone"
                          value={validationForm.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="card-footer text-end">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmitPhone}>
                          Send Code
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
              <>
                {stepFlag === "otp" && (
                  <div className="col-6  mt-5">
                    <div className="card">
                      <div className="card-header">
                        Please Verify Your One Time Password
                      </div>
                      <div className="card-body ">
                        <p>{msg}</p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your OTP"
                          name="otp"
                          value={validationForm.otp}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="card-footer text-end">
                        <button className="btn btn-primary" onClick={verifyOTP}>
                          Verify OTP
                        </button>
                        <OTPTimer sendOTP={handleSendOTP} />
                      </div>
                    </div>
                  </div>
                )}
              </>
              <>
                {stepFlag === "form" && (
                  <>
                    {completed ? (
                      <Alert severity="success">
                        Thank you for completing the form.
                      </Alert>
                    ) : (
                      <>
                        {fueditor ? (
                          <div className="row">
                            {msg && <Alert severity="error">{msg}</Alert>}
                            <div className="col-12">
                              <IconButton
                                aria-label="settings"
                                onClick={() => handleBkBtn()}>
                                <KeyboardBackspaceIcon />
                              </IconButton>
                            </div>
                            <div className="col-12 mb-3">
                              <label className="form-label">Start Date</label>
                              <input
                                type="date"
                                value={inputs.startDate}
                                onChange={handleFusChange}
                                name="startDate"
                                className="form-control mb-3"
                              />
                              <label className="form-label">
                                Follow Up Preference
                              </label>
                              <select
                                className="form-select mb-3"
                                aria-label="Default select example"
                                name="location"
                                onChange={handleFusChange}
                                defaultValue={inputs.location}
                                value={inputs.location}>
                                <option value="phone">
                                  Phone call follow up
                                </option>
                                <option value="form">Form follow up</option>
                              </select>
                              <RecurrenceEditorComponent
                                id="RecurrenceEditor"
                                ref={recObject}
                                value={recString}
                                change={onChange}
                                frequencies={[
                                  "daily",
                                  "weekly",
                                  "monthly",
                                  "yearly",
                                ]}
                              />
                              <Button
                                variant="contained"
                                color="success"
                                sx={{ float: "right" }}
                                onClick={handleSubmit}>
                                Update
                              </Button>
                            </div>
                            <div className="col-12 mb-3 text-center">
                              <Divider>OR</Divider>
                            </div>
                            <div className="col-12">
                              <Button
                                variant="contained"
                                sx={{
                                  float: "right",
                                  backgroundColor: "darkred",
                                }}
                                onClick={() =>
                                  handleUpdateCarePlan(cpid, {
                                    fuOptedIn: false,
                                  })
                                }>
                                Opt Out Follow Up
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Survey model={survey} />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </>
          ) : (
            <div className="col-12">
              <p className="xtext">{validation.message}</p>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
                className="x">
                <circle
                  className="path circle"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeMiterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <line
                  className="path line"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  x1="34.4"
                  y1="37.9"
                  x2="95.8"
                  y2="92.3"
                />
                <line
                  className="path line"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  x1="95.8"
                  y1="38"
                  x2="34.4"
                  y2="92.2"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Applesscp;
