import React from "react";
import { useNavigate } from "react-router-dom";
import patient_management_image from "../../../assets/images/Index_PatientManagementImage.jpg";

const Doctor = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div id="manage-now-div">
        <div className="row" id="manage-now-div-row">
          <div className="col-md-6 col-lg-6" id="manage-now-div-image-column">
            <img
              className="img-fluid"
              id="manage-now-image"
              src={patient_management_image}
              alt="Patient Management by CuroRX"></img>
          </div>
          <div className="col-md-6 col-lg-6" id="manage-now-div-right-column">
            <p className="text-uppercase" id="manage-now-heading">
              Centralized, easy to use patient management
            </p>
            <button
              className="btn btn-success text-uppercase"
              id="manage-now-button"
              type="button"
              onClick={() => navigate("/register")}>
              Manage Now
            </button>
          </div>
        </div>
      </div>
      <div id="make-new-referral-div">
        <div className="row g-0" id="make-new-referral-row">
          <div
            className="col-sm-6 col-md-6 col-lg-6"
            id="make-new-referral-column-left">
            <p className="text-uppercase" id="make-new-referral-heading">
              Now it's easier than ever to refer a new patient
            </p>
            <button
              className="btn btn-success text-uppercase"
              id="make-new-referral-button"
              type="button"
              onClick={() => navigate("/register")}>
              Make a new referral now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doctor;
