import React, { useState } from "react";
import { SideBar } from "../../utils/globalState";
import BirthdayMessageUpdater from "./../../components/pharmacyUser/birthdayLog/BirthdayMessageUpdater";
import BirthdayMessageLogs from "./../../components/pharmacyUser/birthdayLog/BirthdayMessageLogs";

const BirthdayLog = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Birthday Log</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Messages Center</li>
              <li className="breadcrumb-item active">Birthday Log</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="card">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="row">
                    <BirthdayMessageUpdater setIsLoading={setIsLoading} />
                    <hr
                      style={{ width: "70%", marginLeft: "15%" }}
                      className="mt-2"
                    />
                    <BirthdayMessageLogs
                      isDataLoading={isDataLoading}
                      setIsDataLoading={setIsDataLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BirthdayLog;
