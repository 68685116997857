import React, { useState, useEffect } from "react";
import { User } from "../../utils/globalState";
import { SideBar } from "../../utils/globalState";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Model } from "survey-core";
import { SurveyPDF } from "survey-pdf";
import { SurveyHelper, FlatQuestion } from "survey-pdf";
import "survey-core/modern.min.css";
import ReviewResponse from "../../components/pharmacyUser/formCenter/ReviewResponse";
import {
  // convertTime,
  convertTimeLocally,
} from "../../utils/timeConverter";
import "../../styles/page-styles/forms.css";
import {
  archiveResponse,
  reviewResponse,
  cancelReminderForm,
  disableLinkForm,
} from "../../services/PharmacyUser";
import { getResponsesBySenderID } from "../../services/Provider";
import { decodeToken } from "react-jwt";

const FormsHistory = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userInformation = User.getState().info;
  const firstName = localStorage.getItem("first");
  const lastName = localStorage.getItem("last");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const userName =
    userInformation.first && userInformation.last
      ? `${userInformation.first} ${userInformation.last}`
      : `${firstName} ${lastName}`;

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviwpageflag, setReviewPageFlag] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});

  const columnsComplete = [
    { field: "response_ID", headerName: "ID", minWidth: 50, maxWidth: 50 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 150 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 90, maxWidth: 90 },
    { field: "form_ID", headerName: "Form ID", minWidth: 80, maxWidth: 80 },
    { field: "formName", headerName: "Form Name", minWidth: 150 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 60,
      valueFormatter: (params) => (params.value ? "Yes" : "No"),
      cellClassName: (params) => (params.value ? "reviewed" : "not-reviewed"),
    },
    {
      field: "reviewedOn",
      headerName: "Review Date",
      minWidth: 165,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 280,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => {
              handleReview(params);
            }}
          >
            Review
          </button>
          <button
            disabled={!params.row.isReviewed}
            onClick={() => {
              let rowID = params.id;
              archiveResponse(rowID, userName)
                .then((res) => {
                  setData(
                    data.map((item) =>
                      item.response_ID !== rowID
                        ? item
                        : {
                            ...item,
                            isArchived: true,
                            archivedBy: userName,
                            archived_date: convertTimeLocally(new Date()),
                          }
                    )
                  );
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Archive
          </button>
          <button onClick={() => handleDownload(params)}>
            Download as PDF
          </button>
        </div>
      ),
    },
  ];

  const columnsPending = [
    { field: "response_ID", headerName: "ID", minWidth: 50, maxWidth: 50 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 150 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 90, maxWidth: 90 },
    { field: "form_ID", headerName: "Form ID", minWidth: 80, maxWidth: 80 },
    { field: "formName", headerName: "Form Name", minWidth: 150 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "numOfReminders",
      headerName: "Reminders Sent",
      minWidth: 140,
    },
    {
      field: "dateOfLastReminder",
      headerName: "Last Reminder Date",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },

    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 60,
      valueFormatter: (params) => (params.value ? "Yes" : "No"),
      cellClassName: (params) => (params.value ? "reviewed" : "not-reviewed"),
    },
    {
      field: "reviewedOn",
      headerName: "Review Date",
      minWidth: 165,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 450,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            onClick={() => {
              handleReview(params);
            }}
          >
            Review
          </button>
          <button
            onClick={() => {
              handleCancelReminders(params);
            }}
            disabled={params.row.isStop}
          >
            {params.row.isStop ? "Reminders Paused" : "Pause Reminders"}
          </button>
          <button
            onClick={() => {
              handleCancelLink(params);
            }}
            disabled={params.row.isLinkDisabled}
          >
            {params.row.isLinkDisabled ? "Link Disabled" : "Disable Link"}
          </button>
        </div>
      ),
    },
  ];

  const columnsArchived = [
    { field: "response_ID", headerName: "ID", minWidth: 50, maxWidth: 50 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 140 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 80, maxWidth: 80 },
    { field: "form_ID", headerName: "Form ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Form Name", minWidth: 150 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "archived_date", headerName: "Archived On", minWidth: 170 },
    { field: "status", headerName: "Status", minWidth: 90 },
    {
      field: "reviewedOn",
      headerName: "Review Date",
      minWidth: 165,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => {
              handleReview(params);
            }}
          >
            View
          </button>
          <button onClick={() => handleDownload(params)}>
            Download as PDF
          </button>
        </div>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedRowId([]);
  };

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  useEffect(() => {
    setIsLoading(true);

    getResponsesBySenderID(userID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",
              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",

              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTimeLocally(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn
                ? convertTimeLocally(item.reviewedOn)
                : "",
              completion: item.completion
                ? convertTimeLocally(item.completion)
                : "",
              sent: item.sent ? convertTimeLocally(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTimeLocally(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    if (data.length) {
      if (value === 0) {
        const filtered = data.filter(
          (item) =>
            item.status.toLowerCase() === "completed" &&
            item.isArchived === false
        );
        setSelectedData(filtered);
      } else if (value === 1) {
        const filtered = data.filter(
          (item) =>
            item.status.toLowerCase() === "pending" && item.isArchived === false
        );
        setSelectedData(filtered);
      } else if (value === 2) {
        const filtered = data.filter((item) => item.isArchived === true);
        setSelectedData(filtered);
      }
    }
  }, [data, value]);

  const handleReview = (row) => {
    reviewResponse(row.id, {
      reviewedBy: userName,
    }).then((res) => {
      setData(
        data.map((item) => {
          if (item.response_ID === row.id) {
            if (item.isReviewed === true) {
              setCurrentResponse(item);
              setReviewPageFlag(true);
            } else {
              item = {
                ...item,
                isReviewed: true,
                reviewedBy: userName,
                reviewedOn: convertTimeLocally(new Date()),
              };
              setCurrentResponse(item);
              setReviewPageFlag(true);
            }
          }
          return item;
        })
      );
    });
  };

  const handleDownload = (row) => {
    let current = data.find((item) => item.response_ID === row.id);
    current && setCurrentResponse(current);
    if (current) {
      let json = handleObjectRewrite(current);
      json.title = `${json.title} form for - Name: ${
        current.recipientName
      } - MRN: ${current.MRN ? current.MRN : "N/A"} - Phone: ${
        current.recipientInfo.phone
      } - Email: ${current.recipientInfo.email}`;

      function createSurveyPdfModel(surveyModel) {
        let pdfWidth =
          !!surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
        let pdfHeight =
          !!surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
        let options = {
          haveCommercialLicense: true,
          fontSize: 10,
          margins: {
            left: 10,
            right: 10,
            top: 10,
            bot: 10,
          },
          textFieldRenderAs: "multiLine",
          htmlText: true,
          format: [pdfWidth, pdfHeight],
          pageBreak: "auto",
          compress: true,
          header: {
            fontSize: 10,
            textColor: "#00008b",
            fontStyle: "bold",
            padding: 10,
            margin: 10,
            renderAs: "text",
            data: ` Name: ${current.recipientName} - MRN: ${
              current.MRN ? current.MRN : "N/A"
            } - Phone: ${current.recipientInfo.phone} - Email: ${
              current.recipientInfo.email
            }`,
          },
          footer: {
            fontSize: 10,
            textColor: "#00008b",
            fontStyle: "bold",
            padding: 10,
            margin: 10,
          },
          headerText: function (currentPage, pageCount) {
            return currentPage.toString() + " / " + pageCount;
          },
          footerText: function (currentPage, pageCount) {
            return currentPage.toString() + " / " + pageCount;
          },
          headerRenderAs: "text",
          footerRenderAs: "text",
          headerHeight: 10,
          footerHeight: 10,
          headerStyle: "normal",
          footerStyle: "normal",
          headerBorder: {
            top: true,
            bottom: true,
            left: true,
            right: true,
          },
        };
        const surveyPDF = new SurveyPDF(json, options);
        if (surveyModel) {
          surveyPDF.data = surveyModel.data;
        }
        surveyPDF.mode = "display";

        surveyPDF.onRenderHeader.add(function (survey, canvas) {
          const unfoldedPacks = canvas.packs[0].unfold();

          // Colorize the page title
          unfoldedPacks.forEach((el) => {
            el.textColor = "#00008b";
            el.fontSize = 12;
          });
          // Colorize the row line under the title
          unfoldedPacks[unfoldedPacks.length - 1].color = "blue";
          // Specify the thickness of the row line under the title
          unfoldedPacks[unfoldedPacks.length - 1].yTop +=
            canvas.controller.unitHeight * 0.8;
          // Render a company logo
        });

        surveyPDF.onRenderQuestion.add(function (survey, options) {
          // Colorize a question's title
          options.bricks[0].unfold()[0].textColor = "blue";
          options.bricks[0].unfold()[0].fontSize = 10;

          // Colorize the row line under a question and specify the line's thickness
          const lastRowBricks =
            options.bricks[options.bricks.length - 1].unfold();
          lastRowBricks[lastRowBricks.length - 1].color = "blue";
          lastRowBricks[lastRowBricks.length - 1].isPageBreak = true;

          lastRowBricks[lastRowBricks.length - 1].yTop +=
            options.controller.unitHeight * 1.3;
          return new Promise(function (resolve) {
            resolve();
          });
        });

        return surveyPDF;
      }
      function saveSurveyToPdf(filename, surveyModel) {
        createSurveyPdfModel(surveyModel).save(filename);
      }

      SurveyHelper.FORM_BORDER_VISIBLE = false;
      SurveyHelper.VALUE_READONLY_PADDING_SCALE = 0;
      SurveyHelper.MULTIPLETEXT_TEXT_PERS = 0.55;
      SurveyHelper.TITLE_PAGE_FONT_SIZE_SCALE = 1.4;
      SurveyHelper.TITLE_FONT_SCALE = 1.4;
      FlatQuestion.CONTENT_INDENT_SCALE = 0;
      FlatQuestion.CONTENT_GAP_VERT_SCALE = 0.1;
      SurveyHelper.TEXT_COLOR = "black";

      // Add custom fonts to a document

      // DocController.addFont('Roboto', base64TitleFont, 'bold');
      // DocController.addFont('Roboto', base64TextFont, 'normal');

      const survey = new Model(json);
      survey.mode = "display";

      saveSurveyToPdf("surveyResult.pdf", survey);
    }
  };

  function handleCancelReminders(row) {
    let rowID = row.id;
    //// api call to cancel reminders
    cancelReminderForm(rowID)
      .then(() => {
        setData(
          data.map((item) => {
            if (item.response_ID === rowID) {
              return { ...item, isStop: true };
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleCancelLink(row) {
    let rowID = row.id;
    disableLinkForm(rowID)
      .then(() => {
        setData(
          data.map((item) => {
            if (item.response_ID === rowID) {
              return { ...item, isLinkDisabled: true };
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Forms History</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Forms Center</li>
              <li
                onClick={() => {
                  setReviewPageFlag(false);
                  setCurrentResponse({});
                }}
                className={`breadcrumb-item ${
                  reviwpageflag ? "cstm_breadcrumb" : "active"
                }`}
              >
                Forms History
              </li>
              {reviwpageflag && (
                <li className="breadcrumb-item active">
                  View Response (id: {currentResponse.response_ID}){" "}
                </li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {reviwpageflag ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setReviewPageFlag(false);
                      setCurrentResponse({});
                    }}
                  >
                    Back
                  </Button>

                  <ReviewResponse data={currentResponse} />
                </>
              ) : (
                <>
                  {!isLoading ? (
                    <div>
                      <Box sx={{ width: "100%" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="tabs"
                          className="mb-1 ms-2"
                        >
                          <Tab label="Complete" />
                          <Tab label="Pending" />
                          <Tab label="Archived" />
                        </Tabs>
                        {value === 0 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => row.response_ID}
                              columns={columnsComplete}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.response_ID === selectedRowId
                                  ? "selected-row"
                                  : ""
                              }
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    { field: "completion", sort: "desc" },
                                  ],
                                },
                              }}
                            />
                          </div>
                        )}
                        {value === 1 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => row.response_ID}
                              columns={columnsPending}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.response_ID === selectedRowId
                                  ? "selected-row"
                                  : ""
                              }
                            />
                          </div>
                        )}
                        {value === 2 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => row.response_ID}
                              columns={columnsArchived}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.status === "completed"
                                  ? `completed-row ${
                                      params.row.response_ID === selectedRowId
                                        ? "selected-row"
                                        : ""
                                    }`
                                  : `pending-row ${
                                      params.row.response_ID === selectedRowId
                                        ? "selected-row"
                                        : ""
                                    }`
                              }
                            />
                          </div>
                        )}
                      </Box>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <h3 className="text-center mt-5">
                        Loading Data, Please wait...
                      </h3>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormsHistory;
