import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "96vh",
        fontSize: "4rem",
      }}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
};

export default NotFound;
