import React, { useEffect, useState } from "react";
import { SideBar } from "../../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserPrivilege, getUserRole } from "../../../services/Common";
import { decodeToken } from "react-jwt";
import "../../../styles/commonStyles/nav.css";

const Navigator = () => {
  const [privileges, setPrivileges] = useState([]);
  const [isNavContentShown, setNavContentShown] = useState(false);
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};

  const handleClick = (index) => {
    setNavContentShown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleResponse = await getUserRole(myDecodedToken.userId);
        const roleId = roleResponse.data.data[0].id;
        const privilegeResponse = await getUserPrivilege(roleId);
        setPrivileges(
          privilegeResponse.data?.data?.map((privilege) =>
            privilege.name.toLowerCase()
          ) || []
        );
      } catch (error) {
        console.error("Error fetching user role or privilege:", error);
      }
    };

    fetchData();
  }, [myDecodedToken.userId]);

  return (
    <div className={isDivVisible ? "" : "toggle-Navigation"} id="side-bar-nav">
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              {privileges.includes("dashboard") && (
                <li
                  className={`nav-content 
                  `}>
                  <NavLink
                    to="/pharmacy-user/dashboard"
                    className={`nav-link ${
                      isNavContentShown["Dashboard"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav">
                    <i
                      className="bi bi-speedometer2"
                      style={{ fontSize: "1.4rem" }}
                    />
                    Dashboard
                  </NavLink>
                </li>
              )}
              {["add provider", "manage providers"].some((priv) =>
                privileges.includes(priv)
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Provider"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Provider")}>
                    <span>Provider Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Provider"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Provider"] ? "show" : ""
                    }`}>
                    {privileges.includes("add provider") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/add-provider`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-person-plus-fill"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Provider</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("manage providers") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/manage-providers`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people-fill"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Provider</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {["add patient", "manage patients", "add bulk patients"].some(
                (priv) => privileges.includes(priv)
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Patient"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Patient")}>
                    <span>Patient Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Patient"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Patient"] ? "show" : ""
                    }`}>
                    {privileges.includes("add patient") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/add-patient`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-person-plus"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Patient</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("manage patients") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/manage-patients`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Patients</span>
                        </NavLink>
                      </li>
                    )}
                    {privileges.includes("add bulk patients") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/add-bulk-patients`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Bulk Patients</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {["messages", "bulk messages", "birthday messages"].some((priv) =>
                privileges.includes(priv)
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Messages"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Messages")}>
                    <span>Messages Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Messages"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Messages"] ? "show" : ""
                    }`}>
                    {privileges.includes("messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-dots"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Messages</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("bulk messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/bulk-messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-square-quote"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Bulk Messages</span>
                        </NavLink>
                      </li>
                    )}
                    {privileges.includes("birthday messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/birthday-messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-square-heart"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Birthday Messages</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {["manage forms", "forms history", "forms analytics"].some(
                (priv) => privileges.includes(priv)
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Forms"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Forms")}>
                    <span>Forms Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Forms"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Forms"] ? "show" : ""
                    }`}>
                    {privileges.includes("manage forms") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/manage-forms`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-ui-checks"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Forms</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("forms history") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/forms-history`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-clock-history"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Forms History</span>
                        </NavLink>
                      </li>
                    )}
                    {privileges.includes("forms analytics") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/forms-analytics`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-graph-up"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Forms Analytics</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {[
                "manage documents",
                "documents history",
                "documents analytics",
              ].some((priv) => privileges.includes(priv)) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Documents"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Documents")}>
                    <span>Documents Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Documents"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Documents"] ? "show" : ""
                    }`}>
                    {privileges.includes("manage documents") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/manage-documents`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-files"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Documents</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("documents history") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/documents-history`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-file-earmark-medical"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Documents History</span>
                        </NavLink>
                      </li>
                    )}
                    {/* documents-analytics */}
                    {privileges.includes("documents analytics") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/documents-analytics`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-graph-up"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Documents Analytics</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {["manage surveys", "survey dissatisfaction"].some((priv) =>
                privileges.includes(priv)
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Survey"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Survey")}>
                    <span>Survey Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Survey"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Survey"] ? "show" : ""
                    }`}>
                    {privileges.includes("manage surveys") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/manage-surveys`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-kanban"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Surveys</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("survey dissatisfaction") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/survey-dissatisfaction`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-emoji-frown"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Survey Dissatisfaction</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {privileges.includes("careplan center") && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["CarePlan"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("CarePlan")}>
                    <span>Care Plan</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["CarePlan"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["CarePlan"] ? "show" : ""
                    }`}>
                    <li>
                      <NavLink
                        to={`/pharmacy-user/cp-center/scheduler`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-calendar3"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Calendar</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/pharmacy-user/cp-center/care-plan-manager`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className="bi bi-heart-pulse"
                          style={{ fontSize: "1.5rem" }}></i>
                        {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                        <span>Care Plan Manager</span>
                      </NavLink>
                    </li>
                    {/* cp-history */}
                    <li>
                      <NavLink
                        to={`/pharmacy-user/cp-center/cp-history`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className="bi bi-file-earmark-medical"
                          style={{ fontSize: "1.5rem" }}></i>
                        {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                        <span>Care Plan History</span>
                      </NavLink>
                    </li>
                    {/* forms-lib */}
                    {privileges.includes("careplan forms") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-user/cp-center/forms-lib`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className="bi bi-collection-fill"
                            style={{ fontSize: "1.5rem" }}></i>

                          <span>Forms Library</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
