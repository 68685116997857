import React, { useEffect, useState } from "react";
import LandingPage from "./pages/common/Landing";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import PharmacyUserRoutes from "./portals/PharmacyUser";
import PharmacyAdminRoutes from "./portals/PharmacyAdmin";
import Provider from "./portals/Provider";
import AdminRoutes from "./portals/Admin";
import PatientRoutes from "./portals/Patient";
import { Routes, Route } from "react-router-dom";
import "./styles/commonStyles/cssReset.css";
import "./styles/commonStyles/bootstrap.min.css";
import "./styles/commonStyles/curoMainStyles.css";
import "./styles/commonStyles/calendar.css";
import io from "socket.io-client";
import { SocketIoServer } from "./utils/globalState";
import Register from "./pages/common/Register";
import PassReset from "./pages/common/PassReset";
import ApplessSurvey from "./pages/pharmacyUser/serveyCenter/ApplessSurvey";
import Applesssms from "./pages/pharmacyUser/Applesssms";
import Applessform from "./pages/pharmacyUser/Applessform";
import ApplessDocs from "./pages/pharmacyUser/ApplessDocs";
import baseURL from "./utils/baseURL";
import ProviderApplessSurvey from "./pages/provider/SurveyCenter/ProviderApplessSurvey";
import { NewMessages } from "./pages/common/NewMessages";
import ApplessFormPr from "./pages/provider/ApplessFormPr";
import ApplessDocsPr from "./pages/provider/ApplessDocsPr";
import Applesscp from "./pages/pharmacyUser/CarePlan/Applesscp";

function App() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(io(baseURL.curoback));
  }, []);

  useEffect(() => {
    if (socket !== null) {
      SocketIoServer.setState({ SocketIoObj: socket });
      socket.on("connect", () => {
        console.log("connected");
      });
    }
  }, [socket]);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/resetpassword" element={<PassReset />} />

        {/* ========================== POTECTED ROUTES ========================= */}
        <Route path="/" element={<ProtectedRoutes allowedRoles={["Admin"]} />}>
          <Route path="admin/*" element={<AdminRoutes />} />
        </Route>
        <Route
          path="/"
          element={<ProtectedRoutes allowedRoles={["pharmacyAdmin"]} />}>
          <Route path="pharmacy-admin/*" element={<PharmacyAdminRoutes />} />
        </Route>
        <Route
          path="/"
          element={
            <ProtectedRoutes allowedRoles={["User", "pharmacyAdmin"]} />
          }>
          <Route path="pharmacy-user/*" element={<PharmacyUserRoutes />} />
        </Route>
        <Route
          path="/"
          element={<ProtectedRoutes allowedRoles={["Provider"]} />}>
          <Route path="provider/*" element={<Provider />} />
        </Route>
        <Route
          path="/"
          element={<ProtectedRoutes allowedRoles={["Patient"]} />}>
          <Route path="patient/*" element={<PatientRoutes />} />
        </Route>
        {/* ========================== END POTECTED ROUTES ========================= */}

        <Route path="*" element={<LandingPage />} />
        <Route exact path="/appless-survey" element={<ApplessSurvey />} />
        <Route exact path="/appless-sms" element={<Applesssms />} />
        <Route exact path="/appless-form" element={<Applessform />} />
        <Route exact path="/appless-docs" element={<ApplessDocs />} />
        <Route exact path="/applesscp" element={<Applesscp />} />

        {/* Provider Appless routes */}
        <Route exact path="/appless-form-p" element={<ApplessFormPr />} />
        <Route exact path="/appless-docs-p" element={<ApplessDocsPr />} />

        <Route
          exact
          path="/provider-appless-survey"
          element={<ProviderApplessSurvey />}
        />
        <Route path="/newmessages" element={<NewMessages />} />
      </Routes>
    </div>
  );
}

export default App;
