import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/patient/HomePage";
import Profile from "./../pages/patient/Profile";
import Messages from "./../pages/patient/Messages";
import Survey from "./../pages/patient/Survey";
import Footer from "./../components/common/layout/Footer";
import "../styles/page-styles/patient_portal.css";
import "../styles/page-styles/patientPortalNew.css";
import ProfilePictureUploader from "./../components/common/profile/ProfilePictureUploader";
import NotFound from "./../components/common/NotFound";
import FormsCenter from "./../pages/patient/FormsCenter";
import DocsCenter from "./../pages/patient/DocsCenter";
import Header from "./../components/common/layout/Header";
import SessionTimeOut from "./../components/common/SessionTimeOut/SessionTimeOut";
import Navigator from "../components/patient/PatientNav";
import ContactUs from "../components/common/ContactUs";
import FormFillPage from "./../pages/patient/FormFillPage";
import DocSignPage from "./../pages/patient/DocSignPage";
import PtSurveyFillPage from "./../components/patient/PtSurveyFillPage";

const PatientRoutes = () => {
  return (
    <div>
      <SessionTimeOut />
      <Header />
      <Navigator />
      <Routes>
        <Route path="homepage" element={<HomePage />} />
        <Route path="profile" element={<Profile />} />
        <Route path="messages" element={<Messages />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="profile-picture" element={<ProfilePictureUploader />} />
        <Route path="forms-center" element={<FormsCenter />} />
        <Route path="forms-center/:responseId" element={<FormFillPage />} />
        <Route path="documents-center" element={<DocsCenter />} />
        <Route
          path="documents-center/:docResponseId"
          element={<DocSignPage />}
        />
        <Route path="survey" element={<Survey />} />
        <Route path="survey/:surveyTokenId" element={<PtSurveyFillPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default PatientRoutes;
