import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import {
  handleGetIntegrations,
  handleAddIntegration,
  handleUpdateIntegration,
  handleDeleteIntegration,
} from "../../services/Admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";

function ManageIntgrations() {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const [integrations, setIntegrations] = useState([]);
  const getIntegrations = () => {
    handleGetIntegrations()
      .then((res) => {
        setIntegrations(res.data.integrations);
      })
      .catch((err) => {
        console.error(err);
        setIntegrations([]);
      });
  };

  useEffect(() => {
    getIntegrations();
  }, []);
  // =================================== MATERIAL UI=================================
  const [rowModesModel, setRowModesModel] = useState({});
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: false,
    },
    {
      field: "company_name",
      headerName: "Integration Company Name",
      width: 250,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    getIntegrations();
  };

  const handleDeleteClick = (id) => () => {
    setIntegrations(integrations.filter((row) => row.id !== id));
    handleDeleteIntegration(id)
      .then(() => {})
      .catch((err) => console.error(err));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = integrations.find((row) => row.id === id);
    if (editedRow.isNew) {
      setIntegrations(integrations.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setIntegrations(
      integrations.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    if (updatedRow.id === "New") {
      handleAddIntegration({ company_name: updatedRow.company_name })
        .then((res) => {
          getIntegrations();
        })
        .catch((err) => console.error(err));
    } else {
      handleUpdateIntegration(updatedRow.id, {
        company_name: updatedRow.company_name,
      })
        .then(() => {
          getIntegrations();
        })
        .catch((err) => console.error(err));
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  function EditToolbar(props) {
    const { setIntegrations, setRowModesModel } = props;

    const handleClick = () => {
      let id = "New";
      setIntegrations((oldRows) => [
        ...oldRows,
        { id, company_name: "", isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "company_name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add New Integration Company
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Add New Integrated Application</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                Manage Integrated Applications
              </li>
              <li className="breadcrumb-item active">
                Add New Integrated Application
              </li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}>
              <DataGrid
                rows={integrations}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                components={{
                  Toolbar: GridToolbar,
                }}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setIntegrations, setRowModesModel },
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ManageIntgrations;
