import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";

const MultipleSclerosisTecfidera = (props) => {
  // No Changes for the state
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);

  useEffect(() => {
      setRxController(stateObj);
    }, [stateObj]);

  const [QA, setQA] = useState({});
  const [rx, setRx] = useState({
    clinicalinfo: [],
  });


  // No Changes  handleChange

  const handleChange = (event) => {
    setQA({ ...QA, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (QA) {
      var arr = Object.keys(QA).map(function (key) {
        return { [key]: QA[key] };
      });

      const ms = arr.map((x) => {
        for (const [key, value] of Object.entries(x)) {
          return { question: key, answer: value };
        }
      });
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: ms },
          completeflag: { ...rxController.completeflag, Clinical: true },
        },
      });
    } else if (!QA) {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: [] },
          completeflag: { ...rxController.completeflag, Clinical: false },
        },
      });
    }

  
  };

  return (
 
          <div className="container container_hemat">
            <form onSubmit={handleSubmit} className="mb-3 col-12 col-lg-8">
              {/* 3. CLINICAL INFORMATION */}
              <h3 className="hemo_h3 p-3 fs-4 fs-sm-3">
                {" "}
                MULTIPLE SCLEROSIS TECFIDERA / CLINICAL INFORMATION{" "}
              </h3>
              <div className="form-control mb-2">
                <label className="form-label">
                  <b> Past Medical History </b>
                </label>{" "}
                <br />
                <label className="form-label"> Liver problems:</label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Liver problems:"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Liver problems:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <label className="form-label"> Specify: </label>&nbsp;
                <input
                  type="text"
                  className="form-control-sm col-12 col-sm-auto"
                  name="Specify:"
                  size="40"
                  onChange={handleChange}
                />
                <br />
                <label className="form-label"> Herps zoster:</label> &nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Herps zoster:"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Herps zoster:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> Flushing: </label>&nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Flushing"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Flushing:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> PML:</label> &nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="PML:"
                    className="form-check-input"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="PML:"
                    className="form-check-input"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> Pregnancy:</label> &nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Pregnancy:"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="Pregnancy:"
                    className="form-check-input"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> Breast Feeding:</label> &nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="Breast Feeding:"
                    className="form-check-input"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Breat Feeding:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> Other: </label>
                <input
                  type="text"
                  className="form-control"
                  name="Other:"
                  size="60"></input>
              </div>
              <div className="form-control mb-2">
                <label className="form-label">
                  <b>Indication(s)</b> Relapsing forms of Multiple Sclerosis
                  (MS), including:
                </label>{" "}
                <br />
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="Indication1:"
                    onChange={handleChange}
                  />
                  <label className="form-check-label">
                    {" "}
                    Clinically isolated syndrome{" "}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="Indication2:"
                    defaultValue="Relapsing-remitting disease"
                    onChange={handleChange}
                  />
                  <label className="form-check-label">
                    {" "}
                    Relapsing-remitting disease{" "}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="Indication3:"
                    defaultValue="Active secondary progressive disease"
                    onChange={handleChange}
                  />
                  <label className="form-check-label">
                    {" "}
                    Active secondary progressive disease{" "}
                  </label>
                </div>
              </div>
              <div className="form-control mb-2">
                <label className="form-label">
                  <b> Current or most recent therapy </b>
                </label>{" "}
                <br />
                <label> Prior Disease Modifying Therapy: </label> &nbsp;
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Prior Disease Modifying Therapy:"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Prior Disease Modifying Therapy:"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label className="form-check-label"> No </label>
                </div>
                <br />
                <label className="form-label"> Drug name: </label>&nbsp;
                <input
                  type="text"
                  name="Drug name:"
                  className="form-control-sm"
                  size="10"
                  onChange={handleChange}
                />
                &nbsp;
                <label className="form-label"> Date: </label> &nbsp;
                <input
                  type="date"
                  name="Date:"
                  className="form-control-sm"
                  onChange={handleChange}
                />{" "}
                <br />
                <label className="form-label"> Therapy Duration: </label>&nbsp;
                <input
                  type="text"
                  name="Therapy Duration:"
                  className="form-control-sm"
                  size="20"
                  onChange={handleChange}
                />
              </div>
              <div className="d-grid gap-2 col-md-12 mx-auto">
                <button
                  className="btn btn-outline-primary btn_ast"
                  type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>

  );
};
export default MultipleSclerosisTecfidera;
