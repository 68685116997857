import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar, SelectedSurvey } from "../../../utils/globalState";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { addSurvey, updateSurvey } from "../../../services/PharmacyUser";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { decodeToken } from "react-jwt";
import { useModal } from "react-hooks-use-modal";

const EditAndReview = () => {
  const navigate = useNavigate();

  // Get the survey from global state.
  const incomingSurvey = SelectedSurvey.getState().ThisSurvey;

  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
  const pharmacy = localStorage.getItem("pharmacy");

  const [msg, setMsg] = useState("");
  const [survey, setSurvey] = useState(incomingSurvey);
  const [isNew, setIsNew] = useState(true);

  // SurveyJS Creator options
  const creatorOptions = {
    haveCommercialLicense: true,
    showLogicTab: true,
    showOptions: true,
    showSaveSurveyButton: true,
  };
  // Set the survey to the creator.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const creator = new SurveyCreator(creatorOptions);
  creator.isAutoSave = false;

  creator.saveSurveyFunc = (saveNo, callback) => {
    setMsg("");
    const { title, pages } = creator.JSON;

    if (isNew) {
      if (!title || !pages?.length || !pages[0]?.elements?.length) {
        setSurvey({ ...survey, json: creator.JSON });
        openWarningModal();
      } else {
        addSurvey({
          survey: creator.JSON,
          createdBy: userId,
          pharmacyID: pharmacy,
          source: "pharmacy",
        })
          .then((res) => {
            setSurvey({ ...survey, json: creator.JSON });
            setMsg("Survey has been created.");
          })
          .catch((err) => {
            setMsg("Something went wrong. Please try again later.");
          });
      }
    } else {
      if (!title || !pages?.length || !pages[0]?.elements?.length) {
        setSurvey({ ...survey, json: creator.JSON });
        openWarningModal();
      } else {
        updateSurvey(survey.id.toString(), {
          survey: creator.text,
          createdBy: userId,
          pharmacyID: pharmacy,
          source: "pharmacy",
        })
          .then((res) => {
            setSurvey({ ...survey, json: creator.JSON });
            setMsg("Survey has been updated.");
          })
          .catch((err) => {
            console.error(err);
            setMsg("Something went wrong. Please try again later.");
          });
      }
    }
  };

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      setIsNew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    creator.text = JSON.stringify(survey.json);
  }, [creator, survey]);

  //   ====================== Modal ======================

  const [WarningModal, openWarningModal, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  //   ====================== Modal Ends ======================

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}>
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Edit & Review</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            {msg !== "" && msg}
            <SurveyCreatorComponent creator={creator} />
          </div>
        </section>
        <WarningModal>
          <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
            <div className="card-header bg-transparent fw-bold text-center text-danger">
              Title and questions are required.
            </div>

            <div className="card-footer bg-transparent text-center">
              <button
                type="button"
                className="btn btn-small btn-success mx-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  close();
                }}>
                Dismiss
              </button>
            </div>
          </div>
        </WarningModal>
      </main>
    </div>
  );
};

export default EditAndReview;
