import React from "react";
import "../../../styles/page-styles/landingPage.css";

const ContactUs = () => {
  return (
    <div>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Contact Us</h2>
            <p>
              NextEHealth develops custom software platforms and applications
              for healthcare professionals. With cutting-edge technologies, we
              improve patient care while meeting the unique business needs of
              our clients.
            </p>
          </div>
        </div>
        <div>
          <iframe
            style={{ border: 0, width: "100%", height: 350 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.5374884787257!2d-117.83781898534541!3d33.695038343917695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcde9f42dc2385%3A0x879606cd02e21109!2s16782%20Von%20Karman%20Ave%2C%20Irvine%2C%20CA%2092606!5e0!3m2!1sen!2sus!4v1642913520894!5m2!1sen!2sus"
            allowFullScreen
            title="company location"
          />
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="fas fa-location-arrow" />
                  <h4>Location:</h4>
                  <p>16782 Von Karman Ave, Irvine, CA 92606</p>
                </div>
                <div className="email">
                  <i className="fas fa-envelope" />
                  <h4>Email:</h4>
                  <p>info@nextehealth.com</p>
                </div>
                <div className="phone">
                  <i className="fas fa-phone" />
                  <h4>Call:</h4>
                  <p>+1 (866) 413-3156</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-5 mt-lg-0">
              <form
                action="forms/contact.php"
                method="post"
                className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={5}
                    placeholder="Message"
                    required
                    defaultValue={""}
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn-success">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
