import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Chart from "./AverageTurnAroundTimeChart";
import DocsSelectForm from "../common/DocsSelectForm";

const AverageTurnAroundTime = (props) => {
  const { data, allDocs, name } = props;

  const [filteredData, setFilteredData] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [dataForCharts, setDataForCharts] = useState({});
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    const processedData = data.filter((item) => {
      const { status } = item;

      return status === "completed";
    });
    setFilteredData(processedData);
  }, [data]);

  useEffect(() => {
    const allDocsTotal = filteredData.reduce(
      (acc, item) => {
        const { sentDate, completionDate } = item;
        acc.total += dayjs(completionDate).diff(dayjs(sentDate), "minutes");
        acc.count += 1;
        return acc;
      },
      { total: 0, count: 0 }
    );

    const average = allDocsTotal.total / allDocsTotal.count;
    setGrandTotal(parseFloat((average / 60).toFixed(1)));

    const filteredByDocs = filteredData.filter((item) => {
      return selectedDocs.length ? selectedDocs.includes(item.formName) : item;
    });
    const processedData = filteredByDocs.reduce((acc, item) => {
      const { formName, sentDate, completionDate } = item;
      if (acc[formName]) {
        acc[formName].count += 1;
        acc[formName].diff.push(
          dayjs(completionDate).diff(dayjs(sentDate), "minutes")
        );
      } else {
        acc[formName] = {
          count: 1,
          diff: [dayjs(completionDate).diff(dayjs(sentDate), "minutes")],
          formName,
        };
      }
      return acc;
    }, {});

    for (const key in processedData) {
      if (processedData.hasOwnProperty(key)) {
        const element = processedData[key];
        const average =
          element.diff.reduce((acc, item) => {
            return acc + item;
          }, 0) / element.diff.length;
        element.average = parseFloat((average / 60).toFixed(1));
      }
    }

    // remove all other keys and return only object with average
    const processedData2 = Object.keys(processedData).reduce((acc, item) => {
      acc[item] = processedData[item].average;
      return acc;
    }, {});

    setProcessedData(processedData2);
  }, [filteredData, selectedDocs]);

  useEffect(() => {
    const processed = Object.keys(processedData).map((item) => {
      return {
        x: item,
        y: processedData[item],
      };
    });

    setDataForCharts(processed);
  }, [processedData]);

  function handleDocsReset() {
    setSelectedDocs([]);
  }

  function handleSelectDocs(docs) {
    setSelectedDocs(docs);
  }

  return (
    <div className="row mt-4 mb-0 card mx-0">
      <div className="card-header pt-4 pb-1">
        <div className="col-12 d-flex flex-row justify-content-between ">
          <h3 className="mt-2 mb-4">{name} Average turn around time</h3>
        </div>
      </div>

      <div className="card-body">
        <div className="col-12 p-0 m-0 d-flex flex-column align-items-start justify-content-center">
          <DocsSelectForm
            allDocs={allDocs}
            handleSelectDocs={handleSelectDocs}
            handleDocsReset={handleDocsReset}
            name={name}
          />
        </div>
        {dataForCharts.length ? (
          <Chart
            title=""
            type="bar"
            processed={
              [{ x: "TOTAL AVERAGE", y: grandTotal }, ...dataForCharts] || []
            }
          />
        ) : (
          <p className="mt-3">
            No Completed {name} To Calculate Average Turn Around Time
          </p>
        )}
      </div>
    </div>
  );
};

export default AverageTurnAroundTime;
