import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import AnalyticsDatepicker from "../../../../common/forms/AnalyticsDatepicker";
import Chart from "./PharmacyUsersTotalDocsByStaffChart";
import UserSelectForm from "../common/UserSelectForm";
import DocsSelectForm from "../common/DocsSelectForm";

const PharmacyUsersTotalDocsByStaff = (props) => {
  const { data, allDocs, name } = props;

  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [filteredData, setFilteredData] = useState([]);
  const [dataForCharts, setDataForCharts] = useState({});
  const [proccessedData, setProccessedData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);

  const handleStartDatechange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    const processedData = data.filter((item) => {
      const { type: recipientType } = item.recipientInfo;
      const { type: senderType } = item.senderInfo;

      return (
        (recipientType === "User" || recipientType === "pharmacyAdmin") &&
        (dayjs(item.sentDate).isBetween(startDate, endDate) ||
          dayjs(item.sentDate).isSame(startDate, "day") ||
          dayjs(item.sentDate).isSame(endDate, "day")) &&
        (senderType === "User" || senderType === "pharmacyAdmin")
      );
    });
    setFilteredData(processedData);
  }, [startDate, endDate, data]);

  useEffect(() => {
    const filteredByDocs = filteredData.filter((item) => {
      return selectedDocs.length ? selectedDocs.includes(item.formName) : item;
    });
    const processedData = filteredByDocs.reduce((acc, item) => {
      const { sender_ID } = item;
      if (acc[sender_ID]) {
        acc[sender_ID].count += 1;
        acc[sender_ID].dates.push(item.sentDate);
      } else {
        acc[sender_ID] = {
          count: 1,
          dates: [item.sentDate],
          fullName: item.senderInfo.first + " " + item.senderInfo.last,
        };
      }
      return acc;
    }, {});
    for (const key in processedData) {
      processedData[key].dates.sort((a, b) => {
        return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
      });
    }
    setDataForCharts(processedData);
  }, [filteredData, selectedDocs]);

  const groupByDays = (incomingData) => {
    const processedData = [];
    for (const key in incomingData) {
      const data = incomingData[key].dates.reduce(
        (acc, item) => {
          const day = dayjs(item).day();
          if (day === dayjs(startDate).day()) {
            acc.day1 += 1;
          } else if (day === dayjs(startDate).add(1, "day").day()) {
            acc.day2 += 1;
          }
          if (day === dayjs(startDate).add(2, "day").day()) {
            acc.day3 += 1;
          }
          if (day === dayjs(startDate).add(3, "day").day()) {
            acc.day4 += 1;
          }
          if (day === dayjs(startDate).add(4, "day").day()) {
            acc.day5 += 1;
          }
          if (day === dayjs(startDate).add(5, "day").day()) {
            acc.day6 += 1;
          }
          if (day === dayjs(startDate).add(6, "day").day()) {
            acc.day7 += 1;
          }
          return acc;
        },
        { day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0 }
      );
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }

      processedData.push({
        id: key,
        data: arr,
        fullName: incomingData[key].fullName,
        count: incomingData[key].count,
      });
    }

    return processedData;
  };

  const groupByWeek = (data) => {
    const processedData = [];
    for (const key in data) {
      const data = dataForCharts[key].dates.reduce(
        (acc, item) => {
          const week = dayjs(item).week();
          if (week === dayjs(startDate).week()) {
            acc.week1 += 1;
          } else if (week === dayjs(startDate).add(1, "week").week()) {
            acc.week2 += 1;
          }
          if (week === dayjs(startDate).add(2, "week").week()) {
            acc.week3 += 1;
          }
          if (week === dayjs(startDate).add(3, "week").week()) {
            acc.week4 += 1;
          }
          if (week === dayjs(startDate).add(4, "week").week()) {
            acc.week5 += 1;
          }

          return acc;
        },
        { week1: 0, week2: 0, week3: 0, week4: 0, week5: 0 }
      );
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }
      processedData.push({
        id: key,
        data: arr,
        fullName: dataForCharts[key].fullName,
        count: dataForCharts[key].count,
      });
    }
    return processedData;
  };

  const groupByMonthByYears = (incomingData) => {
    const processedData = [];
    for (const key in incomingData) {
      const data = incomingData[key].dates.reduce((acc, item) => {
        const month = dayjs(item).month();
        const year = dayjs(item).year();
        if (acc[year]) {
          if (acc[year][month]) {
            acc[year][month] += 1;
          } else {
            acc[year][month] = 1;
          }
        } else {
          acc[year] = {};
          acc[year][month] = 1;
        }

        return acc;
      }, {});
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }

      const yearsArr = arr.map((item) => {
        const monthsArr = [];
        for (const key in item.y) {
          monthsArr.push({ x: key, y: item.y[key] });
        }
        return {
          x: item.x,
          y: monthsArr,
        };
      });

      const flatArr = yearsArr
        .map((item) => {
          return item.y.map((el) => {
            return {
              x: `${dayjs().month(el.x).format("MMM")} ${item.x}`,
              y: el.y,
            };
          });
        })
        .flat(2);

      processedData.push({
        id: key,
        data: flatArr,
        fullName: incomingData[key].fullName,
        count: incomingData[key].count,
      });
    }

    const dateRange = dayjs(endDate).diff(startDate, "month") + 1;
    const monthsArr = [];
    for (let i = 0; i <= dateRange; i++) {
      if (i === 0) {
        monthsArr.push(dayjs(startDate).format("MMM YYYY").toString());
        continue;
      } else {
        monthsArr.push(
          dayjs(startDate).add(i, "month").format("MMM YYYY").toString()
        );
      }
    }

    processedData.forEach((item) => {
      const arr = [];
      monthsArr.forEach((month) => {
        const found = item.data.find((el) => el.x === month);
        if (found) {
          arr.push(found);
        } else {
          arr.push({ x: month, y: 0 });
        }
      });
      item.data = arr;
    });

    return processedData;
  };

  useEffect(() => {
    const diff = dayjs(endDate).diff(startDate, "day");
    if (diff <= 7) {
      const processedData = groupByDays(dataForCharts);
      setProccessedData(processedData);
    } else if (diff <= 30) {
      const processedData = groupByWeek(dataForCharts);
      setProccessedData(processedData);
    } else {
      const processedData = groupByMonthByYears(dataForCharts);
      setProccessedData(processedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForCharts]);

  useEffect(() => {
    setAllUsers(
      Object.keys(dataForCharts).map((el) => dataForCharts[el].fullName)
    );
  }, [dataForCharts]);

  function handleUsersReset() {
    setSelectedUsers([]);
  }

  function handleSelectUsers(users) {
    setSelectedUsers(users);
  }

  function handleDocsReset() {
    setSelectedDocs([]);
  }

  function handleSelectDocs(docs) {
    setSelectedDocs(docs);
  }

  return (
    <div className="row mt-4 mb-0 card mx-0">
      <div className="card-header pt-4 pb-1">
        <div className="col-12 d-flex flex-row justify-content-between ">
          <h3 className="mt-2">
            {name}s Sent to Pharmacy Users by Staff/{name} names
          </h3>
          <AnalyticsDatepicker
            startDate={startDate}
            endDate={endDate}
            handleStartDatechange={handleStartDatechange}
            handleEndDateChange={handleEndDateChange}
          />
        </div>
      </div>

      <div className="card-body">
        {" "}
        <div className="col-12 p-0 m-0 d-flex flex-column align-items-start justify-content-center">
          <UserSelectForm
            names={allUsers}
            handleSelectUsers={handleSelectUsers}
            handleUsersReset={handleUsersReset}
          />
          <DocsSelectForm
            allDocs={allDocs}
            handleSelectDocs={handleSelectDocs}
            handleDocsReset={handleDocsReset}
            name={name + "s"}
          />
        </div>
        <Chart
          title=""
          type="area"
          processed={
            selectedUsers.length
              ? proccessedData.filter((item) => {
                  return selectedUsers.includes(item.fullName);
                })
              : proccessedData
          }
        />
      </div>
    </div>
  );
};

export default PharmacyUsersTotalDocsByStaff;
