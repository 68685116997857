import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import {
  getAllRoles,
  handelGetPrivlage,
  handelGetPrivlageByRole,
  handelDeletePrivlageFromRole,
  addPrivlageToRole,
  addNewRole,
} from "../../services/PharmacyAdmin";
import { convertTimeLocally } from "../../utils/timeConverter";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

const AddNewRoles = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [rolesList, setRolesList] = useState([]);
  const [privilegesList, setPrivilegesList] = useState([]);
  const [msg, setMsg] = useState("");
  const [detailesFlag, setDetailsFlag] = useState(false);
  const [thisRole, setThisRole] = useState({});
  const [thisRolePrivileges, setThisRolePrivileges] = useState([]);
  const [newRole, setNewRole] = useState({
    name: "",
  });

  const hanelChanges = (e) => {
    setNewRole({
      ...newRole,
      [e.target.name]: e.target.value,
      pharmacyID: pharmacy_id,
    });
  };

  const handelAdd = (e) => {
    e.preventDefault();

    addNewRole(newRole)
      .then((res) => {
        setMsg(res.data.messege);
        setNewRole({
          name: "",
        });
        handelGetRoles();
      })
      .catch((err) => {
        setMsg(err?.response?.data?.message || "");
      });
  };

  const handelGetRoles = () => {
    getAllRoles(pharmacy_id)
      .then((res) => {
        setRolesList(
          res.data.data.map((role) => {
            role.createdAt = convertTimeLocally(role.createdAt);
            return role;
          })
        );
      })
      .catch((err) => {
        setMsg(err?.response?.data?.message || "");
      });
  };

  const handelGetAllPrivelages = () => {
    handelGetPrivlage(pharmacy_id)
      .then((res) => {
        setPrivilegesList(res.data.data);
      })
      .catch((err) => {
        setMsg(err?.response?.data?.message || "");
      });
  };

  useEffect(() => {
    handelGetRoles();
    handelGetAllPrivelages();
  }, []);

  const columns = [
    { field: "ID", headerName: "ID", width: 100 },
    { field: "name", headerName: "Role Name", width: 200 },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <Button onClick={() => handleRowClick(params)}> Edit </Button>
      ),
    },
  ];

  const handleRowClick = (params) => {
    setThisRole(params.row);
    handelGetPrivlageByRole(params.row.ID)
      .then((res) => {
        setThisRolePrivileges(res.data.data);
        setDetailsFlag(true);
      })
      .catch((err) => {
        setMsg(err.response.data.message);
        setThisRolePrivileges([]);
        setDetailsFlag(true);
      });
  };

  // +++++++++++++++++++++++++++++++++++++++ Material UI +++++++++++++++++++++++++++++++++++++++++
  const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };

  const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  };

  const union = (a, b) => {
    return [...a, ...not(b, a)];
  };

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(
    privilegesList.map((priv) => {
      return priv.name;
    })
  );
  const [right, setRight] = useState(
    thisRolePrivileges.map((priv) => {
      return priv.name;
    })
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(
      privilegesList
        .map((priv) => {
          if (
            !thisRolePrivileges.some((thisitem) => thisitem.name === priv.name)
          ) {
            return priv.name;
          }
        })
        .filter((item) => item !== undefined)
    );
    setRight(
      thisRolePrivileges.map((priv) => {
        return priv.name;
      })
    );
  }, [thisRolePrivileges, privilegesList]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setMsg("");
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    checked.forEach((ch) => {
      addPrivlageToRole({
        roleID: thisRole.ID,
        privID: privilegesList.find((priv) => priv.name === ch).ID,
      })
        .then((res) => {
          // handelGetAllPrivelages();
          // handelGetRoles();
          setMsg(res.data.messege);
        })
        .catch((err) => {
          setMsg(err.response.data.message);
        });
    });
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    checked.forEach((ch) => {
      handelDeletePrivlageFromRole(
        thisRole.ID,
        privilegesList.find((priv) => priv.name === ch).ID
      )
        .then((res) => {
          // handelGetAllPrivelages();
          // handelGetRoles();
          setMsg(res.data.message);
        })
        .catch((err) => {
          setMsg(err.response.data.message);
        });
    });
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          minHeight: 230,
          maxHeight: 600,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  // +++++++++++++++++++++++++++++++++++++++ Material UI Ends ++++++++++++++++++++++++++++++++++++
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Roles Management</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">Roles Management</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {msg !== "" && msg}
              <div className="row">
                {detailesFlag ? (
                  <Paper elevation={1}>
                    <IconButton
                      onClick={() => {
                        setDetailsFlag(false);
                        setThisRole({});
                        setMsg("");
                      }}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center">
                      <Grid item>
                        {customList("All Available Privileges", left)}
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right">
                            ADD PRIVILEGE &gt;
                          </Button>
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left">
                            &lt; REMOVE PRIVILEGE
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {customList(
                          `${thisRole.name} Assigned Privileges`,
                          right
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Box>
                    <form className="row g-3">
                      <div className="col-auto">
                        <label for="inputPassword2" className="visually-hidden">
                          Role Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword2"
                          placeholder="Role Name"
                          name="name"
                          value={newRole.name}
                          onChange={hanelChanges}
                        />
                      </div>
                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-success mb-3"
                          onClick={handelAdd}>
                          Add Role
                        </button>
                      </div>
                    </form>
                    <div className="dg_1liner">
                      <DataGrid
                        rows={rolesList}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 100,
                            },
                          },
                          sorting: {
                            sortModel: [{ field: "createdAt", sort: "desc" }],
                          },
                        }}
                        pageSizeOptions={[100]}
                        checkboxSelection
                        getRowId={(row) => row.ID}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        loading={rolesList === 0}
                        onRowClick={handleRowClick}
                      />
                    </div>
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddNewRoles;
