import React, { useEffect, useState } from "react";
import xlsx from "xlsx";
import template from "../../../assets/templates/template.xls";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { searchPtForms } from "../../../services/PharmacyUser";
import { sendEmail, sendSMS } from "../../../services/Common";
import baseURL from "../../../utils/baseURL";

import { decodeToken } from "react-jwt";
import { createNewToken } from "../../../services/PharmacyUser";
import { v4 as uuidv4 } from "uuid";

function PatientsFromFile(props) {
  const { surveyID } = props;
  const [dataMatchingDb, setDataMatchingDb] = useState([]);
  const [dataNotMatchingDb, setDataNotMatchingDb] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [smssuccess, setSmssuccess] = useState([]);
  const [smsfail, setSmsfail] = useState([]);
  const [columns, setColumns] = useState([]);
  const [logflag, setLogflag] = useState(false);

  const pharmacy_id = localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const senderId = myDecodedToken.userId;

  useEffect(() => {
    let c = [
      { field: "id", headerName: "ID", width: 80 },
      { field: "first", headerName: "First name", width: 100 },
      { field: "last", headerName: "Last name", width: 100 },
      { field: "phone", headerName: "Phone", width: 100 },
      { field: "status", headerName: "Status", width: 100 },
      { field: "msg", headerName: "Details", width: 500 },
    ];
    setColumns(c);
  }, []);

  function cleanPhoneNumber(phone) {
    let cleaned = phone.length > 0 ? phone.replace(/\D/g, "").trim() : phone;

    return cleaned?.length === 10 ? cleaned : phone;
  }

  const handleExcel = async (e) => {
    setDataMatchingDb([]);
    setDataNotMatchingDb([]);
    setRowData([]);
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = xlsx.read(data, { type: "buffer" });

    let matching = [];
    let notMatching = [];

    const jsonData = xlsx.utils
      .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      .map((el) => {
        return { ...el, phone: cleanPhoneNumber(el.phone) };
      });

    const promises = jsonData.map(async (el) => {
      try {
        const res = await searchPtForms(pharmacy_id, {
          first: el.first || null,
          last: el.last || null,
          phone: el.phone || null,
        });
        matching.push({ ...res.data, MRN: res.data.MRN || "N/A" });
      } catch (err) {
        notMatching.push(el);
        console.error(err.message);
      }
    });
    setIsWaiting(true);
    await Promise.all(promises);
    setIsWaiting(false);

    setDataMatchingDb(matching.map((el, idx) => ({ ...el, id: idx + 1 })));
    setDataNotMatchingDb(
      notMatching.map((el, idx) => ({ ...el, id: idx + 1 }))
    );
  };

  const columnDef1 = [
    { headerName: "S/N", field: "id", minWidth: 100, hide: true },
    { headerName: "MRN", field: "MRN", minWidth: 100 },
    { headerName: "First Name", field: "first", minWidth: 150 },
    { headerName: "Last Name", field: "last", minWidth: 150 },
    { headerName: "Phone", field: "phone", minWidth: 150 },
    { headerName: "recipient Type", field: "type", minWidth: 150 },
  ];

  const columnDef2 = [
    { headerName: "S/N", field: "id", minWidth: 100, hide: true },
    { headerName: "First Name", field: "first", minWidth: 150 },
    { headerName: "Last Name", field: "last", minWidth: 150 },
    { headerName: "Phone", field: "phone", minWidth: 150 },
  ];

  useEffect(() => {
    setRowData([...dataMatchingDb]);
  }, [dataMatchingDb]);

  const heandleSend = () => {
    dataMatchingDb.map((item) => {
      let token = uuidv4();
      let date = new Date();
      date = date.toString();
      let tokenObject = {
        token: token,
        date: date,
        dispenseDate: "",
        ptid: item.id,
        resflag: false,
        location: "",
        locationNPI: "",
        servid: surveyID,
      };
      createNewToken({ token: JSON.stringify(tokenObject) });

      let url = `${baseURL.frontEnd}/provider-appless-survey/?id=${surveyID}&pid=${item.id}&pdate=1/1/1900&token=${token}`;
      if (item.mail && item.mail !== "") {
        const EmailMessage = `<!DOCTYPE html>
  <html style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
  <head>
  <meta name="viewport" content="width=device-width" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <title>Actionable emails e.g. reset password</title>
  
  
  <style type="text/css">
  img {
  max-width: 100%;
  }
  body {
  -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em;
  }
  body {
  background-color: #f6f6f6;
  }
  @media only screen and (max-width: 640px) {
    body {
      padding: 0 !important;
    }
    h1 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h2 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h3 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h4 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h1 {
      font-size: 22px !important;
    }
    h2 {
      font-size: 18px !important;
    }
    h3 {
      font-size: 16px !important;
    }
    .container {
      padding: 0 !important; width: 100% !important;
    }
    .content {
      padding: 0 !important;
    }
    .content-wrap {
      padding: 10px !important;
    }
    .invoice {
      width: 100% !important;
    }
  }
  </style>
  </head>
  
  <body itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6">
  
  <table class="body-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
      <td class="container" width="600" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;" valign="top">
        <div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
          <table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope itemtype="http://schema.org/ConfirmAction" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9;" bgcolor="#fff"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
                <meta itemprop="name" content="Confirm Email" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" /><table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                   Dear ${item.first} ${item.last} (or legal guardian),
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                  We providing you the very best in health care is our top priority. We gauge success on maintaining the satisfaction of our patients and their families. As a promise to continually improve our services, we ask for your feedback both in areas we can improve and where you think we do well.
                  It's our mission to take this feedback and improve the patient experience for you and others. We appreciate your time and ask that you spend just a few minutes with this short survey by using the link below. Anything that you share is confidential, and you are encouraged to tell us exactly what you think.  
                  Thank you for your feedback.
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      <a href="${url}" class="btn-success" itemprop="url" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Start Survey</a>
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      &mdash; Sincerely,
                    </td>
                  </tr></table></td>
            </tr></table><div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
            <table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;" align="center" valign="top">If you do not wish to participate, please click on the follwing link ${baseURL.frontEnd}/optout/?id=${item.id} to opt out of this survey.</td>
              </tr></table></div></div>
      </td>
      <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
    </tr></table></body>
  </html>`;

        sendEmail({ to: item.email, message: EmailMessage })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }

      if (item.phone && item.phone !== "") {
        let SmsMessage = `${pharmacy_name} Pharmacy would like your feedback. Please rate them at ${url}  .
  To opt out, click on the follwing link ${baseURL.frontEnd}/optout/?id=${item.id} .`;

        sendSMS({ to: item.phone, body: SmsMessage })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  return (
    <div className="card">
      <div
        className="card-header fs-5"
        style={{
          textAlign: "center",
          color: "whitesmoke",
          background: "linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)",
        }}>
        Upload recipients data using spreadsheet
      </div>
      <div className="card-body">
        {" "}
        <h5 className="my-3">
          Please download the spreadsheet template provided{" "}
          <a
            href={template}
            download
            className="text-success fs-5 text-decoration-none">
            HERE&nbsp;
          </a>
          and fill it with recipients data, then upload it using the button
          below.
        </h5>
        <p className="text-success">
          Note: supported file types: .xlsx, .xls and .csv
        </p>
        <p className="text-danger">
          Warning: If you uploaded your own spreadsheet and column names are
          different process will fail
        </p>
        <input
          type="file"
          className="btn btn-success my-2 col-12 col-sm-auto"
          onChange={(e) => {
            handleExcel(e);
          }}
        />
        {!isWaiting ? (
          <div>
            {rowData.length > 0 && (
              <div style={{ height: "500px", width: "100%" }}>
                <DataGrid
                  rows={rowData}
                  columns={columnDef1}
                  pageSize={10}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                />
              </div>
            )}

            <div className="my-3">
              {rowData.length > 0 && (
                <button
                  className="btn btn-success my-5 col-lg-2 py-2"
                  onClick={() => heandleSend()}>
                  Send
                </button>
              )}
              {rowData.length > 0 && dataNotMatchingDb.length === 0 ? (
                <h5 className="text-success  mt-3 fw-bold">
                  Import Successfull, please verify imported data and click send
                </h5>
              ) : rowData.length === 0 && dataNotMatchingDb.length > 0 ? (
                <>
                  <h5 className="text-danger mt-2 fw-bold">
                    All of your imported data don't match our records (indicated
                    in the table below), please register recipients in the
                    system first then try again
                  </h5>
                </>
              ) : rowData.length > 0 && dataNotMatchingDb.length > 0 ? (
                <>
                  <h5 className="text-danger mt-1 fw-bold">
                    Some of your imported data don't match our records
                    (indicated in the table below), you can still send the form
                    but ONLY{" "}
                    <span className="text-success">
                      recipients listed in the above table will receive it{" "}
                    </span>
                  </h5>
                </>
              ) : (
                <></>
              )}
              {dataNotMatchingDb.length > 0 && (
                <div style={{ height: "500px", width: "100%" }}>
                  <DataGrid
                    rows={dataNotMatchingDb}
                    columns={columnDef2}
                    pageSize={10}
                    density="compact"
                    components={{ Toolbar: GridToolbar }}
                  />
                </div>
              )}
            </div>
            <div className="col-12">
              <div style={{ textAlign: "center" }}>
                {smssuccess.length > 0 ? (
                  <>Succeeded: {smssuccess.length}</>
                ) : null}{" "}
                {smsfail.length > 0 ? <> - Failed: {smsfail.length}</> : null}{" "}
                {smsfail.length > 0 || smssuccess.length > 0 ? (
                  <>
                    <Button
                      onClick={() => {
                        setLogflag(true);
                      }}
                      variant="text">
                      Log
                    </Button>{" "}
                  </>
                ) : null}
              </div>
              {logflag ? (
                <>
                  <div>
                    <div>
                      {smssuccess.length > 0 && (
                        <div
                          className=" col-12 col-lg-6 my-3"
                          style={{ height: 500 }}>
                          <h5 className="text-center">Success Log</h5>
                          <DataGrid
                            rows={smssuccess}
                            columns={columns}
                            pageSize={10}
                            density="compact"
                            components={{ Toolbar: GridToolbar }}
                          />
                        </div>
                      )}

                      {smsfail.length > 0 && (
                        <div
                          className=" col-12 col-lg-6 my-3"
                          style={{ height: 500 }}>
                          <h5 className="text-center">Failed Log</h5>
                          <DataGrid
                            rows={smsfail}
                            columns={columns}
                            pageSize={10}
                            density="compact"
                            components={{ Toolbar: GridToolbar }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="text-center">Please wait...</h3>
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PatientsFromFile;
