export const yearSelect = () => {
  let year = 2022;
  let currentYear = new Date().getFullYear();
  const yearsArr = [];
  while (year <= currentYear) {
    yearsArr.push(year);
    year++;
  }
  return yearsArr;
};

export const surveyCounter = (arr, year) => {
  const monthlyHash = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
  };
  let targetDate;
  let targetMonth;
  let targetYear;
  const resultArr = [];
  arr.length &&
    arr.forEach((el) => {
      if (el.date) {
        targetDate = el.date.split(" ");
        targetMonth = targetDate[1];
        targetYear = targetDate[3];
        if (targetYear === year) {
          targetMonth === "Jan"
            ? monthlyHash[0]++
            : targetMonth === "Feb"
            ? monthlyHash[1]++
            : targetMonth === "Mar"
            ? monthlyHash[2]++
            : targetMonth === "Apr"
            ? monthlyHash[3]++
            : targetMonth === "May"
            ? monthlyHash[4]++
            : targetMonth === "Jun"
            ? monthlyHash[5]++
            : targetMonth === "Jul"
            ? monthlyHash[6]++
            : targetMonth === "Aug"
            ? monthlyHash[7]++
            : targetMonth === "Sep"
            ? monthlyHash[8]++
            : targetMonth === "Oct"
            ? monthlyHash[9]++
            : targetMonth === "Nov"
            ? monthlyHash[10]++
            : targetMonth === "Dec"
            ? monthlyHash[11]++
            : void 0;
        }
      }
    });
  for (let i = 0; i < 12; i++) {
    monthlyHash[i] ? resultArr.push(monthlyHash[i]) : resultArr.push(0);
  }
  return resultArr;
};

export const surveyCounterCompleted = (arr, year) => {
  const monthlyHash = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
  };
  let targetDate;
  let targetMonth;
  let targetYear;
  const resultArr = [];
  arr.length &&
    arr.forEach((el) => {
      if (el.date && el.resflag) {
        targetDate = el.date.split(" ");
        targetMonth = targetDate[1];
        targetYear = targetDate[3];
        if (targetYear === year) {
          targetMonth === "Jan"
            ? monthlyHash[0]++
            : targetMonth === "Feb"
            ? monthlyHash[1]++
            : targetMonth === "Mar"
            ? monthlyHash[2]++
            : targetMonth === "Apr"
            ? monthlyHash[3]++
            : targetMonth === "May"
            ? monthlyHash[4]++
            : targetMonth === "Jun"
            ? monthlyHash[5]++
            : targetMonth === "Jul"
            ? monthlyHash[6]++
            : targetMonth === "Aug"
            ? monthlyHash[7]++
            : targetMonth === "Sep"
            ? monthlyHash[8]++
            : targetMonth === "Oct"
            ? monthlyHash[9]++
            : targetMonth === "Nov"
            ? monthlyHash[10]++
            : targetMonth === "Dec"
            ? monthlyHash[11]++
            : void 0;
        }
      }
    });
  for (let i = 0; i < 12; i++) {
    monthlyHash[i] ? resultArr.push(monthlyHash[i]) : resultArr.push(0);
  }
  return resultArr;
};

export const timeConvert = (time) => {
  let dateFormat = new Date(parseInt(time));
  let date = dateFormat.getMonth() + 1 + "/" + dateFormat.getDate();

  let hours = `${
    dateFormat.getHours() > 12
      ? dateFormat.getHours() - 12
      : dateFormat.getHours() === 0
      ? 12
      : dateFormat.getHours()
  }:${
    dateFormat.getMinutes() < 10
      ? "0" + dateFormat.getMinutes()
      : dateFormat.getMinutes()
  } ${dateFormat.getHours() > 12 ? " pm" : " am"}`;

  return `${date} ${hours}`;
};

export const monthlyCounter = (arr, year, userType) => {
  const monthlyHash = {};
  const resultArr = [];
  const valuesArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (userType === "pr" && arr[i].user.createdAt.split("-")[0] === year) {
      valuesArr.push(Number(arr[i].user.createdAt.split("-")[1]));
    }

    if (userType === "pt" && arr[i].createdAt.split("-")[0] === year) {
      valuesArr.push(Number(arr[i].createdAt.split("-")[1]));
    }
  }

  valuesArr.length &&
    valuesArr.forEach((el) => {
      !monthlyHash[el] ? (monthlyHash[el] = 1) : monthlyHash[el]++;
    });

  for (let i = 1; i <= 12; i++) {
    monthlyHash[i] ? resultArr.push(monthlyHash[i]) : resultArr.push(0);
  }

  return resultArr;
};
