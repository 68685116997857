import React, { useEffect, useState } from "react";
import placeHolder from "../../../assets/images/patient_placeholder.png";
import { uploadProfilePic } from "../../../services/Common";
import { User } from "../../../utils/globalState";
import { decodeToken } from "react-jwt";
import { getProfile } from "../../../services/PharmacyUser";
import { SideBar } from "../../../utils/globalState";
import CropEasy from "./crop/CropEasy";

const ProfilePictureUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [curUserInfo, setCurUserInfo] = useState({});
  const [openCrop, setOpenCrop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [photoURL, setPhotoURL] = useState(null);

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userInformation = User.getState().info;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = userInformation.id || myDecodedToken.userId;

  useEffect(() => {
    getProfile(userId)
      .then((res) => {
        setCurUserInfo(res.data.data.user[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setOpenCrop(true);

    if (file && !file.type.startsWith("image/")) {
      setErrorMessage("Please select an image file.");
    } else {
      setErrorMessage("");
    }
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append(
      "file",
      selectedFile,
      `user-${curUserInfo.id}-${Math.random()}`
    );
    // Send the formData to the server using your preferred method (e.g., fetch or Axios).
    uploadProfilePic(formData, userId)
      .then((response) => {
        setCurUserInfo({
          ...curUserInfo,
          pic_url: `${response.data.imageUrl}?v=${Date.now()}`,
        });
        User.setState({
          info: {
            ...userInformation,
            pic_url: `${response.data.imageUrl}?v=${Date.now()}`,
          },
        });
        setIsUpload(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.error("Error uploading file", err);
      });
  };

  const renderPreviewImage = () => {
    if (selectedFile) {
      return URL.createObjectURL(selectedFile);
    } else if (curUserInfo.pic_url) {
      return `${curUserInfo.pic_url}?v=${Date.now()}`;
    } else {
      return placeHolder;
    }
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle mb-5">
          <h1>Profile Picture</h1>
        </div>
        {!openCrop ? (
          <>
            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                <div className="container-fluid text-center">
                  {isUpload ? (
                    <div className="col-8 mx-auto">
                      <div className="row">
                        <img
                          src={renderPreviewImage()}
                          alt="profile pic placeholder"
                          className="mb-2 col-12 mx-auto"
                          style={{ width: "60vh" }}
                        />
                      </div>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="btn col-lg-8 btn-outline-success me-3"
                      />
                      {errorMessage && (
                        <p className="text-danger fs-6">{errorMessage}</p>
                      )}
                      <button
                        type="button"
                        onClick={() => handleUpload()}
                        disabled={!selectedFile || errorMessage}
                        className="btn btn-success col-lg-2 me-1">
                        upload
                      </button>
                      <button
                        className="btn btn-danger col-lg-1 "
                        onClick={() => {
                          setIsUpload(false);
                          setSelectedFile(null);
                        }}>
                        X
                      </button>
                    </div>
                  ) : (
                    <>
                      <img
                        src={renderPreviewImage()}
                        alt="profile pic placeholder"
                        className="w-25 mb-2"
                      />
                      <br />
                      <button
                        className="btn btn-success"
                        onClick={() => setIsUpload(true)}>
                        Change Profile Picture
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <CropEasy
            photoURL={URL.createObjectURL(selectedFile)}
            setOpenCrop={setOpenCrop}
            setSelectedFile={setSelectedFile}
            setPhotoURL={setPhotoURL}
            fileName={selectedFile.name}
          />
        )}
      </main>
    </div>
  );
};

export default ProfilePictureUploader;
