import React, { useState, useEffect } from "react";
import { User } from "../../../utils/globalState";
import {
  getBirthdayMsg,
  updateBirthdayMsg,
} from "../../../services/PharmacyUser";

const BirthdayMessageUpdater = (props) => {
  const { setIsLoading } = props;

  const userInformation = User.getState().info;
  const pharmacy_id =
    userInformation.pharmacyID || localStorage.getItem("pharmacy");

  const [isDisabled, setIsDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateBirthdayMsg(pharmacy_id, { message })
      .then((res) => {
        setSuccessMessage(res.data.message);
        setIsDisabled(true);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      getBirthdayMsg(pharmacy_id)
        .then((res) => {
          setMessage(res.data.birthday_msg);
        })
        .catch((err) => {
          console.error(err);
        });

      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  return (
    <div className=" col-10  mx-auto my-0">
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column align-items-center my-3">
        <div className="form-group d-flex flex-row flex-wrap col-11 ">
          <label className="card-title mx-auto text-center">
            Default Birthday Message
          </label>
          <textarea
            type="text"
            value={message || ""}
            placeholder="Happy Birthday first name!, your custom message "
            onChange={(e) => {
              setMessage(e.target.value);
              setIsDisabled(false);
            }}
            className="form-control rounded mx-auto my-1"
            rows={message?.length > 80 ? 3 : 1}
          />
        </div>

        {successMessage ? (
          <p className="text-success">{successMessage}</p>
        ) : (
          <>
            <small className="d-block mx-2 text-center">
              {message?.length > 0 ? "Update the" : "Create a"} custom message
              and click on the update button, The message by default will always
              start by
            </small>
            <small className="d-block mb-2 mx-2 text-center">
              "Happy Birthday first name!, " and your custom message will appear
              after that.
            </small>
          </>
        )}
        <button
          type="submit"
          className="btn btn-success col-auto"
          disabled={isDisabled}>
          update
        </button>
      </form>
    </div>
  );
};

export default BirthdayMessageUpdater;
