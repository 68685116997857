import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { getProviderFeatures } from "../services/Provider";

const loadProviderFeatures = async () => {
  try {
    const token = localStorage.getItem("Token");
    const myDecodedToken = decodeToken(token) || {};
    const userID = myDecodedToken.userId;
    const resp = await getProviderFeatures(userID);
    const providerFeatures = resp?.data?.features.map((el) => el.feature) || [];
    return providerFeatures;
  } catch (error) {
    console.error("Error in getUserInfo:", error);
    return [];
  }
};

const ProviderProtectedRoutes = ({ restrictedFeature }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const providerAllowedFeatures = await loadProviderFeatures();

        if (providerAllowedFeatures.includes(restrictedFeature)) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
          navigate("notAuthorized");
        }
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching user info:", error);
        navigate("notAuthorized");
        setIsLoaded(true);
      }
    };

    fetchData();
  }, [navigate, restrictedFeature]);

  if (!isLoaded)
    return (
      <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
        <div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="ms-4 align-middle text-secondary">Loading</div>
      </div>
    );
  return isAuthorized ? <Outlet /> : <div>Not Authorized</div>;
};

export default ProviderProtectedRoutes;
