import React from "react";
import { Routes, Route } from "react-router-dom";
import ManagePharmacy from "../pages/admin/ManagePharmacy";
import ManagePrivilege from "../pages/admin/ManagePrivilege";
import Header from "../components/common/layout/Header";
import Navigator from "../components/admin/AdminNaves/AdminNav";
import SessionTimeOut from "./../components/common/SessionTimeOut/SessionTimeOut";
import ManageIntgrations from "../pages/admin/ManageIntgrations";
import NotFound from "./../components/common/NotFound";
import ContactUs from "./../components/common/ContactUs";
import ManageFeatures from '../pages/admin/ManageFeatures';
import ManageProviderFeature from "../pages/admin/ManageProviderFeature";

const AdminRoutes = () => {
  return (
    <div>
      <SessionTimeOut />
      <Header />
      <Navigator />
      <Routes>
        <Route path="managePharmacy" element={<ManagePharmacy />} />
        <Route path="managePrivilege" element={<ManagePrivilege />} />
        <Route path="manageIntgrations" element={<ManageIntgrations />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="manageFeatures" element={<ManageFeatures />} />
        <Route path="manageProviderFeature" element={<ManageProviderFeature />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;
