import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";

const Gastroenterology = (props) => {
  // No Changes for the state

  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);

  useEffect(() => {
      setRxController(stateObj);
    }, [stateObj]);

  const [QA, setQA] = useState({});
  const [rx, setRx] = useState({
    clinicalinfo: [],
  });

  // No Changes  handleChange

  const handleChange = (event) => {
    setQA({ ...QA, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (QA) {
      var arr = Object.keys(QA).map(function (key) {
        return { [key]: QA[key] };
      });

      const ms = arr.map((x) => {
        for (const [key, value] of Object.entries(x)) {
          return { question: key, answer: value };
        }
      });
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: ms },
          completeflag: { ...rxController.completeflag, Clinical: true },
        },
      });
    } else if (!QA) {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: [] },
          completeflag: { ...rxController.completeflag, Clinical: false },
        },
      });
    }

  
  };
  return (

        <div className="container container_alzheimer">

          <form className="mb-3 col-12 col-lg-8" onSubmit={handleSubmit}>
            {/* 4. Diagnosis/Clinical Information */}

            <h3 className="gas_h3 p-3 fs-3">
              {" "}
              Gastroenterology / Clinical Information{" "}
            </h3>
            <div className="col-md-12">
              <label
                className="form-label form-control"
                htmlFor="Gastroenterology">
                {" "}
                Diagnosis:
                <textarea
                  className="form-control"
                  name="Gastroenterology"
                  onChange={handleChange}></textarea>
              </label>
            </div>
            <div className="col-md-12">
              <label
                className="form-label form-control"
                htmlFor="Gastroenterology1">
                {" "}
                ICD-10:
                <input
                  type="text"
                  className="form-control"
                  name="Gastroenterology1"
                  size="30"
                  onChange={handleChange}></input>
              </label>
            </div>
            <div className="d-grid gap-2 col-md-12 mx-auto">
              <button className="btn btn-outline-primary btn_ast" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
   
  );
};
export default Gastroenterology;
