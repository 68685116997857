import React, { useEffect, useState, useRef } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Box from "@mui/material/Box";
import { Buffer } from "buffer";
import CircularProgress from "@mui/material/CircularProgress";
import PDFViewer from "./PDFViewer";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { handleGetDocFiles } from "../../../services/PharmacyUser";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function ReviewResponse(props) {
  // Create new plugin instance
  const windowSize = useRef(window.innerWidth);
  const [base64pdf, setBase64pdf] = useState("");
  const [json, setJson] = useState({});
  const [ismobile, setIsmobile] = useState(false);

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  useEffect(() => {
    setJson(handleObjectRewrite(props.data));
  }, [props.data]);

  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let htmlq = json.pages[0].elements.find((item) => item.type === "html");

      if (htmlq) {
        let url = htmlq.name;
        let filename = url.split("/")[url.split("/").length - 1];

        const existingPdfBytes = handleGetDocFiles(filename).then((res) => {
          return Buffer.from(res.data.file.data, "base64");
        });
        // data:application/pdf;base64,
        existingPdfBytes.then((res) => {
          setBase64pdf(`data:application/pdf;base64,${res.toString("base64")}`);
        });
      }
    }
  }, [json]);

  useEffect(() => {
    if (windowSize.current < 500) {
      setIsmobile(true);
    } else {
      setIsmobile(false);
    }
  }, [windowSize]);

  if (Object.keys(json).length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}>
        <CircularProgress />
      </Box>
    );
  } else {
    json.pages[0].elements.find(
      (item) => item.type === "html"
    ).html = ` <p>Unable to display PDF file?. <a href="${
      json.pages[0].elements.find((item) => item.type === "html").name
    }" target="_blank">Download</a> instead.</p>`;

    const survey = new Model(json);
    survey.mode = "display";

    if (ismobile) {
      return <div id="images"></div>;
    } else {
      return (
        <div>
          {Object.keys(json).length > 0 && (
            <>{/* <Document file={base64pdf} /> */}</>
          )}
          <div style={{ maxWidth: "50%" }}>
            <PDFViewer pdf={base64pdf} />
          </div>
          <Survey model={survey} />
        </div>
      );
    }
  }
}

export default ReviewResponse;
