import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Alert,
} from "@mui/material";
import { updatePassword } from "../../services/Common";

function PasswordChangeForm(props) {
  const { userId } = props;
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (prop) => (event) => {
    setError("");
    setPasswords({ ...passwords, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!passwords.newPassword || !passwords.confirmPassword) {
      setError("Please fill in all fields!");
      return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setError("New password and confirm password do not match!");
      return;
    }
    const password = passwords.newPassword;
    updatePassword(userId, { password })
      .then((res) => {
        setSuccess(true);
        setError("");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.error("Error updating password:", err);
        setError(err.response.data.message || "Error updating password!");
        setSuccess(false);
      })
      .finally(() => {
        setPasswords({
          newPassword: "",
          confirmPassword: "",
        });
      });
  };

  return (
    <Container maxWidth="xs" sx={{ height: "100vh", padding: "10% 0 0 0" }}>
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Create a New Password
      </Typography>

      {success && (
        <Alert severity="success">
          Password successfully changed! You will be Redirected Shortly
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            required
            value={passwords.newPassword}
            onChange={handleChange("newPassword")}
            fullWidth
            InputLabelProps={{ style: { top: "-30%" } }}
          />
          <TextField
            label="Confirm New Password"
            variant="outlined"
            type="password"
            required
            value={passwords.confirmPassword}
            onChange={handleChange("confirmPassword")}
            fullWidth
            InputLabelProps={{ style: { top: "-30%" } }}
          />
          {error && (
            <Alert severity="error" sx={{ margin: "1rem 0" }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!passwords.newPassword || !passwords.confirmPassword}>
            Change Password
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default PasswordChangeForm;
