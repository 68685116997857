import React, { useState, useEffect } from "react";
import { SideBar } from "../../../utils/globalState";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../styles/page-styles/careplan.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddIcon from "@mui/icons-material/Add";
import { useModal } from "react-hooks-use-modal";
import IconButton from "@mui/material/IconButton";
import CpFormBuilder from "../../../components/pharmacyUser/CarePlanCenter/CpFormBuilder";
import { decodeToken } from "react-jwt";
import {
  handleUpdateCarePlanForm,
  handleDeleteCarePlanForm,
  handleGetCpFormsByPharmacyId,
} from "../../../services/CarePlan";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const FormsLib = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const pharmacyId = localStorage.getItem("pharmacy");
  const [userInput, setUserInput] = useState({
    title: "",
    type: "",
  });
  const [msg, setMsg] = useState("");
  const [form, setForm] = useState({});
  const [forms, setForms] = useState([]);
  const [showCreator, setShowCreator] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const handleTitleChange = (event) => {
    setUserInput({ ...userInput, [event.target.name]: event.target.value });
  };

  const [Modal, openModal, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const [value, setValue] = React.useState("1");

  useEffect(() => {
    setValue("1");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetForms = async () => {
    handleGetCpFormsByPharmacyId(pharmacyId)
      .then((res) => {
        setForms(
          res.data.map((form) => {
            form.isSent = form.isSent === 1 ? true : false;
            form.isHidden = form.isHidden === 1 ? true : false;
            form.json = JSON.parse(form.formText);
            return form;
          })
        );
      })
      .catch((err) => {
        setMsg(err.response.data.message);
      });
  };

  useEffect(() => {
    handleGetForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  const handleCreate = () => {
    setMsg("");
    if (userInput.title !== "" && userInput.type !== "") {
      if (forms.some((form) => form.json.title === userInput.title)) {
        setMsg("Form with this title already exists");
        return;
      }
      setForm({
        isSent: false,
        isInitForm: userInput.type === "1" ? true : false,
        json:
          userInput.type === "1"
            ? {
                title: userInput.title,
                pages: [],
              }
            : {
                title: userInput.title,
               pages: [
                  {
                    name: "page1",
                    elements: [
                      {
                        type: "boolean",
                        name: "followup-schedule",
                        title:
                          "Would you like to update the follow up schedule?",
                      },
                    ],
                  },
                ],
                calculatedValues: [
                  {
                    name: "firstname-for-complete-page",
                    expression:
                      "iif({first-name} notempty, {first-name}, patient)",
                  },
                ],
                showQuestionNumbers: "off",
                questionErrorLocation: "bottom",
                completeText: "Complete",
                questionsOnPageMode: "singlePage",
                widthMode: "static",
                width: "1024",
              },
      });
      setShowCreator(true);
      close();
      setUserInput({ title: "", type: "" });
      setMsg("");
    } else {
      setMsg("Please fill in all the fields");
    }
  };

  const handleOpen = () => openModal();
  const handleClose = () => {
    close();
    setDeleteFlag(false);
  };

  const handleShowCreator = () => {
    setShowCreator(!showCreator);
    handleGetForms();
  };

  const handleEdit = (form) => {
    setForm(form);
    setShowCreator(true);
  };

  const handleHideShow = (form) => {
    handleUpdateCarePlanForm(form.id, { isHidden: !form.isHidden })
      .then(() => {
        const newForms = forms.map((el) => {
          if (el.id === form.id) {
            return { ...el, isHidden: !el.isHidden };
          }
          return el;
        });
        setForms(newForms);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleDelete = (form) => {
    handleDeleteCarePlanForm(form.id)
      .then(() => {
        handleClose();
        const newForms = forms.filter((el) => el.id !== form.id);
        setForms(newForms);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Forms Library</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Care Plan</li>
              <li className="breadcrumb-item active">Forms Library</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="schedule-control-section row">
                {showCreator ? (
                  <CpFormBuilder
                    form={form}
                    userID={userID}
                    creatorType="pharmacy"
                    setShowCreator={handleShowCreator}
                  />
                ) : (
                  <>
                    <div className="col-lg-10 control-section">
                      <Button
                        className="mx-2 ms-0  mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleOpen}>
                        Create New Form
                      </Button>
                    </div>

                    <div
                      className="col-lg-2 control-section"
                      style={{ float: "right" }}>
                      {forms.some((el) => el.isHidden) && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showAll}
                                onChange={handleShowAll}
                              />
                            }
                            label="Show Hidden"
                          />
                        </FormGroup>
                      )}
                    </div>
                    <div className="col-lg-12 control-section">
                      <div className="control-wrapper">
                        <TabContext value={value}>
                          <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example">
                            <Tab label="Initial Forms" value="1" />
                            <Tab label="Follow Ups Forms" value="2" />
                          </TabList>
                          <List>
                            {forms
                              .filter((el) =>
                                value === "1" ? el.isInitForm : !el.isInitForm
                              )
                              .map((formItem) => (
                                <Paper
                                  key={formItem.id}
                                  elevation={3}
                                  sx={{
                                    mb: 2,
                                    pl: 2,
                                    pr: 2,
                                    display: formItem.isHidden
                                      ? showAll
                                        ? "block"
                                        : "none"
                                      : "block",
                                  }}>
                                  <ListItem
                                    key={formItem.id}
                                    disableGutters
                                    secondaryAction={
                                      <>
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          startIcon={<BorderColorIcon />}
                                          sx={{
                                            marginRight: "10px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => handleEdit(formItem)}>
                                          {formItem.isSent
                                            ? "View"
                                            : "View / Edit"}
                                        </Button>
                                        &#124;
                                        <Button
                                          onClick={() => {
                                            setForm(formItem);
                                            setDeleteFlag(true);
                                            openModal();
                                          }}
                                          variant="outlined"
                                          size="small"
                                          startIcon={<DeleteIcon />}
                                          sx={{
                                            color: "darkred",
                                            borderColor: "darkred",
                                            marginRight: "10px",
                                            marginLeft: "10px",
                                            ":hover": {
                                              bgcolor: "darkred",
                                              borderColor: "darkred",
                                              color: "white",
                                            },
                                          }}
                                          disabled={formItem.isSent}>
                                          Delete
                                        </Button>
                                        <Tooltip
                                          title={
                                            formItem.isHidden ? "Show" : "Hide"
                                          }>
                                          <IconButton
                                            aria-label="Show/Hide"
                                            size="large"
                                            onClick={() =>
                                              handleHideShow(formItem)
                                            }>
                                            {formItem.isHidden ? (
                                              <VisibilityIcon />
                                            ) : (
                                              <VisibilityOffIcon />
                                            )}
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    }>
                                    <ListItemText
                                      primary={`${formItem.json.title}`}
                                    />
                                  </ListItem>
                                </Paper>
                              ))}
                          </List>
                        </TabContext>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal>
          {deleteFlag ? (
            <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
              <div className="card-header bg-transparent fw-bold text-center">
                Delete {form?.json?.title && form.json.title}
              </div>
              <div className="card-body">
                {/* <h5 className="card-title">Success card title</h5> */}
                <p>
                  By clicking confirm, you will permanently delete{" "}
                  {form.json.title} form.
                </p>
              </div>
              <div className="card-footer bg-transparent text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-2 mx-2"
                  data-bs-dismiss="modal"
                  onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger mx-2 mx-2"
                  onClick={() => handleDelete(form)}>
                  Delete
                </button>
              </div>
            </div>
          ) : (
            <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
              {msg !== "" && <p className="text-danger">{msg}</p>}
              <div className="card-header bg-transparent fw-bold text-center">
                Create New Form
              </div>
              <div className="card-body">
                {/* <h5 className="card-title">Success card title</h5> */}
                <form>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Form Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={handleTitleChange}
                      value={userInput.title}
                      name="title"
                    />
                  </div>
                  <div className="mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleTitleChange}
                      value={userInput.type}
                      name="type">
                      <option selected value="">
                        Please Select Form Type
                      </option>
                      <option value="1">Initial Form</option>
                      <option value="2">Follow Up</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-transparent text-center">
                <button
                  type="button"
                  className="btn btn-danger mx-2 mx-2"
                  data-bs-dismiss="modal"
                  onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-2 mx-2"
                  onClick={handleCreate}>
                  Create
                </button>
              </div>
            </div>
          )}
        </Modal>
      </main>
    </div>
  );
};

export default FormsLib;
