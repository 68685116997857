import React, { useEffect, useState } from "react";
import { ProviderAddRxController } from "../../../../utils/globalState";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { addRx } from "../../../../services/Provider";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";

function Review() {
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: "Review",
      },
    });
  }, []);

  const handelAdd = (event) => {
    event.preventDefault();

    let rxFinal = {
      ICD10: rxController.rx.ICD10,
      clinicalinfo: rxController.rx.clinicalinfo,
      diagnose: rxController.rx.diagnose,
      medications: rxController.rx.medications,
      patient_id: rxController.rx.patientId,
      provider_id: rxController.rx.providerId,
    };

    addRx(rxFinal)
      .then((res) => {
        if (res.data.message === "The referral has been added successfully") {
          setMsg(res.data.message);
          ProviderAddRxController.setState({
            StepperController: {
              ...rxController,
              completeflag: { ...rxController.completeflag, Review: true },
            },
          });
        }
      })
      .catch((err) => {
        setMsg(err.response.data.message);
      });
  };

  return (
    <>
      <Grid xs={12}>
        {msg !== "" && msg}
        <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  textAlign: "center",
                  backgroundColor: "#a8beda",
                  fontSize: "large",
                }}
              >
                Provider Information
              </ListSubheader>
            }
          >
            <ListItemText
              primary={`Provider ID : ${rxController.rx.providerId}`}
            />
            <ListItemText
              primary={`Provider Name : ${rxController.info.provider_name}`}
            />
          </List>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  textAlign: "center",
                  backgroundColor: "#a8beda",
                  fontSize: "large",
                }}
              >
                Patient Information
              </ListSubheader>
            }
          >
            <ListItemText
              primary={`Patient ID : ${rxController.rx.patientId}`}
            />
            <ListItemText
              primary={`Patient Name : ${rxController.info.patient_name}`}
            />
          </List>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  textAlign: "center",
                  backgroundColor: "#a8beda",
                  fontSize: "large",
                }}
              >
                Prescription Information
              </ListSubheader>
            }
          >
            <ListItemText
              primary={`Therapy Type : ${rxController.rx.therapy}`}
            />
            <Divider />
            <ListItemText primary={`ICD10 : ${rxController.rx.ICD10}`} />
            <Divider />
            <ListItemText primary={`Diagnose : ${rxController.rx.diagnose}`} />
            <Divider />
            <ListItemText primary={`Clinical Info : `} />
            <Box
              component="div"
              sx={{ border: "1px dashed #a8beda", paddingLeft: "10px" }}
            >
              <List>
                {rxController.rx.clinicalinfo.map((i) => {
                  return (
                    <ListItemText primary={`${i.question} : ${i.answer}`} />
                  );
                })}
              </List>
            </Box>
            <Divider sx={{ marginTop: "10px" }} />
            <ListItemText primary={`Medications : `} />
            <Box
              component="div"
              sx={{ border: "1px dashed #a8beda", paddingLeft: "10px" }}
            >
              <List>
                {rxController.rx.medications.map((i) => {
                  return (
                    <>
                      <ListItemText primary={`Medication Name : ${i.name}`} />
                      <ListItemText primary={`SIG : ${i.sig}`} />
                      <ListItemText
                        primary={`Medication Dosage Form : ${i.type}`}
                      />
                      <ListItemText
                        primary={`Number of refills : ${i.refills}`}
                      />
                      <ListItemText
                        primary={`Quantity Prescribed  : ${i.quantity}`}
                        sx={{ marginBottom: "30px" }}
                      />
                    </>
                  );
                })}
              </List>
            </Box>
          </List>
        </Paper>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handelAdd}
          sx={{ float: "right", marginTop: "10px" }}
        >
          ADD PRESCRIPTION
        </Button>
      </Grid>
    </>
  );
}

export default Review;
