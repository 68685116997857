import React from "react";
import DocsUserType from "./DocsUserType";
import PatientDocsStatus from "./PatientDocsStatus";
import UserDocsStatus from "./UserDocsStatus";
import ProviderDocsStatus from "./ProviderDocsStatus";

const DocsCharts = (props) => {
  const { data } = props;

  return (
    <div className="card">
    <div className="row">
      <div className="col-12 col-lg-6 ">
        <DocsUserType responses={data} />
      </div>
      <div className="col12 col-lg-6">
        <PatientDocsStatus responses={data} />
      </div>
      <div className="col-12 col-lg-6">
        <UserDocsStatus responses={data} />
      </div>
      <div className="col-12 col-lg-6">
        <ProviderDocsStatus responses={data} />
      </div>
    </div>
    </div>
  ); 
};

export default DocsCharts;
