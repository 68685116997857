import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { generateCode, getCodes } from "../../services/PharmacyAdmin";

const ManageSignupCodes = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [inputs, setInputs] = useState({
    length: "",
    userType: "",
  });
  const [msg, setMsg] = useState("");
  const [codesList, setCodesList] = useState([]);

  const hendleGetAllCodes = () => {
    getCodes(pharmacy_id)
      .then((res) => {
        setCodesList(
          res.data.data.map((code, ind) => {
            code.id = ind + 1;
            return code;
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setMsg(err?.response?.data?.message || "");
      });
  };

  useEffect(() => {
    hendleGetAllCodes();
  }, []);

  const handelChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      editable: true,
    },
    {
      field: "userType",
      headerName: "Code For",
      width: 150,
      editable: true,
    },
  ];

  const handleGenerateCode = (e) => {
    e.preventDefault();
    setMsg("");
    if (isNaN(inputs.length) || inputs.length <= 0 || inputs.length === "") {
      setMsg("Please enter a valid code length.");
    } else if (inputs.userType === "Code For..." || inputs.userType === "") {
      setMsg("Please select a user type.");
    } else {
      generateCode({
        pharmacyID: pharmacy_id,
        userType: inputs.userType,
        number: inputs.length,
      })
        .then((res) => {
          if (res.data.messages) {
            setMsg(res.data.messages);
            hendleGetAllCodes();
          }
        })
        .catch((err) => {
          setMsg("Something went wrong.");
        });
    }
  };
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Register Codes Management</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">
                Register Codes Management
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                {msg !== "" && msg}
                <Box>
                  <form className="row row-cols-lg-auto g-3 align-items-center mb-2">
                    <div className="col-12">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername">
                        Number of codes
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="inlineFormInputGroupUsername"
                          placeholder="Number of codes"
                          onChange={handelChange}
                          value={inputs.length}
                          name="length"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormSelectPref">
                        Code For
                      </label>
                      <select
                        className="form-select"
                        id="inlineFormSelectPref"
                        onChange={handelChange}
                        value={inputs.userType}
                        name="userType"
                        defaultValue="Code For...">
                        <option value="Code For...">Code For...</option>
                        <option value="User">Pharmacy User</option>
                        <option value="Provider">Provider</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={handleGenerateCode}>
                        Generate
                      </button>
                    </div>
                  </form>
                  <div className="dg_1liner">
                    <DataGrid
                      rows={codesList}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 100,
                          },
                        },
                      }}
                      slots={{ toolbar: GridToolbar }}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageSignupCodes;
