import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {
  getAllPharmacyUsers,
  getUserRole,
  getAllRoles,
  handelAddRoleToUser,
  createNewUser,
  handelAddRoleToNewUser,
} from "../../services/PharmacyAdmin";
import RenderCellImage from "../../components/common/dataGrids/RenderCellImage";
import EditUser from "./../../components/PharmacyAdmin/AdminNavs/EditUser";

const ManageUser = () => {
  // ========================================== Material UI +++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = users.find((user) => user.id === id);
    if (editedRow.isNew) {
      setUsers(users.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    if (newRow.Role === "No role added!") {
      setMsg("Please select a valid user role.");
    } else if (newRow.roleId === 0) {
      let currentRole = roles.find((role) => role.name === newRow.Role);

      handelAddRoleToNewUser({
        roleID: currentRole.ID,
        userID: newRow.userID,
      })
        .then((res) => {
          setMsg(res.data.message);
          handelGetAllUsers();
        })
        .catch((err) => {
          console.error(err);
          setMsg(err.response.data.message);
        });
    } else {
      let currentRole = roles.find((role) => role.name === newRow.Role);

      handelAddRoleToUser(newRow.userID, {
        roleID: currentRole.ID,
      })
        .then((res) => {
          setMsg(res.data.message);
          handelGetAllUsers();
        })
        .catch((err) => {
          console.error(err);
          setMsg(err.response.data.message);
        });
    }
    setUsers(users.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // ========================================== Material UI Ends ++++++++++++++++++++++++++++++++++++++++++++

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [users, setUsers] = useState([]);
  const [msg, setMsg] = useState("");
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
  });
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [currentUserInfo, setCurrentUserInfo] = useState({});

  const handleCancelEdit = () => {
    setSelectedUserID(null);
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 50, maxWidth: 50 },
    {
      headerName: "Picture",
      field: "pic_url",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => RenderCellImage(params),
    },
    {
      field: "first",
      headerName: "First Name",
      minWidth: 100,
      width: 120,
    },
    {
      field: "last",
      headerName: "Last Name",
      minWidth: 100,
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100,
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 100,
      width: 120,
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 100,
      width: 100,
    },
    {
      field: "Role",
      headerName: "Role",
      type: "singleSelect",
      valueOptions: roles.map((role) => {
        return role.name;
      }),
      minWidth: 180,
      width: 180,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 300,
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(params.id)}
              color="inherit"
            />
            EDIT ROLE
          </>,
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="editProfile"
              className="textPrimary"
              onClick={() => {
                setSelectedUserID(params.id);
                setCurrentUserInfo(params.row);
              }}
              color="inherit"
            />
            EDIT USER
          </>,
        ];
      },
    },
  ];

  const handelGetAllUsers = () => {
    getAllPharmacyUsers(pharmacy_id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let usersDta = res.data.data.map((user) => {
            return getUserRole(user.id)
              .then((resp) => {
                if (resp.status === 200) {
                  user.Role = resp.data.data[0].name;
                  user.Status = user.active ? "Active" : "Inactive";
                  user.roleId = resp.data.data[0].roleID;
                  return user;
                } else {
                  user.Role = resp.response.data.message;
                  user.Status = user.active ? "Active" : "Inactive";
                  user.roleId = 0;
                  return user;
                }
              })
              .catch((err) => {
                user.Role = err.response.data.message;
                user.Status = user.active ? "Active" : "Inactive";
                user.roleId = 0;
                return user;
              });
          });
          Promise.all(usersDta).then((res) => {
            setUsers(res);
          });
        } else {
          setMsg(res.response.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setMsg(err.response.data.message);
      });
  };

  useEffect(() => {
    handelGetAllUsers();
    getAllRoles(pharmacy_id)
      .then((res) => {
        res.data.data.push({ name: "No role added!" });
        setRoles(res.data.data);
      })
      .catch((err) => {
        setMsg(err?.response?.data?.message || "");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  const handleChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
      pharmacyID: pharmacy_id,
    });
  };

  const handleCreateUser = (e) => {
    e.preventDefault();

    createNewUser(newUser)
      .then((res) => {
        setMsg(res.data.message);
        setNewUser({
          first: "",
          last: "",
          email: "",
          phone: "",
        });
        handelGetAllUsers();
      })
      .catch((err) => {
        setMsg(err.response.data.message);
      });
  };

  const handleUpdateUser = (updatedUserInfo) => {
    setUsers(
      users.map((user) => {
        return user.id === updatedUserInfo.id
          ? { ...user, ...updatedUserInfo }
          : user;
      })
    );
  };

  return !selectedUserID ? (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Pharmacy Users</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools </li>
              <li className="breadcrumb-item active">Manage Pharmacy Users</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                {msg !== "" && msg}
                <Box>
                  <form className="row row-cols-lg-auto g-3 align-items-center mb-2">
                    <div className="col col-12 col-md-6">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername1">
                        First Name
                      </label>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroupUsername1"
                          placeholder="First Name"
                          name="first"
                          value={newUser.first}
                        />
                      </div>
                    </div>

                    <div className="col col-12 col-md-6">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername2">
                        Last Name
                      </label>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroupUsername2"
                          placeholder="Last Name"
                          name="last"
                          value={newUser.last}
                        />
                      </div>
                    </div>

                    <div className="col col-12 col-md-6">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername3">
                        Email Address
                      </label>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="email"
                          className="form-control"
                          id="inlineFormInputGroupUsername3"
                          placeholder="Email Address"
                          name="email"
                          value={newUser.email}
                        />
                      </div>
                    </div>

                    <div className="col col-12 col-md-6">
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername4">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="number"
                          className="form-control"
                          id="inlineFormInputGroupUsername4"
                          placeholder="Phone Number"
                          name="phone"
                          value={newUser.phone}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <button
                        type="submit"
                        disabled={
                          !newUser.first ||
                          !newUser.last ||
                          (!newUser.email && !newUser.phone)
                        }
                        className="btn btn-success"
                        onClick={handleCreateUser}>
                        Create User
                      </button>
                    </div>
                  </form>
                  <div className="dg_2liner">
                    <DataGrid
                      rows={users}
                      columns={columns}
                      editMode="row"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      loading={users === 0}
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <EditUser
      userID={selectedUserID}
      handleCancelEdit={handleCancelEdit}
      handleUpdateUser={handleUpdateUser}
      userInfo={currentUserInfo}
    />
  );
};

export default ManageUser;
