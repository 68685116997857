import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AverageTurnAroundTimeChart = (props) => {
  const { title, type, processed } = props;

  const intialData = {
    series: [
      {
        name: "average",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: title || "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 10,
              offsetY: 10,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        type: "date",
        categories: [],
      },
      legend: {
        position: "right",
        offsetY: 20,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const [data, setData] = useState(intialData);

  useEffect(() => {
    setData({
      series: [
        {
          data: processed.map((item) => item.y),
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
        },
        title: {
          text: "Average Turn Around time in Hours",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "top",
                offsetX: 10,
                offsetY: 10,
              },
            },
          },
        ],
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#0c5f8f",
          "#f45b5b",
          "#13ac9f",
          "#f7a35c",
          "#5b9ad6",
          "#90ed7d",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#2b9e77",
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30rem",
            borderRadius: 7,
            distributed: true,
          },
        },
        xaxis: {
          type: "text",
          categories: processed.map((item) => item.x),
        },

        legend: {
          position: "left",
          offsetY: 25,
          showForSingleSeries: true,
          formatter: function (seriesName, opts) {
            return [seriesName + ` (${processed[opts.seriesIndex].y})`];
          },
        },
        fill: {
          opacity: 0.9,
        },
        animation: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    });
  }, [processed, title]);

  return (
    <>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type={type || "bar"}
        height={350}
      />
    </>
  );
};

export default AverageTurnAroundTimeChart;
