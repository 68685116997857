import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";


const GastroenterologyZinplava = (props) => {
  // No Changes for the state

  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);

  useEffect(() => {
      setRxController(stateObj);
    }, [stateObj]);

  const [QA, setQA] = useState({});
  const [rx, setRx] = useState({
    clinicalinfo: [],
  });

  // No Changes  handleChange

  const handleChange = (event) => {
    setQA({ ...QA, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (QA) {
      var arr = Object.keys(QA).map(function (key) {
        return { [key]: QA[key] };
      });

      const ms = arr.map((x) => {
        for (const [key, value] of Object.entries(x)) {
          return { question: key, answer: value };
        }
      });
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: ms },
          completeflag: { ...rxController.completeflag, Clinical: true },
        },
      });
    } else if (!QA) {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: [] },
          completeflag: { ...rxController.completeflag, Clinical: false },
        },
      });
    }

  
  };

  return (

        <div className="container container_alzheimer">
          <form onSubmit={handleSubmit} className="mb-3 col-12 col-lg-8">
            {/* 3. INDICATION */}
            <h3 className="gas_h3 p-3 fs-3"> Zinplava / Indication </h3>
            <div className="col-md-12">
              <label className="form-label form-control">
                {" "}
                Enterocolitis due to C difficile recurrent: <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Affected Area1"
                    defaultValue="Indication"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Affected Area1"
                    defaultValue="Indication"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="no">
                    No
                  </label>
                </div>
              </label>
            </div>
            <div className="col-md-12">
              <label className="form-label form-control">
                {" "}
                Enterocolitis due to C difficile: <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Affected Area2"
                    defaultValue="Indication"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="Yes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Affected Area2"
                    defaultValue="Indication"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="No">
                    No
                  </label>
                </div>
              </label>
            </div>
            <div className="col-md-12">
              <label className="form-label form-control">
                {" "}
                <strong>PMH:</strong> CHF, HF <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="PMH"
                    defaultValue="Yes"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="html">
                    {" "}
                    Yes{" "}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="PMH"
                    defaultValue="No"
                    onChange={handleChange}
                  />
                  <label htmlFor="css"> No </label>
                </div>
              </label>
            </div>
            <div className="col-md-12">
              <label className="form-label form-control">
                Age: Greater than or equal to ≤18 years of age <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Age"
                    defaultValue="Age"
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label className="form-check-label">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="Age"
                    defaultValue="Age"
                    onChange={handleChange}
                  />
                  &nbsp;
                  <label className="form-check-label">No</label>
                </div>
              </label>
            </div>
            <div className="d-grid gap-2 col-md-12 mx-auto">
              <button className="btn btn-outline-primary btn_ast" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>

  );
};
export default GastroenterologyZinplava;
