import React, { useState, useEffect } from "react";
import { CreateNewCarePlan } from "../../../../utils/globalState";
import ChooseInitForm from "./ChooseInitForm";
import CpFormBuilder from "../CpFormBuilder";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { handleGetInitForms } from "../../../../services/CarePlan";
import { decodeToken } from "react-jwt";
import { useModal } from "react-hooks-use-modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import RruleUI from "../rrulegenerator/RruleStringGen";
const SelectInitForm = (props) => {
  const { privileges } = props;

  const stateObj = CreateNewCarePlan((state) => state.careplanController);
  const pharmacyId = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const [addFormflag, setAddFormflag] = useState(false);
  const [msg, setMsg] = useState("");
  const [forms, setForms] = useState([]);
  const [form, setForm] = useState({});
  const [userInput, setUserInput] = useState({
    title: "",
    type: "",
  });
  const [date, setDate] = useState(null);
  const [Modal, openModal, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  useEffect(() => {
    if (!stateObj.info.init_form.init_call_time) {
      setDate(dayjs());
      CreateNewCarePlan.setState({
        careplanController: {
          ...stateObj,
          info: {
            ...stateObj.info,
            init_form: { ...stateObj.info.init_form, init_call_time: dayjs() },
          },
        },
      });
    } else {
      setDate(dayjs(stateObj.info.init_form.init_call_time));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    CreateNewCarePlan.setState({
      careplanController: {
        ...stateObj,
        completeflag: {
          ...stateObj.completeflag,
          init_form: form?.json?.title && date ? true : false,
        },
        info: {
          ...stateObj.info,
          init_form: { ...stateObj.info.init_form, init_call_time: date },
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleSelectForm = (form) => {
    if (!form) {
      return;
    }
    form.init_call_time = date;
    setForm(form);
    setMsg("");
    if (date === null) {
      setMsg("Please select date");
    }
    CreateNewCarePlan.setState({
      careplanController: {
        ...stateObj,
        completeflag: {
          ...stateObj.completeflag,
          init_form: form?.json?.title && form.init_call_time ? true : false,
        },
        info: {
          ...stateObj.info,
          init_form: form,
        },
      },
    });
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);
    setForm({ ...form, init_call_time: newValue });
  };
  useEffect(() => {
    handleGetInitForms(pharmacyId)
      .then((res) => {
        setForms(
          res.data.map((form) => {
            form.isSent = form.isSent === 1 ? true : false;
            form.isHidden = form.isHidden === 1 ? true : false;
            form.json = JSON.parse(form.formText);
            return form;
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setForms([]);
      });
  }, [pharmacyId]);

  const handleShowCreator = () => {
    setAddFormflag(!addFormflag);
  };

  const handleCreate = () => {
    setMsg("");
    if (userInput.title !== "") {
      if (forms.some((form) => form.json.title === userInput.title)) {
        setMsg("Form with this title already exists");
        return;
      }
      setForm({
        isSent: false,
        isInitForm: true,
        json: {
          title: userInput.title,
        },
      });
      setAddFormflag(true);
      close();
      setUserInput({ title: "" });
      setMsg("");
    } else {
      setMsg("Please fill in all the fields");
    }
  };

  const handleTitleChange = (event) => {
    setUserInput({ ...userInput, [event.target.name]: event.target.value });
  };

  const handleSuccess = (success) => {
    if (success) {
      setMsg("Form added successfully");
      setAddFormflag(false);
    } else {
    }
  };

  return (
    <div className="row">
      {addFormflag ? (
        <>
          <div className="col-12 mb-3">
            <CpFormBuilder
              form={form}
              userID={userID}
              creatorType="pharmacy"
              setShowCreator={handleShowCreator}
              setForm={handleSelectForm}
              handleSuccess={handleSuccess}
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 text-center pt-3 pb-2 w-100 fw-bold d-flex justify-content-center ">
                <h3 className="text-center">
                  Please Select The Initial Call Time and Date
                </h3>
              </div>
              <div
                className="col-12 d-flex justify-content-center"
                style={{ maxWidth: "unset" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker onChange={handleDateChange} value={date} />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 mb-3 ">
            <Divider> AND THEN </Divider>
          </div>
          <div className="col-12 ">
            <ChooseInitForm setForm={handleSelectForm} />
          </div>

          {["admin", "careplan forms"].some((priv) =>
            privileges.includes(priv)
          ) && (
            <>
              <div className="col-12 mt-3 mb-3 ">
                <Divider> OR </Divider>
              </div>
              <div className="col-12 d-flex justify-content-center  ">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => openModal()}>
                  Add New Form
                </Button>
              </div>
            </>
          )}

          <div className="col-12">
            <div className="row">
              <div className="col-12 d-flex justify-content-center pt-3 ">
                <p className="text-center text-success">{msg}</p>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal>
        <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
          {msg !== "" && <p className="text-danger">{msg}</p>}
          <div className="card-header bg-transparent fw-bold text-center">
            Create New Form
          </div>
          <div className="card-body">
            {/* <h5 className="card-title">Success card title</h5> */}
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Form Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={handleTitleChange}
                  value={userInput.title}
                  name="title"
                />
              </div>
            </form>
          </div>
          <div className="card-footer bg-transparent text-center">
            <button
              type="button"
              className="btn btn-danger mx-2 mx-2"
              data-bs-dismiss="modal"
              onClick={() => close()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2 mx-2"
              onClick={handleCreate}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectInitForm;
