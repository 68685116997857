import React, { useState, useEffect } from "react";
import {  ProviderAddRxController } from "../../../../../utils/globalState";


const HIVAIDS = (props) => {
  // No Changes for the state

  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);

  useEffect(() => {
      setRxController(stateObj);
    }, [stateObj]);

  const [QA, setQA] = useState({});
  const [rx, setRx] = useState({
    clinicalinfo: [],
  });

  const handleChange = (event) => {
    setQA({ ...QA, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (QA) {
      var arr = Object.keys(QA).map(function (key) {
        return { [key]: QA[key] };
      });

      const ms = arr.map((x) => {
        for (const [key, value] of Object.entries(x)) {
          return { question: key, answer: value };
        }
      });
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: ms },
          completeflag: { ...rxController.completeflag, Clinical: true },
        },
      });
    } else if (!QA) {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, clinicalinfo: [] },
          completeflag: { ...rxController.completeflag, Clinical: false },
        },
      });
    }

  
  };

  return (

          <div className="container container_hiv">
            <form onSubmit={handleSubmit} className="mb-3 col-12 col-lg-8">
              {/* 3. Diagnosis/Clinical Information */}
              <h3 className="hiv_h3 p-3 fs-3">
                {" "}
                HIV-AIDS / Clinical Information{" "}
              </h3>
              <div className="form-control mb-2">
                <label className="form-label">
                  {" "}
                  <strong>Diagnosis:</strong>
                </label>
                <textarea
                  name="Diagnosis:"
                  className="form-control"
                  size="20"
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mb-2">
                <label className="form-label"> ICD-10: </label> &nbsp;
                <input
                  type="text"
                  className="form-control-sm"
                  name="ICD-10:"
                  size="10"
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mb-2">
                <label className="form-label"> Serum Creatinine: </label> &nbsp;
                <input
                  type="text"
                  className="form-control-sm"
                  name="Serum Creatinine:"
                  size="20"
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mb-2">
                <label className="form-label"> CD4 Count: </label> &nbsp;
                <input
                  type="text"
                  className="form-control-sm"
                  name="CD4 Count:"
                  size="20"
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mb-2">
                <label className="form">Viral Load:</label> &nbsp;
                <input
                  type="text"
                  name="Viral Load:"
                  size="20"
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mb-2">
                <label> Date of labs: </label> &nbsp;
                <input
                  type="date"
                  name="Date of labs:"
                  onChange={handleChange}
                />
              </div>
              <div className="d-grid gap-2 col-md-12 mx-auto">
                <button
                  className="btn btn-outline-primary btn_ast"
                  type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>

  );
};
export default HIVAIDS;
