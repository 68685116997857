import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { convertTime } from "../../utils/timeConverter";
import { useParams, useNavigate } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Box from "@mui/material/Box";
import { Buffer } from "buffer";
import CircularProgress from "@mui/material/CircularProgress";
import PDFViewer from "../../components/pharmacyUser/documentsCenter/PDFViewer";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import {
  handleGetDocFiles,
  handleDocResponse,
  getResponseById,
} from "../../services/PharmacyUser";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const DocSignPage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const { docResponseId } = useParams();

  const navigate = useNavigate();

  const [currentResponse, setCurrentResponse] = useState({});
  const [base64pdf, setBase64pdf] = useState("");
  const [json, setJson] = useState({});

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  useEffect(() => {
    getResponseById(docResponseId)
      .then((res) => {
        const item = res.data[0];
        const updatedItem = {
          ...item,
          isStop: item.isStop === 1 ? true : false,
          formText: item.formText ? JSON.parse(item.formText) : "",
          value: item.value ? JSON.parse(item.value) : "",

          senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
          recipientInfo: item.recipientInfo
            ? JSON.parse(item.recipientInfo)
            : "",
          sender: item.senderInfo
            ? JSON.parse(item.senderInfo).first +
              " " +
              JSON.parse(item.senderInfo).last
            : "",
          recipientName: item.recipientInfo
            ? JSON.parse(item.recipientInfo).first +
              " " +
              JSON.parse(item.recipientInfo).last
            : "",

          recipientType: item.recipientInfo
            ? JSON.parse(item.recipientInfo).type
            : "",
          MRN: item.recipientInfo
            ? JSON.parse(item.recipientInfo).MRN || "N/A"
            : "",
          formName: item.formText ? JSON.parse(item.formText).title : "",

          isArchived: item.isArchived === 1 ? true : false,
          archived_date: item.archived_date
            ? convertTime(item.archived_date)
            : "",
          isReviewed: item.isReviewed === 1 ? true : false,
          reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
          completion: item.completion ? convertTime(item.completion) : "",
          sent: item.sent ? convertTime(item.sent) : "",
          dateOfLastReminder: item.dateOfLastReminder
            ? convertTime(item.dateOfLastReminder)
            : "",
        };

        setCurrentResponse(updatedItem);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [docResponseId]);

  useEffect(() => {
    if (Object.keys(currentResponse).length === 0) return;
    const jsonRaw = handleObjectRewrite(currentResponse);
    jsonRaw.pages[0].elements[1].title =
      currentResponse.status.toLowerCase() === "completed"
        ? "Signature"
        : "Please Sign Here";
    setJson(jsonRaw);
  }, [currentResponse]);

  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let htmlq = json.pages[0].elements.find((item) => item.type === "html");

      if (htmlq) {
        let url = htmlq.name;
        let filename = url.split("/")[url.split("/").length - 1];

        const existingPdfBytes = handleGetDocFiles(filename).then((res) => {
          return Buffer.from(res.data.file.data, "base64");
        });
        existingPdfBytes.then((res) => {
          setBase64pdf(`data:application/pdf;base64,${res.toString("base64")}`);
        });
      }
    }
  }, [json]);

  if (Object.keys(json).length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}>
        <CircularProgress />
      </Box>
    );
  } else {
    json.pages[0].elements.find(
      (item) => item.type === "html"
    ).html = ` <p>Unable to display PDF file?. <a href="${
      json.pages[0].elements.find((item) => item.type === "html").name
    }" target="_blank">Download</a> instead.</p>`;

    const survey = new Model(json);
    survey.mode =
      currentResponse.status.toLowerCase() === "completed" ? "display" : "edit";
    survey.completedHtml = `<div style="text-align: center; margin-top: 20px; margin-bottom: 20px;"><h3>Thank you for signing the document.</h3></div>`;
    survey.onComplete.add((sender, options) => {
      handleDocResponse({ value: sender.data }, docResponseId).catch((err) =>
        console.error(err)
      );
    });

    return (
      <div className={!isDivVisible ? "toggle-sidebar" : ""}>
        <main id="main" className="main">
          <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <div className="container-fluid">
                <div>
                  <button
                    className="btn btn-success col-auto mb-1"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Go Back
                  </button>
                  {Object.keys(currentResponse).length > 0 ? (
                    <div>
                      <div style={{ maxWidth: "65%" }}>
                        <PDFViewer pdf={base64pdf} />
                        <Survey model={survey} />
                      </div>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <h3 className="text-center mt-5">
                        Loading Data, Please wait...
                      </h3>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default DocSignPage;
