import React, { useState, useEffect } from "react";
import { decodeToken } from "react-jwt";
import { useModal } from "react-hooks-use-modal";
import { useSearchParams } from "react-router-dom";
import { SideBar, CreateNewCarePlan } from "../../../utils/globalState";
import CpCreator from "../../../components/pharmacyUser/CarePlanCenter/CpCreator";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import CpFollowUp from "../../../components/pharmacyUser/CarePlanCenter/CpFollowUp";
import { handleDeleteEventWithAllFollowings } from "../../../services/Calendar";
import {
  getUserById,
  getUserRole,
  getUserPrivilege,
} from "../../../services/Common";
import {
  handleGetCarePlans,
  updateCpOptOutByPatientId,
  handleDeleteCarePlan,
  handleUpdateCarePlan,
} from "../../../services/CarePlan";

const CarePlanManager = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const resetCarePlan = CreateNewCarePlan((state) => state.resetCarePlan);

  const pharmacyId = Number(localStorage.getItem("pharmacy"));
  const [showCreator, setShowCreator] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [cpList, setCpList] = useState([]);
  const [currentCp, setCurrentCp] = useState({});
  const [columnDef, setColumnDef] = useState([]);
  const [msg, setMsg] = useState({ text: "", type: "" });
  const [currentCpId, setCurrentCpId] = useState(null);
  const [privileges, setPrivileges] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  let location = useLocation();
  let path = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const [DeleteModal, openDeleteModal, closeDeleteModal] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
    closeOnEscape: false,
  });

  const [OptOutModal, openOptOutModal, closeOptOutModal, isOptOutModalOpen] =
    useModal("root", {
      preventScroll: true,
      closeOnOverlayClick: false,
      closeOnEscape: false,
    });

  useEffect(() => {
    getUserById(userID)
      .then(async (res) => {
        const allPrivileges = [];
        const user = res.data.data.user[0];

        if (user.type.toLowerCase().includes("admin")) {
          setPrivileges(["admin"]);
          allPrivileges.push("admin");
        } else {
          const role = await getUserRole(userID);
          const roleId = role.data.data[0].roleID;

          getUserPrivilege(roleId).then((privRes) => {
            const userPrivs = privRes.data.data.map((priv) =>
              priv.name.toLowerCase()
            );
            setPrivileges(userPrivs);
            allPrivileges.push(...userPrivs);
          });
        }

        return allPrivileges;
      })
      .then((allPrivileges) => {
        setColumnDef([
          {
            field: "id",
            headerName: "ID",
            sortable: true,
            minWidth: 25,
          },
          {
            field: "Patient Name",
            headerName: "Patient Name",
            sortable: true,
            minWidth: 200,
            valueGetter: (params) =>
              `${params.row.recipient.first} ${params.row.recipient.last} `,
          },
          {
            field: "createdOn",
            headerName: "Created Date",
            sortable: true,
            minWidth: 250,
            valueGetter: (params) =>
              dayjs(params.row.createdOn).format("ddd, MMM D, YYYY h:mm A"),
            sortComparator: (v1, v2) => {
              return new Date(v2).getTime() - new Date(v1).getTime();
            },
          },
          {
            field: "Created By",
            headerName: "Created By",
            sortable: true,
            minWidth: 150,
            valueGetter: (params) =>
              params.row?.creator?.first + " " + params.row?.creator?.last,
          },
          {
            field: "Initial Form Title",
            headerName: "Initial Form Title",
            sortable: true,
            minWidth: 150,
            valueGetter: (params) =>
              JSON.parse(params.row?.initForm?.formText)?.title,
          },
          {
            field: "Follow Up Form Title",
            headerName: "Follow Up Form Title",
            sortable: true,
            minWidth: 150,
            valueGetter: (params) =>
              JSON.parse(params.row?.fuForm?.formText)?.title,
          },
          {
            field: "Status",
            headerName: "Status",
            sortable: true,
            minWidth: 150,
            valueGetter: (params) =>
              params.row.initCompletedOn === null
                ? "Initial Form Not Completed"
                : "Initial Form Completed",
          },
          {
            field: "Actions",
            headerName: "Actions",
            sortable: false,
            minWidth: 450,
            renderCell: (params) => (
              <div className="d-flex gap-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    navigate(`${params.row.id}?p=cpm`);
                  }}>
                  View
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    params.row.initCompletedOn !== null ||
                    params.row.recipient.cpOptOut
                  }
                  onClick={() =>
                    navigate(
                      `/${path}/cp-center/init-form-fill/?id=${params.row.id}`,
                      {
                        replace: true,
                      }
                    )
                  }>
                  Start Initial Form
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  disabled={params.row.recipient.cpOptOut}
                  onClick={() => {
                    setCurrentCp(params.row);
                    setShowFollowUp(true);
                  }}>
                  Follow Ups
                </button>
                {["admin", "careplan opt out"].some((priv) =>
                  allPrivileges.includes(priv)
                ) && (
                  <button
                    className={`btn btn-sm btn-${
                      params.row.recipient.cpOptOut ? "primary" : "danger"
                    }`}
                    onClick={() => {
                      setCurrentCp(params.row);
                      openOptOutModal();
                    }}>
                    {params.row.recipient.cpOptOut ? "CP Opt In" : "CP Opt Out"}
                  </button>
                )}
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setCurrentCpId(params.row.id);
                    openDeleteModal();
                  }}>
                  Delete
                </button>
              </div>
            ),
          },
        ]);
      })
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const getAllCpsByPharmacy = async () => {
    await handleGetCarePlans(pharmacyId)
      .then((res) => {
        const cpRes = res.data
          .sort((a, b) => {
            return new Date(b.createdOn) - new Date(a.createdOn);
          })
          .map((cp) => {
            return {
              ...cp,
              initForm: {
                ...cp.initForm,
                json: cp.initForm.formText.length
                  ? JSON.parse(cp.initForm.formText)
                  : {},
              },
              fuForm: {
                ...cp.fuForm,
                json: cp.fuForm.formText.length
                  ? JSON.parse(cp.fuForm.formText)
                  : {},
              },
            };
          })
          .filter((cp) => !cp.completedOn);

        setCpList(cpRes);
      })
      .catch((err) => {
        console.error(err);
        setCpList([]);
      });
  };

  useEffect(() => {
    getAllCpsByPharmacy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  const handleSetCurrentCP = (cpid) => {
    getAllCpsByPharmacy();
    setCurrentCp(cpList.find((cp) => cp.id === cpid));
  };

  async function handleOptOutClick(row) {
    if (!row.recipient.cpOptOut && row.fuEventId) {
      await handleDeleteEventWithAllFollowings(row.fuEventId, userID)
        .then(() => {
          handleUpdateCarePlan(row.id, { fuEventId: null });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const payload = { cpOptOut: !row.recipient.cpOptOut };

    await updateCpOptOutByPatientId(row.recipientID, payload)
      .then(() => {
        getAllCpsByPharmacy();
        setMsg({
          text: "Opt Out Status Updated Successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        setMsg({
          text: err.response?.data?.message || "Error Updating Opt Out Status",
          type: "danger",
        });
      })
      .finally(() => {
        setCurrentCp({});
        closeOptOutModal();
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 3000);
      });
  }

  async function handleDeleteCP(cpid) {
    await handleDeleteCarePlan(cpid)
      .then(() => {
        getAllCpsByPharmacy();
        setMsg({ text: "Care Plan Deleted Successfully", type: "success" });
      })
      .catch((err) => {
        console.error(err);
        setCurrentCpId(null);
        setMsg({
          text: err.response?.data?.message || "Error Deleting Care Plan",
          type: "danger",
        });
      })
      .finally(() => {
        closeDeleteModal();
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 3000);
      });
  }

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Care Plan Manager</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Care Plan</li>
              <li
                className={`breadcrumb-item ${
                  showCreator || showFollowUp ? "cstm_breadcrumb" : "active"
                }`}
                onClick={() => {
                  setShowCreator(false);
                  setShowFollowUp(false);
                  setSearchParams({});
                }}>
                Care Plan Manager
              </li>
              {showCreator && (
                <li className="breadcrumb-item active">Create New Care Plan</li>
              )}
              {showFollowUp && (
                <li className="breadcrumb-item active">Care Plan Follow Ups</li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {msg.text && (
                <div
                  className={`alert alert-${msg.type} alert-dismissible fade show text-center`}
                  role="alert">
                  {msg.text}
                </div>
              )}

              <div className="row">
                {showCreator ? (
                  <CpCreator
                    showCreator={showCreator}
                    setShowCreator={setShowCreator}
                    handleSetCurrentCP={handleSetCurrentCP}
                    carePlans={cpList}
                    privileges={privileges}
                  />
                ) : showFollowUp ? (
                  <CpFollowUp
                    currentCp={currentCp}
                    setCurrentCp={setCurrentCp}
                    setCpList={setCpList}
                    cpList={cpList}
                  />
                ) : (
                  <>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          resetCarePlan();
                          setShowCreator(!showCreator);
                        }}>
                        {" "}
                        Create Care Plan{" "}
                      </button>
                    </div>
                    <div className="col-12 mt-3 mb-3 dg_1liner">
                      <DataGrid
                        rows={cpList}
                        columns={columnDef}
                        slots={{ toolbar: GridToolbar }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                          },
                        }}
                        pageSizeOptions={[5, 100]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <DeleteModal>
        <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
          <div className="card-header bg-transparent fw-bold text-center">
            Delete Care Plan
          </div>
          <div className="card-body">
            <p>
              By clicking confirm, you will permanently delete the careplan and
              all associated events and data.
            </p>
          </div>
          <div className="card-footer bg-transparent text-center">
            <button
              type="button"
              className="btn btn-primary mx-2 mx-2"
              data-bs-dismiss="modal"
              onClick={() => {
                closeDeleteModal();
                setCurrentCpId(null);
              }}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger mx-2 mx-2"
              onClick={() => handleDeleteCP(currentCpId)}>
              Confirm Delete
            </button>
          </div>
        </div>
      </DeleteModal>
      {isOptOutModalOpen && (
        <OptOutModal>
          <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
            <div className="card-header bg-transparent fw-bold text-center">
              Opt {currentCp.recipient.cpOptOut ? "In" : "Out Of"} Care Plan
            </div>
            <div className="card-body">
              {currentCp.recipient.cpOptOut ? (
                <p>
                  By clicking confirm, you will&nbsp;
                  <span className="text-success fw-bold">opt in </span>
                  <span className="fw-bold">
                    &nbsp;
                    {currentCp.recipient.first} {currentCp.recipient.last}
                    &nbsp;
                  </span>
                  for the care plan.
                </p>
              ) : (
                <p>
                  By clicking confirm, you will&nbsp;
                  <span className="text-danger fw-bold">opt out </span>&nbsp;
                  <span className="fw-bold">
                    {currentCp.recipient.first} {currentCp.recipient.last}
                  </span>
                  &nbsp; of the care plan.
                </p>
              )}
            </div>
            <div className="card-footer bg-transparent text-center">
              <button
                type="button"
                className="btn btn-outline-primary mx-5 my-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setCurrentCp({});
                  closeOptOutModal();
                }}>
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-outline-${
                  currentCp.recipient.cpOptOut ? "success" : "danger"
                } mx-5 my-2`}
                onClick={() => handleOptOutClick(currentCp)}>
                Confirm
              </button>
            </div>
          </div>
        </OptOutModal>
      )}
    </div>
  );
};

export default CarePlanManager;
