import React, {useEffect, useState} from 'react'
import { ProviderAddRxController } from "../../../../utils/globalState";
import Alzheimer from "./ClinicalForms/Alzheimer";
import Asthma from "./ClinicalForms/Asthma";
import  AutoimmuneDisorder from"./ClinicalForms/AutoimmuneDisorder";
import Covid19 from"./ClinicalForms/Covid19";
import Dermatology from"./ClinicalForms/Dermatology";
import EosinophilicAsthma from"./ClinicalForms/EosinophilicAsthma";
import Gastroenterology from"./ClinicalForms/Gastroenterology";
import GastroenterologyZinplava from"./ClinicalForms/GastroenterologyZinplava";
import Hematopoietic from"./ClinicalForms/Hematopoietic";
import Hemophilia from"./ClinicalForms/Hemophilia";
import HepatitisB from"./ClinicalForms/HepatitisB";
import HepatitisC from"./ClinicalForms/HepatitisC";
import HepatitisSovaldi from"./ClinicalForms/HepatitisSovaldi";
import HIVAIDS from"./ClinicalForms/HIVAIDS";
import IGAutoimmuneDisorder from"./ClinicalForms/IGAutoimmuneDisorder";
import IGPrimaryImmuneDeficiency from"./ClinicalForms/IGPrimaryImmuneDeficiency";
import ImmuneGlobulin from"./ClinicalForms/ImmuneGlobulin";
import KrystexxaIVInfusionForGout from"./ClinicalForms/KrystexxaIVInfusionForGout";
import LupronDepot from"./ClinicalForms/LupronDepot";
import Migraine from"./ClinicalForms/Migraine";
import MigraineVyepti from"./ClinicalForms/MigraineVyepti";
import MultipleSclerosis from"./ClinicalForms/MultipleSclerosis";
import MultipleSclerosisTecfidera from"./ClinicalForms/MultipleSclerosisTecfidera";
import OncologyIV from"./ClinicalForms/OncologyIV";
import OralOncology from"./ClinicalForms/OralOncology";
import Osteoporosis from"./ClinicalForms/Osteoporosis";
import PrimaryImmuneDeficiency from"./ClinicalForms/PrimaryImmuneDeficiency";
import Rheumatology from"./ClinicalForms/Rheumatology";


const Clinical = () => {
    const stateObj = ProviderAddRxController((state) => state.StepperController);
    const [rxController, setRxController] = useState(stateObj);
    const [clinicalForms, setClinicalForms] = useState([]);

    useEffect(() => {
        setRxController(stateObj);
      }, [stateObj]);

 
  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: "Clinical",
      },
    });
  }, []);

  return (
  <>{rxController.rx.therapy === "Alzheimer" && <Alzheimer /> }
  {rxController.rx.therapy === "Asthma" && <Asthma />}
  {rxController.rx.therapy === "AutoimmuneDisorder" && <AutoimmuneDisorder />}
  {rxController.rx.therapy === "Covid19" && <Covid19 />}
  {rxController.rx.therapy === "Dermatology" && <Dermatology />}
  {rxController.rx.therapy === "EosinophilicAsthma" && <EosinophilicAsthma />}
  {rxController.rx.therapy === "Gastroenterology" && <Gastroenterology />}
  {rxController.rx.therapy === "GastroenterologyZinplava" && <GastroenterologyZinplava />}
  {rxController.rx.therapy === "Hematopoietic" && <Hematopoietic />}
  {rxController.rx.therapy === "Hemophilia" && <Hemophilia />}
  {rxController.rx.therapy === "HepatitisB" && <HepatitisB />}
  {rxController.rx.therapy === "HepatitisC" && <HepatitisC />}
  {rxController.rx.therapy === "HepatitisSovaldi" && <HepatitisSovaldi />}
  {rxController.rx.therapy === "HIVAIDS" && <HIVAIDS />}
  {rxController.rx.therapy === "IGAutoimmuneDisorder" && <IGAutoimmuneDisorder />}
  {rxController.rx.therapy === "IGPrimaryImmuneDeficiency" && <IGPrimaryImmuneDeficiency />}
  {rxController.rx.therapy === "ImmuneGlobulin" && <ImmuneGlobulin />}
  {rxController.rx.therapy === "KrystexxaIVInfusionForGout" && <KrystexxaIVInfusionForGout />}
  {rxController.rx.therapy === "LupronDepot" && <LupronDepot />}
  {rxController.rx.therapy === "Migraine" && <Migraine />}
  {rxController.rx.therapy === "MigraineVyepti" && <MigraineVyepti />}
  {rxController.rx.therapy === "MultipleSclerosis" && <MultipleSclerosis />}
  {rxController.rx.therapy === "MultipleSclerosisTecfidera" && <MultipleSclerosisTecfidera />}
  {rxController.rx.therapy === "OncologyIV" && <OncologyIV />}
  {rxController.rx.therapy === "OralOncology" && <OralOncology />}
  {rxController.rx.therapy === "Osteoporosis" && <Osteoporosis />}
  {rxController.rx.therapy === "PrimaryImmuneDeficiency" && <PrimaryImmuneDeficiency />}
  {rxController.rx.therapy === "Rheumatology" && <Rheumatology />}
  </>




    
  )
}

export default Clinical