import React, { useState, useEffect } from "react";
import MessageArea from "../../components/pharmacyUser/messageCenter/MessageArea";
import { getChannelById } from "../../services/Messages";
import CircularProgress from "@mui/material/CircularProgress";
import { generateToken } from "../../services/Common";
import { decodeToken } from "react-jwt";

export const NewMessages = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const channelId = urlParams.get("id");
  const myid = urlParams.get("r");
  // const pharmacy_id = urlParams.get("s");

  const [incommingFromchannels, setIncommingFromchannels] = useState({});
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState("");

  useEffect(() => {
    generateToken(myid)
      .then((res) => {
        localStorage.setItem("Token", res.data.token);
        setUserID(decodeToken(res.data.token).userId);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [myid]);

  useEffect(() => {
    setLoading(true);
    userID &&
      getChannelById(channelId)
        .then((res) => {
          setIncommingFromchannels(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIncommingFromchannels({});
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, channelId]);

  return (
    <div className="standalone_msgs">
      {loading && <CircularProgress color="success" />}
      {!loading && Object.keys(incommingFromchannels).length === 0 ? (
        <>Something went wrong!!</>
      ) : !loading && Object.keys(incommingFromchannels).length > 0 ? (
        <MessageArea dataFromChannel={incommingFromchannels} />
      ) : (
        <></>
      )}
    </div>
  );
};
