import React, {useEffect, useState} from 'react'
import Accordion from "react-bootstrap/Accordion";

const RxDetails = ({curReferral}) => {
  return (
    <div className="row">
    <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen={true}>
      <div className="referral-header border border-grey py-4 d-flex flex-wrap justify-content-evenly rounded-top">
        <h5 className="mt-1 mx-3  py-md-2">
          <span className="fw-semibold text-white ">PRESCRIPTION ID:</span>{" "}
          <span
            className="fs-5 align-middle fw-semibold text-white"
            style={{ position: "relative", top: "-2px" }}>
            {curReferral.id}
          </span>
        </h5>
        <h5 className="mt-1 mx-3 py-md-2">
          <span className="fw-semibold text-white">CREATED AT:</span>{" "}
          <span
            className="fs-6 align-middle fw-semibold text-white"
            style={{ position: "relative", top: "-1px" }}>
            {curReferral.created_at}
          </span>
        </h5>
      </div>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span className="fw-semibold ">Patient Details</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="col-12 d-flex justify-content-between flex-wrap">
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> Patient Id: </span>
              {curReferral.patient?.id ? curReferral.patient.id : ""}
            </p>
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> Name: </span>
              {curReferral.patient?.first
                ? curReferral.patient.first
                : ""}{" "}
              {curReferral.patient?.last ? curReferral.patient.last : ""}
            </p>
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> DOB: </span>
              {curReferral.patient?.DOB ? curReferral.patient.DOB : ""}
            </p>
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> Gender: </span>
              {curReferral.patient?.gender
                ? curReferral.patient.gender
                : ""}
            </p>
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> Email: </span>
              {curReferral.patient?.email
                ? curReferral.patient.email
                : "N/A"}
            </p>
            <p className="col col-12 col-sm-6">
              <span className="fw-semibold fc-info"> Phone: </span>
              {curReferral.patient?.phone
                ? curReferral.patient.phone
                : "N/A"}
            </p>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <span className="fw-semibold ">Diagnostic</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="col-12 d-flex justify-content-between flex-wrap">
            <p className="col col-12 col-sm-6 pe-sm-5">
              <span className="fw-semibold fc-info"> Diagnostic: </span>
              <br />
              {curReferral?.Diag?.length && curReferral.Diag[0].diagnose}
            </p>
            <p className="col col-12 col-sm-6 mt-2 mt-sm-0">
              <span className="fw-semibold fc-info"> ICD10: </span>
              <br />
              {curReferral?.Diag?.length && curReferral.Diag[0].ICD10}
            </p>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <span className="fw-semibold ">Clinical Information</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row d-flex flex-row flex-wrap justify-content-start justify-items-left ">
            {curReferral?.clinical?.length > 0 ? (
              curReferral.clinical.map((el, idx) => {
                return (
                  <p
                    key={idx}
                    className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                    <span className="fw-semibold fc-info">
                      {" "}
                      {el.question}{" "}
                    </span>
                    <br />
                    {el.answer}
                  </p>
                );
              })
            ) : (
              <p>No Clinical Information</p>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <span className="fw-semibold ">Medications</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="d-flex flex-column gap-4">
            {curReferral?.medications?.length > 0 ? (
              curReferral.medications.map((el, idx) => {
                return (
                  <div className="row border border-grey rounded" key={idx}>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info"> S/N: </span>
                      <br />
                      {idx + 1}
                    </p>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info">
                        {" "}
                        Medication Name:{" "}
                      </span>
                      <br />
                      {el.name}
                    </p>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info"> SIG: </span>
                      <br />
                      {el.sig}
                    </p>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info"> Type: </span>
                      <br />
                      {el.type}
                    </p>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info">
                        {" "}
                        Quantity:{" "}
                      </span>
                      <br />
                      {el.quantity}
                    </p>
                    <p className="col-12 col-md-6  col-lg-4 mx-auto my-3 px-3">
                      <span className="fw-semibold fc-info">
                        {" "}
                        Refills:{" "}
                      </span>
                      <br />
                      {el.refills ? el.refills : "N/A"}
                    </p>
                  </div>
                );
              })
            ) : (
              <div>No Medication</div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
  )
}

export default RxDetails