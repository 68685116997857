import React, { useState, useEffect } from "react";
import { useModal } from "react-hooks-use-modal";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SelectPatient from "./CreateNewCare/SelectCpPatient";
import SelectInitForm from "./CreateNewCare/SelectInitForm";
import SelectFupForm from "./CreateNewCare/SelectFupForm";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CreateNewCarePlan } from "../../../utils/globalState";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListSubheader } from "@mui/material";
import { Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  handleAddCarePlan,
  handleUpdateCarePlanForm,
} from "../../../services/CarePlan";
import { handleAddEvent } from "../../../services/Calendar";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import ReviewResponse from "../formCenter/ReviewResponse";
import { Alert } from "@mui/material";
const CpCreator = (props) => {
  const {
    showCreator,
    setShowCreator,
    handleSetCurrentCP,
    carePlans,
    privileges,
  } = props;

  const stateObj = CreateNewCarePlan((state) => state.careplanController);

  const pharmacyId = Number(localStorage.getItem("pharmacy"));
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [activeStep, setActiveStep] = useState(0);
  const [currentForm, setCurrentForm] = useState(null);
  const [cpForPt, setCpForPt] = useState([]);
  const navigate = useNavigate();
  let location = useLocation();
  let path = location.pathname.split("/")[1];

  const [Modal, open, close] = useModal("root", {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  });

  const steps = [
    "Select Patient",
    "Select Initial Form",
    "Select Followup Form",
  ];

  const handleNext = () => {
    if (activeStep === 0 && !stateObj.completeflag.select_patient) {
      return;
    }

    if (activeStep === 1 && !stateObj.completeflag.init_form) {
      return;
    }

    if (activeStep === 2 && !stateObj.completeflag.followup_form) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSaveCarePlan = () => {
    const eventData = {
      pharmacyId,
      creatorId: userID,
      OwnerId: userID,
      Subject: `Initial Care Plan Call for ${stateObj.info.patient_info.first} ${stateObj.info.patient_info.last} DOB: ${stateObj.info.patient_info.DOB} `,
      StartTime: stateObj.info.init_form.init_call_time,
      EndTime: stateObj.info.init_form.init_call_time,
      IsAllDay: false,
      Location: "Phone",
      patientName:
        stateObj.info.patient_info.first +
        " " +
        stateObj.info.patient_info.last,
      isInitEvent: true,
    };

    handleAddEvent(eventData)
      .then((res) => {
        const payload = {
          pharmacyId: pharmacyId,
          creatorID: userID,
          recipientID: stateObj.info.patient_info.id,
          initFormId: stateObj.info.init_form.id,
          fuFormId: stateObj.info.followup_form.id,
          initEventId: res.data.id,
          fuOptedIn: true,
        };

        handleAddCarePlan(payload)
          .then((resp) => {
            handleUpdateCarePlanForm(stateObj.info.init_form.id, {
              isSent: true,
            });
            handleUpdateCarePlanForm(stateObj.info.followup_form.id, {
              isSent: true,
            });
            setShowCreator(!showCreator);
            handleSetCurrentCP(resp.data.id);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStartCarePlan = () => {
    const eventData = {
      pharmacyId,
      creatorId: userID,
      OwnerId: userID,
      Subject: `Initial Care Plan Call for ${stateObj.info.patient_info.first} ${stateObj.info.patient_info.last} DOB: ${stateObj.info.patient_info.DOB} `,
      StartTime: stateObj.info.init_form.init_call_time,
      EndTime: stateObj.info.init_form.init_call_time + 30 * 60 * 1000,
      IsAllDay: false,
      Location: "Phone",
      patientName:
        stateObj.info.patient_info.first +
        " " +
        stateObj.info.patient_info.last,
      isInitEvent: true,
    };

    handleAddEvent(eventData)
      .then((res) => {
        const payload = {
          pharmacyId: pharmacyId,
          creatorID: userID,
          recipientID: stateObj.info.patient_info.id,
          initFormId: stateObj.info.init_form.id,
          fuFormId: stateObj.info.followup_form.id,
          initEventId: res.data.id,
          fuOptedIn: true,
        };

        handleAddCarePlan(payload)
          .then((resp) => {
            handleUpdateCarePlanForm(stateObj.info.init_form.id, {
              isSent: true,
            });
            handleUpdateCarePlanForm(stateObj.info.followup_form.id, {
              isSent: true,
            });
            handleSetCurrentCP(resp.data.id);
            navigate(`/${path}/cp-center/init-form-fill/?id=${resp.data.id}`, {
              replace: true,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formViewClickHandler = (formType) => {
    if (formType === 0) {
      setCurrentForm({
        value: null,
        formText: stateObj.info.init_form.json,
      });
      open();
    } else {
      setCurrentForm({
        value: null,
        formText: stateObj.info.followup_form.json,
      });
      open();
    }
  };

  useEffect(() => {
    if (Object.values(stateObj.info.patient_info).length > 0) {
      setCpForPt(
        carePlans.filter(
          (cp) => cp?.recipient?.id === stateObj.info.patient_info.id
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObj]);

  return (
    <>
      <div className="col-9">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="p-3">
              <div className="text-center mb-3">
                {cpForPt.length > 0 ? (
                  <Alert severity="warning">
                    {stateObj.info.patient_info.first}&nbsp;
                    {stateObj.info.patient_info.last} already have{" "}
                    {cpForPt.length} active care plan
                    {cpForPt.length > 1 ? "s" : ""}
                  </Alert>
                ) : (
                  ""
                )}
              </div>
              {activeStep === 0 && <SelectPatient />}
              {activeStep === 1 && <SelectInitForm privileges={privileges} />}
              {activeStep === 2 && <SelectFupForm privileges={privileges} />}
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                // color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              {activeStep === steps.length - 1 ? (
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}>
                  <Button
                    disabled={
                      (activeStep === 0 &&
                        !stateObj.completeflag.select_patient) ||
                      (activeStep === 1 && !stateObj.completeflag.init_form) ||
                      (activeStep === 2 && !stateObj.completeflag.followup_form)
                    }
                    variant="contained"
                    onClick={handleSaveCarePlan}>
                    Save For Later
                  </Button>
                  <Button
                    disabled={
                      (activeStep === 0 &&
                        !stateObj.completeflag.select_patient) ||
                      (activeStep === 1 && !stateObj.completeflag.init_form) ||
                      (activeStep === 2 && !stateObj.completeflag.followup_form)
                    }
                    variant="contained"
                    onClick={handleStartCarePlan}>
                    Start Care Plan
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    (activeStep === 0 &&
                      !stateObj.completeflag.select_patient) ||
                    (activeStep === 1 && !stateObj.completeflag.init_form) ||
                    (activeStep === 2 && !stateObj.completeflag.followup_form)
                  }>
                  Next
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </div>

      <div className="col-3">
        <Grid>
          <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#a8beda",
                    fontSize: "large",
                  }}>
                  Patient Information
                </ListSubheader>
              }>
              <ListItemText
                primary={`First Name : ${
                  stateObj.info.patient_info.first || ""
                } `}
              />
              <ListItemText
                primary={`Last Name: ${
                  stateObj.info.patient_info.last || ""
                }  `}
              />
              <ListItemText
                primary={`Date Of Birth: ${
                  stateObj.info.patient_info.DOB || ""
                } `}
              />
              <ListItemText
                primary={`Gender: ${stateObj.info.patient_info.gender || ""} `}
              />
              <ListItemText
                primary={`MRN: ${stateObj.info.patient_info.MRN} `}
              />
              <ListItemText
                primary={`Email Address: ${
                  stateObj.info.patient_info.email || ""
                } `}
              />
              <ListItemText
                primary={`Phone Number: ${
                  stateObj.info.patient_info.phone || ""
                } `}
              />
            </List>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#a8beda",
                    fontSize: "large",
                  }}>
                  Initial Form
                </ListSubheader>
              }>
              <ListItemText
                className={stateObj.info?.init_form?.id ? "clickable_text" : ""}
                onClick={() => {
                  formViewClickHandler(0);
                }}
                primary={`Title : ${
                  stateObj.info?.init_form?.json?.title || ""
                }`}
              />
              <ListItemText
                primary={`Initial Call Time : ${
                  stateObj.info?.init_form?.init_call_time
                    ? dayjs(stateObj.info?.init_form?.init_call_time).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )
                    : ""
                }`}
              />
            </List>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#a8beda",
                    fontSize: "large",
                  }}>
                  Follow Up Form
                </ListSubheader>
              }>
              <ListItemText
                className={
                  stateObj.info?.followup_form?.id ? "clickable_text" : ""
                }
                onClick={() => {
                  formViewClickHandler(1);
                }}
                primary={`Title :  ${
                  stateObj.info?.followup_form?.json?.title || ""
                }`}
              />
            </List>
          </Paper>
        </Grid>
      </div>
      <Modal>
        {
          <div
            className="card p-3"
            style={{
              width: "80vw",
              height: "80vh",
            }}>
            <button
              onClick={() => {
                close();
                setCurrentForm(null);
              }}
              className="btn btn-outline-danger mb-4 p-1"
              style={{ width: "2.1rem" }}>
              <CloseIcon />
            </button>
            {currentForm ? (
              <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                <ReviewResponse data={currentForm} />
              </div>
            ) : null}
          </div>
        }
      </Modal>
    </>
  );
};

export default CpCreator;
