export const convertTimeLocally = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};

export const convertTime = (timestamp) => {
  const date = new Date(timestamp);

  const offsetMinutes = date.getTimezoneOffset();

  const offsetMs = offsetMinutes * 60 * 1000;

  const localDate = new Date(date.getTime() - offsetMs);

  const dateString = `${(localDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${localDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${localDate
    .getFullYear()
    .toString()} ${localDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;

  return dateString;
};

export const convertDateToInputFormat = (date) => {
  let newDate = new Date(date);
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const dateRes = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
  return !dateRes.includes("NaN") && dateRes;
};

export const timeZoneDifference = () => {
  const currentDate = new Date();
  const timeZoneDifference = -currentDate.getTimezoneOffset() / 60;

  return timeZoneDifference * 60 * 60 * 1000;
};
