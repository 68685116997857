import React, { useState, useEffect } from "react";
import { ProviderAddRxController } from "../../../../utils/globalState";

const Therapy = () => {
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [input, setInput] = useState("");

  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  const handleChange = (event) => {
    event.preventDefault();

    setInput(event.target.value);
  };

  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: "Therapy",
      },
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (input !== "") {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, therapy: input },
          completeflag: { ...rxController.completeflag, Therapy: true },
        },
      });
    }
  };
  return (
    <form onSubmit={handleSubmit} className="col-12">
      {/* <div className="container_therapy col-12 col-lg-8"> */}
        <label className="form-label form-control" htmlFor="form">
          Choose a form:
          <select
            className="form-select w-100"
            value={input}
            onChange={handleChange}
          >
            <option defaultValue={""}>Choose</option>
            <option value="Alzheimer">1- Alzheimer</option>
            <option value="Asthma">2- Asthma</option>
            <option value="Covid19">3- Covid19</option>
            <option value="Dermatology">4- Dermatology</option>
            <option value="EosinophilicAsthma">5- Eosinophilic Asthma</option>
            <option value="Gastroenterology">6- Gastroenterology</option>
            <option value="GastroenterologyZinplava">
              7- Gastroenterology Zinplava
            </option>
            <option value="Hematopoietic">8- Hematopoietic</option>
            <option value="Hemophilia">9- Hemophili A</option>
            <option value="HepatitisB">10- Hepatitis B</option>
            <option value="HepatitisC">11- Hepatitis C</option>
            <option value="HepatitisSovaldi">12- Hepatitis Sovaldi</option>
            <option value="HIVAIDS">13- HIVAIDS</option>
            <option value="AutoimmuneDisorder">14- Autoimmune Disorder</option>
            <option value="ImmuneGlobulin">15- Immune Globulin</option>
            <option value="KrystexxaIVInfusionForGout">
              16- Krystexxa IV Infusion For Gout
            </option>
            <option value="Migraine">17- Migraine</option>
            <option value="MigraineVyepti">18- Migraine Vyepti</option>
            <option value="MultipleSclerosis">19- Multiple Sclerosis</option>
            <option value="MultipleSclerosisTecfidera">
              20- Multiple Sclerosis Tecfidera
            </option>
            <option value="OncologyIV">21- Oncology IV</option>
            <option value="OralOncology">22- Oral Oncology</option>
            <option value="Osteoporosis">23- Osteoporosis</option>
            <option value="Rheumatology">24- Rheumatology</option>
            <option value="LupronDepot">25- Lupron Depot</option>
            <option value="LupronDepotPeds">26- Lupron Depot Peds</option>
          </select>
        </label>
        <div className="d-grid gap-2 col-md-12 mx-auto">
          <button className="btn btn-outline-primary btn_ast" type="submit">
            Confirm
          </button>
        </div>
      {/* </div> */}
    </form>
  );
};

export default Therapy;
