import React, { useEffect, useState } from "react";
import { getEventHistoryById } from "../../../../services/Calendar";
import { getUserById } from "../../../../services/Common";
import dayjs from "dayjs";

const EventHistoryModal = (props) => {
  const { curEvent } = props;
  const eventId = curEvent.Id;

  const [processedHistory, setProcessedHistory] = useState([]);
  const [creator, setCreator] = useState({});

  const processesHistory = (history) => {
    let trimmedHistory = [];

    // extract basic info from each event
    history.forEach((event) => {
      const processedEvent = {};
      processedEvent.updatedByName = event.updatedByName;
      processedEvent.updatedOn = dayjs(event.updatedOn).format(
        "MM/DD/YYYY hh:mm A"
      );
      trimmedHistory.push(processedEvent);
    });

    // extract the changes made to each event
    history.forEach((event, idx) => {
      const processedEvent = {};
      for (let key in event) {
        if (key.split("_")[0] === "new" && event[key] !== null) {
          processedEvent[key] = event[key];
          processedEvent["old_" + key.split("_")[1]] =
            event["old_" + key.split("_")[1]];
        }
      }
      trimmedHistory[idx] = { ...trimmedHistory[idx], ...processedEvent };
    });

    setProcessedHistory(trimmedHistory.reverse());
  };

  useEffect(() => {
    const fetchEventHistory = async () => {
      getEventHistoryById(eventId)
        .then((res) => {
          res.data.length > 0 && processesHistory(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    eventId && fetchEventHistory();
  }, [eventId]);

  useEffect(() => {
    const fetchCreator = async () => {
      getUserById(curEvent.creatorId)
        .then((res) => {
          setCreator(res.data.data.user[0]);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    curEvent.creatorId && fetchCreator();
  }, [curEvent.creatorId]);

  // function to generate a unique key for each event no params
  const generateKey = () => {
    return Math.random().toString(36);
  };

  return (
    <div className="card m-4" style={{ maxHeight: "65vh" }}>
      <div className="row mx-3  px-3 card-header">
        <div className="col-12 col-md-6 col-lg-4">
          <p className="fs-6 fw-bold">
            Subject: &nbsp;<span className="fw-normal">{curEvent.Subject}</span>
          </p>
          <p className="fs-6 fw-bold">
            Patient Name: &nbsp;
            <span className="fw-normal">{curEvent.patientName}</span>
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <p className="fs-6 fw-bold">
            Owner: &nbsp;
            <span className="fw-normal">{curEvent.OwnerId}</span>
          </p>
          <p className="fs-6 fw-bold">
            Location: &nbsp;
            <span className="fw-normal">{curEvent.Location}</span>
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <p className="fs-6 fw-bold">
            Created On: &nbsp;
            <span className="fw-normal" style={{ fontSize: "0.85rem" }}>
              {dayjs(curEvent.createdAt).format("MM/DD/YYYY hh:mm A")}
            </span>
          </p>
          <p className="fs-6 fw-bold">
            Created By: &nbsp;
            <span className="fw-normal">
              {creator.first} {creator.last}
            </span>
          </p>
        </div>
      </div>
      <div className="row mx-3 px-3 card-body" style={{ overflowY: "scroll" }}>
        <div>
          <h5 className="text-center fw-bold">History</h5>
        </div>
        {processedHistory.length > 0 ? (
          <div className="my-3">
            <table
              className="table table-striped mt-2"
              style={{
                border: "1px solid #e2e2e2",
              }}>
              <thead>
                <tr
                  style={{
                    position: "sticky",
                    top: "-1.35rem",
                    backgroundColor: "#fff",
                    borderBottom: "2px solid #e2e2e2",
                  }}>
                  <th className="fw-bold" scope="col">
                    Field
                  </th>
                  <th className="fw-bold" scope="col">
                    Old Value
                  </th>
                  <th className="fw-bold" scope="col">
                    New Value
                  </th>
                  <th className="fw-bold" scope="col">
                    Updated By
                  </th>
                  <th className="fw-bold" scope="col">
                    Updated On
                  </th>
                </tr>
              </thead>
              <tbody>
                {processedHistory.map((event, index) => {
                  return Object.keys(event).map((key, idx) => {
                    if (key.split("_")[0] === "old") {
                      return (
                        <tr
                          key={generateKey()}
                          style={{ borderBottom: "1px solid #e2e2e2" }}>
                          <td
                            style={{
                              borderRight: "2px solid #e2e2e2",
                            }}>
                            {key.split("_")[1]}
                          </td>
                          <td>{event[key]}</td>
                          <td>{event["new_" + key.split("_")[1]]}</td>
                          <td>{event.updatedByName}</td>
                          <td>{event.updatedOn}</td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  });
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="my-5 text-center">
            No event updates since it was created
          </p>
        )}
      </div>
    </div>
  );
};

export default EventHistoryModal;
