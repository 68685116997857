import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/commonStyles/dashboard.css";

export default function Cards(props) {
  const { patients, referrals } = props;

  const navigate = useNavigate();

  let date = new Date();
  const options1 = {
    weekday: "long",
  };
  const options2 = {
    month: "long",
    day: "numeric",
  };
  let day_text = Intl.DateTimeFormat("en-US", options1).format(date);
  let date_text = Intl.DateTimeFormat("en-US", options2).format(date);

  return (
    <div className="row main mb-3">
      <div
        className="col-sm-3 dash-card my-1 "
        onClick={() => {
          navigate("/provider/manage-patients");
        }}>
        <div className="card py-2 py-md-0">
          <img
            className="card-img-top d-none d-lg-block"
            src={require("../../../assets/images/drdesk.png")}
            alt="Card cap"
          />
          <div className="card-body">
            <h5 className="card-title my-0 p-0">PATIENTS</h5>
            <p className="card-text card-text_num fw-bold my-0">
              {patients || 0}
            </p>
          </div>
        </div>
      </div>
      <div
        className="col-sm-3  dash-card my-1"
        onClick={() => {
          navigate("/provider/manage-rxs");
        }}>
        <div className="card py-2 py-md-0">
          <img
            className="card-img-top d-none d-lg-block"
            src={require("../../../assets/images/rx.jpg")}
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title my-0 p-0" id="prescription-card">
              PRESCRIPTIONS
            </h5>
            <p className="card-text card-text_num fw-bold my-0">
              {referrals || 0}
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-3  dash-card my-1">
        <div className="card py-2 py-md-0">
          <img
            className="card-img-top d-none d-lg-block"
            src={require("../../../assets/images/AdobeStock_311305302.png")}
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title my-0 p-0" id="prescription-card">
              SURVEYS
            </h5>
            <p className="card-text card-text_num fw-bold my-0">{0}</p>
          </div>
        </div>
      </div>

      <div className="col-sm-3 dash-card my-1 ">
        <div className="card py-2 py-md-0">
          <img
            className="card-img-top d-none d-lg-block py-0 my-0"
            src={require("../../../assets/images/drRx.png")}
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title  my-0 text-uppercase p-0">{day_text}</h5>
            <p className="card-text card-text_num fw-bold my-0">
              {date_text}
              {date_text && date_text.split(" ") === 1
                ? "st"
                : date_text.split(" ") === 2
                ? "nd"
                : date_text.split(" ") === 3
                ? "rd"
                : "th"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
