import React, { useEffect, useState } from "react";

import { randomPassword } from "secure-random-password";
import {
  handleGetPharmacies,
  handleAddPharmacy,
  handleGetAdmins,
  handleAddAdmin,
  handleAssignIntegrationToPharmacy,
  handleGetIntegrations,
  handleDeleteIntegrationFromPharmacy,
} from "../../services/Admin";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SideBar } from "../../utils/globalState";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ManagePharmacyPrivilege from "../../components/admin/ManagePharmacyPrivilege";
import { addMessagesUser } from "../../services/PharmacyAdmin";
import LogoPictureUploader from "../../components/admin/LogoPictureUploader";
import { updatePharmacySMSPhoneNum } from "./../../services/Admin";

const ManagePharmacy = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const [pharmaList, setPharmaList] = useState([]);
  const [pharmaName, setPharmaName] = useState("");
  const [adminsList, setAdminsList] = useState([]);
  const [thisPharma, setThisPharma] = useState({});
  const [msg, setMsg] = useState("");
  const [detailsFlags, setDetailsFlags] = useState(false);
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [phoneNumInput, setPhoneNumInput] = useState(null);
  const [adminInfo, setAdminInfo] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
  });

  const handleChanges = (e) => {
    setPharmaName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const BIN = randomPassword({ length: 4 });
    handleAddPharmacy({ name: pharmaName, BIN: BIN }).then((res) => {
      handlePharmacyInfo();
      setPharmaName("");
    });
  };

  const handlePharmacyInfo = () => {
    handleGetPharmacies().then((res) => {
      let newData = res.data.data.map((pharma) => {
        pharma.active = pharma.active === 1 ? "Active" : "Inactive";
        pharma.pharmacy_Info = "Edit";
        return pharma;
      });

      setPharmaList(newData);
    });
  };

  useEffect(() => {
    handlePharmacyInfo();
  }, []);

  const currentPharma = (pharma) => {
    setThisPharma(pharma);
    setPhoneNumInput(pharma.smsPhoneNum);
    handleGetAdmins(pharma.id)
      .then((res) => {
        setAdminsList(res.data.data);
      })
      .catch((err) => {
        setAdminsList([]);
      });

    handleGetIntegrations()
      .then((res) => {
        setAllIntegrations(res.data.integrations);
      })
      .catch((err) => {
        console.error(err);
        setAllIntegrations([]);
      });
  };

  const handleAdminChanges = (e) => {
    setAdminInfo({ ...adminInfo, [e.target.name]: e.target.value });
  };
  const handleAdminSubmit = (e) => {
    e.preventDefault();
    handleAddAdmin({ ...adminInfo, pharmacyID: thisPharma.id })
      .then((res) => {
        let data = {
          userId: res.data.user.id.toString(),
          pharmacyId: thisPharma.id,
        };
        addMessagesUser(data)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });

        if (res.status === 200) {
          setMsg(res.data.message);
        } else {
          setMsg(res.response.data.message);
        }
        currentPharma(thisPharma);
      })
      .catch((err) => {
        setMsg(err.response.data.message);
      });
  };
  // const [selectedprivileges, setSelectedPrivileges] = useState("");

  // const handlePriv = (e) => {
  //   setSelectedPrivileges(e.target.value);
  // };

  // const handlePrivSubmit = (e) => {
  //   e.preventDefault();

  // };

  const handleInt = (e) => {
    setSelectedIntegration(e.target.value);
  };

  const handleIntSubmit = (e) => {
    e.preventDefault();

    handleAssignIntegrationToPharmacy({
      pharmacyID: thisPharma.id,
      integrationID: selectedIntegration,
    })
      .then((res) => {
        handleGetPharmacies().then((res) => {
          let newData = res.data.data.map((pharma) => {
            pharma.active = pharma.active === 1 ? "Active" : "Inactive";
            pharma.pharmacy_Info = "Edit";
            return pharma;
          });

          setThisPharma(newData.find((pharma) => pharma.id === thisPharma.id));
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      minWidth: 70,
      maxWidth: 70,
    },
    { field: "name", headerName: "Pharmacy Name", minWidth: 250 },
    { field: "BIN", headerName: "BIN Number", minWidth: 150 },
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <button
              className="btn btn-sm btn-primary me-2"
              onClick={() => {
                handleRowClick(params);
              }}>
              <BorderColorIcon /> Edit
            </button>
          </>
        );
      },
    },
  ]);

  const handleRowClick = (params) => {
    setThisPharma(params.row);
    setDetailsFlags(true);
    currentPharma(params.row);
  };

  const handleDelete = (item) => {
    handleDeleteIntegrationFromPharmacy({
      integrationID: item.integrationID,
      pharmacyID: thisPharma.id,
    })
      .then((res) => {
        handleGetPharmacies().then((res) => {
          let newData = res.data.data.map((pharma) => {
            pharma.active = pharma.active === 1 ? "Active" : "Inactive";
            pharma.pharmacy_Info = "Edit";
            return pharma;
          });

          setThisPharma(newData.find((pharma) => pharma.id === thisPharma.id));
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    updatePharmacySMSPhoneNum(thisPharma.id, {
      smsPhoneNum: phoneNumInput,
    })
      .then(() => {
        setThisPharma((prev) => ({ ...prev, smsPhoneNum: phoneNumInput }));
        setPharmaList((prev) =>
          prev.map((pharma) => {
            if (pharma.id === thisPharma.id) {
              pharma.smsPhoneNum = phoneNumInput;
            }
            return pharma;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handledeletePhoneSubmit = (e) => {
    e.preventDefault();
    updatePharmacySMSPhoneNum(thisPharma.id, {
      smsPhoneNum: null,
    })
      .then(() => {
        setPhoneNumInput(null);
        setThisPharma((prev) => ({ ...prev, smsPhoneNum: null }));
        setPharmaList((prev) =>
          prev.map((pharma) => {
            if (pharma.id === thisPharma.id) {
              pharma.smsPhoneNum = null;
            }
            return pharma;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Add New Pharmacy</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Manage Pharmacies</li>
              <li className="breadcrumb-item active">Add New Pharmacy</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {detailsFlags ? (
              <div className="m-2">
                <IconButton
                  onClick={() => {
                    setDetailsFlags(false);
                    setThisPharma({});
                    setMsg("");
                  }}>
                  <ArrowBackIcon />
                </IconButton>
                {pharmaList.length > 0 ? (
                  <div className="container" id="superAdminModal">
                    <div className="row">
                      <div className="card ">
                        <h5 className="card-header">
                          {thisPharma.name} Pharmacy Details
                        </h5>
                        <div className="card-body container">
                          {msg === "" ? (
                            <></>
                          ) : (
                            <div
                              className="alert alert-primary p-3"
                              role="alert">
                              {msg}
                            </div>
                          )}
                          <div className="card-text row">
                            <div className="col-12 col-md-12">
                              <div className="card  mb-5">
                                <div className="card-header">
                                  Pharmacy Admins Assigned
                                </div>
                                <div className="card-body">
                                  <blockquote className="blockquote m-0">
                                    <ul className="list-group list-group-flush">
                                      <form className="row gx-3 gy-2 align-items-center">
                                        <div className="col-12 col-sm-6 col-xl-3">
                                          <label
                                            className="visually-hidden"
                                            htmlFor="specificSizeInputName">
                                            First Name
                                          </label>
                                          <input
                                            type="text"
                                            onChange={handleAdminChanges}
                                            className="form-control"
                                            id="specificSizeInputName"
                                            placeholder="First Name"
                                            name="first"
                                            value={adminInfo.first}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-3">
                                          <label
                                            className="visually-hidden"
                                            htmlFor="specificSizeInputName2">
                                            Last Name
                                          </label>
                                          <input
                                            type="text"
                                            onChange={handleAdminChanges}
                                            className="form-control"
                                            id="specificSizeInputName2"
                                            placeholder="Last Name"
                                            name="last"
                                            value={adminInfo.last}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-3">
                                          <label
                                            className="visually-hidden"
                                            htmlFor="specificSizeInputName3">
                                            Email
                                          </label>
                                          <input
                                            type="text"
                                            onChange={handleAdminChanges}
                                            className="form-control"
                                            id="specificSizeInputName3"
                                            placeholder="Email"
                                            name="email"
                                            value={adminInfo.email}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-3">
                                          <label
                                            className="visually-hidden"
                                            htmlFor="specificSizeInputName4">
                                            Phone Number
                                          </label>
                                          <input
                                            type="text"
                                            onChange={handleAdminChanges}
                                            className="form-control"
                                            id="specificSizeInputName4"
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={adminInfo.phone}
                                          />
                                        </div>
                                        <div className="col-auto">
                                          <button
                                            type="submit"
                                            onClick={handleAdminSubmit}
                                            className="btn btn-success ">
                                            Add New Admin
                                          </button>
                                        </div>
                                      </form>
                                      {adminsList.length > 0
                                        ? adminsList.map((admin) => {
                                            return (
                                              <li className="list-group-item mb-3">
                                                {admin.email}
                                              </li>
                                            );
                                          })
                                        : ""}
                                    </ul>
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="card">
                                <div className="card-header">
                                  Privileges Assigned
                                </div>
                                <div className="card-body">
                                  <blockquote className="blockquote mb-0">
                                    <ManagePharmacyPrivilege
                                      pharmacyId={thisPharma.id}
                                    />
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="card">
                                <div className="card-header">Pharmacy Logo</div>
                                <div className="card-body">
                                  <blockquote className="blockquote mb-0">
                                    <LogoPictureUploader
                                      pharmacy={thisPharma}
                                    />
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="card">
                                <div className="card-header">
                                  Integrations Assigned
                                </div>
                                <div className="card-body">
                                  <blockquote className="blockquote mb-0">
                                    <form className="row gx-4 gy-3 align-items-center">
                                      <div className="col-6">
                                        <label
                                          className="visually-hidden"
                                          htmlFor="specificSizeSelect">
                                          Preference
                                        </label>
                                        <select
                                          className="form-select"
                                          id="specificSizeSelect"
                                          onChange={handleInt}>
                                          <option>Select Integration...</option>
                                          {allIntegrations !==
                                          "No privilege has yet been added!" ? (
                                            allIntegrations.map((int, idx) => {
                                              return (
                                                <option
                                                  value={int.id}
                                                  key={idx}>
                                                  {int.company_name}
                                                </option>
                                              );
                                            })
                                          ) : (
                                            <option value="No privilege has yet been added!">
                                              No Integration has yet been added!
                                            </option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="col-6">
                                        <button
                                          type="submit"
                                          className="btn btn-success "
                                          onClick={handleIntSubmit}>
                                          Add Integration Type
                                        </button>
                                      </div>
                                    </form>
                                    <List>
                                      {thisPharma.integrations.length > 0
                                        ? thisPharma.integrations.map((int) => {
                                            return (
                                              <ListItem
                                                secondaryAction={
                                                  <IconButton
                                                    edge="start"
                                                    aria-label="delete"
                                                    onClick={() =>
                                                      handleDelete(int)
                                                    }>
                                                    <DeleteIcon
                                                      sx={{ fontSize: "35px" }}
                                                    />
                                                  </IconButton>
                                                }>
                                                <ListItemText
                                                  primary={`${int.company_name}`}
                                                />
                                              </ListItem>
                                            );
                                          })
                                        : ""}
                                    </List>
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="card">
                                <div className="card-header">
                                  Pharmacy SMS Phone Number
                                </div>
                                <div className="card-body">
                                  <blockquote className="blockquote mb-0">
                                    <form className="row gx-4 gy-3 align-items-center">
                                      <div className="col-6">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter New Phone Number"
                                          value={
                                            phoneNumInput ? phoneNumInput : ""
                                          }
                                          onChange={(e) =>
                                            setPhoneNumInput(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="col-6">
                                        <button
                                          type="submit"
                                          disabled={
                                            phoneNumInput === "" ||
                                            thisPharma.smsPhoneNum ===
                                              phoneNumInput
                                          }
                                          className="btn btn-success me-4"
                                          onClick={handlePhoneSubmit}>
                                          {thisPharma.smsPhoneNum
                                            ? "Update"
                                            : "Add"}{" "}
                                          Number
                                        </button>
                                        {thisPharma.smsPhoneNum &&
                                          thisPharma.smsPhoneNum.trim() && (
                                            <button
                                              type="submit"
                                              className="btn btn-success "
                                              onClick={handledeletePhoneSubmit}>
                                              Delete Number
                                            </button>
                                          )}
                                      </div>
                                    </form>
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="modal-footer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <>
                <div className="card text-center mb-5 mt-5">
                  <div className="card-header">Add New Pharmacy</div>
                  <div className="card-body">
                    <form className="row gx-3 gy-2 align-items-center">
                      <div className="col-12 col-sm-5 col-xl-3">
                        <label
                          className="visually-hidden"
                          htmlFor="specificSizeInputName">
                          Pharmacy Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="specificSizeInputName"
                          placeholder="Pharmacy Name"
                          onChange={handleChanges}
                          value={pharmaName}
                        />
                      </div>
                      <div className="col-12 col-sm-auto">
                        <button
                          type="submit"
                          className="btn btn-success mt-2 mt-sm-0 px-5 col-12"
                          onClick={handleSubmit}>
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-muted"></div>
                </div>
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={pharmaList}
                    columns={columnDefs}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 100,
                        },
                      },
                    }}
                    pageSizeOptions={[100]}
                    getRowId={(row) => row.id}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    // onRowClick={handleRowClick}
                  />
                </Box>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManagePharmacy;
