import React, { useState, useEffect } from "react";
import {
  SideBar,
  ProviderAddRxController,
} from "../../../../utils/globalState";
import {
  AddPatientProviderLink,
  addNewPatient,
} from "../../../../services/Provider";
import handleAddressAutoComplete from "../../../../utils/addressAutoComplete";
import { decodeToken } from "react-jwt";
import { getUserById } from "../../../../services/Common";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const initialForm = {
  first: "",
  last: "",
  email: "",
  phone: "",
  DOB: "",
  gender: "",
  race: "",
  height: "",
  weight: "",
  SSN: "",
  MRN: "",
  address: [
    {
      name: "",
      relation: "",
      street: "",
      city: "",
      zip: "",
      state: "",
    },
  ],
  ContactInfo: [
    {
      emName: "",
      emRelation: "",
      altPhone: "",
    },
  ],
  pharmacyID: "",
  providerID: "",
};

const AddNewPatient = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);

  const [form, setForm] = useState(initialForm);
  const [errMsg, setErrMsg] = useState("");
  const [addresses, setAddresses] = useState(1);
  const [emContacts, setEmContacts] = useState(1);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [exstedPatient, setExstedPatient] = useState({});
  // ======================================================== Matrial UI =========================================================
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // ======================================================== //

  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  const handleGetAddress = (e) => {
    const { value } = e.target;
    if (!value) {
      setAddressSuggestions([]);
    } else {
      handleAddressAutoComplete(value)
        .then((res) => {
          setAddressSuggestions(res);
        })
        .catch((err) => console.error(err));
    }
  };

  const changeHandler = (e) => {
    if (
      ["street", "city", "zip", "state", "name", "relation"].some(
        (el) => el === e.target.name
      )
    ) {
      let index = Number(e.target.attributes.order.value);
      if (e.target.name === "street") {
        let addrauto = e.target.value.split(",");
        let state = ["", ""];
        if (addrauto.length > 1) {
          state =
            addrauto[addrauto.length - 1].split(" ").length > 1
              ? addrauto[addrauto.length - 1].split(" ")
              : ["", ""];
        }

        if (form.address[index]) {
          setForm({
            ...form,
            address: form.address.map((el, idx) => {
              if (idx !== index) {
                return el;
              } else {
                return {
                  ...el,
                  street: addrauto[0],
                  city: addrauto[1],
                  state: state[0],
                  zip: state[1],
                };
              }
            }),
          });
        } else {
          setForm({
            ...form,
            address: [
              ...form.address,
              (form.address[index] = initialForm.address[0]),
            ],
          });
          setForm({
            ...form,
            address: form.address.map((el, idx) => {
              if (idx !== index) {
                return el;
              } else {
                return {
                  ...el,
                  street: addrauto[0],
                  city: addrauto[1],
                  state: state[0],
                  zip: state[1],
                };
              }
            }),
          });
        }
      } else {
        setForm({
          ...form,
          address: form.address.map((el, idx) => {
            if (idx !== index) {
              return el;
            } else {
              return { ...el, [e.target.name]: e.target.value };
            }
          }),
        });
      }
    } else if (
      ["emName", "emRelation", "altPhone"].some((el) => el === e.target.name)
    ) {
      let index = Number(e.target.attributes.order.value);
      if (form.ContactInfo[index]) {
        setForm({
          ...form,
          ContactInfo: form.ContactInfo.map((el, idx) => {
            if (idx !== index) {
              return el;
            } else {
              return { ...el, [e.target.name]: e.target.value };
            }
          }),
        });
      } else {
        setForm({
          ...form,
          ContactInfo: [
            ...form.ContactInfo,
            (form.ContactInfo[index] = initialForm.ContactInfo[0]),
          ],
        });
        setForm({
          ...form,
          ContactInfo: form.ContactInfo.map((el, idx) => {
            if (idx !== index) {
              return el;
            } else {
              return { ...el, [e.target.name]: e.target.value };
            }
          }),
        });
      }
    } else if (e.target.name === "DOB") {
      let date = new Date(e.target.value + "T00:00-0800");
      setForm({
        ...form,
        DOB: date
          .toLocaleDateString()
          .split("/")
          .map((el) => (el.length < 2 ? "0" + el : el))
          .join("/"),
      });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
    setErrMsg("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    addNewPatient(form)
      .then((res) => {
        getUserById(res.data.user)
          .then((resp) => {
            ProviderAddRxController.setState({
              StepperController: {
                ...rxController,
                rx: { ...rxController.rx, patientId: res.data.user },
                info: {
                  ...rxController.info,
                  patient_name: `${resp.data.data?.user[0].first} ${resp.data.data?.user[0].last}`,
                },
                completeflag: {
                  ...rxController.completeflag,
                  "Select Patient": true,
                },
              },
            });
            setRxController(
              ProviderAddRxController.getState().StepperController
            );
            setErrMsg("Patient added successfully!!");
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        // ====
        setErrMsg(err.response?.data?.message || err?.message);
        if (err.response?.data?.message === "User Exist!") {
          setExstedPatient(err.response.data.patient);
          handleClickOpen();
        }
      });
  };

  useEffect(() => {
    const pharmacy = localStorage.getItem("pharmacy");
    setForm({
      ...form,
      pharmacyID: pharmacy,
      providerID: myDecodedToken.userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <form className="card p-3" onSubmit={submitHandler}>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="first" className="form-label pt-1">
              First Name&nbsp;<span className="requiredField">*</span>
            </label>
            <input
              name="first"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="first"
              placeholder="ex: John"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="last" className="form-label pt-1">
              Last Name&nbsp;<span className="requiredField">*</span>
            </label>
            <input
              name="last"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="last"
              placeholder="ex: Smith"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="email" className="form-label pt-1">
              Email Address&nbsp;
              <span className="requiredField">*</span>
            </label>
            <input
              name="email"
              onChange={changeHandler}
              type="email"
              className="form-control"
              id="email"
              placeholder="ex: name@example.com"
            />
          </div>
          <div className="form-group col-12 col-md-6 ">
            <label htmlFor="phone" className="form-label pt-1">
              Phone&nbsp;<span className="requiredField">*</span>
            </label>
            <input
              name="phone"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="phone"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-4 ">
            <label htmlFor="DOB" className="form-label pt-1">
              Date of Birth
            </label>
            <input
              name="DOB"
              onChange={changeHandler}
              type="date"
              className="form-control"
              id="DOB"
              placeholder="MM/DD/YYYY"
              min="1900-01-01"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="form-group col-12 col-md-4 ">
            <label htmlFor="gender" className="form-label pt-1">
              Gender
            </label>
            <input
              name="gender"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="gender"
            />
          </div>
          <div className="form-group col-12 col-md-4 ">
            <label htmlFor="race" className="form-label pt-1">
              Race
            </label>
            <input
              name="race"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="race"
            />
          </div>

          <div className="form-group col-12 col-md-6 ">
            <label htmlFor="height" className="form-label pt-1">
              Height
            </label>
            <input
              name="height"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="height"
            />
          </div>
          <div className="form-group col-12 col-md-6 ">
            <label htmlFor="weight" className="form-label pt-1">
              Weight
            </label>
            <input
              name="weight"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="weight"
            />
          </div>
          <div className="form-group col-12 col-md-6 ">
            <label htmlFor="SSN" className="form-label pt-1">
              SSN
            </label>
            <input
              name="SSN"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="SSN"
            />
          </div>
          <div className="form-group col-12 col-md-6 ">
            <label htmlFor="MRN" className="form-label pt-1">
              MRN
            </label>
            <input
              name="MRN"
              onChange={changeHandler}
              type="text"
              className="form-control"
              id="MRN"
            />
          </div>
        </div>
        {[...Array(addresses)].map((el, idx) => {
          return (
            <div className="row" key={idx}>
              {idx > 0 && (
                <>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="name" className="form-label pt-1">
                      In Care Of
                    </label>
                    <input
                      name="name"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="ex: John Smith"
                      order={idx}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="relation" className="form-label pt-1">
                      Relation
                    </label>
                    <input
                      name="relation"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="relation"
                      placeholder="ex: Father"
                      order={idx}
                    />
                  </div>
                </>
              )}
              <div className="form-group col-12">
                <label htmlFor="street" className="form-label pt-1">
                  Street
                </label>
                <input
                  name="street"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="street"
                  placeholder="ex: 123 Main street"
                  order={idx}
                  onKeyUp={handleGetAddress}
                  list="addressesList"
                  value={form.address[idx].street}
                />
                <datalist id="addressesList">
                  {addressSuggestions.length > 0 ? (
                    addressSuggestions.map((item, key) => (
                      <option
                        key={key}
                        value={`${item.street},${item.city},${item.state} ${item.postal}`}
                      />
                    ))
                  ) : (
                    <>No Data</>
                  )}
                </datalist>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="city" className="form-label pt-1">
                  City
                </label>
                <input
                  name="city"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="ex: Los Angeles"
                  order={idx}
                  value={form.address[idx].city}
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="state" className="form-label pt-1">
                  State
                </label>
                <input
                  name="state"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="ex: CA"
                  order={idx}
                  value={form.address[idx].state}
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="zip" className="form-label pt-1">
                  Zip
                </label>
                <input
                  name="zip"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="zip"
                  placeholder="ex: 55555"
                  order={idx}
                  value={form.address[idx].zip}
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <p className="form-label pt-1">
                  {idx === 0 ? "Add" : "Add/Remove"} Additioanl Address
                </p>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setAddresses(addresses + 1);
                    setForm({
                      ...form,
                      address: [
                        ...form.address,
                        {
                          name: "",
                          relation: "",
                          street: "",
                          city: "",
                          state: "",
                          zip: "",
                        },
                      ],
                    });
                  }}>
                  <i className="fas fa-solid fa-plus"></i>
                </button>
                {idx >= 1 && (
                  <button
                    type="button"
                    className="btn btn-warning  mx-4"
                    onClick={() => {
                      setAddresses(addresses - 1);
                      setForm({
                        ...form,
                        address: form.address.filter(
                          (i) =>
                            form.address.indexOf(i) !== form.address.length - 1
                        ),
                      });
                    }}>
                    <i className="fas fa-solid fa-minus"></i>
                  </button>
                )}
              </div>
            </div>
          );
        })}
        <h6 className="mt-3">Emeregency Contacts</h6>
        {[...Array(emContacts)].map((el, idx) => {
          return (
            <div className="row" key={idx}>
              <div className="form-group col-12 col-md-4">
                <label htmlFor="emName" className="form-label pt-1">
                  Full Name
                </label>
                <input
                  name="emName"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="emName"
                  placeholder="ex: John Smith"
                  order={idx}
                />
              </div>
              <div className="form-group col-12 col-md-4">
                <label htmlFor="emRelation" className="form-label pt-1">
                  Relation
                </label>
                <input
                  name="emRelation"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="emRelation"
                  placeholder="ex: Cousin"
                  order={idx}
                />
              </div>
              <div className="form-group col-12 col-md-4">
                <label htmlFor="altPhone" className="form-label pt-1">
                  Phone Number
                </label>
                <input
                  name="altPhone"
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  id="altPhone"
                  order={idx}
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <p className="form-label pt-1">
                  {idx === 0 ? "Add" : "Add/Remove"} Additioanl Contact
                </p>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setEmContacts(emContacts + 1);
                  }}>
                  <i className="fas fa-solid fa-plus"></i>
                </button>
                {idx >= 1 && (
                  <button
                    type="button"
                    className="btn btn-warning  mx-4"
                    onClick={() => setEmContacts(emContacts - 1)}>
                    <i className="fas fa-solid fa-minus"></i>
                  </button>
                )}
              </div>
            </div>
          );
        })}
        <div className="row d-flex flex-column justify-content-between my-4">
          <button
            type="submit"
            className="btn btn-success col-12 col-sm-3 mx-auto">
            Submit
          </button>
          {errMsg && (
            <p className="text-center text-danger col-12 h6 errMSg mt-4">
              {errMsg}
            </p>
          )}
        </div>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <p className="text-center text-danger col-12 h6 errMSg mt-4">
          {errMsg !== "" && errMsg}{" "}
        </p>
        {/* <DialogTitle id="alert-dialog-title">
          {'Patient Aleady Exists'}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Object.keys(exstedPatient).length > 0 &&
            exstedPatient.providers.some(
              (provider) => provider.id === myDecodedToken.userId
            ) ? (
              <p>
                Paitent {"  "}
                <span className="font-weight-bold text-capitalize text-danger">
                  {exstedPatient.first} {exstedPatient.last}
                </span>
                {"  "}
                already in your patient list. Marked as{" "}
                {exstedPatient.providers.find(
                  (provider) => provider.id === myDecodedToken.userId
                ).linkStatus === 1 ? (
                  <span className="font-weight-bold text-capitalize text-success">
                    Active
                  </span>
                ) : (
                  <span className="font-weight-bold text-capitalize text-danger">
                    Inactive
                  </span>
                )}
                .
              </p>
            ) : (
              <p>
                Paitent {"  "}
                <span className="font-weight-bold text-capitalize text-danger">
                  {exstedPatient.first} {exstedPatient.last}
                </span>
                {"  "}
                already exists in CuroRx database. If you wish to add this
                patient to your patient list, please click confirm button below.
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (
                exstedPatient.providers.some(
                  (provider) => provider.id === myDecodedToken.userId
                )
              ) {
                handleClose();
              } else {
                AddPatientProviderLink({
                  patientID: exstedPatient.userID,
                  providerID: myDecodedToken.userId,
                })
                  .then(() => {
                    ProviderAddRxController.setState({
                      StepperController: {
                        ...rxController,
                        rx: {
                          ...rxController.rx,
                          patientId: exstedPatient.userID,
                        },
                        info: {
                          ...rxController.info,
                          patient_name: `${exstedPatient.first} ${exstedPatient.last}`,
                        },
                        completeflag: {
                          ...rxController.completeflag,
                          "Select Patient": true,
                        },
                      },
                    });
                    setRxController(
                      ProviderAddRxController.getState().StepperController
                    );
                    setErrMsg("Patient added successfully!!");
                    handleClose();
                  })
                  .catch((err) => {
                    setErrMsg("Something went wrong");
                  });
              }
            }}
            disabled={
              Object.keys(exstedPatient).length > 0 &&
              exstedPatient.providers.some(
                (provider) => provider.id === myDecodedToken.userId
              )
            }
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewPatient;
