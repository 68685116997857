import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  getPharmaPrivileges,
  handleUpdatePharmaPrivileges,
  handleGetPrivileges,
  handleDeletePrivilegeFromPharmacy,
} from "../../services/Admin";

const ManagePharmacyPrivilege = (props) => {
  const { pharmacyId } = props;
  const [allprivileges, setAllPrivileges] = useState([]);
  const [privilegesList, setPrivilegesList] = useState([]);
  const [avilablePrivileges, setAvailablePrivileges] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [msg, setMsg] = useState("");

  const handleGetAllPrivileges = () => {
    handleGetPrivileges().then((res) => {
      Array.isArray(res.data.data)
        ? setAllPrivileges(res.data.data)
        : setAllPrivileges([]);
    });
  };



  const handleGetThePharmacyPrivileges = () => {
    getPharmaPrivileges(pharmacyId)
      .then((res) => {
        res?.data?.data && setPrivilegesList(res.data.data);
      })
      .catch((err) => {
        setPrivilegesList([]);
      });
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setMsg("");

    if (leftChecked.length > 0) {
      leftChecked.forEach((item) => {
        handleUpdatePharmaPrivileges({
          PharmacyID: pharmacyId,
          PrivilegeID: allprivileges.find((priv) => priv.name === item).ID,
        })
          .then((res) => {
            handleGetAllPrivileges();
            handleGetThePharmacyPrivileges();
          })
          .catch((err) => {
            setMsg(
              err.response.data.message,
              `We couldn't add ${item} to the pharmacy.`
            );
          });
      });
      setChecked(not(checked, leftChecked));
    }
  };

  const handleCheckedLeft = () => {
    setMsg("");
    if (rightChecked.length > 0) {
      rightChecked.forEach((item) => {
        handleDeletePrivilegeFromPharmacy(
          pharmacyId,
          allprivileges.find((priv) => priv.name === item).ID
        )
          .then((res) => {
            handleGetAllPrivileges();
            handleGetThePharmacyPrivileges();
          })
          .catch((err) => {
            setMsg(
              err.response.data.message,
              `We couldn't add ${item} to the pharmacy.`
            );
          });
      });
    }

    // setLeft(left.concat(rightChecked));
    // setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  useEffect(() => {
    handleGetAllPrivileges();
  }, []);

  useEffect(() => {
    handleGetThePharmacyPrivileges();
  }, [pharmacyId]);

  useEffect(() => {
    setAvailablePrivileges(
      allprivileges
        .map((priv) => {
          if (
            privilegesList.find((item) => item.name === priv.name) === undefined
          ) {
            return priv;
          }
        })
        .filter((item) => item !== undefined)
    );
  }, [allprivileges, privilegesList]);

  useEffect(() => {
    setLeft(avilablePrivileges.map((priv) => priv.name));
    setRight(privilegesList.map((priv) => priv.name));
  }, [privilegesList, avilablePrivileges]);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {msg !== "" && msg}
      <Grid item>{customList("Available Privileges", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Assigned Privileges", right)}</Grid>
    </Grid>
  );
};

export default ManagePharmacyPrivilege;
