import React, { useState, useEffect } from "react";
import PtSurveyRun from "./../../components/patient/PtSurveyRun";
import { decodeToken } from "react-jwt";
import { getSurveys } from "./../../services/PharmacyUser";
import {
  getPatientFullInfo,
  addPtToSurvey,
  getSurveyPtById,
} from "../../services/Patient";
import PtSurveysLog from "../../components/patient/PtSurveysLog";
import { SideBar } from "../../utils/globalState";

const Survey = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userid = myDecodedToken.userId;
  const isDivVisible = SideBar((state) => state.isDivVisible);
const pharmacyId = localStorage.getItem("pharmacy");
  const [surveys, setSurveys] = useState([]);
  const [inputsValue, setInputsValue] = useState({
    surveyname: "",
    servicedate: "",
  });
  const [msg, setMsg] = useState("");
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [surveyPt, setSurveyPt] = useState({});

  const handleSurveyPt = () => {
    getPatientFullInfo(userid).then((r) => {
      let currentpt = r.data[0].user;
      let pttosurvey = {
        firstName: currentpt.first,
        lastName: currentpt.last,
        DOB: currentpt.DOB,
        MRN: currentpt.MRN,
        phone: currentpt.phone,
        providing_pharmacy: "River's Edge Pharmacy - PD",
        pharmacy_NPI: "",
        dispense_date: "",
        dispense_type: "",
      };

      addPtToSurvey(pttosurvey)
        .then((res) => {
          if (res.data.patinet_id) {
            getSurveyPtById(res.data.patinet_id).then((re) => {
              setSurveyPt(re.data.patient);
            });
          } else if (res.data.patient) {
            getSurveyPtById(res.data.patient[0].id).then((resp) => {
              setSurveyPt(resp.data.patient);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  useEffect(() => {
    handleSurveyPt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllSurveys = () => {
    getSurveys()
      .then((res) => {
        setSurveys(
          res.data.Surveys.filter(
            (el) => el.pharmacyID === pharmacyId
          ).map((el) => {
            return { id: el.id, json: JSON.parse(el.survey) };
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setSurveys([]);
      });
  };
console.log(surveys)
  useEffect(() => {
    getAllSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputsValue({ ...inputsValue, [name]: value });
    setMsg("");
  };

  const handleStartSurvey = (event) => {
    event.preventDefault();
    setShowSurvey(false);
    if (
      inputsValue.surveyname === "Choose a Survey..." ||
      inputsValue.surveyname === ""
    ) {
      setMsg("Please choose a survey");
    } else {
      let newData = {
        surveyname: inputsValue.surveyname.split(" - ")[1],
        surveyid: inputsValue.surveyname.split(" - ")[0],
        servicedate: surveyPt.dispense_date,
        pateintid: surveyPt.id,
      };

      setSurveyData(newData);
      setShowSurvey(true);
      setInputsValue({
        surveyname: "",
      });
    }
  };

  const goBack = () => {
    setShowSurvey(false);
    setInputsValue({
      surveyname: "",
    });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Surveys</h1>
        </div>
        <div className="container-fluid">
          <div className="col-12">
            {showSurvey ? (
              <PtSurveyRun data={surveyData} onGoback={goBack} />
            ) : (
              <>
                <div className="card  p-3 mb-5 mt-3 bg-body rounded">
                  <div className="card-body mt-3">
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}>
                      {msg}
                    </p>
                    <div className="row g-3 align-items-center">
                      <div className="col-md-12">
                        <select
                          id="inputState"
                          defaultValue="Choose a Survey..."
                          className="form-select"
                          onChange={handleInputChange}
                          name="surveyname">
                          <option value="Choose a Survey..." disabled>
                            Choose a Survey...
                          </option>
                          {surveys.length > 0 ? (
                            surveys.map((s) => {
                              return (
                                <option
                                  key={s.id}
                                  value={`${s.id} - ${s.json.title}`}>{`${s.id} - ${s.json.title}`}</option>
                              );
                            })
                          ) : (
                            <>No Surveys</>
                          )}
                        </select>
                      </div>

                      <div className="col-md-12">
                        <button
                          type="submit"
                          className=" btn btn-success"
                          onClick={handleStartSurvey}>
                          Start Survey
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card  p-3 mb-5 mt-3 bg-body rounded">
            <div className="card-body mt-3">
              <PtSurveysLog patient={surveyPt} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Survey;
