import React, { useEffect, useState } from "react";
import { ProviderAddRxController } from "../../../../utils/globalState";
import { getDrug } from "../../../../services/Provider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Unstable_Grid2";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

function Medication() {
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [medsList, setMedsList] = useState([]);
  const [medsSuggestion, setMedsSuggestion] = useState([]);
  const [selectedMeds, setSelectedMeds] = useState([]);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [msg, setMsg] = useState("");
  const [medDosage, setMedDosage] = useState({ quantity: "", refills: "" });

  useEffect(() => {
    selectedMeds.map((m, i) => {
      if (
        medDosage[`quantity-${i}`] === undefined ||
        medDosage[`refills-${i}`] === undefined
      ) {
        setMedDosage({
          ...medDosage,
          [`quantity-${i}`]: "",
          [`refills-${i}`]: "",
        });
      }
    });
  }, [selectedMeds]);

  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  const handelGetMedications = (medInput) => {
    getDrug(medInput)
      .then((res) => {
        setMedsList(res.data.results[0].products);
        setMedsSuggestion(
          res.data.results[0].products.map((med) => {
            return `${med.brand_name} - ${med.active_ingredients[0].strength}`;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: "Medication",
      },
    });
  }, []);

  const handelSelect = (event, newValue) => {
    setValue(newValue);
    setMsg("");

    if (newValue !== null) {
      let selectedMed = medsList.find(
        (med) =>
          newValue.split(" - ")[0] === med.brand_name &&
          newValue.split(" - ")[1] === med.active_ingredients[0].strength
      );

      if (
        selectedMeds.some(
          (meditem) =>
            meditem.name === selectedMed.brand_name &&
            meditem.sig === selectedMed.active_ingredients[0].strength
        )
      ) {
        setMsg("Medication Already In The Medications List.");
      } else {
        setSelectedMeds([
          ...selectedMeds,
          {
            name: selectedMed.brand_name,
            type: selectedMed.dosage_form,
            sig: selectedMed.active_ingredients[0].strength,
            quantity: "",
            refills: "",
          },
        ]);
        setMsg("New Medication Added.");
        setValue("");
      }
    }
  };
  const handelDelete = (med) => {
    setSelectedMeds(
      selectedMeds.filter(
        (newmed) => newmed.name !== med.name && newmed.sig !== med.sig
      )
    );
    setMsg("One Medication Deleted.");
  };

  const handelDosageChange = (event, med) => {
    if (event.target.value >= 0) {
      setMedDosage({
        ...medDosage,
        [event.target.name]: event.target.value,
      });

      setSelectedMeds(
        selectedMeds.map((newmed) => {
          if (newmed.name === med.name && newmed.sig === med.sig) {
            if (event.target.name.split("-")[0] === "quantity") {
              newmed.quantity = event.target.value;
            }
            if (event.target.name.split("-")[0] === "refills") {
              newmed.refills = event.target.value;
            }
          }
          return newmed;
        })
      );
    } else {
      setMsg("Please Enter Positive Numbers Only");
    }
  };

  const handelConfirm = (event) => {
    event.preventDefault();
    if (selectedMeds.some((i) => i.quantity === "")) {
      setMsg("Please Fill The Missing Quantity");
    } else if (selectedMeds.some((i) => i.refills === "")) {
      setMsg("Please Fill The Missing Refills Number");
    } else {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, medications: selectedMeds },
          completeflag: { ...rxController.completeflag, Medication: true },
        },
      });
    }
  };

  return (
    <>
      <p> {msg !== "" && msg} </p>
      <Autocomplete
        value={value}
        onChange={handelSelect}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          handelGetMedications(newInputValue);
        }}
        id="controllable-states-demo"
        options={medsSuggestion}
        sx={{ width: "100%", marginBottom: "10px" }}
        renderInput={(params) => (
          <TextField {...params} label="Select Medication..." />
        )}
      />
      <Divider />
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Stack spacing={2}>
          {selectedMeds.map((med, ind) => {
            return (
              <Paper>
                <Card>
                  <CardHeader
                    sx={{ backgroundColor: "#a8beda" }}
                    subheader={`${med.name} - ${med.sig}`}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid xs={4}>
                        <form className="row g-2">
                          <div className="col-auto">
                            <label htmlFor="Quantity">Quantity</label>
                            <input
                              type="number"
                              className="form-control"
                              id="Quantity"
                              onChange={(event) =>
                                handelDosageChange(event, med)
                              }
                              name={`quantity-${ind}`}
                              value={medDosage[`quantity-${ind}`]}
                            />
                          </div>
                          <div className="col-auto">
                            <label htmlFor="Refills">Refills</label>
                            <input
                              type="number"
                              className="form-control"
                              id="Refills"
                              onChange={(event) =>
                                handelDosageChange(event, med)
                              }
                              name={`refills-${ind}`}
                              value={medDosage[`refills-${ind}`]}
                            />
                          </div>
                        </form>
                      </Grid>
                      <Grid xs={8}>
                        <List
                          sx={{ width: "100%", bgcolor: "background.paper" }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          subheader={
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                              sx={{
                                textAlign: "center",
                                backgroundColor: "#a8beda",
                                fontSize: "large",
                              }}>
                              Medication Information
                            </ListSubheader>
                          }>
                          <ListItemText
                            primary={`Medication Name : ${med.name}`}
                          />
                          <ListItemText primary={`SIG : ${med.sig}`} />
                          <ListItemText
                            primary={`Medication Dosage Form : ${med.type}`}
                          />
                          <ListItemText
                            primary={`Number of refills : ${med.refills}`}
                          />
                          <ListItemText
                            primary={`Quantity Prescribed  : ${med.quantity}`}
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions sx={{ float: "right" }}>
                    <Button
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => {
                        handelDelete(med);
                      }}>
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            );
          })}
        </Stack>
      </Box>
      {selectedMeds.length > 0 && (
        <button
          className="btn btn-outline-primary  mt-2"
          style={{ width: "100%" }}
          type="button"
          onClick={handelConfirm}>
          Confirm
        </button>
      )}
    </>
  );
}

export default Medication;
