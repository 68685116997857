import React, { useState } from "react";
import { SideBar } from "../../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";

import "../../../styles/commonStyles/nav.css";

const Navigator = () => {
  const [isNavContentShown, setNavContentShown] = useState(false);
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");

  const handleClick = (index) => {
    setNavContentShown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  return (
    <div className={isDivVisible ? "" : "toggle-Navigation"} id="side-bar-nav">
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/pharmacy-admin/dashboard"
                  className={`nav-link ${
                    isNavContentShown["Dashboard"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-speedometer2"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Dashboard
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Admin"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Admin")}>
                  <span>Admin Tools</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Admin"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Admin"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-codes`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-fingerprint"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Register Codes</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-users`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-video2"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Pharmacy Users</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/add-roles`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-badge-fill"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Roles Management</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/pharmacy-logo`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-image"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Pharmacy Logo</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/messages-groupe`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-wechat"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Messaging groups</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/cp-owners`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className="bi bi-person-lines-fill"
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage CP-Owners</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/announcements`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-menu-button-wide"}`}
                        style={{ fontSize: "1.5rem" }}></i>

                      <span>Manage Announcements</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Provider"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Provider")}>
                  <span>Provider Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Provider"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Provider"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/add-provider`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-plus-fill"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Add Provider</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-providers`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-people-fill"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Provider</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Patient"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Patient")}>
                  <span>Patient Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Patient"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Patient"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/add-patient`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-plus"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Add Patient</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-patients`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-people"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Patients</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/add-bulk-patients`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-people"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Add Bulk Patients</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Messages"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Messages")}>
                  <span>Messages Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Messages"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Messages"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/messages`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-chat-dots"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Messages</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/bulk-messages`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-chat-square-quote"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Bulk Messages</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/birthday-messages`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-chat-square-heart"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Birthday Messages</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Forms"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Forms")}>
                  <span>Forms Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Forms"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Forms"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-forms`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-ui-checks"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Forms</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/forms-history`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-clock-history"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Forms History</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/forms-analytics`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-graph-up"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Forms Analytics</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Documents"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Documents")}>
                  <span>Documents Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Documents"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Documents"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-documents`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-files"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Documents</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/documents-history`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-file-earmark-medical"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Documents History</span>
                    </NavLink>
                  </li>
                  {/* documents-analytics */}
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/documents-analytics`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-graph-up"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Documents Analytics</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Survey"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Survey")}>
                  <span>Survey Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Survey"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Survey"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-surveys`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-kanban"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Surveys</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/survey-dissatisfaction`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-emoji-frown"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Survey Dissatisfaction</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["CarePlan"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("CarePlan")}>
                  <span>Care Plan</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["CarePlan"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["CarePlan"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/cp-center/scheduler`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-calendar3"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Calendar</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/cp-center/care-plan-manager`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className="bi bi-heart-pulse"
                        style={{ fontSize: "1.5rem" }}></i>
                      {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                      <span>Care Plan Manager</span>
                    </NavLink>
                  </li>
                  {/* cp-history */}
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/cp-center/cp-history`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className="bi bi-file-earmark-medical"
                        style={{ fontSize: "1.5rem" }}></i>
                      {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                      <span>Care Plan History</span>
                    </NavLink>
                  </li>
                  {/* forms-lib */}
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/cp-center/forms-lib`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className="bi bi-collection-fill"
                        style={{ fontSize: "1.5rem" }}></i>
                      {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                      <span>Forms Library</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
