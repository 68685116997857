import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import 'survey-core/defaultV2.min.css';
import {
  sendSMS,
  sendEmail,
  generateToken,
  handleEncryption,
} from '../../services/Common';
import {
  getResponseByid,
  handleSetDraft,
  handleFormResponse,
  getProfile,
} from '../../services/PharmacyUser';
import { getFormsByCreatorID } from '../../services/Provider';

const ApplessFormPr = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sentInstanceId = urlParams.get('t');
  const sentFormId = urlParams.get('fid');
  const recipientId = urlParams.get('rid');
  const providerId = urlParams.get('pr');
  const [otpprgressflag, setOtpprgressflag] = useState('phone');
  const [completed, setCompleted] = useState(
    localStorage.getItem('completed') === 'true' ? true : false
  );
  const [urlisvalid, setUrlisvalid] = useState(false);
  const [user, setUser] = useState({});
  const [Loadingflag, setLoadingflag] = useState(true);
  const [phoneinput, setPhoneinput] = useState('');
  const [phonenumberisvalid, setPhonenumberisvalid] = useState(true);
  const [otpinput, setOtpinput] = useState('');
  const [otpisvalid, setOtpisvalid] = useState(true);
  //   const [intervalId, setIntervalId] = useState(null);
  //   const [resendotpflag, setResendotpflag] = useState(false);
  const [form, setForm] = useState({});
  const [activeLink, setActiveLink] = useState(true);
  const [resdata, setResdata] = useState({});
  const [sendingotp, setSndingOtp] = useState(false);
  const [authflag, setAuthflag] = useState(
    localStorage.getItem('authflag') || false
  );

  useEffect(() => {
    generateToken(recipientId).then((res) => {
      localStorage.setItem('Token', res.data.token);

      if (sentInstanceId && sentFormId && recipientId && providerId) {
        setUrlisvalid(true);
        // 1- Get the sending instance to get the status (completed or not)

        getResponseByid(sentInstanceId).then((res) => {
          if (
            res.data[0].isLinkDisabled === true ||
            res.data[0].isLinkDisabled.toString() === '1'
          ) {
            setActiveLink(false);
          }

          if (res?.data[0]?.value !== null) {
            setResdata(JSON.parse(res?.data[0]?.value));
          }

          res?.data[0]?.status?.toLowerCase() === 'completed' &&
            setCompleted(true);
          res?.data[0]?.status?.toLowerCase() === 'completed' &&
            localStorage.setItem('completed', true);
        });
        // 2- Get the form by ProviderId
        getFormsByCreatorID(providerId).then((res) => {
          if (
            res?.data?.length === 0 ||
            res.data.find((form) => form.ID.toString() === sentFormId) ===
              undefined
          ) {
            setUrlisvalid(false);
          } else {
            res?.data &&
              setForm(
                JSON.parse(
                  res.data.find((form) => form.ID.toString() === sentFormId)
                    .formText
                )
              );
          }
        });
        // 3- Get the recipient by id
        getProfile(recipientId)
          .then((result) => {
            setLoadingflag(false);
            if (result?.data?.data?.user.length === 0) {
              setUrlisvalid(false);
            }

            result?.data?.data?.user[0] && setUser(result.data.data.user[0]);
          })
          .catch((err) => {
            setUrlisvalid(false);
          });
      } else {
        setUrlisvalid(false);
        setLoadingflag(false);
      }
    });
  }, [sentInstanceId, sentFormId, recipientId, providerId]);

  const handleSendOTP = () => {
    // clearInterval(intervalId);

    // setResendotpflag(false);
    const val = Math.floor(1000 + Math.random() * 9000);
    localStorage.setItem('otp', handleEncryption(val.toString()));

    sendSMS({
      to: user?.phone,
      body: `Your CuroRx One Time Password is ${val}`,
    })
      .then((res) => {
        setOtpprgressflag('otp');
        setSndingOtp(false);
      })
      .catch((err) => {
        console.error(err);
        sendEmail({
          to: user?.email,
          message: ` Your CuroRx One Time Password is ${val}`,
        })
          .then((res) => {
            setOtpprgressflag('otp');
            setSndingOtp(false);
          })
          .catch((err) => {
            setPhonenumberisvalid(false);
          });
      });
  };

  const handleVerifyNumber = () => {
    setSndingOtp(true);
    if (phoneinput !== '') {
      if (phoneinput === user.phone || phoneinput === user.email) {
        setPhonenumberisvalid(true);
        handleSendOTP();
      } else {
        setPhonenumberisvalid(false);
        setSndingOtp(false);
      }
    } else {
      setPhonenumberisvalid(false);
      setSndingOtp(false);
    }
  };

  const handleVerifyOTP = () => {
    if (otpinput !== '') {
      if (handleEncryption(otpinput) === localStorage.getItem('otp')) {
        setOtpisvalid(true);
        setOtpprgressflag('form');
        localStorage.setItem('authflag', true);
        setAuthflag(true);
      } else {
        setOtpisvalid(false);
      }
    }
  };

  if (Loadingflag) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    const servey = new Model(form);
    if (Object.keys(resdata).length > 0) {
      servey.data = resdata;
    }
    servey.onValueChanged.add((sender, options) => {
      handleSetDraft({ value: sender.data }, sentInstanceId).then((res) => {});
    });

    servey.onComplete.add((sender, options) => {
      setCompleted(true);
      localStorage.setItem('completed', true);
      handleFormResponse({ value: sender.data }, sentInstanceId).then(
        (res) => {}
      );
    });

    return (
      <>
        {!activeLink ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100vh' }}
          >
            <h4 className="">
              The Page you requested is no longer available, sorry for any
              inconvenience
            </h4>
          </div>
        ) : (
          <div>
            {urlisvalid ? (
              <div>
                {completed ? (
                  <h1>Thank you for your response</h1>
                ) : (
                  <>
                    {authflag ? (
                      <div>
                        <Survey model={servey} />
                      </div>
                    ) : (
                      <div>
                        {otpprgressflag === 'phone' ? (
                          <Box
                            component="span"
                            sx={{
                              display: 'flex',
                              mx: '2px',
                              transform: 'scale(0.8)',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '100px',
                            }}
                          >
                            <Card sx={{ minWidth: 500 }}>
                              <CardHeader
                                title="Welcome"
                                subheader="Please enter your phone number to receive the OTP"
                                sx={{
                                  textAlign: 'center',
                                  borderRadius: '10px 10px 0px 0px',
                                  background:
                                    'linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)',
                                  color: 'whitesmoke !important',
                                }}
                              ></CardHeader>
                              <CardContent>
                                <Box
                                  component="form"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleVerifyNumber();
                                  }}
                                  sx={{
                                    '& .MuiTextField-root': {
                                      m: 1,
                                      width: '50ch',
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    error={!phonenumberisvalid}
                                    id="outlined-error-helper-text"
                                    label="Phone number or Email"
                                    helperText={
                                      phonenumberisvalid
                                        ? ''
                                        : 'Please enter a valid phone number or email'
                                    }
                                    placeholder="ex: 1234568765 or 1k5Jg@example.com"
                                    value={phoneinput}
                                    onChange={(e) =>
                                      setPhoneinput(e.target.value)
                                    }
                                  />
                                </Box>
                              </CardContent>
                              <CardActions
                                style={{
                                  float: 'right',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                {sendingotp ? (
                                  <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                  >
                                    Sending
                                  </LoadingButton>
                                ) : (
                                  <Button
                                    onClick={handleVerifyNumber}
                                    variant="contained"
                                  >
                                    Send OTP
                                  </Button>
                                )}
                              </CardActions>
                            </Card>
                          </Box>
                        ) : otpprgressflag === 'otp' ? (
                          <Box
                            component="span"
                            sx={{
                              display: 'flex',
                              mx: '2px',
                              transform: 'scale(0.8)',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '100px',
                            }}
                          >
                            <Card sx={{ minWidth: 500 }}>
                              <CardHeader
                                title="Plewse enter the OTP"
                                sx={{
                                  textAlign: 'center',
                                  borderRadius: '10px 10px 0px 0px',
                                  background:
                                    'linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)',
                                  color: 'whitesmoke !important',
                                  height: '70px',
                                }}
                              ></CardHeader>
                              <CardContent>
                                <Box
                                  component="form"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleVerifyOTP();
                                  }}
                                  sx={{
                                    '& .MuiTextField-root': {
                                      m: 1,
                                      width: '50ch',
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    error={!otpisvalid}
                                    id="outlined-error-helper-text"
                                    label="OTP"
                                    // defaultValue=""
                                    helperText={
                                      otpisvalid ? '' : 'OTP is not valid'
                                    }
                                    placeholder="ex: 0000"
                                    value={otpinput}
                                    onChange={(e) =>
                                      setOtpinput(e.target.value)
                                    }
                                  />
                                </Box>
                              </CardContent>
                              <CardActions
                                style={{
                                  float: 'right',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Button
                                  onClick={handleVerifyOTP}
                                  variant="contained"
                                >
                                  Verify OTP
                                </Button>
                              </CardActions>
                            </Card>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <h1>URL not valid</h1>
            )}
          </div>
        )}
      </>
    );
  }
};
export default ApplessFormPr;
