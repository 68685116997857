import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/common/layout/Header";
import Navigator from "../components/pharmacyUser/pharmacyUserNavs/pharmacyUserNav";
import Footer from "../components/common/layout/Footer";
import Profile from "../pages/pharmacyUser/Profile";
import CsvFileUpload from "../pages/pharmacyUser/serveyCenter/CsvFileUpload";
import EditAndReview from "../pages/pharmacyUser/serveyCenter/EditAndReview";
import Analytics from "../pages/pharmacyUser/serveyCenter/Analytics";
import Results from "../pages/pharmacyUser/serveyCenter/Results";
import NotificationsRecipients from "../pages/pharmacyUser/serveyCenter/NotificationsRecipients";
import BirthdayLog from "./../pages/pharmacyUser/BirthdayLog";
import MassText from "./../pages/pharmacyUser/MassText";
import FormsHistory from "./../pages/pharmacyUser/FormsHistory";
import FormsManage from "./../pages/pharmacyUser/FormsManage";
import DocumentsHistory from "./../pages/pharmacyUser/DocumentsHistory";
import DocumentsManage from "./../pages/pharmacyUser/DocumentsManage";
import AddProvider from "./../pages/pharmacyUser/AddProvider";
import ManageProviders from "./../pages/pharmacyUser/ManageProviders";
import AddPatient from "./../pages/pharmacyUser/AddPatient";
import ManagePatients from "./../pages/pharmacyUser/ManagePatients";
import SurveyCenter from "./../pages/pharmacyUser/serveyCenter/SurveyCenter";
import ProfilePictureUploader from "./../components/common/profile/ProfilePictureUploader";
import SurveyDissatisfaction from "./../pages/pharmacyUser/SurveyDissatisfaction";
import Dashboard from "./../pages/pharmacyUser/Dashboard";
import Messages from "./../pages/pharmacyUser/Messages";
import SessionTimeOut from "./../components/common/SessionTimeOut/SessionTimeOut";
import BulkCreatePatients from "./../pages/pharmacyUser/AddBulkPatients";
import NotFound from "./../components/common/NotFound";
import ContactUs from "./../components/common/ContactUs";
import FormsAnalytics from "../pages/pharmacyUser/FormsAnalytics";
import DocumentsAnalytics from "./../pages/pharmacyUser/DocumentsAnalytics";
import NotAuthorized from "./../components/common/NotAuthorized";
import PharmacyProtectedRoutes from "./../utils/PharmacyProtectedRoutes";
import CarePlanManager from "../pages/pharmacyUser/CarePlan/CarePlanManager";
import Scheduler from "./../pages/pharmacyUser/CarePlan/Scheduler";
import CarePlanHistory from "../pages/pharmacyUser/CarePlan/CarePlanHistory";
import DeletedEvents from "./../pages/pharmacyUser/CarePlan/DeletedEvents";
import FormsLib from "../pages/pharmacyUser/CarePlan/FormsLib";
import CarePlanHistoryDetails from "./../pages/pharmacyUser/CarePlan/CarePlanHistoryDetails";
import InitFormFill from "../pages/pharmacyUser/CarePlan/InitFormFill";
import DissatisfactionDetails from "./../components/pharmacyUser/surveyCenter/DissatisfactionDetails";

const PharmacyUserRoutes = () => {
  return (
    <div>
      <SessionTimeOut />
      <Header />
      <Navigator />
      <Routes>
        <Route path="profile" element={<Profile />} />
        <Route path="profile-picture" element={<ProfilePictureUploader />} />
        <Route path="contact-us" element={<ContactUs />} />

        {/*  ================ RESTRICTED ROUTES ================ */}
        <Route
          path="/"
          element={<PharmacyProtectedRoutes restrictedPrivilege="Dashboard" />}>
          <Route index path="/" element={<Dashboard />} />
        </Route>
        <Route
          path="/"
          element={<PharmacyProtectedRoutes restrictedPrivilege="Dashboard" />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Add Provider" />
          }>
          <Route path="add-provider" element={<AddProvider />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Manage Providers" />
          }>
          <Route path="manage-providers" element={<ManageProviders />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Add Patient" />
          }>
          <Route path="add-patient" element={<AddPatient />} />
        </Route>
        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Add Bulk Patients" />
          }>
          <Route path="add-bulk-patients" element={<BulkCreatePatients />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Manage Patients" />
          }>
          <Route path="manage-patients" element={<ManagePatients />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Manage Documents" />
          }>
          <Route path="manage-documents" element={<DocumentsManage />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Documents History" />
          }>
          <Route path="documents-history" element={<DocumentsHistory />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Documents Analytics" />
          }>
          <Route path="documents-analytics" element={<DocumentsAnalytics />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Manage Forms" />
          }>
          <Route path="manage-forms" element={<FormsManage />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Forms History" />
          }>
          <Route path="forms-history" element={<FormsHistory />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Forms Analytics" />
          }>
          <Route path="forms-analytics" element={<FormsAnalytics />} />
        </Route>
        <Route
          path="/"
          element={<PharmacyProtectedRoutes restrictedPrivilege="Messages" />}>
          <Route path="messages" element={<Messages />} />
        </Route>

        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Birthday Messages" />
          }>
          <Route path="birthday-messages" element={<BirthdayLog />} />
        </Route>
        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Bulk Messages" />
          }>
          <Route path="bulk-messages" element={<MassText />} />
        </Route>
        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Manage Surveys" />
          }>
          <Route path="manage-surveys" element={<SurveyCenter />} />
          <Route path="manage-surveys/*">
            <Route path="csv-file-upload" element={<CsvFileUpload />} />
            <Route path="edit-and-review" element={<EditAndReview />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="results" element={<Results />} />
            <Route
              path="notifications-recipients"
              element={<NotificationsRecipients />}
            />
          </Route>
        </Route>
        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Survey Dissatisfaction" />
          }>
          <Route
            path="survey-dissatisfaction"
            element={<SurveyDissatisfaction />}
          />
          <Route
            path="survey-dissatisfaction/:responseId"
            element={<DissatisfactionDetails />}
          />
        </Route>
        <Route
          path="/"
          element={
            <PharmacyProtectedRoutes restrictedPrivilege="Careplan Center" />
          }>
          <Route path="cp-center/*">
            <Route path="care-plan-manager" element={<CarePlanManager />} />
            <Route path="scheduler" element={<Scheduler />} />
            <Route
              path="scheduler/deleted-events"
              element={<DeletedEvents />}
            />
            <Route path="cp-history" element={<CarePlanHistory />} />
            <Route path="cp-history/:id" element={<CarePlanHistoryDetails />} />
            <Route
              path="care-plan-manager/:id"
              element={<CarePlanHistoryDetails />}
            />
            <Route
              path=""
              element={
                <PharmacyProtectedRoutes restrictedPrivilege="Careplan Forms" />
              }>
              <Route path="forms-lib" element={<FormsLib />} />
            </Route>
            <Route path="init-form-fill" element={<InitFormFill />} />
            <Route path="notAuthorized" element={<NotAuthorized />} />
          </Route>
        </Route>

        <Route path="notAuthorized" element={<NotAuthorized />} />
        {/*  ================ END RESTRICTED ROUTES ================ */}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default PharmacyUserRoutes;
