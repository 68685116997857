import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { handleGetFuForms } from "../../../../services/CarePlan";

const ChooseFuForm = (props) => {
  const pharmacyId = localStorage.getItem("pharmacy");
  const { setForm } = props;
  const [forms, setForms] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    handleGetFuForms(pharmacyId)
      .then((res) => {
        console.log(res);
        setForms(
          res.data.map((form) => {
            form.isSent = form.isSent === 1 ? true : false;
            form.isHidden = form.isHidden === 1 ? true : false;
            form.json = JSON.parse(form.formText);
            return form;
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setForms([]);
      });
  }, [pharmacyId]);
  return (
    <div className="row">
      <div className=" col-12 text-center pt-3 pb-3 w-100 fw-bold d-flex justify-content-center">
        <p>Select Follow-up Form</p>
      </div>

      <div className="col-12 d-flex justify-content-center">
        <Autocomplete
          // className="d-flex justify-content-center"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            setForm(newValue);
            // setValue(newValue);
          }}
          disablePortal
          id="combo-box-demo"
          options={forms}
          sx={{
            width: 300,
          }}
          getOptionLabel={(item) => `${item.json.title}`}
          renderInput={(params) => (
            <TextField {...params} label="Search Form" />
          )}
        />
      </div>
      <br />
    </div>
  );
};

export default ChooseFuForm;
