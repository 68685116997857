import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { getProfile } from "../../../services/PharmacyUser";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-react-schedule";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button, Alert } from "@mui/material";
import { getSystemUserByPharmacyId } from "../../../services/Common";
import {
  handleGetCarePlanById,
  handleUpdateCarePlan,
  handleAddResponse,
} from "../../../services/CarePlan";
import {
  handleAddEvent,
  handleUpdateEvent,
  handleGetEventById,
} from "../../../services/Calendar";

const FillCpFuForm = (props) => {
  const { getAllData, setShowFUfill } = props;
  const queryString = window.location.search;

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const urlParams = new URLSearchParams(queryString);
  let cpid = parseInt(urlParams.get("id"));
  let userID = parseInt(urlParams.get("uid"));
  let pharmacyId = parseInt(urlParams.get("phId"));
  let exceptionString = urlParams.get("except");
  let recDate = parseInt(urlParams.get("d"));

  const recObject = useRef(null);
  let survey = new Model({});

  // eslint-disable-next-line no-unused-vars
  const [invaledUrl, setInvaledUrl] = useState(false);
  const [cp, setCP] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [systemUserId, setSystemUserId] = useState("");
  const [fueditor, setFueditor] = useState(false);
  const [surveyJson, setSurveyJson] = useState({});
  const [resValue, setResValue] = useState({});
  const [recString, setRecString] = useState("");
  const [fuEvent, setFuEvent] = useState({});

  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [location, setLocation] = useState("form");
  const [msg, setMsg] = useState("");
  const [completed, setCompleted] = useState(false);

  const getCpById = async () => {
    handleGetCarePlanById(cpid)
      .then((res) => {
        setCP(res.data);
        setLocation(res.data.isPhoneFu ? "phone" : "form");
        setSurveyJson(JSON.parse(res.data.fuForm.formText));
        if (res.data?.fuEventId) {
          handleGetEventById(res.data.fuEventId).then((re) => {
            setStartDate(dayjs(re.data.StartTime).format("YYYY-MM-DD"));

            setRecString(re.data.RecurrenceRule);
            setFuEvent(re.data);
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setInvaledUrl(true);
      });
  };

  survey = new Model(surveyJson);

  useEffect(() => {
    if (cpid) {
      getCpById();
    } else {
      setInvaledUrl(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpid]);

  useEffect(() => {
    getProfile(userID)
      .then((res) => {
        setCurrentUser(res.data.data.user[0]);
      })
      .catch((err) => {
        console.error(err);
      });

    getSystemUserByPharmacyId(pharmacyId)
      .then((res) => {
        setSystemUserId(res.data.id);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacyId, userID]);

  // handle new response gets fired twice, Debugging needed
  const handleNewResponse = (answers) => {
    const subEventPayload = {
      ...fuEvent,
      RecurrenceID: fuEvent.Id,
      RecurrenceException: exceptionString,
      Status: true,
      StartTime: dayjs(recDate).toISOString(),
      EndTime: dayjs(recDate).toISOString(),
      OwnerId: location === "phone" ? userID : systemUserId,
      RecurrenceRule: recString,
    };

    delete subEventPayload.Id;

    subEventPayload.pharmacyId &&
      handleAddEvent(subEventPayload)
        .then((ceRes) => {
          const newEventId = ceRes?.data?.id;

          if (newEventId) {
            const exceptionStringHandler = (exceptString) => {
              if (!fuEvent.RecurrenceException) {
                return exceptString;
              }
              let exceptionArry = fuEvent.RecurrenceException.split(",");
              if (!exceptionArry.length) {
                return exceptString;
              } else {
                if (exceptionArry.includes(exceptString)) {
                  return fuEvent.RecurrenceException;
                } else {
                  exceptionArry.push(exceptString);
                  return exceptionArry.join(",");
                }
              }
            };
            handleUpdateEvent(fuEvent.Id, {
              updatedBy: userID,
              RecurrenceException: exceptionStringHandler(exceptionString),
              OwnerId: location === "phone" ? userID : systemUserId,
              isAutomated: location === "phone" ? false : true,
              Location: location,
              StartTime: dayjs(startDate).toISOString(),
              RecurrenceRule: recString,
            });
          }
          return newEventId;
        })
        .then((newEventId) => {
          const payload = {
            pharmacyId,
            cpId: cpid,
            cpFormId: cp.initFormId,
            senderId: userID,
            senderInfo: currentUser,
            isInitResponse: false,
            fuSubEventId: newEventId,
            value: JSON.stringify(answers),
            completedOn: new Date(),
          };
          newEventId &&
            handleAddResponse(payload).then(() => {
              setCompleted(true);
              getAllData(fuEvent.Id);
              setTimeout(() => {
                setShowFUfill(false);
                setSearchParams({});
              }, 3000);
            });
        })
        .catch((err) => console.error(err));
  };

  survey.onComplete.add((sender, options) => {
    if (sender.data["followup-schedule"]) {
      setFueditor(true);
      setResValue(sender.data);
    } else {
      handleNewResponse(sender.data);
    }
  });

  const onChange = (args) => {
    setRecString(args.value);
  };

  const handleSubmit = async () => {
    if (cp.fuEventId !== null && recString !== "") {
      if (
        (cp.isPhoneFu && location === "form") ||
        (!cp.isPhoneFu && location === "phone")
      ) {
        await handleUpdateCarePlan(cpid, {
          isPhoneFu: location === "phone" ? true : false,
        });
        setCP({ ...cp, isPhoneFu: location === "phone" ? true : false });
      }

      handleNewResponse(resValue);
      getAllData(fuEvent.Id);
    } else {
      setMsg("Please select follow up schedule.");
    }
  };

  const handleBkBtn = () => {
    survey.clear();
    survey.data = resValue;
    setFueditor(false);
  };

  const getDisabledValue = (careplan, event) => {
    if (!careplan.fuEventId) {
      return false;
    } else {
      if (event.RecurrenceRule !== recString) {
        return false;
      }

      if (dayjs(event.StartTime).format("YYYY-MM-DD") !== startDate) {
        return false;
      }

      if (
        (careplan.isPhoneFu && location === "form") ||
        (!careplan.isPhoneFu && location === "phone")
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      {completed ? (
        <Alert severity="success">Thank you for completing the form.</Alert>
      ) : (
        <>
          {fueditor ? (
            <div className="row">
              {msg && <Alert severity="error">{msg}</Alert>}
              <div className="col-12">
                <IconButton aria-label="settings" onClick={() => handleBkBtn()}>
                  <KeyboardBackspaceIcon />
                </IconButton>
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  name="startDate"
                  className="form-control mb-3"
                />

                <label className="form-label">Follow Up Preference</label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  name="location"
                  defaultValue={location}
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}>
                  <option value="phone">Phone call follow up</option>
                  <option value="form">Form follow up</option>
                </select>
                <RecurrenceEditorComponent
                  id="RecurrenceEditor"
                  ref={recObject}
                  value={recString}
                  change={onChange}
                  frequencies={["daily", "weekly", "monthly", "yearly"]}
                />
                <Button
                  disabled={getDisabledValue(cp, fuEvent)}
                  variant="contained"
                  color="success"
                  sx={{ float: "right" }}
                  onClick={handleSubmit}>
                  {fuEvent.RecurrenceRule ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          ) : (
            <Survey model={survey} />
          )}
        </>
      )}
    </>
  );
};

export default FillCpFuForm;
