import React, { useState, useEffect } from "react";
import { handleAddPrivilege, handleGetPrivileges } from "../../services/Admin";
import Accordion from "react-bootstrap/Accordion";
import { SideBar } from "../../utils/globalState";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
const ManagePrivilege = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const [input, setInput] = useState({});

  const handleChanges = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddPrivilege({
      name: input.name,
      description: input.description,
      icon: input.icon,
    }).then((res) => {
      getPrivileges();
      setInput({ name: "", description: "", icon: "" });
    });
  };

  const [privilegeList, setPrivilegeList] = useState([]);
  const getPrivileges = () => {
    handleGetPrivileges().then((res) => {
      res?.data?.data && setPrivilegeList(res.data.data);
    });
  };
  useEffect(() => {
    getPrivileges();
  }, []);

  const handleEdit = (id) => {};

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Add New Privilege</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Manage Privilege</li>
              <li className="breadcrumb-item active">Add New Privilege</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="card text-center mt-3 mt-md-5 mb-3 mb-md-4">
              <div className="card-header">Add New Privilege</div>
              <div className="card-body">
                <form className="row gx-3 gy-2 align-items-center">
                  <div className="col-12  col-sm-4 col-xl-3">
                    <label
                      className="visually-hidden"
                      htmlFor="specificSizeInputName"
                    >
                      Privilege Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="specificSizeInputName"
                      placeholder="Privilege Name"
                      onChange={handleChanges}
                      value={input.name}
                      name="name"
                    />
                  </div>
                  <div className="col-12  col-sm-4 col-xl-3">
                    <label
                      className="visually-hidden"
                      htmlFor="specificSizeInputDescription"
                    >
                      Privilege Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="specificSizeInputDescription"
                      placeholder="Privilege Description"
                      onChange={handleChanges}
                      value={input.description}
                      name="description"
                    />
                  </div>
                  <div className="col-12  col-sm-4 col-xl-3">
                    <label
                      className="visually-hidden"
                      htmlFor="specificSizeInputIcon"
                    >
                      Privilege Icon
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="specificSizeInputIcon"
                      placeholder="Privilege Icon"
                      onChange={handleChanges}
                      value={input.icon}
                      name="icon"
                    />
                  </div>
                  <div className="col-12 col-sm-auto">
                    <button
                      type="submit"
                      className="btn btn-success col-12 col-sm-auto px-4"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="card-footer text-muted"></div>
            </div>
            <Accordion>
              {privilegeList !== "No privilege has yet been added!" ? (
                privilegeList.map((p, i) => {
                  return (
                    <Accordion.Item eventKey={i} key={p.id}>
                      <Accordion.Header>
                        <div className="row w-100">
                          <p className="col-12 col-sm-6  col-xl-4 ">
                            <strong>Privilege Name: </strong>
                            {p.name}
                          </p>
                          <p className="col-12 col-sm-6  col-xl-4 mb-0">
                            <strong>Privilege Status:</strong>
                            {p.active ? " Active" : " Inactive"}
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label={`Privilege Description: ${p.description}`}
                            variant="outlined"
                            onClick={handleEdit}
                          />
                          <Chip
                            label={`Privilege Icon: ${p.icon}`}
                            variant="outlined"
                            onClick={handleEdit}
                            icon={
                              <i
                                className={p.icon}
                                style={{
                                  fontSize: "1.5rem",
                                  padding: "0.4rem",
                                }}
                              ></i>
                            }
                          />
                        </Stack>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              ) : (
                <p>No privilege has yet been added!</p>
              )}
            </Accordion>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManagePrivilege;
