import React from "react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/modern.min.css";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { Buffer } from "buffer";
import { handleGetDocFiles } from "../../../services/PharmacyUser";

function DocsCreator(props) {
  const survey = new Model(props.doc.json);
  survey.data = {
    pdfUrl: props.doc.json.pages[0].elements.find((x) => x.type === "html")
      .name,
  };

  survey.onComplete.add(function (result) {
    async function modifyPdf(results) {
      // Fetch an existing PDF document
      const filename =
        results.pdfUrl.split("/")[results.pdfUrl.split("/").length - 1];
      const existingPdfBytes = await handleGetDocFiles(filename).then((res) => {
        return Buffer.from(res.data.file.data, "base64");
      });

      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Embed the Helvetica font
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // Get the first page of the document
      const pages = pdfDoc.getPages();
      const firstPage = pages[pages.length - 1];

      // Fetch JPEG image

      const pngImage = await pdfDoc.embedPng(results.signature);
      const pngDims = pngImage.scale(0.5);

      // Get the width and height of the first page

      firstPage.drawText("___________________________________________", {
        x: 350,
        y: pngDims.height + 30,
        font: helveticaFont,
        size: 12,
      });
      firstPage.drawText("__________Signed Above {Patient Name}_________", {
        x: 350,
        y: 5,
        font: helveticaFont,
        size: 12,
      });

      // Add a blank page to the document

      firstPage.drawImage(pngImage, {
        x: 350,
        y: 20,
        width: pngDims.width,
        height: pngDims.height,
      });

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      var blob = new Blob([pdfBytes], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "new.pdf";
      link.click();
    }
    modifyPdf(result.data);
  });

  return (
    <>
      <Survey model={survey} />
    </>
  );
}

export default DocsCreator;
