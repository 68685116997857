import React, { useState, useEffect, useRef } from "react";
import { decodeToken } from "react-jwt";
import { SideBar } from "../../utils/globalState";
import { StyledEngineProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useModal } from "react-hooks-use-modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Unstable_Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useSnackbar from "@mui/base/useSnackbar";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { css, keyframes } from "@mui/system";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { MuiFileInput } from "mui-file-input";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HideSource from "@mui/icons-material/HideSource";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  getPrivelge,
  toggleDocumentHide,
  getDocs,
  createDoc,
  deleteDocument,
  handleStoreFiles,
} from "../../services/PharmacyUser";
import SendDoc from "../../components/pharmacyUser/documentsCenter/SendDoc";
import DocsCreator from "../../components/pharmacyUser/documentsCenter/DocsCreator";
import { getUserById, getUserRole } from "../../services/Common";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const DocumentsManage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyID = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [loadingflag, setLoadingflag] = useState(false);
  const [pdfelements, setPdfelements] = useState({});
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openfsd, setOpenfsd] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [docTitle, setdocTitle] = useState("");
  const [randflag, setRandflag] = useState("init");
  const [thisdoc, setThisdoc] = useState({});
  const [deletebuttonflag, setDeletebuttonflag] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [hiddenVisibility, setHiddenVisibility] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState({});

  const [on, setOn] = useState(false);
  const [yesornotitle, setYesornotitle] = useState("");

  const [search, setSearch] = useState("");
  const [privilege, setPrivilege] = useState([]);

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    fetchDocs();
    userID &&
      getUserById(userID)
        .then((res) => {
          setUser(res.data.data.user[0]);
          return res.data.data.user[0].type;
        })
        .then((type) => {
          type.toLowerCase() !== "pharmacyadmin" &&
            getUserRole(userID).then((res) => {
              setUserRole(res.data.data[0].roleID || "");
            });
        })
        .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  useEffect(() => {
    userRole &&
      getPrivelge(userRole)
        .then((res) => {
          setPrivilege(res.data.data.map((x) => x.name));
        })
        .catch((err) => console.error(err));
  }, [userRole]);

  const fetchDocs = () => {
    getDocs(pharmacyID).then((res) => {
      setData(
        res.data
          .map((item) => {
            return {
              ID: item.ID,
              json: JSON.parse(item.formText),
              text: "",
              isHidden: item.isHidden === 1 ? true : false,
              isSent: item.isSent === 1 ? true : false,
            };
          })
          .filter((item) => item.json.pages)
          .reverse()
      );
    });
  };

  //  ======================== Images  ======================

  const onConvert = async () => {
    setLoadingflag(true);

    const formData = new FormData();
    formData.append("pdf", selectedFile);
    formData.append("type", "pdf");
    handleStoreFiles(formData, userID)
      .then((res) => {
        // const filename =
        //   res.data.url.split("/")[res.data.url.split("/").length - 1];

        // pharmacistHelper.handleGetFiles({filename}).then(resp => {
        setPdfelements({
          type: "html",
          name: `${res.data.url}`,
          html: `<object>
                              <embed  src="https://drive.google.com/viewerng/viewer?embedded=true&url=${res.data.url}" type='application/pdf' style="width:900px; height:900px;">
                             <p>Unable to display PDF file?. <a href="${res.data.url}">Download</a> instead.</p>
                           </embed >
                           </object>`,
        });

        let div = document.getElementById("pdf");
        div.innerHTML = "";
        div.innerHTML = ` <object>
                             <embed  src="https://drive.google.com/viewerng/viewer?embedded=true&url=${res.data.url}" type='application/pdf' style="width:900px; height:900px;">
                            <p>Unable to display PDF file?. <a href="${res.data.url}">Download</a> instead.</p>
                          </embed >
                          </object>`;
        setLoadingflag(false);
        // });
      })
      .catch((err) => {});
  };

  // ========================================================

  //   ====================== Material UI  ======================

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));
  // ============================================== Full Screen Dialog ================================

  const handleClickOpen = () => {
    setOpenfsd(true);
    setScroll("body");
  };

  const handlefsdClose = () => {
    setOpenfsd(false);
  };

  useEffect(() => {
    if (openfsd) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openfsd]);

  //   ========================================== Snackbar ==========================================
  const blue = {
    50: "#F0F7FF",
    400: "#3399FF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    200: "#E0E3E7",
  };

  const snackbarInRight = keyframes`
      from {
        transform: translateX(100%);
      }
    
      to {
        transform: translateX(0);
      }
    `;

  const CustomSnackbar = styled("div")(
    ({ theme }) => css`
      position: fixed;
      z-index: 5500;
      display: flex;
      right: 16px;
      top: 16px;
      left: auto;
      justify-content: start;
      max-width: 560px;
      min-width: 300px;
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? blue[600] : blue[400]};
      box-shadow: ${theme.palette.mode === "dark"
        ? `0 5px 13px -3px rgba(0,0,0,0.4)`
        : `0 5px 13px -3px ${grey[200]}`};
      padding: 0.75rem;
      color: ${theme.palette.mode === "dark" ? "#fff" : blue[900]};
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      animation: ${snackbarInRight} 500ms;
      transition: transform 0.2s ease-out;
    `
  );

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  //  ========================================== Snackbar Ends ==========================================

  //  ====================== Material UI Ends  ======================
  //   ====================== Modal ======================

  const [DeleteModal, openDeleteModal, closeDelete] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  //   ====================== Modal Ends ======================

  const handleChanges = (e) => {
    setdocTitle(e.target.value);
  };

  const handleCreate = () => {
    setMsg("");

    if (docTitle === "" && Object.keys(pdfelements).length === 0) {
      setMsg("Please enter a title for the document and upload a pdf file");
      setOpenfsd(true);
      handleOpen();
      return;
    }

    if (docTitle === "") {
      setMsg("Please enter a title for the document");
      setOpenfsd(true);
      handleOpen();
      return;
    }

    if (Object.keys(pdfelements).length !== 0) {
      let doc = {
        json: {
          logoPosition: "right",
          pages: [
            {
              name: "page1",
              elements: [pdfelements],
            },
          ],
          title: "Document #2",
          showQuestionNumbers: false,
        },
        text: "",
      };

      doc.json.title = docTitle;
      if (on) {
        doc.json.pages[0].elements.push({
          type: "boolean",
          name: "booleanQuestion",
          indent: 3,
          title: yesornotitle,
          isRequired: true,
        });
      }
      doc.json.pages[0].elements.push({
        type: "signaturepad",
        name: "signature",
        indent: 3,

        penColor: "#000000",
        title: "Please Sign Here",
        isRequired: true,
      });

      createDoc({
        creatorID: userID,
        pharmacyID: pharmacyID,
        formText: doc.json,
        creatorType: "pharmacy",
      })
        .then((res) => {
          setThisdoc(doc);
          setRandflag("creator");
          fetchDocs();
        })
        .catch((err) => {
          console.error(err);
          setMsg("Something went wrong, please try again later");
          setOpenfsd(true);
          handleOpen();
        });
    } else {
      setMsg("Please add a PDF file to continue");
      setOpenfsd(true);
      handleOpen();
    }
  };

  const handleDeleteChanges = (e) => {
    if (e.target.value.toLowerCase() === thisdoc.json.title.toLowerCase()) {
      setDeletebuttonflag(true);
    } else {
      setDeletebuttonflag(false);
    }
  };

  const handleDelete = () => {
    // delete form
    deleteDocument(thisdoc.ID)
      .then(() => {
        setMsg(
          `Document "${thisdoc.json.title}" Has Been Successfully Deleted`
        );
        setData(data.filter((doc) => doc.ID !== thisdoc.ID));
        handleOpen();
      })
      .catch((err) => {
        console.error(err);
        setMsg(
          `Form "${thisdoc.json.title}" is active and can not be deleted `
        );
        handleOpen();
        setMsg(
          `Document "${thisdoc.json.title}" is active and can not be deleted `
        );
      });
  };

  const handleHideDocument = (item) => {
    let docId = item.ID;
    toggleDocumentHide(docId)
      .then(() => {
        setData(
          data.map((doc) =>
            doc.ID !== docId ? doc : { ...doc, isHidden: !doc.isHidden }
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleToggleHidden = () => {
    setHiddenVisibility(!hiddenVisibility);
  };
  useEffect(() => {
    setSearchData(data);
  }, [data]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let searchLower = e.target.value.toLowerCase();
    if (e.target.value === "") {
      setSearchData(data);
    }
    setSearchData(
      data.filter((el) => el.json.title.toLowerCase().includes(searchLower))
    );
  };

  const handleAddQuestion = (e) => {
    setOn(!on);
  };

  const yesornoChanges = (e) => {
    setYesornotitle(e.target.value);
  };

  useEffect(() => {
    if (!on) {
      setYesornotitle("");
    }
  }, [on]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Documents</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Documents Center</li>
              <li
                className={`breadcrumb-item ${
                  randflag === "init" ? "active" : "cstm_breadcrumb"
                }`}
                onClick={() => {
                  setRandflag("init");
                  setThisdoc({});
                }}>
                Manage Documents
              </li>
              {randflag !== "init" && (
                <li className="breadcrumb-item active">
                  {randflag === "creator"
                    ? "Preview Document"
                    : randflag === "send"
                    ? "Send Form"
                    : ""}
                </li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {randflag === "init" ? (
                <>
                  <div className="mb-2">
                    {(privilege.includes("writeForms") ||
                      user.type?.toLowerCase() === "pharmacyadmin") && (
                      <Button
                        className="mx-2 ms-0  mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleClickOpen}>
                        Create New Document
                      </Button>
                    )}
                    {data.some((item) => item.isHidden === true) && (
                      <Button
                        className="mx-sm-2 mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<HideSource />}
                        onClick={() => handleToggleHidden()}>
                        {!hiddenVisibility ? "Show Hidden " : "hide "}
                        Documents
                      </Button>
                    )}
                  </div>
                  <StyledEngineProvider injectFirst>
                    <div className="card px-1 pb-3">
                      <div className="col-12 col-md-6 col-lg-4 my-2">
                        <div className="input-group flex-nowrap  ">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Search Documents"
                            aria-label="Search"
                            aria-describedby="addon-wrapping"
                            onChange={handleSearch}
                            value={search}
                          />
                          <span
                            className="input-group-text"
                            id="addon-wrapping">
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              size={"1x"}
                            />
                          </span>
                        </div>
                      </div>
                      <Box sx={{ width: 1 }}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}>
                          <Box gridColumn="span 12">
                            {searchData.map((item, i) => {
                              return hiddenVisibility ? (
                                <React.Fragment key={item.ID}>
                                  <Item>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                          background: `${
                                            item.isHidden
                                              ? "linear-gradient(-170deg, #e1e2e3,  #687178 )"
                                              : "linear-gradient(-170deg, #ffffff,  #5A99CD )"
                                          }`,
                                        }}>
                                        <Typography
                                          style={{ color: "whitesmoke" }}
                                          key={item.ID}
                                          className="d-flex justify-content-between col-12">
                                          {item.json.title}
                                          <span>
                                            {item.isSent ? (
                                              <span className="text-success fw-bold font-italic me-5">
                                                Active Document
                                              </span>
                                            ) : null}
                                          </span>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        key={item.ID}
                                        style={{
                                          backgroundColor: "transparent",
                                        }}>
                                        <Grid container spacing={2}>
                                          <Grid className="col-12 col-sm-8">
                                            <Stack
                                              spacing={2}
                                              direction={{
                                                xs: "column",
                                                sm: "row",
                                              }}>
                                              <Button
                                                variant="outlined"
                                                onClick={() => {
                                                  setRandflag("send");
                                                  setThisdoc(item);
                                                }}>
                                                Send Document
                                              </Button>
                                              <Button
                                                variant="outlined"
                                                onClick={() => {
                                                  setThisdoc(item);
                                                  setRandflag("creator");
                                                }}>
                                                Preview Document
                                              </Button>
                                            </Stack>
                                          </Grid>
                                          <Grid className="col-12 col-sm-4">
                                            <Stack
                                              spacing={2}
                                              direction="row-reverse">
                                              {(privilege.includes(
                                                "writeForms"
                                              ) ||
                                                user.type?.toLowerCase() ===
                                                  "pharmacyadmin") && (
                                                <Button
                                                  variant="contained"
                                                  style={{ float: "right" }}
                                                  disabled={
                                                    item.isSent ? true : false
                                                  }
                                                  onClick={() => {
                                                    openDeleteModal();
                                                    setThisdoc(item);
                                                  }}
                                                  color="error"
                                                  startIcon={
                                                    <DeleteForeverIcon />
                                                  }>
                                                  Delete
                                                </Button>
                                              )}
                                              <Button
                                                variant="contained"
                                                style={{ float: "right" }}
                                                onClick={() => {
                                                  handleHideDocument(item);
                                                }}
                                                color="warning"
                                                startIcon={<HideSource />}>
                                                {!item.isHidden
                                                  ? "Hide"
                                                  : "Unhide"}
                                              </Button>
                                            </Stack>
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Item>
                                  {i < data.length - 1 ? (
                                    <Divider
                                      style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}{" "}
                                </React.Fragment>
                              ) : (
                                !item.isHidden && (
                                  <React.Fragment key={item.ID}>
                                    <Item>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          style={{
                                            background:
                                              "linear-gradient(-170deg, #ffffff,  #5A99CD )",
                                          }}>
                                          <Typography
                                            style={{ color: "whitesmoke" }}
                                            key={item.ID}
                                            className="d-flex justify-content-between col-12">
                                            {item.json.title}
                                            <span>
                                              {item.isSent ? (
                                                <span className="text-success fw-bold font-italic me-5">
                                                  Active Document
                                                </span>
                                              ) : null}
                                            </span>
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          key={item.ID}
                                          style={{
                                            backgroundColor: "transparent",
                                          }}>
                                          <Grid container spacing={2}>
                                            <Grid className="col-12 col-sm-8">
                                              <Stack
                                                spacing={2}
                                                direction={{
                                                  xs: "column",
                                                  sm: "row",
                                                }}>
                                                <Button
                                                  variant="outlined"
                                                  onClick={() => {
                                                    setRandflag("send");
                                                    setThisdoc(item);
                                                  }}>
                                                  Send Document
                                                </Button>
                                                <Button
                                                  variant="outlined"
                                                  onClick={() => {
                                                    setThisdoc(item);
                                                    setRandflag("creator");
                                                  }}>
                                                  Preview Document
                                                </Button>
                                              </Stack>
                                            </Grid>
                                            <Grid className="col-12 col-sm-4">
                                              <Stack
                                                spacing={2}
                                                direction="row-reverse">
                                                {(privilege.includes(
                                                  "writeForms"
                                                ) ||
                                                  user.type?.toLowerCase() ===
                                                    "pharmacyadmin") && (
                                                  <Button
                                                    variant="contained"
                                                    style={{ float: "right" }}
                                                    disabled={
                                                      item.isSent ? true : false
                                                    }
                                                    onClick={() => {
                                                      openDeleteModal();
                                                      setThisdoc(item);
                                                    }}
                                                    color="error"
                                                    startIcon={
                                                      <DeleteForeverIcon />
                                                    }>
                                                    Delete
                                                  </Button>
                                                )}
                                                <Button
                                                  variant="contained"
                                                  style={{ float: "right" }}
                                                  onClick={() => {
                                                    handleHideDocument(item);
                                                  }}
                                                  color="warning"
                                                  startIcon={<HideSource />}>
                                                  {!item.isHidden
                                                    ? "Hide"
                                                    : "Unhide"}
                                                </Button>
                                              </Stack>
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Item>
                                    {i < data.length - 1 ? (
                                      <Divider
                                        style={{
                                          marginTop: "20px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}{" "}
                                  </React.Fragment>
                                )
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </StyledEngineProvider>
                  {/*======================= Dialog ========================*/}
                  <Dialog
                    fullScreen
                    open={openfsd}
                    onClose={handlefsdClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    style={{ marginTop: "60px" }}
                    // TransitionComponent={Transition}
                  >
                    <DialogTitle id="scroll-dialog-title">
                      <AppBar sx={{ position: "relative" }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                              setRandflag("init");
                              setThisdoc({});
                              setOpenfsd(false);
                              setSelectedFile(null);
                              setdocTitle("");
                              handlefsdClose();
                            }}
                            aria-label="close">
                            <CloseIcon />
                          </IconButton>
                          <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div">
                            Upload Document
                          </Typography>
                          <Button
                            autoFocus
                            color="inherit"
                            onClick={() => {
                              handleCreate();
                              handlefsdClose();
                            }}>
                            Create
                          </Button>
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                      <form style={{ width: "435px" }}>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label">
                            Document Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChanges}
                            name="title"
                            value={docTitle}
                          />
                        </div>
                        <MuiFileInput
                          value={selectedFile}
                          onChange={(e) => setSelectedFile(e)}
                          placeholder="SELECT A FILE"
                        />

                        {selectedFile && (
                          <LoadingButton
                            color="secondary"
                            onClick={onConvert}
                            loading={loadingflag}
                            loadingPosition="start"
                            startIcon={<FileUploadIcon />}
                            variant="contained"
                            style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <span>UPLOAD</span>
                          </LoadingButton>
                        )}
                        <br></br>

                        <Switch
                          inputProps={{ "aria-label": "Switch demo" }}
                          checked={on}
                          onClick={() => {
                            handleAddQuestion();
                          }}
                        />
                        <span>Switch to add a 'YES' or 'NO' question.</span>
                        {on && (
                          <div className="mb-3">
                            <label htmlFor="yesorno" className="form-label">
                              Yes or No Question Title
                            </label>
                            <input
                              type="text"
                              name="yesorno"
                              value={yesornotitle}
                              onChange={yesornoChanges}
                              id="yesorno"
                              className="form-control"
                            />
                          </div>
                        )}
                      </form>

                      {/* <input
                            style={{ marginLeft: "20px" }}
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          /> */}

                      <div id="pdf" style={{ marginTop: "50px" }}></div>
                      {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "rosybrown" }}>
                      <Button
                        style={{ color: "whitesmoke" }}
                        onClick={() => {
                          setRandflag("init");
                          setThisdoc({});
                          setOpenfsd(false);
                          setSelectedFile(null);
                          setdocTitle("");
                          handlefsdClose();
                        }}>
                        Cancel
                      </Button>
                      <Button
                        style={{ color: "whitesmoke" }}
                        onClick={() => {
                          handleCreate();
                          handlefsdClose();
                        }}>
                        Create
                      </Button>
                      {/* { progress = 100 ? <Button onClick={handleCreate}>Create</Button> : <Button disabled onClick={handleCreate}>Create</Button>} */}
                    </DialogActions>
                  </Dialog>
                  {/* ==================== Dialog Ends ==================== */}
                  <DeleteModal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Delete {thisdoc?.json?.title && thisdoc.json.title}
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ color: "red" }}>
                              Please type "
                              {thisdoc?.json?.title && thisdoc.json.title}" in
                              the field below to confirm the deleted document
                              title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInput"
                              aria-describedby="emailHelp"
                              onChange={handleDeleteChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeDelete();
                          }}>
                          Cancel
                        </button>
                        <button
                          disabled={!deletebuttonflag}
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          onClick={() => {
                            closeDelete();
                            handleDelete();
                          }}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </DeleteModal>
                  {open ? (
                    <ClickAwayListener onClickAway={onClickAway}>
                      <CustomSnackbar {...getRootProps()}>{msg}</CustomSnackbar>
                    </ClickAwayListener>
                  ) : null}
                </>
              ) : randflag === "creator" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisdoc({});
                      setOpenfsd(false);
                      setSelectedFile(null);
                      setdocTitle("");
                    }}>
                    Back
                  </Button>
                  <DocsCreator doc={thisdoc} />
                  {/* <TestCreatoe doc={thisdoc} /> */}
                </>
              ) : randflag === "send" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisdoc({});
                    }}>
                    Back
                  </Button>
                  <SendDoc doc={thisdoc} />{" "}
                </>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocumentsManage;
