import React, { useEffect } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { updateForm, createForm } from "../../../services/PharmacyUser";

function FormsCreator(props) {
  const { userID, isAdmin, privilege, form, creatorType } = props;

  const pharmacyID = localStorage.getItem("pharmacy");
  const formId = form?.ID ? form.ID : "";

  const creatorOptions = {
    showLogicTab:
      form.isSent || (!privilege.includes("writeForms") && !isAdmin)
        ? false
        : true,
    showDesignerTab:
      form.isSent || (!privilege.includes("writeForms") && !isAdmin)
        ? false
        : true,
    showJSONEditorTab: true,
    readOnly:
      form.isSent || (!privilege.includes("writeForms") && !isAdmin)
        ? true
        : false,
    showOptions: true,
    isAutoSave: false,
    haveCommercialLicense: true,
  };

  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = props.form.json;
  creator.isAutoSave = false;
  creator.saveSurveyFunc = (saveNo, callback) => {
    //   Save the form JSON and text into your database here

    const dataForDB = {
      formText: { ...creator.JSON },
      pharmacyID: creatorType === "pharmacy" ? pharmacyID : null,
      creatorID: userID,
      hasBeenSent: false,
      creatorType: creatorType ? creatorType : "pharmacy",
    };

    const dataForDBUpdate = { formText: { ...creator.JSON } };

    if (props.form.ID) {
      updateForm(formId, dataForDBUpdate)
        .then(() => {
          props.setRandflag("init");
          props.setIsUpdate(false);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      createForm(dataForDB)
        .then(() => {
          props.setRandflag("init");
        })
        .catch((err) => {
          console.error(err);
        });
    }

    callback(saveNo, true);
  };

  useEffect(() => {
    creator.showJSONEditorTab = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return <SurveyCreatorComponent creator={creator} />;
}

export default FormsCreator;
