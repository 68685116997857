import React, { useState, useEffect } from "react";
import MessageArea from "../../components/pharmacyUser/messageCenter/MessageArea";
import { getChannelById } from "../../services/Messages";
import { generateToken } from "../../services/Common";
import { decodeToken } from "react-jwt";
import CircularProgress from "@mui/material/CircularProgress";
import {
  sendSMS,
  handleEncryption,
  sendEmail,
  getPharmacyById,
} from "../../services/Common";
import { getProfile } from "../../services/PharmacyUser";
import OTPTimer from "../../components/common/OTPTimer";
function Applesssms() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const channelId = urlParams.get("id");
  const myid = urlParams.get("r");
  const pharmacy_id = urlParams.get("s");
  const sourceID = urlParams.get("sid");

  const [incommingFromchannels, setIncommingFromchannels] = useState({});
  // const [pharmacyName, setPharmacyName] = useState("");
  const [userID, setUserID] = useState(null);
  const [curUserInfo, setCurUserInfo] = useState({});
  const [PharmacySMSNum, setPharmacySMSNum] = useState(null);

  useEffect(() => {
    getPharmacyById(pharmacy_id)
      .then((res) => {
        setPharmacySMSNum(res.data.smsPhoneNum);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacy_id]);

  useEffect(() => {
    generateToken(myid)
      .then((res) => {
        localStorage.setItem("Token", res.data.token);
        setUserID(decodeToken(res.data.token).userId);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [myid]);

  useEffect(() => {
    getProfile(myid)
      .then((res) => {
        setCurUserInfo(res.data.data.user[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [myid, userID]);
  useEffect(() => {
    userID &&
      getChannelById(channelId)
        .then((res) => {
          setIncommingFromchannels(res.data);
        })
        .catch((err) => {
          console.error(err);
          setIncommingFromchannels({});
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const [msg, setMsg] = useState("");
  const [authflag, setAuthflag] = useState(
    localStorage.getItem("auth") === "true" ? true : false
  );
  const [otpflag, setOtpflag] = useState("phone");

  const [phone, setPhone] = useState("");

  const handleChange = (e) => {
    setPhone(e.target.value.trim());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMsg("");
    if (phone === curUserInfo.phone || phone === curUserInfo.email) {
      const val = Math.floor(1000 + Math.random() * 9000);
      localStorage.setItem("otp", handleEncryption(val.toString()));

      if (sourceID === "sms") {
        const smsPayload = {
          to: phone,
          body: `Your CuroRx One Time Password is ${val}`,
          from: PharmacySMSNum,
        };

        sendSMS(smsPayload)
          .then((res) => {
            setOtpflag("otp");
          })
          .catch((err) => {
            console.log(err);
            err?.response?.data?.message &&
              setMsg(
                JSON.parse(err.response.data.message).value[0].errorMessage
              );
            // setMsg("Invalid Phone Number");
          });
      } else {
        sendEmail({
          to: phone,
          message: `Your CuroRx One Time Password is ${val}`,
        })
          .then((res) => {
            setOtpflag("otp");
          })
          .catch((err) => {
            err?.response?.data?.message &&
              setMsg(
                JSON.parse(err.response.data.message).value[0].errorMessage
              );
          });
      }
    } else {
      setMsg("Please Enter Correct Phone Number or Email");
    }
  };
  const [otp, setOtp] = useState("");

  const handleOtpchange = (e) => {
    setOtp(e.target.value.trim());
  };

  const handleAuth = (e) => {
    e.preventDefault();

    if (handleEncryption(otp) === localStorage.getItem("otp")) {
      localStorage.setItem("auth", true);
      setAuthflag(true);
      setOtpflag("");
      setMsg("");
    } else {
      setMsg("Invalid OTP");
    }
  };

  return (
    <>
      <>
        {authflag ? (
          <>
            {incommingFromchannels.channelId !== undefined ? (
              <div className="standalone_appless">
                <p
                  className="text-success"
                  style={{
                    padding: "0 20px",
                    margin: "0",
                    textAlign: "center",
                  }}>
                  - You can leave this window open in the browser so you don't
                  have to verify your number again.
                </p>

                <MessageArea dataFromChannel={incommingFromchannels} />
              </div>
            ) : (
              <CircularProgress color="success" />
            )}
          </>
        ) : (
          <>
            {" "}
            <div class="container">
              <div class="row mt-5">
                <div class="col-12 mt-5">
                  <div class="card mt-5">
                    <>
                      <>
                        {msg === "" ? (
                          <></>
                        ) : (
                          <p className="p-3 text-danger">{msg}</p>
                        )}
                      </>
                      {otpflag === "phone" ? (
                        <>
                          <div class="card-header">
                            Please Verify Your{" "}
                            {sourceID === "sms" ? "Mobile Number" : "Email"}
                          </div>
                          <div class="card-body">
                            <form class="row gx-3 gy-2 align-items-center">
                              <div class="col-sm-3">
                                <label
                                  class="visually-hidden"
                                  for="specificSizeInputName">
                                  {sourceID === "sms"
                                    ? "Mobile Number"
                                    : "Email"}
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="specificSizeInputName"
                                  placeholder={
                                    sourceID === "sms"
                                      ? "1234567890"
                                      : "email@domain.com"
                                  }
                                  value={phone}
                                  onChange={handleChange}
                                />
                              </div>
                              <div class="col-auto">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  onClick={handleSubmit}>
                                  Confirm
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : otpflag === "otp" ? (
                        <>
                          <div class="card-header">
                            Please Enter Your Verification Code
                          </div>
                          <div class="card-body">
                            <form class="row gx-3 gy-2 align-items-center">
                              <div class="col-sm-3">
                                <label class="visually-hidden" for="otp">
                                  Verification Code
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="otp"
                                  placeholder="0000"
                                  value={otp}
                                  onChange={handleOtpchange}
                                />
                              </div>

                              <div class="col-auto">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  onClick={handleAuth}>
                                  Confirm
                                </button>
                              </div>
                              <OTPTimer sendOTP={handleSubmit} />
                            </form>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}

export default Applesssms;
