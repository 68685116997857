import React, { useState, useEffect } from "react";

import { getPtLog } from "../../../services/PharmacyUser";
import { User } from "../../../utils/globalState";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const BirthdayMessageLogs = (props) => {
  const { isDataLoading, setIsDataLoading } = props;

  const userInformation = User.getState().info;
  const pharmacy_id =
    userInformation.pharmacyID || localStorage.getItem("pharmacy");

  const [ptRowData, setPtRowData] = useState([]);
  const [phRowData, setPhRowData] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columnDefPatients = [
    {
      field: "patient id",
      headerName: "Patient ID",
      sortable: true,
      minWidth: 120,
    },
    { field: "first", headerName: "First", minWidth: 100 },
    { field: "last", headerName: "Last", minWidth: 100 },
    { field: "DOB", headerName: "DOB", minWidth: 120 },
    { field: "phone", headerName: "Phone", minWidth: 120 },
    { field: "message", headerName: "Message", minWidth: 250 },
    {
      field: "sent on",
      headerName: "Sent On",
      minWidth: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "delivered", headerName: "Delivered", minWidth: 110 },
    { field: "note", headerName: "Note", minWidth: 200 },
  ];
  const columnDefUsers = [
    {
      field: "user id",
      headerName: "User ID",
      sortable: true,
      minWidth: 120,
    },
    { field: "rule", headerName: "Rule", minWidth: 120 },
    { field: "first", headerName: "First", minWidth: 100 },
    { field: "last", headerName: "Last", minWidth: 100 },
    { field: "DOB", headerName: "DOB", minWidth: 120 },
    { field: "phone", headerName: "Phone", minWidth: 120 },
    { field: "message", headerName: "Message", minWidth: 250 },
    {
      field: "sent on",
      headerName: "Sent On",
      minWidth: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "delivered", headerName: "Delivered", minWidth: 110 },
    { field: "note", headerName: "Note", minWidth: 200 },
  ];

  useEffect(() => {
    getPtLog()
      .then((res) => {
        setPtRowData(
          res.data.data
            .filter(
              (el) =>
                el.phID === Number(pharmacy_id) &&
                (el.user_type === "Patient" || el.user_type === "patient")
            )
            .map((el) => ({
              ...el,
              "patient id": el.pt_id,
              "sent on": el.msg_sent_on,
              message: el.msg,
              delivered: el.success ? "Yes" : "No",
              note: el.error ? el.error : "N/A",
            }))
        );
        setPhRowData(
          res.data.data
            .filter(
              (el) =>
                el.phID === Number(pharmacy_id) &&
                el.user_type !== "patient" &&
                el.user_type !== "Patient"
            )
            .map((el) => ({
              ...el,
              "user id": el.pt_id,
              "sent on": el.msg_sent_on,
              message: el.msg,
              delivered: el.success ? "Yes" : "No",
              note: el.error ? el.error : "N/A",
              rule: el.user_type,
            }))
        );
      })
      .finally(() => setIsDataLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy_id]);

  return (
    <Box className="col-12 p-0 mb-4 ms-2 ps-2">
      <p className="card-title text-center m-0 p-0 mt-2">
        Birthday Messages Log
      </p>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="ms-3">
        <Tab label="Patients" />
        <Tab label="Pharmacy Users" />
      </Tabs>
      {value === 0 && (
        <div className="row mx-auto">
          <div className="mt-2 col-12 ">
            {isDataLoading ? (
              <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                <div className="spinner-border text-warning" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="ms-4 align-middle text-secondary">
                  Loading Patients Data Please wait...
                </div>
              </div>
            ) : (
              <div style={{ height: 400, width: "auto" }}>
                <DataGrid
                  rows={ptRowData}
                  columns={columnDefPatients}
                  density="compact"
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "sent on", sort: "desc" }],
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {value === 1 && (
        <div className="row mx-auto mb-2">
          <div className=" mt-2 col-12 ">
            {isDataLoading ? (
              <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                <div className="spinner-border text-warning" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="ms-4 align-middle text-secondary">
                  Loading Users Data Please wait...
                </div>
              </div>
            ) : (
              <div style={{ height: 400, width: "auto" }}>
                {" "}
                <DataGrid
                  rows={phRowData}
                  columns={columnDefUsers}
                  density="compact"
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "sent on", sort: "desc" }],
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default BirthdayMessageLogs;
