import React, { useEffect, useState } from "react";
import { ProviderAddRxController } from "../../../../utils/globalState";
import { handleDiag } from "../../../../services/Provider";

function Diagnose() {
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [value, setValue] = useState("");
  const [diag, setDiag] = useState([]);
  const [rx, setRx] = useState({
    ICD10: "",
    diagnose: "",
  });
  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  useEffect(() => {
    ProviderAddRxController.setState({
      StepperController: {
        ...rxController,
        currentstep: "Diagnose",
      },
    });
  }, []);

  const [filteredDiag, setFilteredDiag] = useState([]);

  useEffect(() => {
    handleDiag()
      .then((res) => {
        setDiag(res.data.diag);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleChoice = (e) => {
    setValue(e.target.value);
    e.preventDefault();
  };

  const handleChange = (event) => {
    if (event.target.name === "ICD10") {
      setFilteredDiag(
        diag.filter((ele) => {
          return ele.ICD10.toLowerCase().startsWith(event.target.value);
        })
      );
    } else {
      setFilteredDiag(
        diag.filter((ele) => {
          return ele.diagnose.toLowerCase().startsWith(event.target.value);
        })
      );
    }
    setRx({ ...rx, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (rx.diagnose !== "" || rx.ICD10 !== "") {
      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, diagnose: rx.diagnose, ICD10: rx.ICD10 },
          completeflag: { ...rxController.completeflag, Diagnose: true },
        },
      });
    }
    setRx({
      ICD10: "",
      diagnose: "",
    });
  };

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <form className="col-12 col-lg-8">
        <div className="container container_diagnose mb-3">
          <h3 className="diagnose_h3"> Diagnosis </h3>
          <label className="form-label form-control" htmlFor="form">
            ICD10 OR Diagnosis:
            <select className="form-select" onChange={handleChoice}>
              <option defaultValue="">Choose</option>
              <option value="ICD10">ICD10</option>
              <option value="Diagnosis">Diagnosis</option>
            </select>
          </label>
        </div>
      </form>
      {value === "ICD10" ? (
        <form onSubmit={handleSubmit} className="col-12 col-lg-8">
          <div className="container container_diagnose">
            <label className="form-label form-control" htmlFor="form">
              ICD10:
              <input
                list="ICD10"
                className="form-control"
                type="text"
                name="ICD10"
                value={rx.ICD10}
                onChange={handleChange}
              />
            </label>
            <datalist id="ICD10">
              {diag.length > filteredDiag.length &&
                filteredDiag.map((each, idx) => {
                  return idx < 500 && <option key={idx}>{each.ICD10}</option>;
                })}
            </datalist>
            <div className="d-grid gap-2 col-md-12 mx-auto">
              <button className="btn btn-outline-primary " type="submit">
                {" "}
                Confirm
              </button>
            </div>
          </div>
        </form>
      ) : value === "Diagnosis" ? (
        <div className="container container_diagnose mb-3 col-12 col-lg-8">
          <form onSubmit={handleSubmit}>
            <label className="form-label form-control" htmlFor="form">
              Diagnosis:
              <input
                list="diagnose"
                placeholder="Choose"
                className="form-control"
                type="text"
                name="diagnose"
                value={rx.diagnose}
                onChange={handleChange}
              />
            </label>
            <datalist id="diagnose">
              {diag.length > filteredDiag.length &&
                filteredDiag.map((each, idx) => {
                  return (
                    idx < 500 && <option key={idx}>{each.diagnose}</option>
                  );
                })}
            </datalist>
            <div className="d-grid gap-2 col-md-12 mx-auto">
              <button className="btn btn-outline-primary btn_ast" type="submit">
                Confirm
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
}

export default Diagnose;
