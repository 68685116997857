import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { getProviders } from "../../services/PharmacyUser";
import { convertTimeLocally } from "./../../utils/timeConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ProviderDetails from "./../../components/pharmacyUser/providerCenter/ProviderDetails";
import RenderCellImage from "../../components/common/dataGrids/RenderCellImage";

const ManageProviders = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");
  const [users, setUsers] = useState([]);
  const [details, setDetails] = useState(false);
  const [curUserInfo, setCurUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProviders(pharmacy_id).then((res) => {
      let usersSorted =
        res.data?.data?.length > 0
          ? res.data.data.map((el) => {
              return {
                ...el.user,
                createdAt: convertTimeLocally(el.user.createdAt),
                updatedAt:
                  el.user.updatedAt === el.user.createdAt
                    ? "N/A"
                    : convertTimeLocally(el.user.updatedAt),
                address: el.address,
                ContactInfo: el.contacts.length
                  ? el.contacts
                  : [{ id: "", emName: "", emRelation: "", altPhone: "" }],
                status: el.user.active ? "Active" : "Inactive",
              };
            })
          : [];
      setUsers(usersSorted);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const columnDef = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      headerName: "Picture",
      field: "pic_url",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => RenderCellImage(params),
    },
    { field: "first", headerName: "First", minWidth: 100 },
    { field: "last", headerName: "Last", minWidth: 100 },
    { field: "email", headerName: "Email", minWidth: 190 },
    { field: "phone", headerName: "Phone", minWidth: 130 },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 160,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated On",
      minWidth: 160,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      cellClassName: (params) => {
        return params.row.active ? "pt_active cellwa" : "pt_inactive cellwa";
      },
    },
  ];

  const rowClickedListener = (params) => {
    setDetails(true);
    setCurUserInfo(params.row);
  };

  const getRowClassName = (params) => {
    return "pointer-cursor";
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Providers</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Provider Center</li>
              <li
                onClick={() => setDetails(false)}
                className={`breadcrumb-item ${
                  details ? "cstm_breadcrumb" : "active"
                }`}
              >
                Manage Providers
              </li>
              {details && (
                <li className="breadcrumb-item active">{`Dr ${curUserInfo.first} ${curUserInfo.last}`}</li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {!details ? (
              <div className="container-fluid">
                {isLoading ? (
                  <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
                    <div className="spinner-border text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="ms-4 align-middle text-secondary">
                      Loading Providers Data Please wait...
                    </div>
                  </div>
                ) : (
                  <div className="dg_0liner">
                    <DataGrid
                      rows={users}
                      columns={columnDef}
                      onRowClick={rowClickedListener}
                      density="compact"
                      getRowClassName={getRowClassName}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "updatedAt", sort: "desc" }],
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ProviderDetails
                curUserInfo={curUserInfo}
                setCurUserInfo={setCurUserInfo}
                setDetails={setDetails}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageProviders;
