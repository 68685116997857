import React, { useState, useEffect } from "react";
import TotalCompleteByUserType from "./totalCompletedByUserType/TotalCompleteByUserType";
import PatientCompletedVSPending from "./patientCompletedVSPending/PatientCompletedVSPending";
import ProviderCompletedVSPending from "./providerCompletedVSPending/ProviderCompletedVSPending";
import StaffCompletedVSPending from "./staffCompletedVSPending/StaffCompletedVSPending";
import PatientTotalDocsByStaff from "./patientTotalDocsByStaff/PatientTotalDocsByStaff";
import ProviderTotalDocsByStaff from "./providerTotalDocsByStaff/ProviderTotalDocsByStaff";
import PharmacyUsersTotalDocsByStaff from "./pharmacyUsersTotalDocsByStaff/PharmacyUsersTotalDocsByStaff";
import { getDocs } from "../../../../services/PharmacyUser";
import AverageTurnAroundTime from "./averageTurnAroundTime/AverageTurnAroundTime";

const DocsTrends = (props) => {
  const { data } = props;
  const pharmacyID = localStorage.getItem("pharmacy");

  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    getDocs(pharmacyID)
      .then((res) => {
        const docsFromDB = res.data.map(
          (item) => JSON.parse(item.formText).title
        );
        setAllDocs(docsFromDB);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacyID]);

  return (
    <div>
      <TotalCompleteByUserType data={[...data]} name="Documents" />
      <PatientCompletedVSPending data={[...data]} name="Document" />
      <ProviderCompletedVSPending data={[...data]} name="Document" />
      <StaffCompletedVSPending data={[...data]} name="Document" />
      <PatientTotalDocsByStaff
        data={[...data]}
        name="Document"
        allDocs={allDocs}
      />
      <ProviderTotalDocsByStaff
        data={[...data]}
        name="Document"
        allDocs={allDocs}
      />
      <PharmacyUsersTotalDocsByStaff
        data={[...data]}
        name="Document"
        allDocs={allDocs}
      />
      <AverageTurnAroundTime
        data={[...data]}
        name="Documents"
        allDocs={allDocs}
      />
    </div>
  );
};

export default DocsTrends;
