import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { Model } from "survey-core";
import { SideBar } from "../../../utils/globalState";
import { markSurveyResponseAsReviewed } from "./../../../services/PharmacyUser";
import {
  postNewAction,
  toggleResolved,
  getSurveyResponseById,
  getSurveyById,
  handleGetptbyid,
} from "./../../../services/PharmacyUser";

const handleGetAnswers = (surveyData, responseData) => {
  let form = surveyData;
  let response = responseData;
  const filteredResponse = {};

  for (let key in response) {
    if (key.includes("question")) {
      filteredResponse[key] = response[key];
    }
  }

  const survey = new Model(form);
  survey.data = filteredResponse;
  const questionsAnswered = survey.getPlainData();

  return questionsAnswered;
};

const DissatisfactionDetails = () => {
  const { responseId } = useParams();
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const navigate = useNavigate();
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
  const userName = `${localStorage.getItem("first")} ${localStorage.getItem(
    "last"
  )}`;

  const [actionText, setActionText] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [data, setData] = useState({});

  const fetchData = async () => {
    const resp = await getSurveyResponseById(responseId);
    if (resp.data) {
      setIsInvalid(false);
      const parsedResp = {
        ...resp.data,
        survey_response: resp.data.survey_response
          ? JSON.parse(resp.data.survey_response)
          : {},
        action: resp.data.action ? JSON.parse(resp.data.action).reverse() : [],
        resolved: resp.data.resolved ? true : false,
      };
      const surveyID = parseInt(parsedResp.survey_response.surveyId);
      const survey = await getSurveyById(surveyID);

      const parsedSurvey = {
        ...survey.data[0],
        json: survey.data[0].json,
        survey: JSON.parse(survey.data[0].survey),
      };

      const patientId = parsedResp.pt_id;
      const ptRes = await handleGetptbyid(patientId);

      const combinedData = {
        surveyResponse: {
          ...parsedResp,
          action: parsedResp.action.map((el) => ({
            ...el,
            action_date: dayjs(el.action_date).format("MM/DD/YYYY hh:mm a"),
          })),
        },
        survey: parsedSurvey,
        patientInfo: ptRes.data.patient,
        questionsAnswered: handleGetAnswers(
          parsedSurvey.survey,
          parsedResp.survey_response.response
        ),
      };

      if (!combinedData.surveyResponse.isReviewed) {
        markSurveyResponseAsReviewed(combinedData.surveyResponse.id, {
          reviewedById: userId,
          reviewedBy: userName,
        }).catch((err) => console.error(err));
      }
      setData(combinedData);
    } else {
      setIsInvalid(true);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseId]);

  const handleChange = (e) => {
    setActionText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postNewAction(responseId, actionText, userName)
      .then(() => {
        setData((prev) => ({
          ...prev,
          surveyResponse: {
            ...prev.surveyResponse,
            action: [
              {
                action: actionText,
                action_date: dayjs().format("MM/DD/YYYY hh:mm a"),
                action_user: userName,
              },
              ...prev.surveyResponse.action,
            ],
          },
        }));
      })
      .catch((err) => console.error(err));

    setActionText("");
  };

  const resolutionCLickHandler = () => {
    toggleResolved(responseId, !data.surveyResponse.resolved)
      .then((res) => {
        setData((prev) => ({
          ...prev,
          surveyResponse: {
            ...prev.surveyResponse,
            resolved: !prev.surveyResponse.resolved,
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main">
        <div className="pagetitle">
          <h1>Survey Dissatisfaction Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">survey-center</li>
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate("../", { relative: "path" })}>
                survey-dissatisfaction
              </li>
              <li className="breadcrumb-item active">
                survey-dissatisfaction Details
              </li>
            </ol>
          </nav>
        </div>

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {isInvalid ? (
              <div
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h1 className="text-center text-danger fs-4">
                  Invalid Survey Response ID
                </h1>
              </div>
            ) : (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="card">
                      <div className="card-body pt-3">
                        <div className="tab-content pt-2 provider_info">
                          <div
                            className="tab-pane fade show active profile-overview"
                            id="profile-overview">
                            <h4 className="card-title text-center my-3 ">
                              Patient Details
                            </h4>
                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-semibold">
                                Patient Name
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {`${data.patientInfo?.firstName} ${data.patientInfo?.lastName}`}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-semibold">
                                Patient ID
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {data.patientInfo?.id}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-semibold">
                                MRN
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {data.patientInfo?.MRN}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-semibold">
                                Birth Date
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {data.patientInfo?.DOB}
                              </p>
                            </div>

                            <div className="row">
                              <p className="col-lg-5 col-md-4 label fw-semibold">
                                Phone
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {data.patientInfo?.phone}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-lg-5 col-md-4 label fw-semibold">
                                Pharmacy
                              </p>
                              <p className="col-lg-7 col-md-8">
                                {data.patientInfo?.providing_pharmacy}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-body pt-3">
                        <div className="tab-content pt-2 provider_info">
                          <div
                            className="tab-pane fade show active profile-overview"
                            id="profile-overview">
                            <h4 className="card-title text-center my-3">
                              Survey Q&A
                            </h4>
                            <p className="col-12 label fw-bold mt-4 fs-5">
                              Survey Name: {data?.survey?.survey?.title}
                            </p>
                            {data?.questionsAnswered?.length > 0 &&
                              data.questionsAnswered.map((item, idx) => {
                                return (
                                  <div className="row" key={idx}>
                                    <p className="col-12  label fw-bold m-1">
                                      {item.title}
                                    </p>
                                    <p className="col-12 m-1 ms-3">
                                      &#8226;&nbsp;
                                      {item.displayValue === true
                                        ? "yes"
                                        : item.displayValue === false
                                        ? "no"
                                        : item.displayValue}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card mb-3">
                      <div className="card-body pt-3">
                        <div className="tab-content pt-2 provider_info">
                          <div
                            className="tab-pane fade show active profile-overview"
                            id="profile-overview">
                            <h4 className="card-title text-center my-3">
                              Resolution
                            </h4>

                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-bold">
                                Status
                              </p>
                              <p
                                className={`col-lg-7 col-md-8 ${
                                  data.surveyResponse?.resolved
                                    ? "text-success"
                                    : "text-danger"
                                }`}>
                                {data.surveyResponse?.resolved
                                  ? "Resolved"
                                  : "Pending"}
                              </p>
                            </div>
                            <div className="row">
                              <button
                                className={`btn btn-success col-auto`}
                                onClick={() => resolutionCLickHandler()}>
                                {`${
                                  data.surveyResponse?.resolved
                                    ? "Mark As Unresolved"
                                    : "Mark As Resolved"
                                }`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body pt-3">
                        <div className="tab-content pt-2 provider_info">
                          <div
                            className="tab-pane fade show active profile-overview"
                            id="profile-overview">
                            <h4 className="card-title text-center my-3">
                              Actions
                            </h4>
                            <form onSubmit={handleSubmit} className="mt-4">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="type what action you took here "
                                  value={actionText}
                                  onChange={handleChange}
                                />
                                <button
                                  type="submit"
                                  className="btn btn-success">
                                  Add New Action
                                </button>
                              </div>
                            </form>
                            <div className="row">
                              <p className="col-lg-5 col-md-4  label fw-bold mt-4">
                                Actions Taken:
                              </p>
                            </div>
                            {data.surveyResponse?.action?.length > 0 &&
                              data.surveyResponse.action.map((item, idx) => {
                                return (
                                  <div className="row mb-2" key={idx}>
                                    <p className="col-12  label fw-semibold m-1 ">
                                      {item.action_user +
                                        " - " +
                                        item.action_date}
                                    </p>
                                    <p className="col-12 m-0 ms-3 fs-6">
                                      &#8226;&nbsp;{item.action}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default DissatisfactionDetails;
