import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
const AddBulkPatientsLogs = (props) => {
  const { recordsAdded, existedRecords, erroredRecords, missingInfList } =
    props;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'first', headerName: 'First name', width: 100 },
      { field: 'last', headerName: 'Last name', width: 100 },
      { field: 'DOB', headerName: 'Date of Birth', width: 100 },
      { field: 'MRN', headerName: 'MRN', width: 100 },
      { field: 'phone', headerName: 'Phone', width: 100 },
      { field: 'email', headerName: 'Email', width: 100 },
      { field: 'error', headerName: 'Error', width: 100 },
    ]);
  }, []);

  return (
    <div className="row text-center">
      {recordsAdded && recordsAdded.length > 0 && (
        <div className="col p-4 ">
          <h3>Records Added</h3>
          <DataGrid
            rows={recordsAdded}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(r) => recordsAdded.indexOf(r)}
          />
        </div>
      )}
      {existedRecords && existedRecords.length > 0 && (
        <div className="col p-4">
          <h3>Existed Records</h3>
          <DataGrid
            rows={existedRecords}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(r) => existedRecords.indexOf(r)}
          />{' '}
        </div>
      )}
      {erroredRecords && erroredRecords.length > 0 && (
        <div className="col p-4">
          <h3>Errored Records</h3>
          <DataGrid
            rows={erroredRecords}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(r) => erroredRecords.indexOf(r)}
          />
        </div>
      )}
      {missingInfList && missingInfList.length > 0 && (
        <div className="col p-4">
          <h3>Missing Information</h3>
          <DataGrid
            rows={missingInfList}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(r) => missingInfList.indexOf(r)}
            getCellClassName={(params) => {
              if (params.row.note && params.row.note !== '') {
                if (
                  params.field === 'first' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
                if (
                  params.field === 'last' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
                if (
                  params.field === 'DOB' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
                if (
                  params.field === 'MRN' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
                if (
                  params.field === 'phone' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
                if (
                  params.field === 'email' &&
                  (params.value === '' || params.value === null)
                ) {
                  return 'errorcell';
                }
              } else {
                return '';
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AddBulkPatientsLogs;
