import React, { useEffect, useState } from "react";
import {
  updateToken,
  getSurveyById,
  getTokenById,
  addResponse,
} from "../../../services/Provider";
import { generateToken, sendEmail } from "../../../services/Common";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { SocketIoServer } from "../../../utils/globalState";

function ProviderApplessSurvey() {
  // -----------------------------------------Socket Io-----------------------------------------
  const value = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(value);
  }, [value]);
  // ==================================================================================

  const [survey, setSurvey] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let id = urlParams.get("id");
  let patientId = urlParams.get("pid");
  let token = urlParams.get("token");
  // get patient id from url

  const [expflag, setExpflag] = useState(false);
  const [tokObj, setTokObj] = useState({});
  const [patient, setPatient] = useState({});
  const [googleSurveyFlag, setGoogleSurveyFlag] = useState(false);
  const gettBy = (tok) => {
    getTokenById(tok)
      .then((res) => {
        let TokenObj = JSON.parse(res.data.token);
        setExpflag(TokenObj.resflag);
        setTokObj(TokenObj);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (
      id === "" ||
      patientId === "" ||
      token === "" ||
      id === null ||
      patientId === null ||
      token === null
    ) {
      setExpflag(true);
    }
  }, [id, patientId, token]);

  useEffect(() => {
    generateToken(patientId).then((res) => {
      localStorage.setItem("Token", res.data.token);
    });
  }, [patientId]);

  const getsBy = (id) => {
    getSurveyById(id)
      .then((res) => {
        res.data[0].json = JSON.parse(res.data[0].survey);
        setSurvey(res.data[0].json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [Recipients, setRecipients] = useState([]);

  useEffect(() => {
    getsBy(id);
    gettBy(token);
  }, [id, token]);

  if (Object.keys(survey).length === 0) {
    return <div>Loading...</div>;
  } else if (
    expflag ||
    id === "" ||
    patientId === "" ||
    token === "" ||
    id === null ||
    patientId === null ||
    token === null
  ) {
    return <div>Survey expired and/or Invalid survey URL.</div>;
  } else {
    const model = new Model(survey);
    model.onComplete.add((sender) => {
      let NewTokenObj = tokObj;
      NewTokenObj.resflag = true;
      updateToken(token, { token: JSON.stringify(NewTokenObj) })
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
      let payLoad = {
        survey_response: {
          response: sender.data,
          surveyId: id,
        },
        pt_id: patientId,
      };
      addResponse(payLoad)
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    });

    return (
      <div>
        <div id="wrapper">
          <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <div className="container-fluid">
                <div className="pagetitle">
                  <h1>{survey.title}</h1>
                </div>
                <Survey model={model} />
                {googleSurveyFlag ? (
                  <div>
                    <h4>
                      {" "}
                      Please click{" "}
                      <strong>
                        <a
                          href="https://g.page/r/CXJ8ZV3UEIM0EB0/review"
                          target="_blank"
                          rel="noreferrer">
                          HERE
                        </a>
                      </strong>{" "}
                      to leave a review.
                    </h4>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderApplessSurvey;
