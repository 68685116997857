import React, { useEffect, useState } from 'react';
import { SideBar } from '../../../utils/globalState';
import { getSurveys, deleteSurvey } from '../../../services/Provider';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { decodeToken } from 'react-jwt';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

const ManageSurvey = () => {
  const navigate = useNavigate();
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem('Token');
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
const pharmacyId = localStorage.getItem('pharmacy');
  const [surveysList, setSurveysList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  // const [curUserInfo, setCurUserInfo] = useState({});
  const [currSurvey, setCurrSurvey] = useState({});
  const [open, setOpen] = React.useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [deleteSurveyErrorFlag, setDeleteSurveyErrorFlag] = useState(false);

  useEffect(() => {
    getSurveys()
      .then((res) => {
        setIsLoading(false);

        setSurveysList(
          res.data.Surveys.filter(
            (survey) => survey.createdBy === userId.toString()  
          ).map((survey) => {
            survey.json = JSON.parse(survey.survey);
            return survey;
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        setSurveysList([]);
        setErrorMessage(err.message);
      });
  }, [userId]);

  const handleAddSurvey = () => {
    navigate(`edit-and-review`);
  };

  // ===================================================================
  // Delete Survey

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCurrSurvey({});
    setOpen(false);
  };

  const handleDeleteSurvey = (survey) => {
    setCurrSurvey(survey);
    handleClickOpen();
  };

  const handleChange = (event) => {
    setDeleteInputValue(event.target.value);
  };
  const MyFormHelperText = () => {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (focused) {
        if (deleteInputValue !== currSurvey.json.title) {
          setDeleteSurveyErrorFlag(true);
          return 'Please enter the correct survey title';
        } else {
          setDeleteSurveyErrorFlag(false);
          return '';
        }
      }
      // return '';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  };

  const handleConfirmDeleteSurvey = () => {
    deleteSurvey(currSurvey.id)
      .then((res) => {
        setCurrSurvey({});
        handleClose();
        setSurveysList(
          surveysList.filter((survey) => survey.id !== currSurvey.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className={!isDivVisible ? 'toggle-sidebar' : ''}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                Surveys Management Board
              </li>
            </ol>
          </nav>
          <button className="btn btn-primary" onClick={handleAddSurvey}>
            <i className="fa fa-plus" aria-hidden="true"></i> ADD NEW SURVEY
          </button>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          {errorMessage && <p className="text-danger fs-6">{errorMessage}</p>}
          <div className="row">
            {isLoading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <Accordion defaultActiveKey="0" className="survey-container">
                {surveysList.length > 0 ? (
                  surveysList.map((survey, idx) => {
                    return (
                      <Accordion.Item eventKey={idx.toString()} key={idx}>
                        <Accordion.Header>
                          <p className="fw-bold">{survey.json.title}</p>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="survey-options d-flex flex-wrap justify-content-between col-12 align-items-center  px-0 gap-1">
                            <button
                              className="survey-option btn survey-option-btn btn-outline-success col-12 col-sm-auto my-2"
                              onClick={() => {
                                navigate(`send-survey/?id=${survey.id}`);
                                // handleSelectSurvey(survey);
                              }}
                            >
                              <i className="fas fa-upload color-success"></i>{' '}
                              UPLOAD RECIPIENTS LIST & SEND
                            </button>
                            <button
                              className="survey-option btn survey-option-btn btn-outline-success col-12 col-sm-auto my-2"
                              onClick={() => {
                                navigate(`edit-and-review/?id=${survey.id}`);
                                // handleSelectSurvey(survey);
                              }}
                            >
                              <i className="fas fa-edit"></i> VIEW & EDIT
                            </button>
                            <button
                              className="survey-option btn survey-option-btn btn-outline-success col-12 col-sm-auto my-2"
                              onClick={() => {
                                // handleSelectSurvey(survey);
                                navigate(`results/?id=${survey.id}`);
                              }}
                            >
                              <i className="fas fa-poll-h"></i> RESULTS
                            </button>
                            <button
                              className="survey-option btn survey-option-btn btn-outline-success col-12 col-sm-auto my-2"
                              onClick={() => {
                                // handleSelectSurvey(survey);
                                navigate(`analytics/?id=${survey.id}`);
                              }}
                            >
                              <i className="fas fa-chart-area"></i> ANALYTICS
                            </button>
                            {/* <button
                              className="survey-option btn survey-option-btn btn-outline-success col-12 col-sm-auto my-2"
                              onClick={() => {
                                // handleSelectSurvey(survey);
                                navigate(
                                //   `notifications-recipients/?id=${survey.id}`
                                );
                              }}
                            >
                              <i className="fas fa-users"></i> SURVEY
                              NOTIFICATIONS RECIPIENTS
                            </button> */}
                            <>
                              {/* {Object.keys(curUserInfo).length > 0 &&
                              curUserInfo.type.toLowerCase() ===
                                'pharmacyadmin' ? ( */}
                              <button
                                className="btn btn-outline-danger col-12 col-sm-auto my-2"
                                onClick={() => handleDeleteSurvey(survey)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>{' '}
                                DELETE
                              </button>
                              {/* // ) : null} */}
                            </>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                ) : (
                  <p>No surveys found</p>
                )}
              </Accordion>
            )}
          </div>
          <div>
            {Object.keys(currSurvey).length > 0 ? (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Please Confirm {currSurvey.json.title} Survey Deletion.
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    To delete {currSurvey.json.title} survey, please confirm by
                    typing the following "{currSurvey.json.title}" in the input
                    field below.:
                    <form noValidate autoComplete="off">
                      <FormControl sx={{ width: '35ch', marginTop: 2 }}>
                        <OutlinedInput
                          placeholder="Please enter text"
                          onChange={handleChange}
                          error={deleteSurveyErrorFlag}
                          value={deleteInputValue}
                        />
                        <MyFormHelperText />
                      </FormControl>
                    </form>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDeleteSurvey}
                    disabled={
                      deleteSurveyErrorFlag &&
                      deleteInputValue !== currSurvey.json.title
                    }
                  >
                    Confirm Delete
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              <></>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ManageSurvey;
