import React, { useEffect, useState } from "react";
import { SideBar } from "../../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";

import "../../../styles/commonStyles/nav.css";

const Navigator = () => {
  const [isNavContentShown, setNavContentShown] = useState(false);
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");

  const handleClick = (index) => {
    setNavContentShown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  const currentPath = window.location.pathname;

  return (
    <div className={isDivVisible ? "" : "toggle-Navigation"} id="side-bar-nav">
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              {/* <li
                 className={`nav-content 
                  `}
                >
              <NavLink
 
                to="/pharmacy-admin/dashboard"
                className={`nav-link ${
                    isNavContentShown["Dashboard"] ? "active_group" : null
                  }`}
                data-bs-target="#components-nav">
                <i
                  className="bi bi-speedometer2"
                  style={{ fontSize: "1.4rem" }}
                />
                Dashboard
              </NavLink>
              </li> */}
              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/admin/managePharmacy"
                  className={`nav-link ${
                    isNavContentShown["managePharmacy"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-buildings"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Manage Pharmacy
                </NavLink>
              </li>
              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/admin/managePrivilege"
                  className={`nav-link ${
                    isNavContentShown["managePrivilege"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-person-fill-lock"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Manage Privilege
                </NavLink>
              </li>

              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/admin/manageIntgrations"
                  className={`nav-link ${
                    isNavContentShown["manageIntgrations"]
                      ? "active_group"
                      : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-speedometer2"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Manage Integrations
                </NavLink>
              </li>
              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/admin/manageFeatures"
                  className={`nav-link ${
                    isNavContentShown["manageFeatures"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav">
                  <i className="bi bi-sliders" style={{ fontSize: "1.4rem" }} />
                  Manage Features
                </NavLink>
              </li>
              <li
                className={`nav-content 
                  `}>
                <NavLink
                  to="/admin/manageProviderFeature"
                  className={`nav-link ${
                    isNavContentShown["manageProviderFeature"]
                      ? "active_group"
                      : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-person-gear"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Manage Provider Features
                </NavLink>
              </li>
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
