import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SideBar } from '../../../utils/globalState';
import PatientsFromDB from '../../../components/provider/surveyCenter/PatientsFromDB';
import PatientsFromFile from '../../../components/provider/surveyCenter/PatientsFromFile';

const SendSurvey = () => {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let id = urlParams.get('id');
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  return (
    <div className={!isDivVisible ? 'toggle-sidebar' : ''}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}
              >
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Edit & Review</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pt-3">
                  <PatientsFromDB surveyID={id} />
                </div>
              </div>

              <h1 className="text-center mb-4">OR</h1>
              <PatientsFromFile surveyID={id} />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SendSurvey;
