import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewer = (props) => {
  const [viewPDF, setViewPDF] = useState(null);

  useEffect(() => {
    if (props.pdf !== null) {
      setViewPDF(props.pdf);
    } else {
      setViewPDF(null);
    }
  }, [props.pdf]);
  const newPlugin = defaultLayoutPlugin();
  return (
    <div className="container">
      <div className="pdf-container">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
          {viewPDF ? (
            <>
              <Viewer fileUrl={viewPDF} plugins={[newPlugin]} />
            </>
          ) : (
            <>no PDF</>
          )}
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;
