import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const PatientTotalDocsByStaffChart = (props) => {
  const { title, type, processed } = props;

  const intialData = {
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: title || "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        type: "date",
      },
      legend: {
        position: "left",
        horizontalAlign: "right",
        showForSingleSeries: true,
      },
      fill: {
        opacity: 1,
      },
      animation: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
  };

  const [data, setData] = useState(intialData);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const seriesData = processed.map((item) => ({
      name: `${item.fullName}`,
      data: item.data,
    }));
    setData({
      series: seriesData,
      options: {
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#2b908f",
          "#f45b5b",
          "#91e8e1",
          "#f7a35c",
          "#7cb5ec",
          "#90ed7d",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#2b9e77",
        ],
        fill: {
          colors: undefined,
          opacity: 0.25,
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        title: {
          text: title || "",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "date",
        },
        legend: {
          position: "left",
          horizontalAlign: "right",
          showForSingleSeries: true,
          formatter: function (seriesName, opts) {
            return [
              seriesName + ` (${processed[opts.seriesIndex].count})`,
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex],
            ];
          },
        },
        animation: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    });

    setTotal(getTotal(processed));
  }, [processed, title]);

  function getTotal(data) {
    return data.reduce((acc, item) => {
      return acc + item.count;
    }, 0);
  }

  return (
    <>
      <h4
        style={{
          fontSize: "0.9rem",
          padding: 0,
          margin: "0.5rem 0 0 1.5rem",
          fontWeight: "bold",
        }}>
        Total: {total || 0}
      </h4>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type={type || "bar"}
        height={350}
      />
    </>
  );
};

export default PatientTotalDocsByStaffChart;
