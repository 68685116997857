import React, { useState, useEffect } from 'react';
import { SideBar } from '../../../utils/globalState';
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
import {
  getAllRes,
  getSurveyById,
} from '../../../services/Provider';
import { useNavigate } from 'react-router-dom';
import { convertTimeLocally } from '../../../utils/timeConverter';
import CircularProgress from '@mui/material/CircularProgress';

function Analytics() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');
  const navigate = useNavigate();

  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  var vizPanelOptions = {
    haveCommercialLicense: true, //Add this line
    allowHideQuestions: false,
  };

    const [survey, setSurvey] = useState({});
    const [surveyResults, setSurveyResults] = useState([]);
    const [resLoadingFlag, setResLoadingFlag] = useState(true);
    const [msg, setMsg] = useState('');

       const getResponsesBySurvid = (surveyId) => {
         setResLoadingFlag(true);
         getAllRes()
           .then((res) => {
             if (res.data.All_Patients_Response.length > 0) {
               Promise.all(
                 res.data.All_Patients_Response.map((i) => {
                   try {
                     JSON.parse(i.survey_response);
                     i.survey_response = JSON.parse(i.survey_response);
                   } catch (error) {
                     i.survey_response = {};
                   }

                   return i;
                 })
                   .filter(
                     (item) => item.survey_response.surveyId === id.toString()
                   )
                   .map((i) => {
                     i.survey_response.response.id = i.id;
                     i.survey_response.response.created_at = convertTimeLocally(
                       i.created_at
                     );
                     i.survey_response.response.source = i.survey_response
                       .source
                       ? i.survey_response.source
                       : 'SMS';
                     return i.survey_response.response;
                   })
               ).then((res) => {
                 setSurveyResults(res);
                 setResLoadingFlag(false);
               });
             } else {
               setSurveyResults([]);
               setResLoadingFlag(false);
             }
           })
           .catch((err) => {
             setMsg('Something went wrong.');
           });
       };



    useEffect(() => {

        if (id) {
               getSurveyById(id)
                 .then((res) => {
                 
                   res.data[0].json = JSON.parse(res.data[0].survey);
                   setSurvey(res.data[0]);
                 })
                 .catch((err) => {
                   console.error(err);
                 });
            getResponsesBySurvid(id);
        }

    }, [id]);

     useEffect(() => {
       if (Object.keys(survey).length > 0 && surveyResults.length > 0 && resLoadingFlag === false) {
     
           let su = new Model(survey.json);

           document.getElementById('surveyVizPanel').innerHTML = '';
           let vizP = new VisualizationPanel(
             su.getAllQuestions(),
             surveyResults,
             vizPanelOptions
           );
           vizP.showHeader = false;
           vizP.render('surveyVizPanel');
           // eslint-disable-next-line react-hooks/exhaustive-deps
        
       }

       // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [surveyResults, survey, resLoadingFlag]);

  return (
    <div className={!isDivVisible ? 'toggle-sidebar' : ''}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}
              >
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Edit & Review</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            {msg !== '' && msg}
            <>
              {resLoadingFlag ? (
                <CircularProgress />
              ) : (
                <>
                  {surveyResults.length > 0 ? (
                    <div id="surveyVizPanel"></div>
                  ) : (
                    <div>
                      <p>No responses found!</p>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Analytics