import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

function AnalyticsDatepicker(props) {
  const { startDate, endDate, handleStartDatechange, handleEndDateChange } =
    props;

  const [days, setDays] = useState(30);

  const ytd = dayjs().diff(dayjs().startOf("year"), "day");

  const calcDiff = (startDate, endDate) => {
    const diff = dayjs(endDate).diff(dayjs(startDate), "day");
    return diff ? diff : 0;
  };

  const last7Days = () => {
    handleStartDatechange(dayjs().subtract(7, "day"));
    handleEndDateChange(dayjs());
    setDays(7);
  };

  const last30Days = () => {
    handleStartDatechange(dayjs().subtract(30, "day"));
    handleEndDateChange(dayjs());
    setDays(30);
  };

  const thisMonth = () => {
    handleStartDatechange(dayjs().startOf("month"));
    handleEndDateChange(dayjs());
    setDays(-1);
  };

  const lastMonth = () => {
    handleStartDatechange(dayjs().subtract(1, "month").startOf("month"));
    handleEndDateChange(dayjs().subtract(1, "month").endOf("month"));
    setDays(-2);
  };

  const last60Days = () => {
    handleStartDatechange(dayjs().subtract(60, "day"));
    handleEndDateChange(dayjs());
    setDays(60);
  };

  const last90Days = () => {
    handleStartDatechange(dayjs().subtract(90, "day"));
    handleEndDateChange(dayjs());
    setDays(90);
  };

  const last120Days = () => {
    handleStartDatechange(dayjs().subtract(120, "day"));
    handleEndDateChange(dayjs());
    setDays(120);
  };

  const last6Months = () => {
    handleStartDatechange(dayjs().subtract(180, "day"));
    handleEndDateChange(dayjs());
    setDays(180);
  };

  const yearToDate = () => {
    handleStartDatechange(dayjs().startOf("year"));
    handleEndDateChange(dayjs());
    setDays(-3);
  };

  const allTime = () => {
    handleStartDatechange(dayjs("2008-01-01"));
    handleEndDateChange(dayjs());
    setDays(-4);
  };

  const handleChange = (event) => {
    setDays(event.target.value);
  };

  useEffect(() => {
    const diff = calcDiff(startDate, endDate);

    const isToday = dayjs(endDate).isSame(dayjs(), "day");

    if (diff === 7 && isToday) {
      setDays(7);
    } else if (diff === 30 && isToday) {
      setDays(30);
    } else if (
      dayjs(startDate).isSame(dayjs().startOf("month"), "day") &&
      isToday
    ) {
      setDays(-1);
    } else if (
      dayjs(startDate).isSame(
        dayjs().subtract(1, "month").startOf("month"),
        "day"
      ) &&
      dayjs(endDate).isSame(dayjs().subtract(1, "month").endOf("month"), "day")
    ) {
      setDays(-2);
    } else if (diff === 60 && isToday) {
      setDays(60);
    } else if (diff === 90 && isToday) {
      setDays(90);
    } else if (diff === 120 && isToday) {
      setDays(120);
    } else if (diff === 180 && isToday) {
      setDays(180);
    } else if (diff === ytd && isToday) {
      setDays(-3);
    } else if (
      dayjs(startDate).isSame(dayjs("2008-01-01").startOf("year"), "day") &&
      isToday
    ) {
      setDays(-4);
    } else {
      setDays(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div id="custom_datepicker_container" className="">
      <FormControl>
        <InputLabel id="date-range">Date Range</InputLabel>
        <Select
          labelId="date-range"
          id="date-range"
          label="Date Range"
          onChange={handleChange}
          value={days}>
          <MenuItem onClick={last7Days} value={7}>
            Last 7 Days
          </MenuItem>
          <MenuItem onClick={last30Days} value={30}>
            Last 30 Days
          </MenuItem>
          <MenuItem onClick={thisMonth} value={-1}>
            This Month
          </MenuItem>
          <MenuItem onClick={lastMonth} value={-2}>
            Last Month
          </MenuItem>
          <MenuItem onClick={last60Days} value={60}>
            Last 60 Days
          </MenuItem>
          <MenuItem onClick={last90Days} value={90}>
            Last 90 Days
          </MenuItem>
          <MenuItem onClick={last120Days} value={120}>
            Last 120 Days
          </MenuItem>
          <MenuItem onClick={last6Months} value={180}>
            Last 6 Months
          </MenuItem>
          <MenuItem onClick={yearToDate} value={-3}>
            Year To Date
          </MenuItem>
          <MenuItem onClick={allTime} value={-4}>
            All
          </MenuItem>
          <MenuItem disabled value={0}>
            Custom Range
          </MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => handleStartDatechange(newValue)}
          minDate={dayjs("2008-01-01")}
          maxDate={dayjs(endDate) || dayjs()}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => handleEndDateChange(newValue)}
          minDate={dayjs(startDate)}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
    </div>
  );
}
export default AnalyticsDatepicker;
