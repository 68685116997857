import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import { handleGetPharmacies } from "../../services/Admin";
import LogoPictureUploader from "../../components/admin/LogoPictureUploader";
const PharmacyLogoUpdate = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");
  const [pharmacy, setPharmacy] = useState({});
  useEffect(() => {
    handleGetPharmacies().then((res) => {
      setPharmacy(
        res.data.data.find((item) => item.id.toString() === pharmacy_id)
      );
    });
  }, [pharmacy_id]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pharmacy Logo Update</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">Pharmacy Logo Update</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                {/* <!-- Page  --> */}
                {Object.keys(pharmacy).length > 0 && (
                  <LogoPictureUploader pharmacy={pharmacy} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PharmacyLogoUpdate;
