import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import { SideBar } from "../../../utils/globalState";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, Link } from "react-router-dom";
import ReviewResponse from "../../../components/pharmacyUser/formCenter/ReviewResponse";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { decodeToken } from "react-jwt";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListSubheader } from "@mui/material";
import { Paper } from "@mui/material";
import dayjs from "dayjs";
import { handleAddEvent, handleUpdateEvent } from "../../../services/Calendar";
import { getProfile } from "../../../services/PharmacyUser";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-react-schedule";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button, Divider, Alert } from "@mui/material";
import { getSystemUserByPharmacyId } from "../../../services/Common";
import {
  handleGetCarePlanById,
  handleUpdateCarePlan,
  handleAddResponse,
} from "../../../services/CarePlan";

const InitFormFill = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let cpid = parseInt(urlParams.get("id"));
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"));
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const [survey, setSurvey] = useState(new Model({}));
  let location = useLocation();
  let path = location.pathname.split("/")[1];
  // eslint-disable-next-line no-unused-vars
  const [invaledUrl, setInvaledUrl] = useState(false);
  const [cp, setCP] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [resValue, setResValue] = useState({});
  const [recString, setRecString] = useState("");
  const [currentForm, setCurrentForm] = useState(null);
  const [systemUser, setSystemUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [Modal, open, close] = useModal("root", {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  });

  const [inputs, setInputs] = useState({
    startDate: new Date(),
    location: "form",
  });
  const [fueditor, setFueditor] = useState(false);
  const [msg, setMsg] = useState("");
  const [completed, setCompleted] = useState(false);
  const recObject = useRef(null);
  const onChange = (args) => {
    setRecString(args.value);
  };

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };
  const getCpById = async () => {
    setIsLoading(true);
    handleGetCarePlanById(cpid)
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        const cpRes = {
          ...data,
          initForm: {
            ...data.initForm,
            json: data.initForm.formText.length
              ? JSON.parse(data.initForm.formText)
              : {},
          },
          fuForm: {
            ...data.fuForm,
            json: data.fuForm.formText.length
              ? JSON.parse(data.fuForm.formText)
              : {},
          },
        };
        setCP(cpRes);

        setSurvey(new Model(JSON.parse(res.data.initForm.formText)));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        setInvaledUrl(true);
      });
  };

  useEffect(() => {
    if (cpid) {
      getCpById();
    } else {
      setInvaledUrl(true);
    }
    getProfile(userID).then((res) => {
      setCurrentUser(res.data.data.user[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpid, userID]);

  const handleNewResponse = (answers) => {
    const payload = {
      pharmacyId,
      cpId: cpid,
      value: JSON.stringify(answers),
      cpFormId: cp.initFormId,
      senderId: userID,
      senderInfo: currentUser,
      isInitResponse: true,
      completedOn: new Date(),
    };
    handleAddResponse(payload)
      .then(() => {
        handleUpdateEvent(cp.initEventId, { updatedBy: userID, Status: true });
        setCompleted(true);
      })
      .catch((err) => console.error(err));
  };

  survey.onComplete.add((sender, options) => {
    setFueditor(true);
    setResValue(sender.data);
  });

  const handleSubmit = () => {
    if (cp.fuEventId === null && recString !== "") {
      let eventData = {
        pharmacyId,
        creatorId: cp.creatorID,
        OwnerId: inputs.location === "phone" ? userID : systemUser.id,
        Subject: `Follow up for ${cp?.recipient?.first} ${cp?.recipient?.last} Started ${inputs.startDate}`,
        StartTime: dayjs(inputs.startDate).toISOString(),
        EndTime: dayjs(inputs.startDate).toISOString(),
        IsAllDay: false,
        Location: inputs.location,
        Priority: "Normal",
        RecurrenceRule: recString,
        patientName: `${cp?.recipient?.first} ${cp?.recipient?.last}`,
        isInitEvent: false,
        isAutomated: inputs.location === "form" ? true : false,
      };

      handleAddEvent(eventData)
        .then((res) => {
          handleUpdateCarePlan(cpid, {
            fuEventId: res.data.id,
            isInitialCallAnswered: true,
            initCompletedOn: new Date(),
            isPhoneFu: inputs.location === "phone" ? true : false,
          })
            .then((resp) => {
              handleNewResponse(resValue);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setCompleted(true);
          setTimeout(() => {
            navigate(`/${path}/cp-center/care-plan-manager`);
          }, 3000);
        });
    } else if (cp.fuEventId !== null && recString !== "") {
      handleUpdateEvent(cp.fuEventId, {
        updatedBy: userID,
        pharmacyId,
        creatorId: cp.creatorID,
        OwnerId: userID,
        Subject: `Follow up for ${cp?.recipient?.first} ${cp?.recipient?.last} Started ${inputs.startDate}`,
        StartTime: dayjs(inputs.startDate).toISOString(),
        EndTime: dayjs(inputs.startDate).toISOString(),
        IsAllDay: false,
        Location: inputs.location,
        Priority: "Normal",
        RecurrenceRule: recString,
        patientName: `${cp?.recipient?.first} ${cp?.recipient?.last}`,
        isInitEvent: false,
        isAutomated: true,
      })
        .then((r) => {
          handleNewResponse(resValue);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setCompleted(true);
          setTimeout(() => {
            navigate(`/${path}/cp-center/care-plan-manager`);
          }, 3000);
        });
    } else {
      setMsg("Please select follow up schedule.");
    }
  };

  const formViewClickHandler = (formType) => {
    if (formType === 0) {
      setCurrentForm({
        value: null,
        formText: cp.initForm.json,
      });
      open();
    } else {
      setCurrentForm({
        value: null,
        formText: cp.fuForm.json,
      });
      open();
    }
  };

  const fuOptOutClickHandler = () => {
    handleUpdateCarePlan(cpid, {
      fuOptedIn: false,
      isInitialCallAnswered: true,
      initCompletedOn: new Date(),
    })
      .then(() => {
        const payload = {
          pharmacyId,
          cpId: cpid,
          cpFormId: cp.initFormId,
          senderId: userID,
          senderInfo: currentUser,
          isInitResponse: true,
          value: JSON.stringify(resValue),
          completedOn: new Date(),
        };
        handleAddResponse(payload).then(() => {
          setCompleted(true);
          setTimeout(() => {
            navigate(`/${path}/cp-center/care-plan-manager`);
          }, 3000);
        });
      })
      .catch((err) => console.error(err));
  };

  const handleBkBtn = () => {
    survey.clear();
    survey.data = resValue;
    setFueditor(false);
  };

  useEffect(() => {
    getSystemUserByPharmacyId(pharmacyId)
      .then((res) => {
        setSystemUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacyId]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Init Form</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link
                  to={`${
                    path.search("admin") > -1
                      ? "/pharmacy-admin"
                      : "/pharmacy-user"
                  }/cp-center/care-plan-manager`}
                >
                  {" "}
                  Care Plan Manager
                </Link>
              </li>
              <li className="breadcrumb-item active">Init Form</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {completed ? (
                <Alert severity="success">
                  Thank you for filling the form. you will be redirected shortly
                </Alert>
              ) : (
                <div className="row">
                  <div className="col-9">
                    {" "}
                    {fueditor ? (
                      <div className="row">
                        {msg && <Alert severity="error">{msg}</Alert>}
                        <div className="col-12">
                          <IconButton
                            aria-label="settings"
                            onClick={() => handleBkBtn()}
                          >
                            <KeyboardBackspaceIcon />
                          </IconButton>
                        </div>
                        <div className="col-12 mb-3">
                          <h4 className="my-3 fs-5 fw-bold">
                            Create Follow Up Schedule
                          </h4>
                          <label className="form-label">Start Date</label>
                          <input
                            type="date"
                            value={inputs.startDate}
                            onChange={handleChange}
                            name="startDate"
                            className="form-control mb-3"
                          />
                          <label className="form-label">
                            Follow Up Preference
                          </label>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            name="location"
                            onChange={handleChange}
                            defaultValue={inputs.location}
                            value={inputs.location}
                          >
                            <option value="phone">Phone call follow up</option>
                            <option value="form">Form follow up</option>
                          </select>
                          <RecurrenceEditorComponent
                            id="RecurrenceEditor"
                            ref={recObject}
                            change={onChange}
                            frequencies={[
                              "daily",
                              "weekly",
                              "monthly",
                              "yearly",
                            ]}
                          />
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Create
                          </Button>
                        </div>
                        <div className="col-12 mb-3 text-center">
                          <Divider>OR</Divider>
                        </div>
                        <div className="col-12">
                          <Button
                            variant="contained"
                            sx={{ float: "right", backgroundColor: "darkred" }}
                            onClick={() => fuOptOutClickHandler()}
                          >
                            Opt Out Follow Up
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isLoading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          <Survey model={survey} />
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-3">
                    <div className="row">
                      {Object.keys(cp).length > 0 && cp?.recipient && (
                        <div className="col-12">
                          <Grid>
                            <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}
                                  >
                                    Patient Information
                                  </ListSubheader>
                                }
                              >
                                <ListItemText
                                  primary={`First Name : ${
                                    cp?.recipient?.first || ""
                                  } `}
                                />
                                <ListItemText
                                  primary={`Last Name: ${
                                    cp?.recipient?.last || ""
                                  }  `}
                                />
                                <ListItemText
                                  primary={`Date Of Birth: ${
                                    cp?.recipient?.DOB || ""
                                  } `}
                                />
                                <ListItemText
                                  primary={`Gender: ${
                                    cp?.recipient?.gender || ""
                                  } `}
                                />
                                <ListItemText
                                  primary={`MRN: ${cp?.recipient?.MRN} `}
                                />
                                <ListItemText
                                  primary={`Email Address: ${
                                    cp?.recipient?.email || ""
                                  } `}
                                />
                                <ListItemText
                                  primary={`Phone Number: ${
                                    cp?.recipient?.phone || ""
                                  } `}
                                />
                              </List>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}
                                  >
                                    Initial Form
                                  </ListSubheader>
                                }
                              >
                                <ListItemText
                                  className={
                                    cp.initForm.json.title
                                      ? "clickable_text"
                                      : ""
                                  }
                                  onClick={() => {
                                    formViewClickHandler(0);
                                  }}
                                  primary={`Title : ${
                                    JSON.parse(cp?.initForm?.formText)?.title ||
                                    ""
                                  }`}
                                />
                              </List>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}
                                  >
                                    Follow Up Form
                                  </ListSubheader>
                                }
                              >
                                <ListItemText
                                  className={
                                    cp.fuForm.json.title ? "clickable_text" : ""
                                  }
                                  onClick={() => {
                                    formViewClickHandler(1);
                                  }}
                                  primary={`Title :  ${
                                    JSON.parse(cp?.fuForm?.formText)?.title ||
                                    ""
                                  }`}
                                />
                              </List>

                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                  <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    sx={{
                                      textAlign: "center",
                                      backgroundColor: "#a8beda",
                                      fontSize: "large",
                                    }}
                                  >
                                    General
                                  </ListSubheader>
                                }
                              >
                                <ListItemText
                                  primary={`Created By :  ${
                                    cp?.creator?.first || ""
                                  } ${
                                    cp?.creator?.last || ""
                                  } - Created On : ${dayjs(
                                    cp?.createdOn
                                  ).format("ddd, MMM D, YYYY h:mm A")}`}
                                />
                              </List>
                            </Paper>
                          </Grid>
                        </div>
                      )}
                      <div className="col-12"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Modal>
        {
          <div
            className="card p-3"
            style={{
              width: "80vw",
              height: "80vh",
            }}
          >
            <button
              onClick={() => {
                close();
                setCurrentForm(null);
              }}
              className="btn btn-outline-danger mb-4 p-1"
              style={{ width: "2.1rem" }}
            >
              <CloseIcon />
            </button>
            {currentForm ? (
              <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                <ReviewResponse data={currentForm} />
              </div>
            ) : null}
          </div>
        }
      </Modal>
    </div>
  );
};

export default InitFormFill;
