import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import AnalyticsDatepicker from "../../../../common/forms/AnalyticsDatepicker";
import Chart from "./ProviderCompletedVSPendingChart";

const ProviderCompletedVSPending = (props) => {
  const { data, name } = props;

  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [filteredData, setFilteredData] = useState([]);
  const [dataForCharts, setDataForCharts] = useState({
    completed: { count: 0, dates: [] },
    pending: { count: 0, dates: [] },
  });
  const [proccessedData, setProccessedData] = useState([]);

  const handleStartDatechange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    const processedData = data.filter((item) => {
      const { type } = item.recipientInfo;
      return (
        type === "Provider" &&
        (dayjs(item.sentDate).isBetween(startDate, endDate) ||
          dayjs(item.sentDate).isSame(startDate, "day") ||
          dayjs(item.sentDate).isSame(endDate, "day"))
      );
    });
    setFilteredData(processedData);
  }, [startDate, endDate, data]);

  useEffect(() => {
    const processedData = filteredData.reduce(
      (acc, item) => {
        const { status } = item;
        if (status === "pending") {
          acc.pending.count += 1;
          acc.pending.dates.push(item.sentDate);
        } else {
          acc.completed.count += 1;
          acc.completed.dates.push(item.sentDate);
        }
        return acc;
      },
      {
        completed: { count: 0, dates: [] },
        pending: { count: 0, dates: [] },
      }
    );
    for (const key in processedData) {
      processedData[key].dates.sort((a, b) => {
        return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
      });
    }
    setDataForCharts(processedData);
  }, [filteredData]);

  const groupByDays = (incomingDate) => {
    const processedData = [];
    for (const key in incomingDate) {
      const data = incomingDate[key].dates.reduce(
        (acc, item) => {
          const day = dayjs(item).day();
          if (day === dayjs(startDate).day()) {
            acc.day1 += 1;
          } else if (day === dayjs(startDate).add(1, "day").day()) {
            acc.day2 += 1;
          }
          if (day === dayjs(startDate).add(2, "day").day()) {
            acc.day3 += 1;
          }
          if (day === dayjs(startDate).add(3, "day").day()) {
            acc.day4 += 1;
          }
          if (day === dayjs(startDate).add(4, "day").day()) {
            acc.day5 += 1;
          }
          if (day === dayjs(startDate).add(5, "day").day()) {
            acc.day6 += 1;
          }
          if (day === dayjs(startDate).add(6, "day").day()) {
            acc.day7 += 1;
          }
          return acc;
        },
        { day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0 }
      );
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }

      processedData.push({ name: key, data: arr });
    }

    return processedData;
  };

  const groupByWeek = (data) => {
    const processedData = [];
    for (const key in data) {
      const data = dataForCharts[key].dates.reduce(
        (acc, item) => {
          const week = dayjs(item).week();
          if (week === dayjs(startDate).week()) {
            acc.week1 += 1;
          } else if (week === dayjs(startDate).add(1, "week").week()) {
            acc.week2 += 1;
          }
          if (week === dayjs(startDate).add(2, "week").week()) {
            acc.week3 += 1;
          }
          if (week === dayjs(startDate).add(3, "week").week()) {
            acc.week4 += 1;
          }
          if (week === dayjs(startDate).add(4, "week").week()) {
            acc.week5 += 1;
          }

          return acc;
        },
        { week1: 0, week2: 0, week3: 0, week4: 0, week5: 0 }
      );
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }
      processedData.push({ name: key, data: arr });
    }
    return processedData;
  };

  const groupByMonthByYears = (incomingData) => {
    const processedData = [];
    for (const key in incomingData) {
      const data = incomingData[key].dates.reduce((acc, item) => {
        const month = dayjs(item).month();
        const year = dayjs(item).year();
        if (acc[year]) {
          if (acc[year][month]) {
            acc[year][month] += 1;
          } else {
            acc[year][month] = 1;
          }
        } else {
          acc[year] = {};
          acc[year][month] = 1;
        }

        return acc;
      }, {});
      const arr = [];
      for (const key in data) {
        arr.push({ x: key, y: data[key] });
      }

      const yearsArr = arr.map((item) => {
        const monthsArr = [];
        for (const key in item.y) {
          monthsArr.push({ x: key, y: item.y[key] });
        }
        return {
          x: item.x,
          y: monthsArr,
        };
      });

      const flatArr = yearsArr
        .map((item) => {
          return item.y.map((el) => {
            return {
              x: `${dayjs().month(el.x).format("MMM")} ${item.x}`,
              y: el.y,
            };
          });
        })
        .flat(2);

      processedData.push({ name: key, data: flatArr });
    }

    const dateRange = dayjs(endDate).diff(startDate, "month") + 1;
    const monthsArr = [];
    for (let i = 0; i <= dateRange; i++) {
      if (i === 0) {
        monthsArr.push(dayjs(startDate).format("MMM YYYY").toString());
        continue;
      } else {
        monthsArr.push(
          dayjs(startDate).add(i, "month").format("MMM YYYY").toString()
        );
      }
    }

    processedData.forEach((item) => {
      const arr = [];
      monthsArr.forEach((month) => {
        const found = item.data.find((el) => el.x === month);
        if (found) {
          arr.push(found);
        } else {
          arr.push({ x: month, y: 0 });
        }
      });
      item.data = arr;
    });

    return processedData;
  };

  useEffect(() => {
    const diff = dayjs(endDate).diff(startDate, "day");
    if (diff <= 7) {
      const processedData = groupByDays(dataForCharts);
      setProccessedData(processedData);
    } else if (diff <= 30) {
      const processedData = groupByWeek(dataForCharts);
      setProccessedData(processedData);
    } else {
      const processedData = groupByMonthByYears(dataForCharts);
      setProccessedData(processedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForCharts]);

  return (
    <div className="row my-4 card mx-0">
      <div className="card-header d-flex justify-content-between h-50 pt-4 pb-1">
        <h3 className="mt-2">Providers Engagement by {name} Status</h3>
        <AnalyticsDatepicker
          startDate={startDate}
          endDate={endDate}
          handleStartDatechange={handleStartDatechange}
          handleEndDateChange={handleEndDateChange}
        />
      </div>
      <Chart
        total={`Total (${
          dataForCharts.completed.count + dataForCharts.pending.count
        })`}
        name1={{ text: "Completed", count: dataForCharts.completed.count }}
        name2={{ text: "Pending", count: dataForCharts.pending.count }}
        data1={proccessedData[0] ? proccessedData[0].data : []}
        data2={proccessedData[1] ? proccessedData[1].data : []}
        title=""
        color1="#e58f1f"
        color2="#3fcdcd"
        type="area"
      />
      <div className="card-body"></div>
    </div>
  );
};

export default ProviderCompletedVSPending;
