import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UserSelectForm(props) {
  const { names, handleSelectUsers, handleUsersReset } = props;
  const [personNames, setPersonNames] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="d-flex align-items-center">
      <FormControl sx={{ m: 1, minWidth: 500, maxWidth: "50vw" }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">
          {personNames.length ? "Select Users" : "All Users"}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personNames}
          onChange={handleChange}
          input={
            <OutlinedInput
              label={personNames.length ? "Select Users" : "All Users"}
            />
          }
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}>
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personNames.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {personNames.length ? (
        <div>
          <button
            className="btn btn-success me-2"
            onClick={() => handleSelectUsers(personNames)}>
            Submit
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              handleUsersReset();
              setPersonNames([]);
            }}>
            Reset
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default UserSelectForm;
