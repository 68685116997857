import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../utils/globalState";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Tabs, Tab, Box } from "@mui/material";
import { useModal } from "react-hooks-use-modal";
import { Model } from "survey-core";
import {
  getAllRes,
  handleGetptbyid,
  getSurveys,
} from "./../../services/PharmacyUser";
import { getUserById } from "./../../services/Common";
import dayjs from "dayjs";
import { decodeToken } from "react-jwt";
import { markSurveyResponseAsReviewed } from "./../../services/PharmacyUser";

const handleObjectRewrite = (Arr) => {
  return Arr.map((object) => {
    let form = object.survey;
    let response = object.response;
    const filteredResponse = {};

    for (let key in response) {
      if (key.includes("question")) {
        filteredResponse[key] = response[key];
      }
    }

    const survey = new Model(form);
    survey.data = filteredResponse;
    const questionsAnswered = survey.getPlainData();

    return {
      ...form,
      pages: [
        {
          name: "page1",
          elements: questionsAnswered,
        },
      ],
      patientId: response.patientId,
      actions:
        response.actions.length > 0
          ? JSON.parse(response.actions).reverse()
          : [],
      resolved: response.resolved,
      resolved_date: response.resolved_date,
      id: response.id,
      completedOn: response.created_at?.split(" ")[0].split("-"),
      isReviewed: response.isReviewd ? true : false,
      reviewedBy: response.reviewedBy,
      reviewedOn: response.reviewedOn
        ? dayjs(response.reviewedOn).format("MM-DD-YYYY hh:mm a")
        : null,
      reviewedById: response.reviewedById,
    };
  });
};

const SurveyDissatisfaction = (props) => {
  const { dashboard } = props;
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userId = decodeToken(localStorage.getItem("Token")).userId;
  const pharmacyId = localStorage.getItem("pharmacy");
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [responses, setResponses] = useState([]);
  const [rawSurveyResp, setRawSurveyResp] = useState([]);
  const [processedSurveyResp, setProcessedSurveyResp] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [curUser, setCurUser] = useState({});

  ////////////////////////////// start ///////////////////////////

  // eslint-disable-next-line no-unused-vars
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });

  const getAllSurvys = () => {
    getSurveys()
      .then((resp) => {
        setSurveys(
          resp.data.Surveys.filter(
            (survey) => survey.pharmacyID === pharmacyId
          ).map((el) => {
            return { id: el.id, json: JSON.parse(el.survey) };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getresBy = () => {
    getAllRes().then((resp) => {
      if (resp.data.All_Patients_Response.length > 0) {
        Promise.all(
          resp.data.All_Patients_Response.map((i) => {
            try {
              i.survey_response = JSON.parse(i.survey_response);
            } catch (error) {
              i.survey_response = {};
            }

            return i;
          }).map((i) => {
            i.survey_response.response.created_at =
              `${i.created_at.split("T")[0]} ${i.created_at
                .split("T")[1]
                .split("Z")[0]
                .slice(0, -4)}` || "";
            i.survey_response.response.id = i.id;
            i.survey_response.response.survey_id =
              i.survey_response.surveyId || "";
            i.survey_response.response.actions = i.action ? i.action : [];
            i.survey_response.response.resolved = i.resolved ? true : false;
            i.survey_response.response.resolved_date = i.resolved_date
              ? i.resolved_date
              : "";
            i.survey_response.response.patientId = i.pt_id || "";

            return {
              ...i.survey_response.response,
              isReviewd: i.isReviewed,
              reviewedBy: i.reviewedBy,
              reviewedOn: i.reviewedOn,
              reviewedById: i.reviewedById,
            };
          })
        ).then((res) => {
          setResponses(res);
        });
      } else {
        setResponses([]);
      }
    });
  };

  useEffect(() => {
    getAllSurvys();
    getresBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userId &&
      getUserById(userId)
        .then((res) => {
          setCurUser(res.data?.data?.user[0] || {});
        })
        .catch((err) => console.error(err));
  }, [userId]);

  useEffect(() => {
    setRawSurveyResp(
      responses
        .map((el) => {
          return {
            survey:
              surveys.find((item) => item.id.toString() === el.survey_id) !==
              undefined
                ? surveys.find((item) => item.id.toString() === el.survey_id)
                    .json
                : null,

            response: el,
          };
        })
        .filter((item) => item.survey !== undefined && item.survey !== null)
    );
  }, [surveys, responses]);

  useEffect(() => {
    rawSurveyResp.length > 0 &&
      setProcessedSurveyResp(handleObjectRewrite(rawSurveyResp));
  }, [rawSurveyResp]);

  useEffect(() => {
    let rawData = processedSurveyResp.map((el, idx) => {
      return {
        ...el,
        actions:
          el.actions.length > 0
            ? el.actions.map((item) => {
                return {
                  ...item,
                  action_date: dayjs(item.action_date).format(
                    "MM/DD/YYYY hh:mm a"
                  ),
                };
              })
            : [],
        lastAction: el.actions.length > 0 ? el.actions[0].action : "",
        lastActionDate:
          el.actions.length > 0
            ? dayjs(el.actions[0].action_date).format("MM/DD/YYYY hh:mm a")
            : "",
        action_user: el.actions.length > 0 ? el.actions[0].action_user : "",
        surveyTitle: el.title,
        completedOn: `${el.completedOn[1]}/${el.completedOn[2]}/${el.completedOn[0]}`,
        questionsAnswers: el.pages[0].elements.map((item) => {
          return {
            answer:
              typeof item.value === "boolean" || typeof item.value === "number"
                ? item.value
                : item.displayValue || item.value || "",
            question: item.title ? item.title : "",
          };
        }),
      };
    });
    setData(rawData);
  }, [processedSurveyResp]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const preSort =
        data.length > 0 &&
        data.filter((response) =>
          response.questionsAnswers.find(
            (ansr) =>
              typeof ansr.answer === "string" &&
              ansr.answer.trim().toLowerCase() === "disagree"
          )
        );

      const finalSort =
        preSort.length > 0 &&
        preSort.map((item) => {
          return {
            ...item,
            toBeContacted: item.questionsAnswers.find(
              (item) =>
                typeof item.question === "string" &&
                item.question.includes("need to be contacted")
            )?.answer
              ? true
              : false,
          };
        });

      if (Array.isArray(finalSort) && finalSort.length > 0) {
        // Add a check to ensure finalSort is an array
        const sortedDataWithPtInfo = await Promise.all(
          finalSort.map(async (el) => {
            try {
              const res = await handleGetptbyid(el.patientId);
              el.patientInfo = res.data.patient;
              el.patientName = `${res.data.patient.firstName} ${res.data.patient.lastName}`;
              el.MRN = res.data.patient.MRN;
              el.providing_pharmacy = res.data.patient.providing_pharmacy;
            } catch (err) {
              console.error(err);
            }
            return el;
          })
        );

        if (sortedDataWithPtInfo.length > 0) {
          setFilteredData(sortedDataWithPtInfo);
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columnDef = [
    { field: "id", headerName: "ID", minWidth: 70, maxWidth: 70, hide: true },
    { field: "surveyTitle", headerName: "Survey Title", minWidth: 300 },
    { field: "patientName", headerName: "Patient Name", minWidth: 170 },
    { field: "MRN", headerName: "MRN", minWidth: 80 },
    {
      field: "completedOn",
      headerName: "Completed On",
      minWidth: 140,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "providing_pharmacy",
      headerName: "Providing Pharmacy",
      minWidth: 220,
    },
    {
      field: "toBeContacted",
      headerName: "To Be Contacted",
      minWidth: 150,
      valueGetter: (params) => (params.row.toBeContacted ? "Yes" : "No"),
    },
    {
      field: "lastAction",
      headerName: "Last Action Taken",
      minWidth: 350,
    },
    {
      field: "action_user",
      headerName: "Action Taken By",
      minWidth: 150,
    },
    {
      field: "lastActionDate",
      headerName: "Action Date",
      minWidth: 200,
    },
    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 100,
      valueGetter: (params) => (params.row.isReviewed ? "Yes" : "No"),
    },
    {
      field: "reviewedOn",
      headerName: "Reviewed On",
      minWidth: 200,
    },
    {
      field: "reviewedBy",
      headerName: "Reviewed By",
      minWidth: 200,
    },
  ];

  const handleRowClick = (params) => {
    if (dashboard) {
      navigate(`../survey-dissatisfaction/${params.row.id}`, {
        relative: "path",
      });
    } else {
      navigate(`${params.row.id}`);
    }

    if (!params.row.isReviewed) {
      markSurveyResponseAsReviewed(params.row.id, {
        reviewedById: curUser.id,
        reviewedBy: `${curUser.first} ${curUser.last}`,
      })
        .then(() => {
          const updatedData = filteredData.map((el) => {
            if (el.id === params.row.id) {
              return {
                ...el,
                isReviewed: true,
                reviewedBy: `${curUser.first} ${curUser.last}`,
                reviewedOn: dayjs().format("MM-DD-YYYY hh:mm a"),
                reviewedById: curUser.id,
              };
            }
            return el;
          });
          setFilteredData(updatedData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getRowClassName = (params) => {
    return `pointer-cursor ${params.row.toBeContacted ? "tbc" : "ntbc"} ${
      params.row.isReviewed ? "" : "notReviewed"
    }`;
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id={`${dashboard ? "" : "main"}`} className="main">
        {!dashboard && (
          <div className="pagetitle">
            <h1>Survey Dissatisfaction Log</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">survey-center</li>
                <li className="breadcrumb-item active">
                  survey-dissatisfaction
                </li>
              </ol>
            </nav>
          </div>
        )}
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div>
                {isLoading ? (
                  <div className="d-flex flex-row justify-content-center align-items-center ">
                    <div className="spinner-border text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="ms-4 align-middle text-secondary px-1 py-5">
                      Loading Data Please wait...
                    </div>
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="tabs">
                      <Tab label="Pending Action" />
                      <Tab label="Resolved" />
                    </Tabs>
                    {value === 0 && (
                      <div className="dg_1liner">
                        <DataGrid
                          rows={filteredData.filter(
                            (el) => el.resolved === false
                          )}
                          columns={columnDef}
                          onRowClick={handleRowClick}
                          density="compact"
                          getRowClassName={getRowClassName}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          initialState={{
                            sorting: {
                              sortModel: [
                                { field: "completedOn", sort: "desc" },
                              ],
                            },
                          }}
                        />
                      </div>
                    )}
                    {value === 1 && (
                      <div className="dg_1liner">
                        <DataGrid
                          rows={filteredData.filter(
                            (el) => el.resolved === true
                          )}
                          columns={columnDef}
                          onRowClick={handleRowClick}
                          density="compact"
                          getRowClassName={getRowClassName}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          initialState={{
                            sorting: {
                              sortModel: [
                                { field: "completedOn", sort: "desc" },
                              ],
                            },
                          }}
                        />
                      </div>
                    )}
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SurveyDissatisfaction;
