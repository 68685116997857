import React, { useEffect, useState } from "react";
import {
  getAllProviders,
  getFeatureByProviderId,
  handleGetFeatures,
  handleAddProviderFeature,
  handleDeleteProviderFeature,
} from "../../services/Admin";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SideBar } from "../../utils/globalState";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

function ManageProviderFeature() {
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const [providersList, setProvidersList] = useState([]);
  const [thisProvider, setThisProvider] = useState({});
  const [msg, setMsg] = useState("");
  const [detailsFlags, setDetailsFlags] = useState(false);
  const [allFeatures, setAllFeatures] = useState([]);
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [providerfeatures, setProviderFeatures] = useState([]);

  const handleProvidersInfo = () => {
    getAllProviders().then((res) => {
      setProvidersList(res.data);
    });
  };

  const getAllFeatures = () => {
    handleGetFeatures().then((res) => {
      setAllFeatures(res.data.features);
    });
  };
  useEffect(() => {
    handleProvidersInfo();
    getAllFeatures();
  }, []);

  useEffect(() => {}, [allFeatures]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      field: "pic_url",
      headerName: "Profile Picture",
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              alt={params.first + " " + params.last}
              src={params.pic_url}
            />
          </>
        );
      },
    },
    { field: "first", headerName: "First Name", minWidth: 250 },
    { field: "last", headerName: "Last Name", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 200 },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 150,
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <button
              className="btn btn-sm btn-primary me-2"
              onClick={() => {
                handleRowClick(params);
              }}>
              <BorderColorIcon /> Edit
            </button>
          </>
        );
      },
    },
  ]);

  const handleRowClick = (params) => {
    setThisProvider(params.row);
    setDetailsFlags(true);
  };

  const handelGetPrivlage = (provider_id) => {
    getFeatureByProviderId(provider_id)
      .then((res) => {
        setProviderFeatures(
          allFeatures
            .map((feature) => {
              if (
                res.data.features.some((f) => f.feature === feature.feature)
              ) {
                return feature;
              }
            })
            .filter((feature) => {
              return feature !== undefined;
            })
        );
        setAvailableFeatures(
          allFeatures
            .map((feature) => {
              if (
                !res.data.features.some((f) => f.feature === feature.feature)
              ) {
                return feature;
              }
            })
            .filter((feature) => {
              return feature !== undefined;
            })
        );
      })
      .catch((err) => {
        console.error(err);
        setProviderFeatures([]);
        setThisProvider({});
        setDetailsFlags(false);
      });
  };

  useEffect(() => {
    if (Object.keys(thisProvider).length > 0) {
      handelGetPrivlage(thisProvider.id);
    }
  }, [thisProvider]);
  // +++++++++++++++++++++++++++++++++++++++ Material UI +++++++++++++++++++++++++++++++++++++++++
  const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };

  const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  };

  const union = (a, b) => {
    return [...a, ...not(b, a)];
  };

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(
      availableFeatures
        .map((priv) => {
          return priv.feature;
        })
        .filter((item) => item !== undefined)
    );
    setRight(
      providerfeatures
        .map((priv) => {
          return priv.feature;
        })
        .filter((item) => item !== undefined)
    );
  }, [providerfeatures, availableFeatures]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setMsg("");
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    checked.forEach((ch) => {
      let payload = {
        providerID: thisProvider.id,
        featureID: allFeatures.find((fea) => fea.feature === ch).id,
      };

      handleAddProviderFeature(payload)
        .then((res) => {
          setMsg(res.data.message);
        })
        .catch((err) => {
          setMsg(err.response.data.message);
        });
    });
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    checked.forEach((ch) => {
      let payload = {
        providerID: thisProvider.id,
        featureID: allFeatures.find((fea) => fea.feature === ch).id,
      };
      handleDeleteProviderFeature(payload)
        .then((res) => {
          // handelGetAllPrivelages();
          // handelGetRoles();
          setMsg(res.data.message);
        })
        .catch((err) => {
          setMsg(err.response.data.message);
        });
    });
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          minHeight: 230,
          maxHeight: 600,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Manage Provider Features</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Manage Provider Features</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {detailsFlags ? (
              <div className="m-2">
                <IconButton
                  onClick={() => {
                    setDetailsFlags(false);
                    setThisProvider({});
                    setMsg("");
                  }}>
                  <ArrowBackIcon />
                </IconButton>
                {msg === "" ? (
                  <></>
                ) : (
                  <div className="alert alert-primary p-3" role="alert">
                    {msg}
                  </div>
                )}
                <div className="container" id="superAdminModal">
                  <div className="row">
                    <div className="col-4 col-md-4">
                      <Card
                        sx={{
                          maxWidth: "100%",
                          padding: "10px",
                        }}>
                        <Stack
                          direction="column"
                          spacing={1}
                          justifyContent="center"
                          alignItems="center">
                          <img
                            src={
                              thisProvider.pic_url ||
                              require("../../assets/images/patient_placeholder.png")
                            }
                            alt={`${thisProvider.first} ${thisProvider.last}`}
                            className="rounded-circle"
                            style={{ maxWidth: "50%", maxHeight: "50%" }}
                          />

                          <Typography gutterBottom variant="h5" component="div">
                            {thisProvider.first} {thisProvider.last}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {thisProvider.email}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {thisProvider.phone}
                          </Typography>
                        </Stack>
                      </Card>
                    </div>
                    <div className="col-8 col-md-8">
                      <Card sx={{ maxWidth: "100%", padding: "10px" }}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center">
                          <Grid item>
                            {customList("All Available Features", left)}
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="center">
                              <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right">
                                ADD Feature &gt;
                              </Button>
                              <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left">
                                &lt; REMOVE Feature
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item>
                            {customList(
                              `${thisProvider.first} ${thisProvider.last} Assigned Features`,
                              right
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={providersList}
                    columns={columnDefs}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 100,
                        },
                      },
                    }}
                    pageSizeOptions={[100]}
                    getRowId={(row) => row.id}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                  />
                </Box>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ManageProviderFeature;
