import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

export const generateCode = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/generateCode`,
    payload
  );
};

export const getCodes = (pharmacy_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/pharmacyID/${pharmacy_id}/generatedCodes`
  );
};

export const getAllPharmacyUsers = (pharmacy_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/pharmacy/${pharmacy_id}/users`
  );
};

export const createNewUser = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addUser`,
    payload
  );
};

export const getUserRole = (user_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/user/${user_id}/role`
  );
};

export const getAllRoles = (pharmacy_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/pharmacy/${pharmacy_id}/roles`
  );
};

export const handelAddRoleToUser = (user_Id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/pharmacyAdmin/user/${user_Id}/role`,
    payload
  );
};

export const handelAddRoleToNewUser = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addRoleToUser`,
    payload
  );
};

export const handelGetPrivlage = (pharmacy_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/pharmacy/${pharmacy_id}/privileges`
  );
};

export const handelGetPrivlageByRole = (rol_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/role/${rol_id}/privileges`
  );
};

export const handelDeletePrivlageFromRole = (roleID, privilegeID) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/pharmacyAdmin/role/${roleID}/privilege/${privilegeID}`
  );
};

export const addPrivlageToRole = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addPrivilegeToRole`,
    payload
  );
};

export const addNewRole = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addRole`,
    payload
  );
};

export const getMessagesUsers = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/getAllGroupUsers/${pharmacyId}`
  );
};

export const addMessagesUser = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addToGroup`,
    payload
  );
};

export const deleteMessagesUser = (payload) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/pharmacyAdmin/deleteFromGroup`,
    {
      data: payload,
    }
  );
};

export const getPharmacyAnnouncement = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/announcements/${pharmacyId}`
  );
};

export const updatePharmacyAnnouncement = (pharmacyId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/pharmacyAdmin/announcements/${pharmacyId}`,
    payload
  );
};
