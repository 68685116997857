import React from 'react'
import MessageUsers from '../../components/pharmacyUser/messageCenter/MessageUsers'
import { SideBar } from '../../utils/globalState';

 const AddUsersToMessagesGroupe = () => {
     const isDivVisible = SideBar((state) => state.isDivVisible);

  return (
    <div className={!isDivVisible ? 'toggle-sidebar' : ''}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Messages Groups Management</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">
                Messages Groups Management
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <MessageUsers />
          </div>
        </div>
      </main>
    </div>
  );
}

export default AddUsersToMessagesGroupe