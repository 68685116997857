import React, { useEffect, useState } from "react";
import placeholder from "../../../assets/images/patient_placeholder.png";
import { getProviders } from "../../../services/PharmacyUser";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import {
  linkPatientToProvider,
  togglePatientProviderLinkStatus,
} from "../../../services/Common";
import CaregiversModal from "./CaregiversModal";

const ManageCaregivers = (props) => {
  const { curUserInfo } = props;
  const pharmacy_id = localStorage.getItem("pharmacy");

  const [currentPatient, setCurrentPatient] = useState({});
  const [providersRaw, setProvidersRaw] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [msg, setMsg] = useState({ status: false, text: "" });
  const [selectedProviderForModal, setSelectedProviderForModal] =
    useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (provider) => {
    setSelectedProviderForModal(provider);
    setOpenModal(true);
  };

  useEffect(() => {
    if (curUserInfo) {
      setCurrentPatient({
        ...curUserInfo,
        providers: curUserInfo.providers.map((el) => ({
          ...el,
          linkStatus: el.linkStatus === 1 ? true : false,
        })),
      });
    }

    getProviders(pharmacy_id)
      .then((res) => {
        const providersFromDB = res.data?.data || [];
        if (providersFromDB.length > 0) {
          setProvidersRaw(providersFromDB.map((item) => item.user));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [curUserInfo, pharmacy_id]);

  useEffect(() => {
    const linkedProvidersIds =
      currentPatient.providers?.map((el) => el.id) || [];

    const notLinkedProviders = providersRaw.filter(
      (el) => !linkedProvidersIds.includes(el.id)
    );

    notLinkedProviders.length > 0 &&
      setProvidersOptions(
        notLinkedProviders.map(
          (el) => `${el.id}- ${el.first} ${el.last} (NPI:${el.NPI})`
        )
      );
  }, [currentPatient.providers, providersRaw]);

  const handleSelect = (event, newValue) => {
    setSelectedProvider(newValue);
  };

  const handleFilterChange = (event, newInputValue) => {
    setFilterValue(newInputValue);
  };

  const filteredProvidersOptions = providersOptions.filter((option) => {
    const searchValue =
      typeof filterValue === "string" ? filterValue.toLowerCase() : "";
    return option.toLowerCase().includes(searchValue);
  });

  const handleSubmit = () => {
    setMsg({ status: false, text: "" });
    const providerId = selectedProvider
      ? Number(selectedProvider.split("-")[0].trim())
      : "";

    linkPatientToProvider(providerId, currentPatient.id)
      .then((res) => {
        setMsg({ status: true, text: res.data.message });
        setSelectedProvider(null);
        setFilterValue("");

        setCurrentPatient({
          ...currentPatient,
          providers: [
            ...currentPatient.providers,
            {
              ...providersRaw.find((el) => el.id === providerId),
              linkStatus: true,
            },
          ],
        });
        setTimeout(() => {
          setMsg({ status: false, text: "" });
        }, 2500);
      })
      .catch((err) => {
        console.error(err);
        setMsg({
          status: false,
          text:
            err.response.data.error ||
            err.response.data.message ||
            "Error OCcured",
        });
      });
  };

  const handleTogglerSubmit = (providerID, PatientID) => {
    setMsg({ status: false, text: "" });

    togglePatientProviderLinkStatus(providerID, PatientID)
      .then((res) => {
        setMsg({ status: true, text: "Link Status Changed Successfully" });

        setCurrentPatient({
          ...currentPatient,
          providers: currentPatient.providers.map((el) => {
            if (el.id === providerID) {
              return { ...el, linkStatus: !el.linkStatus };
            }
            return el;
          }),
        });
        setTimeout(() => {
          setMsg({ status: false, text: "" });
        }, 2500);
      })
      .catch((err) => {
        console.error(err);
        setMsg({
          status: false,
          text:
            err.response.data.error ||
            err.response.data.message ||
            "Error Changing Link Status",
        });
      });
  };

  const renderProviderListItem = (provider) => (
    <li key={provider.id} className="provider_list_item">
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <div
          className="provider_info_subitem d-flex flex-row justify-content-start align-items-center"
          onClick={() => handleOpenModal(provider)}>
          <img
            className="rounded-circle"
            src={provider.pic_url ? provider.pic_url : placeholder}
            alt="pic"
            style={{ width: "40px", height: "40px", padding: "2px" }}
          />
          <span className="ms-3">{`${provider.first} ${provider.last}`}</span>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center ">
          <span
            className={`me-4 fw-bold ${
              provider.linkStatus
                ? "status-active text-success"
                : "status-inactive text-danger"
            }`}>
            {provider.linkStatus ? "Active" : "Inactive"}
          </span>
          <button
            className="link-status-toggle btn btn-sm btn-success "
            onClick={() => handleTogglerSubmit(provider.id, currentPatient.id)}>
            {provider.linkStatus ? "Deactivate Link" : "Activate Link"}
          </button>
        </div>
      </div>
    </li>
  );

  return (
    <div className="col-12 p-2" style={{ minHeight: "30rem" }}>
      <div>
        <h4 className="card-title text-center my-1">Link New Caregiver</h4>
        {msg.text && (
          <div
            className={`alert text-center ${
              msg.status ? "alert-success" : "alert-danger"
            }`}>
            {msg.text}
          </div>
        )}
        <div className="row">
          <Autocomplete
            className="col-12 col-md-10"
            value={selectedProvider}
            onChange={handleSelect}
            inputValue={filterValue}
            onInputChange={handleFilterChange}
            options={filteredProvidersOptions}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Select Caregiver" />
            )}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedProvider}
            className={`col-5 col-md-2 mx-auto ${
              selectedProvider ? "btn-success " : "btn-grey"
            }`}>
            Submit
          </Button>
        </div>
      </div>
      <hr />
      {currentPatient.providers?.length > 0 ? (
        <div>
          <h4 className="card-title text-center my-1">Linked Caregivers</h4>
          <ul>{currentPatient.providers.map(renderProviderListItem)}</ul>
        </div>
      ) : (
        <h4 className="card-title text-center my-1">No Linked Caregivers</h4>
      )}
      {selectedProviderForModal && (
        <CaregiversModal
          selectedProviderForModal={selectedProviderForModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
};

export default ManageCaregivers;
