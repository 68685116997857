import React, { useState, useEffect } from "react";
import { useModal } from "react-hooks-use-modal";
import { updateCpOptOutByPatientId } from "../../../services/CarePlan";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getPatientscharts } from "../../../services/PharmacyUser";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

const SelectPatient = (props) => {
  const { setPatient } = props;
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"), 10);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const [Modal, open, close] = useModal("root", {
    preventScroll: true,
  });

  useEffect(() => {
    getPatientscharts(pharmacyId)
      .then((res) => {
        setOptions(res.data ? res.data : []);
      })
      .catch((err) => {
        console.error(err);
        setOptions([]);
      });
  }, [pharmacyId]);

  const handleSelectPatient = (patient) => {
    if (patient.cpOptOut) {
      open();
    } else {
      setConfirmed(true);
      setPatient(patient);
    }
  };

  const cpStatusClickHandler = () => {
    const payload = {
      cpOptOut: !value.cpOptOut,
    };
    updateCpOptOutByPatientId(value.id, payload)
      .then(() => {
        setPatient(value);
        setConfirmed(true);
        setOptions(
          options.map((item) =>
            item.id === value.id ? { ...item, cpOptOut: !value.cpOptOut } : item
          )
        );
        close();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className="row">
        <div className=" col-12 text-center pt-3 pb-3 w-100 fw-bold d-flex justify-content-center">
          <p>Select Patient</p>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <Autocomplete
            disabled={confirmed}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{
              width: 300,
            }}
            getOptionLabel={(item) =>
              `${item.first} ${item.last}${item.MRN ? "- MRN:" + item.MRN : ""}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Search Patient" />
            )}
          />
        </div>
        <br />
        {value !== null && (
          <>
            <div className="col-12">
              <ul className="list-group mb-3 mt-3" disabled={confirmed}>
                <li className="list-group-item text-center pt-3 pb-3 w-100 fw-bold bg-info">
                  <Stack direction="column" spacing={2}>
                    Patient Details
                    <Avatar
                      alt={`${value.first} ${value.last}`}
                      src="../../../assets/images/patient_placeholder.png"
                      sx={{
                        alignSelf: "center",
                        alignItems: "center",
                        float: "right",
                      }}
                    />
                  </Stack>
                </li>
                <li className="list-group-item">
                  Patient First Name : {value.first}
                </li>
                <li className="list-group-item">
                  Patient Last Name : {value.last}
                </li>
                <li className="list-group-item">
                  Patient Date of Birth : {value.DOB}
                </li>
                <li className="list-group-item">Patient MRN : {value.MRN}</li>
                <li className="list-group-item">
                  Patient Gender : {value.gender}
                </li>
                <li className="list-group-item">
                  Patient Phone : {value.phone}
                </li>
                <li className="list-group-item">
                  Patient Email : {value.email}
                </li>
              </ul>
            </div>
            <Stack direction="column" spacing={2} sx={{ paddingRight: "10px" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setConfirmed(false);
                  setValue(null);
                }}
                disabled={!confirmed}>
                Select Different Patient
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={() => handleSelectPatient(value)}
                disabled={confirmed}>
                Confirm
              </Button>
            </Stack>
          </>
        )}
      </div>

      <Modal title="Change Careplan Status" close={close}>
        {value ? (
          <div className="card" style={{ width: "35vw", height: "35vh" }}>
            <div className="card-body">
              <h5 className="text-center fs-5 text-danger">
                {value.first} {value.last} Opted Out from Care Plan
              </h5>
              <hr />
              <p className="card-text">
                Discuss with patient to confirm if he/she would like to{" "}
                <span className="fw-bold text-success">Opt In </span>
                before proceeding
              </p>
              <p className="card-text mt-4">
                If you already confirmed you can click{" "}
                <span className="fw-bold text-success">Opt In </span>, Otherwise
                click <span className="fw-bold"> Cancel</span>
              </p>
              <div className="d-flex justify-content-between mt-3">
                <button
                  className={`btn btn-outline-${
                    value.cpOptOut ? "success" : "danger"
                  }`}
                  onClick={() => cpStatusClickHandler()}>
                  {value.cpOptOut ? "Opt In" : "Opt Out"}
                </button>
                <button className="btn btn-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default SelectPatient;
