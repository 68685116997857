import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

function Log(props) {
  const { log, dublicates } = props;
  const [failerData, setFailerData] = useState([]);
  const [dublicateData, setDublicateData] = useState([]);
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'patientId', headerName: 'Patient ID', width: 150 },
    { field: 'patientName', headerName: 'Patient Name', width: 150 },
    { field: 'patientPhone', headerName: 'Patient Phone', width: 150 },
    { field: 'error', headerName: 'Error Message', width: 400 },
  ];

  const dublicatesColumns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "fname", headerName: "First name", width: 150 },
    { field: "lname", headerName: "Last name", width: 150 },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 150,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "mrn", headerName: "MRN", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "patientPhone", headerName: "Patient Phone", width: 150 },
  ];

  useEffect(() => {
    setDublicateData(
      dublicates.map((item, ind) => {
        item.id = ind + 1;
        return item;
      })
    );
  }, [dublicates]);

  useEffect(() => {
    setFailerData(
      log.map((item, ind) => {
        item.id = ind + 1;
        return item;
      })
    );
  }, [log]);
  return (
    <div className="pb-5">
      {' '}
      {failerData.length > 0 && (
        <Box sx={{ height: 400, width: '100%', marginTop: 8 }}>
          <h3 className="text-center">Errors Log</h3>
          <DataGrid
            rows={failerData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      )}
      {dublicateData.length > 0 && (
        <Box sx={{ height: 400, width: '100%', marginTop: 8 }}>
          <h3 className="text-center">Dublicates Log</h3>
          <DataGrid
            rows={dublicateData}
            columns={dublicatesColumns}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      )}
    </div>
  );
}

export default Log;
