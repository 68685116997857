import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

// ################## Calendar Events CRUD Start ##################
export const handleAddEvent = async (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/calendar/events`, payload);
};

export const handleGetEvents = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/calendar/events/pharmacy/${pharmacyId}`
  );
};

export const handleGetEventById = async (eventId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/calendar/events/${eventId}`);
};

export const handleGetFollowingEvents = async (eventId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/calendar/events/following/${eventId}`
  );
};

export const handleUpdateEvent = async (eventId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/calendar/events/${eventId}`,
    payload
  );
};

export const handleDeleteEvent = async (eventId, deletedById) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/calendar/events/${eventId}/${deletedById}`
  );
};

export const handleDeleteEventWithAllFollowings = async (
  eventId,
  deletedById
) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/calendar/events/recurrence/${eventId}/${deletedById}`
  );
};

// ################## Calendar Events CRUD End ##################

// ################## Calendar Owners CRUD Start ##################
export const handleAddOwner = async (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/calendar/owners`, payload);
};

export const handleGetOwners = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/calendar/owners/pharmacy/${pharmacyId}`
  );
};

export const handleGetOwnerById = async (ownerId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/calendar/owners/${ownerId}`);
};

export const handleUpdateOwner = async (ownerId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/calendar/owners/${ownerId}`,
    payload
  );
};

export const handleDeleteOwner = async (ownerId) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/calendar/owners/${ownerId}`
  );
};
// ################## Calendar Owners CRUD End ##################

// ################## Calendar History Start ##################

export const getEventHistoryById = async (eventId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/calendar/events/history/${eventId}`
  );
};

export const getDeletedEventsHistory = async (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/calendar/events/history/deleted/${pharmacyId}`
  );
};

// ################## Calendar History End   ##################
