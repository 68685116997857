/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { getMessagesUsers } from "../../../services/PharmacyAdmin";
import { generateToken, sendEmail } from "../../../services/Common";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { SocketIoServer } from "../../../utils/globalState";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import baseURL from "../../../utils/baseURL";
import {
  updateToken,
  getSurveyById,
  handleGetptbyid,
  getNotificationsUsers,
  getTokenById,
  addResponse,
  getTokens,
} from "../../../services/PharmacyUser";

const ApplessSurvey = () => {
  // -----------------------------------------Socket Io-----------------------------------------
  const value = SocketIoServer.getState().SocketIoObj;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let id = urlParams.get("id");
  let patientId = urlParams.get("pid");
  let ddate = urlParams.get("pdate");
  let token = urlParams.get("token");
  let pharmacyId = urlParams.get("phid");

  const [socket, setSocket] = useState(null);
  const [userID, setUserID] = useState(null);
  const [survey, setSurvey] = useState([]);
  const [expflag, setExpflag] = useState(false);
  const [tokObj, setTokObj] = useState({});
  const [patient, setPatient] = useState({});
  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    setSocket(value);
  }, [value]);

  const gettBy = (tok) => {
    getTokenById(tok)
      .then((res) => {
        let TokenObj = JSON.parse(res.data.token);
        if (dayjs(TokenObj.date) <= dayjs().subtract(30, "day")) {
          TokenObj.resflag = true;
        }
        setExpflag(TokenObj.resflag);
        setTokObj(TokenObj);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getTokens()
      .then((res) => {
        let tokensList = res.data
          .map((token) => {
            return JSON.parse(token.token);
          })
          .filter(
            (item) =>
              Number(item.servid) === Number(id) &&
              Number(item.ptid) === Number(patientId)
          )
          .sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        if (tokensList.length > 0) {
          if (tokensList[tokensList.length - 1].token !== token) {
            setExpflag(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id || !patientId || !ddate || !token) {
      setExpflag(true);
    }
    userID &&
      handleGetptbyid(patientId).then((res) => {
        setPatient(res.data.patient);
      });
  }, [id, patientId, ddate, token, userID]);

  useEffect(() => {
    generateToken(patientId).then((res) => {
      localStorage.setItem("Token", res.data.token);
      setUserID(decodeToken(res.data.token).userId);
    });
  }, [patientId]);

  const getsBy = (id) => {
    getSurveyById(id)
      .then((res) => {
        res.data[0].json = JSON.parse(res.data[0].survey);
        setSurvey(res.data[0].json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRecipients = () => {
    getNotificationsUsers()
      .then((res) => {
        setRecipients(res.data.filter((user) => user.survey_ID === id));
      })
      .catch((err) => {
        console.error(err);
        setRecipients([]);
      });
  };

  useEffect(() => {
    userID && getsBy(id);
    userID && gettBy(token);
    userID && getRecipients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token, userID]);

  if (Object.keys(survey).length === 0) {
    return <div>Loading...</div>;
  } else if (expflag || !id || !patientId || !ddate || !token) {
    return <div>Survey expired and/or Invalid survey URL.</div>;
  } else {
    const model = new Model(survey);
    let reviewFlag = true;
    let disagreeFlag = false;

    model.onComplete.add(async (sender) => {
      let NewTokenObj = tokObj;
      NewTokenObj.resflag = true;

      updateToken(token, { token: JSON.stringify(NewTokenObj) }).catch(
        (error) => {
          console.error(error);
        }
      );
      let payLoad = {
        survey_response: {
          response: sender.data,
          surveyId: id,
        },
        pt_id: patientId,
        dispense_date: ddate,
      };

      const newResp = await addResponse(payLoad);

      const newRespId = newResp.data.id;

      const generateLink = (userType, responseId) => {
        const portal = userType === "User" ? "pharmacy-user" : "pharmacy-admin";
        const feURL = baseURL.frontEnd;

        return `${feURL}/${portal}/survey-dissatisfaction/${responseId}`;
      };

      for (let index = 0; index < survey.pages.length; index++) {
        survey.pages[index].elements.forEach((element) => {
          if (
            element.title &&
            (element?.title?.includes("contacted") ||
              element?.title?.includes("Contacted") ||
              element?.title?.includes("contact") ||
              element?.title?.includes("Contact"))
          ) {
            if (sender.data[element.name] === true) {
              reviewFlag = false;

              if (recipients.length > 0) {
                recipients.forEach((recipient) => {
                  const msg = `${patient.firstName} ${patient.lastName} DOB: ${
                    patient.DOB
                  } has completed the survey ${
                    survey.title
                  } and has indicated that they need to be contacted. Please contact them at ${
                    patient.phone
                  } to discuss the results of the survey. 
                    Click the following link for more details ${generateLink(
                      recipient.userType,
                      newRespId
                    )}`;

                  sendEmail({
                    to: recipient.email,
                    message: msg,
                  });
                });
              }

              getMessagesUsers(pharmacyId).then((r) => {
                r.data.users.forEach((u) => {
                  socket.emit("noteset", {
                    sender_name: `Patient: ${patient.firstName} ${patient.lastName} DOB: ${patient.DOB}`,
                    message: `${patient.firstName} ${patient.lastName} DOB: 
                    ${patient.DOB} has completed the survey ${survey.title}{" "}
                        and has indicated that they need to be contacted. Please
                        contact them at ${patient.phone} to discuss the results
                        of the survey. ${generateLink(u.type, newRespId)}`,
                    sender: patient.id.toString(),
                    receiver: u.userId.toString(),
                    body: `survey`,
                    unread: true,
                    url: `#`,
                    timestamp: Date.now(),
                  });
                });
              });
            }
          }

          if (element.type === "radiogroup") {
            if (sender.data[element.name]) {
              if (
                element.choices
                  .find((choice) => choice.value === sender.data[element.name])
                  .text?.trim() === "Disagree"
              ) {
                reviewFlag = false;
                disagreeFlag = true;
              }
            }
          }

          if (element.type && element.type === "comment") {
            if (sender.data[element.name]) {
              let msg = `${patient.firstName} ${patient.lastName} DOB: ${
                patient.DOB
              } has completed the survey ${survey.title} and commented "${
                sender.data[element.name]
              }".`;
              if (recipients.length > 0) {
                recipients.forEach((recipient) => {
                  sendEmail({
                    to: recipient.email,
                    message: msg,
                  });
                });
              }
              getMessagesUsers(pharmacyId).then((r) => {
                r.data.users.forEach((u) => {
                  socket.emit("noteset", {
                    sender_name: `Patient: ${patient.firstName} ${patient.lastName} DOB: ${patient.DOB}`,
                    message: `${patient.firstName} ${patient.lastName} DOB: ${patient.DOB} has completed the survey ${survey.title} and left a comment.`,
                    sender: patient.id.toString(),
                    receiver: u.userId.toString(),
                    body: `survey`,
                    unread: true,
                    url: `#`,
                    timestamp: Date.now(),
                  });
                });
              });
            }
          }
        });
      }

      if (disagreeFlag) {
        if (recipients.length > 0) {
          recipients.forEach((recipient) => {
            const msg = `${patient.firstName} ${patient.lastName} DOB: ${
              patient.DOB
            } has completed the survey ${
              survey.title
            } and has some negative experiences. 
              Click the following link for more details ${generateLink(
                recipient.userType,
                newRespId
              )}`;
            sendEmail({
              to: recipient.email,
              message: msg,
            });
          });

          getMessagesUsers(pharmacyId).then((r) => {
            r.data.users.forEach((u) => {
              socket.emit("noteset", {
                sender_name: `Patient: ${patient.firstName} ${patient.lastName} DOB: ${patient.DOB}`,
                message: `${patient.firstName} ${patient.lastName} DOB: 
                ${patient.DOB} has completed the survey ${survey.title} and
                    has some negative experiences. ${generateLink(
                      u.type,
                      newRespId
                    )}`,
                sender: patient.id.toString(),
                receiver: u.userId.toString(),
                body: `survey`,
                unread: true,
                url: `#`,
                timestamp: Date.now(),
              });
            });
          });
        }
      }

      sender.completedHtml = reviewFlag
        ? `<div>
              <h3>Please click <strong>
       <a
         href="https://g.page/r/CVU29Y0EBaAXEAI/review"
        target="_blank"
        rel="noreferrer">
        HERE
      </a>
          </strong> to leave a review</h3>
           <br/>
      <h3> Thank you for completing the survey </h3>
      <br/>

          </div>`
        : "<h3> Thank you for completing the survey </h3>";
    });

    return (
      <div>
        <div id="wrapper">
          <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <div className="container-fluid">
                <div className="pagetitle">
                  <h1>
                    {survey.title} for your visit of {ddate}
                  </h1>
                </div>
                <Survey model={model} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ApplessSurvey;
