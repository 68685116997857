import React, { useState, useEffect } from "react";
import { SideBar, SelectedSurvey } from "../../../utils/globalState";
import "survey-analytics/survey.analytics.min.css";
import { Model } from "survey-core";
import { VisualizationPanel } from "survey-analytics";
import {
  getAllRes,
  handleGetptbyid,
  getSurveyById,
  getTokens,
} from "../../../services/PharmacyUser";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const navigate = useNavigate();

  // Get the survey from global state.
  const incomingSurvey = SelectedSurvey.getState().ThisSurvey;
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);

  var vizPanelOptions = {
    haveCommercialLicense: true, //Add this line
    allowHideQuestions: false,
  };

  const [survey, setSurvey] = useState(incomingSurvey);
  const [surveyResults, setSurveyResults] = useState([]);
  const [resLoadingFlag, setResLoadingFlag] = useState(true);
  const [tokLoadingFlag, setTokLoadingFlag] = useState(true);
  const [totalSurSent, setTotalSurSent] = useState([]);
  const [totalSurCompleted, setTotalSurCompleted] = useState([]);
  const [totalSurCompletedPd, setTotalSurCompletedPd] = useState([]);
  const [totalSurCompletedIrv, setTotalSurCompletedIrv] = useState([]);
  const [totalAgree, setTotaltotalAgree] = useState([]);
  const [totalDisagree, setTotaltotalDisagree] = useState([]);
  const [totalAgreePd, setTotaltotalAgreePd] = useState([]);
  const [totalDisagreePd, setTotaltotalDisagreePd] = useState([]);
  const [totalAgreeIrv, setTotaltotalAgreeIrv] = useState([]);
  const [totalDisagreeIrv, setTotaltotalDisagreeIrv] = useState([]);
  const [viapatientportal, setViaPatientPortal] = useState([]);
  const [dateinput, setDateinput] = useState({
    from: "",
    to: "",
  });
  const [msg, setMsg] = useState("");

  const handleGetTotalSurveysSent = () => {
    setTokLoadingFlag(true);
    getTokens()
      .then((res) => {
        handleData(
          res.data
            .map((token) => JSON.parse(token.token))
            .filter((item) => item.servid === id)
        );
        setTokLoadingFlag(false);
      })
      .catch((err) => {
        setMsg("Something went wrong.");
        handleData([]);
        setTokLoadingFlag(false);
      });
  };

  const getResponsesBySurvid = (surveyId) => {
    setResLoadingFlag(true);

    getAllRes(surveyId)
      .then((res) => {
        if (res.data.All_Patients_Response.length > 0) {
          Promise.all(
            res.data.All_Patients_Response.map((i) => {
              try {
                JSON.parse(i.survey_response);
                i.survey_response = JSON.parse(i.survey_response);
              } catch (error) {
                i.survey_response = {};
              }

              return i;
            })
              .filter((item) => item.survey_response.surveyId === id)
              .map((i) => {
                i.survey_response.response.created_at = i.created_at;
                i.survey_response.response.source = i.survey_response.source
                  ? i.survey_response.source
                  : "SMS";
                return handleGetptbyid(i.pt_id)
                  .then((res) => {
                    i.survey_response.response.providingPharmacy =
                      res?.data?.patient?.providing_pharmacy ||
                      "River's Edge Pharmacy - PD";
                    return i.survey_response.response;
                  })
                  .catch((err) => {
                    i.survey_response.response.providingPharmacy =
                      "River's Edge Pharmacy - PD";
                    return i.survey_response.response;
                  });
              })
          ).then((res) => {
            setSurveyResults(res);
            setResLoadingFlag(false);
          });
        } else {
          setSurveyResults([]);
          setResLoadingFlag(false);
        }
      })
      .catch((err) => {
        setMsg("Something went wrong.");
      });
  };

  useEffect(() => {
    getSurveyById(id).then((res) => {
      res.data[0].json = JSON.parse(res.data[0].survey);
      setSurvey(res.data[0]);
    });
    getResponsesBySurvid(id);
    handleGetTotalSurveysSent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setDateinput({
      ...dateinput,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (new Date(dateinput.to) >= new Date(dateinput.from)) {
      handleData(
        totalSurSent.filter((item) => {
          return (
            new Date(item.date) >= new Date(dateinput.from) &&
            new Date(item.date) <= new Date(dateinput.to)
          );
        })
      );

      handleAgree(
        surveyResults.filter((item) => {
          return (
            new Date(item.created_at) >= new Date(dateinput.from) &&
            new Date(item.created_at) <= new Date(dateinput.to)
          );
        })
      );
    }
  };

  const handleData = (data) => {
    setTotalSurSent(data);
    setTotalSurCompleted(data.filter((item) => item.resflag === true));
    setTotalSurCompletedPd(
      data.filter(
        (item) =>
          item.resflag === true &&
          item.location === "River's Edge Pharmacy - PD"
      )
    );
    setTotalSurCompletedIrv(
      data.filter(
        (item) =>
          item.resflag === true &&
          item.location === "River's Edge Pharmacy - IRV"
      )
    );
  };

  const handleAgree = (data) => {
    let good = [];
    let bad = [];
    let pDgood = [];
    let pDbad = [];
    let irvGood = [];
    let irvBad = [];
    setViaPatientPortal(data.filter((item) => item.source === "PP"));

    Promise.all(
      // eslint-disable-next-line array-callback-return
      data.map((i) => {
        // eslint-disable-next-line array-callback-return
        Object.values(i).map((item) => {
          if (
            item === "Agree" ||
            item === "Strongly Agree" ||
            item === "item1" ||
            item === "item2"
          ) {
            good.push(item);
          } else if (
            item === "Disagree" ||
            item === "Strongly Disagree" ||
            item === "item4" ||
            item === "item5"
          ) {
            bad.push(item);
          }
          // River's Edge Pharmacy - PD
          if (i.providingPharmacy === "River's Edge Pharmacy - PD") {
            if (
              item === "Agree" ||
              item === "Strongly Agree" ||
              item === "item1" ||
              item === "item2"
            ) {
              pDgood.push(item);
            } else if (
              item === "Disagree" ||
              item === "Strongly Disagree" ||
              item === "item4" ||
              item === "item5"
            ) {
              pDbad.push(item);
            }
          }
          if (i.providingPharmacy === "River's Edge Pharmacy - IRV") {
            if (
              item === "Agree" ||
              item === "Strongly Agree" ||
              item === "item1" ||
              item === "item2"
            ) {
              irvGood.push(item);
            } else if (
              item === "Disagree" ||
              item === "Strongly Disagree" ||
              item === "item4" ||
              item === "item5"
            ) {
              irvBad.push(item);
            }
          }
        });
      })
    ).then(() => {
      setTotaltotalAgree(good);
      setTotaltotalDisagree(bad);
      setTotaltotalAgreePd(pDgood);
      setTotaltotalDisagreePd(pDbad);
      setTotaltotalAgreeIrv(irvGood);
      setTotaltotalDisagreeIrv(irvBad);
    });

    // setTotaltotalAgree( data.filter((item) => Object.values (item).includes("Strongly Agree") || Object.values (item).includes("Agree") || Object.values (item).includes("item1") || Object.values (item).includes("item2")))
    // setTotaltotalDisagree(data.filter((item) => Object.values (item).includes("Strongly Disagree") || Object.values (item).includes("Disagree") || Object.values (item).includes("item4") || Object.values (item).includes("item5")))
  };

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      if (!resLoadingFlag & !tokLoadingFlag) {
        handleAgree(surveyResults);
        let su = new Model(survey.json);

        document.getElementById("surveyVizPanel").innerHTML = "";
        let vizP = new VisualizationPanel(
          su.getAllQuestions(),
          surveyResults,
          vizPanelOptions
        );
        vizP.showHeader = false;
        vizP.render("surveyVizPanel");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyResults, survey, resLoadingFlag, tokLoadingFlag]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item cstm_breadcrumb"
                onClick={() => navigate(-1)}
              >
                Surveys Management Board
              </li>
              <li className="breadcrumb-item active">Analytics</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        {resLoadingFlag || tokLoadingFlag ? (
          <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="ms-4 align-middle text-secondary">
              Loading Survey Data Please wait...
            </div>
          </div>
        ) : (
          <section className="section profile">
            <div className="row">
              <form className="row gx-3 my-1  align-items-center">
                <div className="row my-3">
                  <div className="form-group col col-12 col-md-4 col-lg-3 col-xl-2">
                    <label className="form-label" htmlFor="from">
                      From
                    </label>
                    <div className="input-group">
                      <input
                        name="from"
                        type="Date"
                        className="form-control"
                        id="from"
                        placeholder="From Date"
                        value={dateinput.from}
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                  <div className="form-group col col-12 col-md-4 col-lg-3 col-xl-2">
                    <label
                      className="form-label"
                      htmlFor="inlineFormInputGroupUsername"
                    >
                      To
                    </label>
                    <div className="input-group">
                      <input
                        name="to"
                        type="Date"
                        className="form-control"
                        id="inlineFormInputGroupUsername"
                        placeholder="To Date"
                        value={dateinput.to}
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                </div>
                <div className="col mb-3 gap-2 d-flex">
                  <div className="form-group mx-1">
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={handleSubmit}
                    >
                      Filter
                    </button>
                  </div>
                  <div className="form-group d-inline">
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={() => {
                        handleGetTotalSurveysSent();
                        handleAgree(surveyResults);
                        setDateinput({
                          from: "",
                          to: "",
                        });
                      }}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </form>

              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [`Surveys not Completed`, `Total Surveys Completed`],
                    ["Completed", totalSurCompleted.length],
                    [
                      "Not Completed",
                      totalSurSent.length - totalSurCompleted.length,
                    ],
                  ]}
                  options={{
                    title: "Total Surveys Completed",
                    slices: {
                      0: { color: "#19b394" },
                      1: { color: "#87A2FB" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [`Surveys Completed `, `Total Surveys Completed`],
                    ["Completed Using SMS", totalSurCompleted.length],
                    ["Completed using Patient Portal", viapatientportal.length],
                  ]}
                  options={{
                    title:
                      "Total Surveys Completed Using SMS VS Total Surveys Completed Using Patient Portal",
                    slices: {
                      0: { color: "#19b394" },
                      1: { color: "#87A2FB" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [
                      `Total Surveys Completed-PD`,
                      `Total Surveys Completed-IRV`,
                    ],
                    ["PD", totalSurCompletedPd.length],
                    ["IRV", totalSurCompletedIrv.length],
                  ]}
                  options={{
                    // is3D: true,
                    title: "Total Surveys Completed Per Location",
                    pieHole: 0.3,
                    slices: {
                      0: { color: "#bc5090" },
                      1: { color: "#58508d" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [`Satisfied`, `Not Satisfied`],
                    ["Satisfied", totalAgree.length],
                    ["Not Satisfied", totalDisagree.length],
                  ]}
                  options={{
                    title: "Satisfaction Rate",
                    slices: {
                      0: { color: "#00af63" },
                      1: { color: "#ef2731" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [`Satisfied-PD`, `Not Satisfied-PD`],
                    ["Satisfied", totalAgreePd.length],
                    ["Not Satisfied", totalDisagreePd.length],
                  ]}
                  options={{
                    title: "Satisfaction Rate-PD",
                    slices: {
                      0: { color: "#00af63" },
                      1: { color: "#ef2731" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="border rounded mb-3 shadow">
                <Chart
                  chartType="PieChart"
                  data={[
                    [`Satisfied-IRV`, `Not Satisfied-IRV`],
                    ["Satisfied", totalAgreeIrv.length],
                    ["Not Satisfied", totalDisagreeIrv.length],
                  ]}
                  options={{
                    title: "Satisfaction Rate-IRV",
                    slices: {
                      0: { color: "#00af63" },
                      1: { color: "#ef2731" },
                    },
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="row d-flex justify-content-center">
                <div className="border rounded mb-3 shadow col-11 col-md-6 col-lg-4 px-1">
                  <Chart
                    chartType="Table"
                    data={[
                      ["", ""],
                      ["Total Surveys Sent", { v: totalSurSent.length }],
                      ["Completed Using SMS", { v: totalSurCompleted.length }],
                      [
                        "Completed Using Patients Portal",
                        { v: viapatientportal.length },
                      ],
                      [
                        "Total Completed ",
                        {
                          v: viapatientportal.length + totalSurCompleted.length,
                        },
                      ],
                      [
                        "Total Surveys Not Completed",
                        { v: totalSurSent.length - totalSurCompleted.length },
                      ],
                      [
                        "Total Surveys Completed-PD",
                        { v: totalSurCompletedPd.length },
                      ],
                      [
                        "Total Surveys Completed-IRV",
                        { v: totalSurCompletedIrv.length },
                      ],
                      ["Satisfied", { v: totalAgree.length }],
                      ["Not Satisfied", { v: totalDisagree.length }],
                      ["Satisfied PD Location", { v: totalAgreePd.length }],
                      [
                        "Not Satisfied PD Location",
                        { v: totalDisagreePd.length },
                      ],
                      ["Satisfied IRV Location", { v: totalAgreeIrv.length }],
                      [
                        "Not Satisfied IRV Location",
                        { v: totalDisagreeIrv.length },
                      ],
                    ]}
                    options={{
                      title: "Company Performance",
                      curveType: "function",
                      legend: { position: "bottom" },
                      pageSize: 20,
                      width: "100%",
                      height: "auto",
                      alternatingRowStyle: true,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div id="surveyVizPanel"></div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default Analytics;
