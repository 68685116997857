import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/commonStyles/scheduler.css";
import { SideBar } from "../../../utils/globalState";
import { useModal } from "react-hooks-use-modal";
import dayjs from "dayjs";
import { createElement } from "@syncfusion/ej2-base";
import { Query, Predicate, DataManager } from "@syncfusion/ej2-data";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { PropertyPane } from "../../../components/pharmacyUser/CarePlanCenter/PropertyPane";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { decodeToken } from "react-jwt";
import CloseIcon from "@mui/icons-material/Close";
import EventHistoryModal from "./../../../components/pharmacyUser/CarePlanCenter/eventsHistory/EventHistoryModal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getSystemUserByPharmacyId } from "../../../services/Common";
import {
  handleAddEvent,
  handleGetEvents,
  handleUpdateEvent,
  handleDeleteEvent,
  handleGetOwners,
} from "../../../services/Calendar";
import {
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
  DragAndDrop,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";

const Scheduler = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const pharmacyId = Number(window.localStorage.getItem("pharmacy"));
  const navigate = useNavigate();

  let scheduleObj = useRef(null);
  let formObj = useRef(null);

  const [events, setEvents] = useState([]);
  const [owners, setOwners] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchGridShow, setSearchGridShow] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [curEvent, setCurEvent] = useState(null);
  const [sortBy, setSortBy] = useState(0);
  const [statusOptions, setStatusOptions] = useState([true, true]);
  const [automationOptions, setAutomationOptions] = useState([true, true]);
  const [eventTypeOptions, setEventTypeOptions] = useState([true, true, true]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [msg, setMsg] = useState({ text: "", type: "" });
  const [currentDate, setCurrentDate] = useState(null);

  const eventSettings = { dataSource: events, enableTooltip: true };

  const [Modal, open, close] = useModal("root", {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  });

  const modalCloseHandler = () => {
    setCurEvent(null);
    close();
  };

  const columnDef = [
    { field: "Subject", headerName: "Subject", minWidth: 180 },
    { field: "patientName", headerName: "Patient Name", minWidth: 180 },
    {
      field: "StartTime",
      headerName: "Start Time",
      minWidth: 180,
      valueFormatter: (params) => {
        return dayjs(params.value).format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      field: "EndTime",
      headerName: "End Time",
      minWidth: 180,
      valueFormatter: (params) => {
        return dayjs(params.value).format("MM/DD/YYYY hh:mm A");
      },
    },
  ];

  useEffect(() => {
    handleGetEvents(pharmacyId)
      .then((res) => {
        const processedDate = res.data.map((event) => {
          return {
            ...event,
            isInitEvent:
              event.isInitEvent === null
                ? null
                : event.isInitEvent === 1
                ? true
                : false,
          };
        });
        setEvents(processedDate);
        setFilteredEvents(processedDate);
      })
      .catch((err) => console.error(err));

    handleGetOwners(pharmacyId)
      .then(async (res) => {
        const ownersArr = res?.data?.length
          ? res.data.map((owner) => {
              return {
                Text: owner.first + " " + owner.last,
                Color: owner.Color,
                OwnerId: owner.OwnerId,
              };
            })
          : [];

        const systemUserRes = await getSystemUserByPharmacyId(pharmacyId);
        const systemUser = systemUserRes.data;
        if (systemUser) {
          ownersArr.push({
            Text: systemUser.first + " " + systemUser.last,
            Color: "#ff5e00",
            OwnerId: systemUser.id,
          });
        }
        setOwners(ownersArr);
        setSelectedOwners(ownersArr.map((owner) => owner.OwnerId));
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoaded(true));
  }, [pharmacyId]);

  const onActionBegin = (args) => {
    if (
      args.requestType === "eventCreate" ||
      (args.requestType === "eventChanged" && args.changedRecords.length > 0)
    ) {
      if (owners.length === 0) {
        setMsg({
          text: "No events can be created without owners. please contact pharmacy admin",
          type: "danger",
        });
        args.cancel = true;
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 4000);
      }
    }
  };

  const onActionComplete = (args) => {
    if (args.requestType) {
      if (args.requestType === "eventChanged") {
        setCurrentDate(scheduleObj.current.selectedDate);
        if (args.addedRecords.length > 0) {
          // update parent by id
          // create new event using the occurrence instance
          const postPayload = { ...args.addedRecords[0], creatorId: userID };
          postPayload.Guid && delete postPayload.Guid;
          postPayload.Id && delete postPayload.Id;

          handleAddEvent(postPayload)
            .then(() => {
              const putPayload = {
                ...args.changedRecords[0],
                updatedBy: userID,
                isInitEvent: null,
              };
              putPayload.Guid && delete putPayload.Guid;
              putPayload.Id && delete putPayload.Id;

              args.changedRecords.length &&
                handleUpdateEvent(args.changedRecords[0].Id, putPayload)
                  .then(() => {
                    const newEvents = events.map((event) => {
                      if (event.Id === args.changedRecords[0].Id) {
                        return { ...event, ...args.changedRecords[0] };
                      }
                      return event;
                    });
                    newEvents.push({
                      Id: args.addedRecords[0].Id,
                      creatorId: userID,
                      pharmacyId,
                      Subject: args.addedRecords[0].Subject || null,
                      StartTime: args.addedRecords[0].StartTime || null,
                      EndTime: args.addedRecords[0].EndTime || null,
                      IsAllDay: args.addedRecords[0].IsAllDay || null,
                      Description: args.addedRecords[0].Description || null,
                      Location: args.addedRecords[0].Location || null,
                      CategoryColor: args.addedRecords[0].CategoryColor || null,
                      Priority: args.addedRecords[0].Priority || null,
                      RecurrenceRule:
                        args.addedRecords[0].RecurrenceRule || null,
                      RecurrenceException:
                        args.addedRecords[0].RecurrenceException || null,
                      RecurrenceID: args.addedRecords[0].RecurrenceID || null,
                      StartTimezone: args.addedRecords[0].StartTimezone || null,
                      EndTimezone: args.addedRecords[0].EndTimezone || null,
                      OwnerId: args.addedRecords[0].OwnerId || null,
                      FollowingID: args.addedRecords[0].FollowingID || null,
                      IsReadonly: args.addedRecords[0].IsReadonly || null,
                      IsBlock: args.addedRecords[0].IsBlock || null,
                      isInitEvent:
                        args.addedRecords[0].isInitEvent === undefined
                          ? null
                          : args.addedRecords[0].isInitEvent === null
                          ? null
                          : args.addedRecords[0].isInitEvent,
                      patientName: args.addedRecords[0].patientName || null,
                      Status: args.addedRecords[0].Status || false,
                    });
                    setEvents(newEvents);
                    scheduleObj.current.eventSettings.dataSource = newEvents;
                  })
                  .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } else {
          // update parent by id

          const payload = {
            ...args.changedRecords[0],
            updatedBy: userID,
            isInitEvent:
              args.changedRecords[0].isInitEvent === null
                ? null
                : args.changedRecords[0].isInitEvent === undefined
                ? null
                : args.changedRecords[0].isInitEvent,
          };
          payload.Guid && delete payload.Guid;
          payload.Id && delete payload.Id;

          handleUpdateEvent(args.changedRecords[0].Id, payload)
            .then(() =>
              setEvents(
                events.map((event) => {
                  if (event.Id === args.changedRecords[0].Id) {
                    return { ...event, ...args.changedRecords[0] };
                  }
                  return event;
                })
              )
            )
            .catch((err) => console.error(err));
        }
      }
      if (args.requestType === "eventRemoved") {
        setCurrentDate(scheduleObj.current.selectedDate);
        if (args.deletedRecords.length === 0) {
          const payload = { ...args.changedRecords[0], updatedBy: userID };
          payload.Guid && delete payload.Guid;
          payload.Id && delete payload.Id;

          handleUpdateEvent(args.changedRecords[0].Id, payload)
            .then(() => {
              setEvents(
                events.map((event) => {
                  if (event.Id === args.changedRecords[0].Id) {
                    return { ...event, ...args.changedRecords[0] };
                  }
                  return event;
                })
              );
            })
            .catch((err) => console.error(err));
        } else {
          args.deletedRecords.forEach((record) => {
            handleDeleteEvent(record.Id, userID)
              .then(() => {
                setEvents(events.filter((event) => event.Id !== record.Id));
              })
              .catch((err) => console.error(err));
          });
        }
      }
      if (args.requestType === "eventCreated") {
        setCurrentDate(scheduleObj.current.selectedDate);
        const newEvents = [...events];
        const jumpToDate = args.addedRecords[0].StartTime;

        Promise.all(
          args.addedRecords.map(async (record) => {
            try {
              const postPayload = {
                ...record,
                creatorId: userID,
                pharmacyId,
                isInitEvent:
                  record.isInitEvent === null
                    ? null
                    : record.isInitEvent === undefined
                    ? null
                    : record.isInitEvent,
              };
              postPayload.Guid && delete postPayload.Guid;
              postPayload.Id && delete postPayload.Id;
              const res = await handleAddEvent(postPayload);
              newEvents.push({
                Id: res.data.id,
                creatorId: userID,
                pharmacyId,
                Subject: record.Subject || null,
                StartTime: record.StartTime || null,
                EndTime: record.EndTime || null,
                IsAllDay: record.IsAllDay || null,
                Description: record.Description || null,
                Location: record.Location || null,
                CategoryColor: record.CategoryColor || null,
                Priority: record.Priority || null,
                RecurrenceRule: record.RecurrenceRule || null,
                RecurrenceException: record.RecurrenceException || null,
                RecurrenceID: record.RecurrenceID || null,
                StartTimezone: record.StartTimezone || null,
                EndTimezone: record.EndTimezone || null,
                OwnerId: record.OwnerId || null,
                FollowingID: record.FollowingID || null,
                IsReadonly: record.IsReadonly || null,
                IsBlock: record.IsBlock || null,
                isInitEvent:
                  record.isInitEvent === undefined
                    ? null
                    : record.isInitEvent === null
                    ? null
                    : record.isInitEvent,
                patientName: record.patientName || null,
                Status: record.Status || false,
              });
            } catch (err) {
              console.error(err);
            }
          })
        ).then(() => {
          const eventsUnique = newEvents.filter((el) => el.creatorId);
          scheduleObj.current.eventSettings.dataSource = eventsUnique;
          // jump to the date of the new event
          scheduleObj.current.scrollTo(jumpToDate);
          setEvents(eventsUnique);
        });
      }
    }
  };

  const onPopupOpen = (args) => {
    if (args.type === "EventContainer") {
      const eventArr = args.data.event;
      const targets = args.element.querySelectorAll(
        ".e-more-appointment-wrapper .e-appointment"
      );
      if (targets.length > 0) {
        targets.forEach((target) => {
          const targetId = target.dataset.id;
          const eve = eventArr.find((el) => el.Id === Number(targetId));
          if (eve && eve.CategoryColor) {
            target.style.backgroundColor = eve.CategoryColor;
          }
        });
      }
    }
    if (args.type === "Editor") {
      // Create required custom elements in initial time
      if (!args.element.querySelector(".custom-field-row")) {
        let row = createElement("div", { className: "custom-field-row" });
        let formElement = args.element.querySelector(".e-schedule-form");
        formElement.firstChild.appendChild(
          row,
          formElement.firstChild.firstChild
        );

        let container = createElement("div", {
          className: "custom-field-container",
        });

        let inputEle = createElement("input", {
          className: "e-field",
          attrs: { name: "Priority" },
        });

        container.appendChild(inputEle);
        row.appendChild(container);

        // Create DropDownList for Priority
        let dropDownList = new DropDownList({
          dataSource: [
            { text: "High", value: "High" },
            { text: "Normal", value: "Normal" },
            { text: "Low", value: "Low" },
          ],
          fields: { text: "text", value: "value" },
          value: args.data.Priority,
          floatLabelType: "Always",
          placeholder: "Priority",
        });

        // Create DropDownList for Patients with filtering enabled
        dropDownList.appendTo(inputEle);
      } else {
        // set the value of the dropdown to the current value of the event
        let statusDropDown = args.element.querySelector("input[name='Status']")
          ? args.element.querySelector("input[name='Status']").ej2_instances[0]
          : null;
        if (statusDropDown) {
          statusDropDown.value = args.data.Status ? true : false;
        }
      }
    } else if (args.type === "QuickInfo") {
      // create a button to view history of the event and append to the quick popup
      if (args.element.querySelector(".e-event-popup")) {
        let button = createElement("button", {
          className: "e-btn",
          innerHTML: "View History",
        });

        button.addEventListener("click", () => {
          // open the history modal
          const event = events.find((el) => el.Id === args.data.Id);
          setCurEvent(event);
          open();
        });

        args.element.querySelector(".e-event-popup").appendChild(button);
      }

      if (args.element.querySelector(".e-popup-content")) {
        // create view to display priority and patient name
        let priorityContainer = createElement("div", {
          className: "e-resource",
        });

        let firstDiv = createElement("div", {
          className: "e-sorting-1 e-icons",
        });

        let secondDiv = createElement("div", {
          className: "e-resource-details e-text-ellipsis",
          innerHTML: `${args.data.Priority}`,
        });

        priorityContainer.appendChild(firstDiv);
        priorityContainer.appendChild(secondDiv);

        let patientContainer = createElement("div", {
          className: "e-resource",
        });

        let firstPtDiv = createElement("div", {
          className: "e-user e-icons",
          innerHTML: "PT",
        });

        let secondPtDiv = createElement("div", {
          className: "e-resource-details e-text-ellipsis",
          innerHTML: `${args.data.patientName}`,
        });
        secondPtDiv.style.paddingTop = "1px";

        patientContainer.appendChild(firstPtDiv);
        patientContainer.appendChild(secondPtDiv);

        let statusContainer = createElement("div", {
          className: "e-resource",
        });

        let firstStatusDiv = createElement("div", {
          className: "e-circle-check e-icons",
        });

        let secondStatusDiv = createElement("div", {
          className: "e-resource-details e-text-ellipsis",
          innerHTML: `${args.data.Status ? "Complete" : "Pending"}`,
        });

        statusContainer.appendChild(firstStatusDiv);
        statusContainer.appendChild(secondStatusDiv);

        args.data.Priority &&
          args.element
            .querySelector(".e-popup-content")
            .appendChild(priorityContainer);
        args.data.patientName &&
          args.element
            .querySelector(".e-popup-content")
            .appendChild(patientContainer);
        args.element
          .querySelector(".e-popup-content")
          .appendChild(statusContainer);
      }
    }
  };

  const onEventRendered = (args) => {
    // make event background color same as event.CategoryColor
    args.element.style.backgroundColor = args.data.CategoryColor;
  };

  const ownerChangeHandler = (data) => {
    let newSelectedOwners = [...selectedOwners];
    if (newSelectedOwners.includes(data.OwnerId)) {
      newSelectedOwners = newSelectedOwners.filter((id) => id !== data.OwnerId);
    } else {
      newSelectedOwners.push(data.OwnerId);
    }
    setSelectedOwners(newSelectedOwners);
  };

  const allOwnersSelected =
    selectedOwners.length === owners.length && owners.length > 0;
  const someOwnersSelected =
    selectedOwners.length > 0 && selectedOwners.length < owners.length;

  const handleSelectAllOwners = () => {
    if (allOwnersSelected) {
      setSelectedOwners([]);
    } else {
      setSelectedOwners(owners.map((owner) => owner.OwnerId));
    }
  };

  // Search Section

  const searchOnclick = () => {
    setSearchResults([]);
    let searchObj = [];
    let startDate;
    let endDate;
    let formElements = [].slice.call(
      document.querySelectorAll(".event-search .search-field")
    );
    formElements.forEach((node) => {
      let fieldOperator;
      let predicateCondition;
      let fieldValue;
      let fieldInstance;
      if (
        node.value &&
        node.value !== "" &&
        !node.classList.contains("e-datepicker")
      ) {
        fieldOperator = "contains";
        predicateCondition = "or";
        fieldValue = node.value;
        searchObj.push({
          field: node.getAttribute("data-name"),
          operator: fieldOperator,
          value: fieldValue,
          predicate: predicateCondition,
          matchcase: true,
        });
      }
      if (
        node.classList.contains("e-datepicker") &&
        node.ej2_instances[0]?.value
      ) {
        fieldInstance = node.ej2_instances[0];
        fieldValue = fieldInstance.value;
        if (node.classList.contains("e-start-time")) {
          fieldOperator = "greaterthanorequal";
          predicateCondition = "and";
          startDate = new Date(+fieldValue);
        } else {
          fieldOperator = "lessthanorequal";
          predicateCondition = "and";
          let date = new Date(+fieldInstance.value);
          fieldValue = new Date(date.setDate(date.getDate() + 1));
          endDate = fieldValue;
        }
        searchObj.push({
          field: node.getAttribute("data-name"),
          operator: fieldOperator,
          value: fieldValue,
          predicate: predicateCondition,
          matchcase: false,
        });
      }
    });
    if (searchObj.length > 0) {
      let filterCondition = searchObj[0];
      let predicate = new Predicate(
        filterCondition.field,
        filterCondition.operator,
        filterCondition.value,
        filterCondition.matchcase
      );
      for (let i = 1; i < searchObj.length; i++) {
        predicate = predicate.and(
          searchObj[i].field,
          searchObj[i].operator,
          searchObj[i].value,
          searchObj[i].matchcase
        );
      }
      let result = new DataManager(
        scheduleObj.current.getEvents(startDate, endDate, true)
      ).executeLocal(new Query().where(predicate));
      setSearchResults(result);
      setSearchGridShow(true);
    } else {
      setSearchGridShow(false);
    }
  };

  const clearOnClick = () => {
    setSearchResults([]);
    setSearchGridShow(false);
    let formElements = [].slice.call(
      document.querySelectorAll(".event-search .search-field")
    );
    formElements.forEach((node) => {
      if (
        node.classList.contains("e-datepicker") &&
        node.ej2_instances[0]?.value
      ) {
        node.ej2_instances[0].value = null;
      } else {
        node.value = "";
      }
    });
  };

  const handleSortByChange = (e) => {
    if (scheduleObj.current?.selectedDate) {
      setCurrentDate(scheduleObj.current.selectedDate);
    }
    setSortBy(e.target.value);
  };

  const statusChangeHandler = (e, index) => {
    const newSortByOptions = [...statusOptions];
    newSortByOptions[index] = e.target.checked;
    setStatusOptions(newSortByOptions);
  };

  const automationChangeHandler = (e, index) => {
    const newSortByOptions = [...automationOptions];
    newSortByOptions[index] = e.target.checked;
    setAutomationOptions(newSortByOptions);
  };

  const eventTypeChangeHandler = (e, index) => {
    const newSortByOptions = [...eventTypeOptions];
    newSortByOptions[index] = e.target.checked;
    setEventTypeOptions(newSortByOptions);
  };

  useEffect(() => {
    const multiFilteredEvents = events
      .filter((event) => selectedOwners.includes(event.OwnerId))
      .filter((event) => {
        if (statusOptions[0] && statusOptions[1]) return true;
        if (statusOptions[0] && !statusOptions[1]) return !event.Status;
        if (!statusOptions[0] && statusOptions[1]) return event.Status;
        return false;
      })
      .filter((event) => {
        if (automationOptions[0] && automationOptions[1]) return true;
        if (automationOptions[0] && !automationOptions[1])
          return event.isAutomated;
        if (!automationOptions[0] && automationOptions[1])
          return !event.isAutomated;
        return false;
      })
      .filter((event) => {
        if (eventTypeOptions[0] && eventTypeOptions[1] && eventTypeOptions[2])
          return true;
        if (eventTypeOptions[0] && !eventTypeOptions[1] && !eventTypeOptions[2])
          return event.isInitEvent === true;
        if (!eventTypeOptions[0] && eventTypeOptions[1] && !eventTypeOptions[2])
          return event.isInitEvent === false;
        if (!eventTypeOptions[0] && !eventTypeOptions[1] && eventTypeOptions[2])
          return event.isInitEvent === null;
        if (eventTypeOptions[0] && eventTypeOptions[1] && !eventTypeOptions[2])
          return event.isInitEvent === true || event.isInitEvent === false;
        if (eventTypeOptions[0] && !eventTypeOptions[1] && eventTypeOptions[2])
          return event.isInitEvent === true || event.isInitEvent === null;
        if (!eventTypeOptions[0] && eventTypeOptions[1] && eventTypeOptions[2])
          return !event.isInitEvent;
        return false;
      });

    setFilteredEvents(multiFilteredEvents);

    if (scheduleObj?.current?.eventSettings?.dataSource) {
      scheduleObj.current.eventSettings.dataSource = multiFilteredEvents;
    }

    // if (currentDate && scheduleObj.current) {
    //   scheduleObj.current.selectedDate = currentDate;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    automationOptions,
    events,
    selectedOwners,
    statusOptions,
    eventTypeOptions,
  ]);

  const colorSelector = () => {
    if (sortBy === 0) {
      const sortedEvents = filteredEvents.map((event) => {
        const owner = owners.find((owner) => owner.OwnerId === event.OwnerId);
        if (owner && owner.Color) {
          return { ...event, CategoryColor: owner.Color };
        } else {
          return event;
        }
      });
      if (scheduleObj?.current?.eventSettings?.dataSource) {
        scheduleObj.current.eventSettings.dataSource = sortedEvents;
      }
    } else if (sortBy === 1) {
      const sortedEvents = filteredEvents.map((event) => {
        const color = event.Status ? "#2e7d32" : "#ed6c02";
        return { ...event, CategoryColor: color };
      });
      if (scheduleObj?.current?.eventSettings?.dataSource) {
        scheduleObj.current.eventSettings.dataSource = sortedEvents;
      }
    } else if (sortBy === 2) {
      const sortedEvents = filteredEvents.map((event) => {
        const color = event.isAutomated ? "#1976d2" : "#9c27b0";
        return { ...event, CategoryColor: color };
      });
      if (scheduleObj?.current?.eventSettings?.dataSource) {
        scheduleObj.current.eventSettings.dataSource = sortedEvents;
      }
    } else {
      const sortedEvents = filteredEvents.map((event) => {
        const color =
          event.isInitEvent === true
            ? "#fc701f"
            : event.isInitEvent === false
            ? "#9a00b9"
            : "#005bc3";

        return { ...event, CategoryColor: color };
      });
      if (scheduleObj?.current?.eventSettings?.dataSource) {
        scheduleObj.current.eventSettings.dataSource = sortedEvents;
      }
    }
    if (currentDate && scheduleObj.current) {
      scheduleObj.current.selectedDate = currentDate;
    }
  };

  useEffect(() => {
    isLoaded && colorSelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEvents, owners, sortBy, searchGridShow]);

  const filterResetHandler = () => {
    scheduleObj.current.eventSettings.dataSource = events;
    setFilteredEvents(events);
    setStatusOptions([true, true]);
    setAutomationOptions([true, true]);
    setEventTypeOptions([true, true, true]);
    setSelectedOwners(owners.map((owner) => owner.OwnerId));
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Care Plan Manager</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Care Plan</li>
              <li className="breadcrumb-item active">Calendar</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {msg.text && (
                <div
                  className={`text-${
                    msg.type === "danger" ? "danger" : "success"
                  }`}>
                  {msg.text}
                </div>
              )}

              {isLoaded ? (
                <div className="schedule-control-section row">
                  <div className="col-lg-9 control-section">
                    <div className="control-wrapper">
                      {!searchGridShow && (
                        <ScheduleComponent
                          id="schedule"
                          cssClass="resource"
                          selectedDate={new Date()}
                          ref={scheduleObj}
                          eventSettings={eventSettings}
                          width="100%"
                          height="550px"
                          currentView="Month"
                          actionComplete={onActionComplete}
                          actionBegin={onActionBegin}
                          popupOpen={onPopupOpen}
                          eventRendered={onEventRendered}>
                          <ViewsDirective>
                            <ViewDirective option="Month" />
                            <ViewDirective option="WorkWeek" />
                            <ViewDirective option="Day" />
                            <ViewDirective option="Agenda" />
                            <ViewDirective option="Resize" />
                            <ViewDirective option="DragAndDrop" />
                          </ViewsDirective>
                          {owners.length ? (
                            <ResourcesDirective>
                              <ResourceDirective
                                field="OwnerId"
                                title="Owners"
                                name="Owners"
                                allowMultiple={true}
                                dataSource={owners}
                                textField="Text"
                                idField="OwnerId"
                                colorField="Color"
                              />
                            </ResourcesDirective>
                          ) : null}
                          <Inject
                            services={[
                              Day,
                              Week,
                              WorkWeek,
                              Month,
                              Agenda,
                              Resize,
                              DragAndDrop,
                            ]}
                          />
                        </ScheduleComponent>
                      )}
                      {searchGridShow && (
                        <div className="dg_1liner schedulerGrid">
                          <DataGrid
                            pageSize={25}
                            rows={searchResults}
                            getRowId={(row) => row.Id}
                            columns={columnDef}
                            density="compact"
                            components={{
                              Toolbar: GridToolbar,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 property-section">
                    <div>
                      <div>
                        <FormControl sx={{ minWidth: 140, mb: 2 }} size="small">
                          <InputLabel
                            id="sort-label"
                            sx={{
                              width: "auto",
                              zIndex: 1,
                              backgroundColor: "#fff",
                              pr: 0.5,
                            }}>
                            Sort By
                          </InputLabel>
                          <Select
                            labelId="sort-label"
                            value={sortBy}
                            label="Age"
                            onChange={handleSortByChange}>
                            <MenuItem value={0}>Event Owners</MenuItem>
                            <MenuItem value={1}>Status</MenuItem>
                            <MenuItem value={2}>Action Required</MenuItem>
                            <MenuItem value={3}>Event Type</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {sortBy === 0 ? (
                        <div>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  indeterminate={someOwnersSelected}
                                  checked={allOwnersSelected}
                                  onChange={handleSelectAllOwners}
                                  size="small"
                                />
                              }
                              label="Select All"
                            />
                            {owners.map((data) => (
                              <FormControlLabel
                                key={data.OwnerId}
                                control={
                                  <Checkbox
                                    checked={selectedOwners.includes(
                                      data.OwnerId
                                    )}
                                    size="small"
                                    style={{
                                      color: data.Color,
                                    }}
                                    onChange={() => ownerChangeHandler(data)}
                                    sx={{ m: 0, px: 1, py: 0 }}
                                  />
                                }
                                label={data.Text}
                              />
                            ))}
                          </FormGroup>
                        </div>
                      ) : sortBy === 1 ? (
                        <div className="ms-2">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={statusOptions[1]}
                                  size="small"
                                  style={{
                                    color: "#2e7d32",
                                  }}
                                  onChange={(e) => {
                                    statusChangeHandler(e, 1);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Complete"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={statusOptions[0]}
                                  size="small"
                                  style={{
                                    color: "#ed6c02",
                                  }}
                                  onChange={(e) => {
                                    statusChangeHandler(e, 0);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Pending"
                            />
                          </FormGroup>
                        </div>
                      ) : sortBy === 2 ? (
                        <div className="ms-2">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={automationOptions[0]}
                                  size="small"
                                  style={{
                                    color: "#1976d2",
                                  }}
                                  onChange={(e) => {
                                    automationChangeHandler(e, 0);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Automated"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={automationOptions[1]}
                                  size="small"
                                  style={{
                                    color: "#9c27b0",
                                  }}
                                  onChange={(e) => {
                                    automationChangeHandler(e, 1);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Action Required"
                            />
                          </FormGroup>
                        </div>
                      ) : (
                        <div className="ms-2">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eventTypeOptions[0]}
                                  size="small"
                                  style={{
                                    color: "#fc701f",
                                  }}
                                  onChange={(e) => {
                                    eventTypeChangeHandler(e, 0);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Initial Assessment"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eventTypeOptions[1]}
                                  size="small"
                                  style={{
                                    color: "#9a00b9",
                                  }}
                                  onChange={(e) => {
                                    eventTypeChangeHandler(e, 1);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Follow-up Assessment"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eventTypeOptions[2]}
                                  size="small"
                                  style={{
                                    color: "#005bc3",
                                  }}
                                  onChange={(e) => {
                                    eventTypeChangeHandler(e, 2);
                                  }}
                                  sx={{ m: 0, px: 1, py: 0 }}
                                />
                              }
                              label="Other Events"
                            />
                          </FormGroup>
                        </div>
                      )}
                      <button
                        className="btn btn-sm btn-success mt-3"
                        onClick={() => filterResetHandler()}>
                        Reset All Filters
                      </button>
                    </div>
                    <hr />
                    <PropertyPane title="Search Events">
                      <form
                        className="event-search"
                        id="form-search"
                        ref={formObj}>
                        {/* <p
                          className="property-panel-header header-customization"
                          style={{ width: "100%", padding: "22px 0 0 0" }}>
                          Search by specific event fields
                        </p> */}
                        <table
                          id="property-specific"
                          className=" table"
                          style={{ width: "100%" }}>
                          <tbody>
                            <tr className="row">
                              <td
                                className="property-panel-content"
                                colSpan={2}>
                                <input
                                  type="text"
                                  className="e-input search-field"
                                  id="searchEventName"
                                  data-name="Subject"
                                  placeholder="Subject"
                                />
                              </td>
                            </tr>
                            <tr className="row">
                              <td
                                className="property-panel-content"
                                colSpan={2}>
                                <input
                                  type="text"
                                  className="e-input search-field"
                                  id="searchPatientName"
                                  data-name="patientName"
                                  placeholder="patientName"
                                />
                              </td>
                            </tr>
                            <tr className="row" style={{ height: "45px" }}>
                              <td
                                className="property-panel-content"
                                colSpan={2}>
                                <input
                                  type="text"
                                  className="e-input search-field"
                                  id="searchEventLocation"
                                  data-name="Location"
                                  placeholder="Location"
                                />
                              </td>
                            </tr>
                            <tr className="row" style={{ height: "45px" }}>
                              <td
                                className="property-panel-content"
                                colSpan={2}>
                                <DatePickerComponent
                                  className="search-field e-start-time"
                                  value={null}
                                  data-name="StartTime"
                                  showClearButton={false}
                                  placeholder="Start Time"></DatePickerComponent>
                              </td>
                            </tr>
                            <tr className="row" style={{ height: "45px" }}>
                              <td
                                className="property-panel-content"
                                colSpan={2}>
                                <DatePickerComponent
                                  className="search-field e-end-time"
                                  value={null}
                                  data-name="EndTime"
                                  showClearButton={false}
                                  placeholder="End Time"></DatePickerComponent>
                              </td>
                            </tr>
                            <tr className="row" style={{ height: "45px" }}>
                              <td className="e-field button-customization col-auto">
                                <ButtonComponent
                                  title="Search"
                                  type="button"
                                  onClick={searchOnclick}
                                  disabled={searchGridShow}>
                                  Search
                                </ButtonComponent>
                              </td>
                              <td className="e-field button-customization col-auto">
                                <ButtonComponent
                                  title="Clear"
                                  type="button"
                                  onClick={clearOnClick}
                                  disabled={!searchGridShow}>
                                  Clear
                                </ButtonComponent>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </PropertyPane>
                    <div>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => navigate("deleted-events")}>
                        View Deleted Events
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p>Loading...</p>
                </div>
              )}
              <Modal>
                {
                  <div
                    className="card p-3"
                    style={{
                      width: "80vw",
                      height: "80vh",
                    }}>
                    <div className="text-center">
                      <button
                        onClick={modalCloseHandler}
                        className="btn"
                        style={{ position: "absolute", top: 4, left: 4 }}>
                        <CloseIcon style={{ color: "#d20000" }} />
                      </button>
                      <p className="fw-bold fs-5">Event History</p>
                    </div>

                    <EventHistoryModal curEvent={curEvent} />
                  </div>
                }
              </Modal>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Scheduler;
