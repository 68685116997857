import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  sendDoc,
  storeDocResponseLinkInDB,
  deleteDocResponse,
  getProfile,
} from "../../../services/PharmacyUser";
import { decodeToken } from "react-jwt";
import { sendSMS } from "../../../services/Common";
import baseURL from "../../../utils/baseURL";
import {
  searchPtForms as searchUsers,
  searchPt,
} from "../../../services/Provider";

function RecipentsFromDB(props) {
  const pharmacy_id = localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;

  const [selected, setSelected] = useState([]);
  const [recepint, setRecepint] = useState("");
  const [suggistions, setSuggistions] = useState([]);
  const [smssuccess, setSmssuccess] = useState([]);
  const [smsfail, setSmsfail] = useState([]);
  const [columns, setColumns] = useState([]);
  const [logflag, setLogflag] = useState(false);
  const [sfirsname, setSfirsname] = useState([]);
  const [slastname, setSlastname] = useState([]);
  const [smrn, setSmrn] = useState([]);

  useEffect(() => {
    let combinedResults = [...sfirsname, ...slastname, ...smrn];

    let nameToMrnMap = {};
    combinedResults.forEach((element) => {
      let [name, mrn] = element.split("-MRN: ");
      nameToMrnMap[name] = mrn ? `MRN: ${mrn}` : "";
    });

    let uniqueNames = Object.keys(nameToMrnMap);

    let uniqueUsers = uniqueNames.map((name) => {
      let mrn = nameToMrnMap[name];
      return mrn ? `${name}-${mrn}` : name;
    });

    setSuggistions(
      uniqueUsers
        .map((user) => {
          return selected.includes(user) ? null : user;
        })
        .filter((el) => el !== null) || []
    );
  }, [sfirsname, slastname, smrn, selected]);

  useEffect(() => {
    let c = [
      { field: "id", headerName: "ID", width: 80 },
      { field: "first", headerName: "First name", width: 100 },
      { field: "last", headerName: "Last name", width: 100 },
      { field: "phone", headerName: "Phone", width: 100 },
      { field: "status", headerName: "Status", width: 100 },
      { field: "msg", headerName: "Details", width: 500 },
    ];
    setColumns(c);
  }, []);

  const handleSearch = (e) => {
    setRecepint(e.target.value);

    try {
      let fnamesearch = `first=${e.target.value}&last=&MRN=`;

      searchUsers(pharmacy_id, fnamesearch, userId).then((res) => {
        if (res.data?.data?.length > 0) {
          setSfirsname([
            ...sfirsname,
            ...res.data.data.map((el) => {
              return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
            }),
          ]);
        }
      });
      let lnamesearch = `first=&last=${e.target.value}&MRN=`;
      searchUsers(pharmacy_id, lnamesearch, userId).then((res) => {
        if (res.data.length > 0) {
          setSlastname([
            ...slastname,
            ...res.data.data.map((el) => {
              return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
            }),
          ]);
        }
      });

      let mrnsearch = {
        first: "",
        last: "",
        email: "",
        phone: "",
        DOB: "",
        MRN: e.target.value,
      };
      searchPt(mrnsearch, 0, pharmacy_id).then((res) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            setSmrn([
              ...smrn,
              ...res.data.data
                .filter((el) => el.user.id.toString() !== userId)
                .map((el) => {
                  return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
                }),
            ]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const hanleSetRecipents = () => {
    setLogflag(false);
    setSmsfail([]);
    setSmssuccess([]);
    if (recepint !== "") {
      setSelected([...selected, recepint]);
    }
    setRecepint("");
  };

  const heandleSend = () => {
    let smssuccessholder = [];
    let smsfailholder = [];

    selected.forEach((el) => {
      getProfile(el.split("-")[0]).then((resp) => {
        let phone = resp?.data?.data?.user[0]?.phone
          ? resp.data.data.user[0].phone
          : "";
        let first = resp.data.data.user[0].first;
        let last = resp.data.data.user[0].last;
        let recId = resp.data.data.user[0].id;

        if (phone.trim() !== "") {
          let clean = ("" + phone.trim()).replace(/\D/g, "");
          let match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
          const invcodes = [
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "001",
            "011",
            "002",
            "000",
          ];

          if (match) {
            if (invcodes.includes(match[1])) {
              phone = "";
            } else {
              phone = `${match[1]}${match[2]}${match[3]}`;
            }
          }
        }
        sendDoc({
          DocId: props.data.doc.ID.toString(),
          senderId: userId,
          recipientId: recId.toString(),
        })
          .then((re) => {
            let data = {
              date: new Date(),
              completed: "",
              PatientId: el.split("-")[0],
              Statusflag: "pending",
              FormId: props.data.doc.ID,
              Token: re.data.response_id,
              ActionUserId: "",
              pharmacyId: pharmacy_id,
              recipientName: `${first} ${last}`,
            };
            let url = `${baseURL.frontEnd}/appless-docs-p/?fid=${props.data.doc.ID}&rid=${recId}&t=${re.data.response_id}&pr=${userId}`;

            sendSMS({
              to: phone,
              body: `Dear ${first} ${last}, You were sent a form from ${pharmacy_name} Pharmacy. Please take a moment to fill out the form linked below. Your responses will help us provide you with the best possible care. ${url} , Thank you for your cooperation! If you would like to unsubscribe please send STOP`,
            })
              .then(() => {
                smssuccessholder = [
                  ...smssuccessholder,
                  {
                    id: recId,
                    first: first,
                    last: last,
                    msg: `Sent to ${phone}`,
                    status: "success",
                    phone: phone,
                  },
                ];
                setSmssuccess(smssuccessholder);
                storeDocResponseLinkInDB(data.Token, url).catch((err) => {
                  console.error(err);
                });
              })
              .catch((err) => {
                smsfailholder = [
                  ...smsfailholder,
                  {
                    id: recId,
                    first: first,
                    last: last,
                    msg: err?.response?.data?.message || "error sending SMS",
                    status: "failed",
                    phone: phone,
                  },
                ];
                setSmsfail(smsfailholder);
                deleteDocResponse(data.Token).catch((err) => {
                  console.error(err);
                });
              })
              .finally(() => {
                setSelected([]);
                setSfirsname([]);
                setSlastname([]);
                setSmrn([]);
                setSuggistions([]);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    });
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div
            className="card-header fs-5"
            style={{
              textAlign: "center",
              color: "whitesmoke",
              background:
                "linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)",
            }}>
            Search for Recipients
          </div>
          <div className="card-body">
            <div className="input-group  mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Recipient's Name..."
                aria-label="Recipient's username with two button addons"
                list="datalistOptions"
                onChange={handleSearch}
                value={recepint}
              />
              <datalist id="datalistOptions">
                {suggistions.map((item) => {
                  return <option value={item} key={item} />;
                })}
              </datalist>
              <button
                className="btn btn-outline-secondary"
                onClick={hanleSetRecipents}
                type="button">
                Add
              </button>
            </div>
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="Receipts"
              placeHolder="Receipts List..."
            />
            <Button
              variant="contained"
              style={{ float: "right", marginTop: "20px" }}
              onClick={heandleSend}>
              Send Document
            </Button>
          </div>
          <p style={{ textAlign: "center" }}>
            {smssuccess.length > 0 ? <>Succeeded: {smssuccess.length}</> : null}{" "}
            {smsfail.length > 0 ? <> - Failed: {smsfail.length}</> : null}{" "}
            {smsfail.length > 0 || smssuccess.length > 0 ? (
              <>
                <Button
                  onClick={() => {
                    setLogflag(true);
                  }}
                  variant="text">
                  Log
                </Button>{" "}
              </>
            ) : null}
          </p>
        </div>
      </div>
      {logflag ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}>
            {logflag ? (
              <>
                <div className="row mb-4">
                  {smssuccess.length > 0 && (
                    <div
                      className=" col-12 col-lg-6 my-3"
                      style={{ height: 500 }}>
                      <h5 className="text-center">Success Log</h5>
                      <DataGrid
                        rows={smssuccess}
                        columns={columns}
                        pageSize={10}
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                      />
                    </div>
                  )}

                  {smsfail.length > 0 && (
                    <div
                      className=" col-12 col-lg-6 my-3"
                      style={{ height: 500 }}>
                      <h5 className="text-center">Failed Log</h5>
                      <DataGrid
                        rows={smsfail}
                        columns={columns}
                        pageSize={10}
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}

export default RecipentsFromDB;
