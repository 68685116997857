const handleAddressAutoComplete = (addresses) => {
  if (addresses === undefined || addresses === null || addresses === "") {
    return null;
  } else {
    const handleGoogle = (inp) => {
      if (inp === "" || inp === null || inp === undefined) {
        return [];
      } else {
        return new Promise((resolve, reject) => {
          if (!inp) {
            return reject("Need valid text input");
          }

          // for use in things like GatsbyJS where the html is generated first
          if (typeof window === "undefined") {
            return reject("Need valid window object");
          }

          try {
            const center = { lat: 50.064192, lng: -130.605469 };
            // Create a bounding box with sides ~10km away from the center point
            const defaultBounds = {
              north: center.lat + 0.1,
              south: center.lat - 0.1,
              east: center.lng + 0.1,
              west: center.lng - 0.1,
            };

            const options = {
              bounds: defaultBounds,
              componentRestrictions: { country: "us" },
              fields: ["address_components", "geometry", "icon", "name"],
              strictBounds: false,
              types: ["address"],
            };

            return new window.google.maps.places.AutocompleteService().getPlacePredictions(
              { input: inp, options },
              resolve
            );
          } catch (e) {
            reject(e);
          }
        }).then((results) => {
          if (results) {
            return results.map((obj) => {
              let placeId = obj.place_id;
              return new Promise((resolve, reject) => {
                if (!placeId) reject("placeId not provided");
                try {
                  return new window.google.maps.places.PlacesService(
                    document.createElement("div")
                  ).getDetails(
                    {
                      placeId,
                      fields: ["address_components"],
                    },
                    (details) => {
                      if (details) {
                        return resolve(details.address_components);
                      } else {
                        return null;
                      }
                    }
                  );
                } catch (e) {
                  reject(e);
                }
              });
            });
          }
        });
      }
    };

    return handleGoogle(addresses).then((res) => {
      if (res === null || res === undefined) {
        return [];
      } else {
        return Promise.all(res).then((a) => {
          return a.map((i) => {
            let dob = {};
            i.forEach((obj) => {
              if (obj.types[0] === "street_number") {
                dob.streetnum = obj.short_name;
              }
              if (obj.types[0] === "route") {
                dob.streetname = obj.short_name;
              }
              if (obj.types[0] === "postal_code") {
                dob.postal_code = obj.short_name;
              }

              if (
                obj.types[0] === "locality" ||
                obj.types[0] === "sublocality_level_1"
              ) {
                dob.city = obj.short_name;
              }
              if (obj.types[0] === "administrative_area_level_1") {
                dob.state = obj.short_name;
              }
            });
            return {
              street: `${dob.streetnum} ${dob.streetname}`,
              state: dob.state,
              city: dob.city,
              postal: dob.postal_code,
            };
          });
        });
      }
    });
  }
};

export default handleAddressAutoComplete;
