import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { getUserById } from "../services/Common";
import PasswordChangeForm from "../pages/common/PasswordChangeForm";

const getUserInfo = async () => {
  try {
    const token = localStorage.getItem("Token");
    const myDecodedToken = decodeToken(token) || {};

    const resp = await getUserById(myDecodedToken.userId);
    const user = resp?.data?.data?.user[0] || {};

    if (user.id) {
      return {
        userId: user.id,
        isAuthenticated: true,
        role: user.type,
        isUserPassword: user.isUserPassword,
      };
    }
  } catch (error) {
    console.error("Error in getUserInfo:", error);
  }

  return { isAuthenticated: false, role: "" };
};

const ProtectedRoutes = ({ allowedRoles }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserPassword, setIsUserPassword] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserInfo();

        setUserId(userInfo.userId);

        if (!userInfo.isUserPassword) {
          setIsUserPassword(false);
        }

        if (userInfo.isAuthenticated && allowedRoles.includes(userInfo.role)) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigate("/");
        }
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching user info:", error);
        navigate("/");
        setIsLoaded(true);
      }
    };

    fetchData();
  }, [allowedRoles, navigate]);

  if (!isLoaded)
    return (
      <div className="d-flex flex-row justify-content-center mt-5  align-items-center ">
        <div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="ms-4 align-middle text-secondary">Loading</div>
      </div>
    );
  return isAuthenticated && isUserPassword ? (
    <Outlet />
  ) : isAuthenticated && !isUserPassword ? (
    <PasswordChangeForm userId={userId} />
  ) : (
    <div>Not Authorized</div>
  );
};

export default ProtectedRoutes;
