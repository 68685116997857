import React, { useState, useEffect } from "react";
import { ProviderAddRxController } from "../../../../utils/globalState";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchForPatientToAddRx } from "../../../../services/Provider";
import { decodeToken } from "react-jwt";

function SearchForPatient() {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const providerId = myDecodedToken.userId;
  const stateObj = ProviderAddRxController((state) => state.StepperController);
  const [rxController, setRxController] = useState(stateObj);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [fnameCase, setFnameCase] = useState([]);
  const [lnameCase, setLnameCase] = useState([]);
  const [dobCase, setDobCase] = useState([]);
  const [mrnCase, setMrnCase] = useState([]);
  const [suggistions, setSuggistions] = useState([]);
  useEffect(() => {
    setRxController(stateObj);
  }, [stateObj]);

  const handelChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // patients?offset=&limit=&first=&last=&DOB=&email=&phone=&MRN=
    if (newInputValue !== "") {
      searchForPatientToAddRx(
        providerId,
        `patients?offset=&limit=&first=${newInputValue}&last=&DOB=&email=&phone=&MRN=`
      )
        .then((res) => {
          res.data !== "" &&
            res?.data?.data &&
            setFnameCase([
              ...fnameCase,
              ...res.data.data.map((i) => {
                return `${i.user.id} - ${i.user.first} ${i.user.last} - ${i.user.DOB} - ${i.user.MRN}`;
              }),
            ]);
        })
        .catch((err) => {
          console.error(err);
        });
      searchForPatientToAddRx(
        providerId,
        `patients?offset=&limit=&first=&last=${newInputValue}&DOB=&email=&phone=&MRN=`
      )
        .then((res) => {
          res.data !== "" &&
            res?.data?.data &&
            setLnameCase([
              ...lnameCase,
              ...res.data.data.map((i) => {
                return `${i.user.id} - ${i.user.first} ${i.user.last} - ${i.user.DOB} - ${i.user.MRN}`;
              }),
            ]);
        })
        .catch((err) => {
          console.error(err);
        });
      searchForPatientToAddRx(
        providerId,
        `patients?offset=&limit=&first=&last=&DOB=${newInputValue}&email=&phone=&MRN=`
      )
        .then((res) => {
          res.data !== "" &&
            res?.data?.data &&
            setDobCase([
              ...dobCase,
              ...res.data.data.map((i) => {
                return `${i.user.id} - ${i.user.first} ${i.user.last} - ${i.user.DOB} - ${i.user.MRN}`;
              }),
            ]);
        })
        .catch((err) => {
          console.error(err);
        });
      searchForPatientToAddRx(
        providerId,
        `patients?offset=&limit=&first=&last=&DOB=&email=&phone=&MRN=${newInputValue}`
      )
        .then((res) => {
          res.data !== "" &&
            res?.data?.data &&
            setMrnCase([
              ...mrnCase,
              ...res.data.data.map((i) => {
                return `${i.user.id} - ${i.user.first} ${i.user.last} - ${i.user.DOB} - ${i.user.MRN}`;
              }),
            ]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  useEffect(() => {
    let newHolder = [...fnameCase, ...lnameCase, ...dobCase, ...mrnCase];

    let uniqueusers = newHolder.filter((element, index) => {
      return newHolder.indexOf(element) === index;
    });
    setSuggistions(uniqueusers);
  }, [fnameCase, lnameCase, dobCase, mrnCase]);

  const handelSelect = (event, newValue) => {
    setValue(newValue);
    if (newValue !== null) {
      let patientId = newValue.split(" - ")[0];
      let patientName = newValue.split(" - ")[1];

      ProviderAddRxController.setState({
        StepperController: {
          ...rxController,
          rx: { ...rxController.rx, patientId: patientId },
          info: { ...rxController.info, patient_name: patientName },
          completeflag: {
            ...rxController.completeflag,
            "Select Patient": true,
          },
        },
      });
      setRxController(ProviderAddRxController.getState().StepperController);
    }
  };

  return (
    <div className="container-fluid">
      <div className="card p-3">
        <Autocomplete
          value={value}
          onChange={handelSelect}
          inputValue={inputValue}
          onInputChange={handelChange}
          id="controllable-states-demo"
          options={suggistions}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="SELECT A PATIENT..." />
          )}
        />
      </div>
    </div>
  );
}

export default SearchForPatient;
