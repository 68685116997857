import React from "react";
import Login from "../../components/common/landing/Login";
import Doctor from "../../components/common/landing/Doctor";
import ContactUs from "../../components/common/landing/ContactUs";

const LandingPage = () => {
  return (
    <div>
      {/* <Test/> */}
      <Login />
      <Doctor />
      <ContactUs />
    </div>
  );
};

export default LandingPage;
