import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

function ReviewResponse(props) {
  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };

  const [json, setJson] = useState({});
  useEffect(() => {
    setJson(handleObjectRewrite(props.data));
  }, [props.data]);

  const survey = new Model(json);
  survey.mode = "display";

  return (
    <>
      <Survey model={survey} />
    </>
  );
}

export default ReviewResponse;
